/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  SimpleModal,
  Button,
  FormChunk,
  CheckBox,
  TextEditor,
} from '../../../components';
import { updateHouseholdInfo, deleteHousehold } from '../../../store/actions';
import {
  FlexCenterAll,
  FlexCenterStart,
  PageContent,
} from '../../../styles/library/layoutStyles';
import {
  EditorContainer,
  InputLabel,
  TooltipIcon,
} from '../../../styles/library/inputStyles';
import {
  ButtonLink,
  ButtonLinkExternal,
} from '../../../styles/library/fontStyles';
import { ButtonThemes } from '../../../styles/themes';
import {
  colors,
  inputColors,
  buttonColors,
  fonts,
} from '../../../styles/variables';
import {
  ErrorNotification,
  GetCountries,
  GetStates,
  GetCurrencies,
  SetDefaultItem,
  states,
  SendTrackingEvent,
} from '../../../utils';
import { Tooltip } from 'react-tippy';
import { fileExport, hint, trashDark } from '../../../assets';
import { ToolbarContainer } from '../../../styles/library/sidebarStyles';
import { ButtonsRowContainer } from '../../../styles/library/modalStyles';

const HouseholdDetails = ({ householdId, isConsumer, isSidebar = false }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    currentHousehold,
    updateHouseholdError,
    countries,
    currencies,
    cantDeleteHousehold,
    canExportHousehold,
    deletedHousehold,
    updatedHouseholdInfo,
    invocationId,
    canSetHouseholdToPrivate,
  } = useSelector((state) => ({
    currentHousehold: state.households.currentHousehold,
    updateHouseholdError: state.households.updateHouseholdError,
    countries: state.configs.countries,
    currencies: state.configs.currencies,
    cantDeleteHousehold: state.user.cantDeleteHousehold,
    canExportHousehold: state.user.canExportHousehold,
    deletedHousehold: state.households.deletedHousehold,
    updatedHouseholdInfo: state.households.updatedHouseholdInfo,
    invocationId: state.configs.invocationId,
    canSetHouseholdToPrivate: state.user.canSetHouseholdToPrivate,
  }));
  const [householdInfo, setHouseholdInfo] = useState({
    status: 'client',
    is_private: false,
    name: '',
    address: '',
    address2: '',
    city: '',
    state: '',
    zip: '',
    country: '',
    currency: '',
    phone_number: '',
  });
  const [selectedCountry, setSelectedCountry] = useState();
  const [selectedState, setSelectedState] = useState();
  const [stateDisabled, setStateDisabled] = useState(false);
  const [selectedCurrency, setSelectedCurrency] = useState();
  const [householdDescription, setHouseholdDescription] = useState('');
  const [isSavingHousehold, setIsSavingHousehold] = useState(false);
  const [isDeletingHousehold, setIsDeletingHousehold] = useState(false);
  const [errorFields, setErrorFields] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  //LIST OF HOUSEHOLD TYPE OPTIONS
  const householdTypes = [
    { name: 'Client', value: 'client' },
    { name: 'Prospective', value: 'prospect' },
    { name: 'Sample', value: 'sample' },
    { name: 'Stencil', value: 'stencil' },
  ];

  //HOUSEHOLD INPUTS USED FOR FORM CHUNK
  const householdInputs = [
    {
      name: 'name',
      placeholder: 'Household Name',
      type: 'text',
      width: '100%',
      required: true,
      lastpassIgnore: true,
    },
    {
      name: 'address',
      placeholder: 'Address',
      type: 'text',
      width: isSidebar ? '100%' : '50%',
      required: false,
      lastpassIgnore: true,
    },
    {
      name: 'address2',
      placeholder: 'Address Additional',
      type: 'text',
      width: isSidebar ? '100%' : '50%',
      required: false,
      lastpassIgnore: true,
    },
    {
      name: 'city',
      placeholder: 'City',
      type: 'text',
      width: isSidebar ? '100%' : '50%',
      required: false,
      lastpassIgnore: true,
      margin: isSidebar ? '0 0 10px 0px' : '0 8px 10px 0px',
    },
    {
      name: 'state',
      placeholder: 'State',
      type: 'select',
      width: isSidebar ? 'calc(60% - 8px)' : 'calc(30% - 8px)',
      selectedOption: selectedState,
      onChange: (e) => setSelectedState(e),
      options: GetStates(states),
      required: false,
      isDisabled: stateDisabled,
      margin: isSidebar ? '0 8px 10px 8px' : '0 8px 10px 0px',
    },
    {
      name: 'zip',
      placeholder: 'Zip',
      type: 'text',
      width: isSidebar ? 'calc(40% - 8px)' : 'calc(20% - 8px)',
      required: false,
      lastpassIgnore: true,
    },
    {
      name: 'country',
      placeholder: 'Country',
      type: 'select',
      width: isSidebar ? '100%' : 'calc(50% - 16px)',
      selectedOption: selectedCountry,
      onChange: (e) => setSelectedCountry(e),
      options: GetCountries(countries),
      required: true,
      margin: '0 8px 10px 8px',
    },
    {
      name: 'currency',
      placeholder: 'Currency',
      type: 'select',
      width: isSidebar ? '100%' : 'calc(50% - 16px)',
      selectedOption: selectedCurrency,
      onChange: (e) => setSelectedCurrency(e),
      options: GetCurrencies(currencies),
      required: true,
      margin: '0 8px 10px 8px',
    },
    {
      name: 'phone_number',
      placeholder: 'Phone',
      type: 'text',
      width: isSidebar ? '100%' : '50%',
      required: false,
      lastpassIgnore: true,
    },
  ];

  //HOOK THAT SETS HOUSEHOLD INFO FROM API VALUES
  useEffect(() => {
    if (currentHousehold && currentHousehold.hasOwnProperty('name')) {
      setHouseholdInfo({
        ...householdInfo,
        status: currentHousehold.status,
        is_private: currentHousehold.is_private,
        name: currentHousehold.name,
        address: currentHousehold.address,
        address2: currentHousehold.address2,
        city: currentHousehold.city,
        state: SetDefaultItem(states, currentHousehold.state, setSelectedState),
        zip: currentHousehold.zip,
        country: SetDefaultItem(
          countries,
          currentHousehold.country,
          setSelectedCountry
        ),
        currency: SetDefaultItem(
          currencies,
          currentHousehold.currency,
          setSelectedCurrency
        ),
        phone_number: currentHousehold.phone_number,
      });
      setHouseholdDescription(currentHousehold.description);
      setIsSavingHousehold(false);
    }
  }, [currentHousehold, countries, states]);

  //HOOK THAT DISABLES STATE SELECT IF NOT USA OR SELECTS DEFAULT STATE
  useEffect(() => {
    if (selectedCountry && selectedCountry.value !== 'US') {
      setSelectedState(null);
      setStateDisabled(true);
    } else {
      SetDefaultItem(states, currentHousehold.state, setSelectedState);
      setStateDisabled(false);
    }
  }, [selectedCountry, currentHousehold.state]);

  //HOOK TO SET ERROR FIELDS AND MESSAGES FOR INPUTS IF SAVE HOUSEHOLD ERROR
  useEffect(() => {
    if (updateHouseholdError) {
      if (updateHouseholdError.hasOwnProperty('data')) {
        if (updateHouseholdError.data?.status && isSavingHousehold) {
          ErrorNotification(updateHouseholdError.data.status);
        }
        setIsSavingHousehold(false);
        let errorValues = [];
        for (const [key, value] of Object.entries(updateHouseholdError.data)) {
          errorValues = [...errorValues, { field: key, message: value }];
        }
        setErrorFields(errorValues);
      }
    } else {
      setErrorFields([]);
    }
  }, [updateHouseholdError]);

  //HOOK TO SEND TRACKING EVENT AFTER SUCCESSFUL HOUSEHOLD UPDATE
  useEffect(() => {
    if (updatedHouseholdInfo) {
      SendTrackingEvent(
        invocationId,
        'action',
        'household_update',
        'household_details',
        { household: householdId }
      );
    }
  }, [updatedHouseholdInfo]);

  //HOOK TO SEND TRACKING AND REDIRECT USER TO HHI AFTER DELETING HOUSEHOLD
  useEffect(() => {
    if (deletedHousehold) {
      setIsDeletingHousehold(false);
      navigate(`/member/households`);
      SendTrackingEvent(
        invocationId,
        'action',
        'household_delete',
        'household_details',
        { household: householdId }
      );
    }
  }, [deletedHousehold]);

  //FUNCTION TO MAKE API CALL UPDATE HOUSEHOLD DETAILS
  const saveHouseholdInfo = () => {
    let householdObj = { ...householdInfo };
    if (selectedState) {
      householdObj.state = selectedState.value;
    }
    if (selectedCountry) {
      householdObj.country = selectedCountry.value;
    } else {
      householdObj.country = 'US';
    }
    if (selectedCurrency) {
      householdObj.currency = selectedCurrency.value;
    } else {
      householdObj.currency = 'USD';
    }
    if (householdDescription) {
      householdObj.description = householdDescription;
    }
    if (householdObj.name === '') {
      setErrorFields([
        { field: 'name', message: 'Household name is required' },
      ]);
      return;
    }
    setErrorFields([]);
    setIsSavingHousehold(true);
    dispatch(updateHouseholdInfo(householdId, householdObj, isConsumer));
  };

  //FUNCTION THAT UPDATES LOCAL HOUSEHOLD INFO OBJECT WHEN USER CHANGES INPUTS
  const updateHouseholdInputs = (e, fieldName, value) => {
    if (fieldName) {
      setHouseholdInfo({
        ...householdInfo,
        [fieldName]: value,
      });
    } else {
      setHouseholdInfo({
        ...householdInfo,
        [e.currentTarget.name]: e.currentTarget.value,
      });
    }
  };

  //DATA OBJECT NEEDED TO PASS INTO FORM CHUNK
  const householdInfoForm = {
    type: 'noHeader',
    inputs: householdInputs,
    value: householdInfo,
    onChange: updateHouseholdInputs,
    hasErrorDisplay: true,
    borderBottom: false,
  };

  //HELP FUNCTION TO DETERMINE IF HOUSEHOLD STATUS OPTION IS SELECTED
  const isActive = (value) => {
    return householdInfo.status === value;
  };

  //DELETE MODAL OBJECT FOR SIMPLE MODAL
  const deleteModalContent = {
    heading: `Delete ${currentHousehold.name}?`,
    text: `<p>Are you sure you want to delete <strong>${currentHousehold.name}</strong>?</p></br/>This action cannot be undone. <br/><br/><p><strong>Note:</strong> If you are not the Lead Advisor, your association with the Household will be deleted, but the Household will remain.</p>`,
    minWidth: '400px',
    buttons: [
      {
        text: 'Cancel',
        function: () => setShowDeleteModal(false),
        theme: ButtonThemes.cancel,
      },
      {
        text: 'Yes, delete',
        function: () => {
          setIsDeletingHousehold(true);
          dispatch(deleteHousehold(householdId, isConsumer));
        },
        showLoading: isDeletingHousehold,
        loadingText: 'Deleting',
        theme: ButtonThemes.error,
      },
    ],
  };

  return (
    <>
      {showDeleteModal && (
        <SimpleModal
          hide={() => setShowDeleteModal(false)}
          content={deleteModalContent}
        />
      )}
      {isSidebar ? (
        <SidebarContent>
          <HouseholdInfoFormContainer $padding="20px 15px">
            <StatusOptionsContainer>
              <InputLabel>Status</InputLabel>
              <StatusContainer>
                {householdTypes.map((type, index) => {
                  return (
                    <StatusOption
                      key={index}
                      onClick={() =>
                        updateHouseholdInputs(null, 'status', type.value)
                      }
                      $padding={'5px 10px'}
                      $fontSize={'11px'}
                      data-active={isActive(type.value)}
                      style={
                        isActive(type.value)
                          ? {
                              color: colors.white,
                              background: colors.blue,
                              border: `1px solid ${colors.blue}`,
                            }
                          : null
                      }
                    >
                      {type.name}
                    </StatusOption>
                  );
                })}
              </StatusContainer>
            </StatusOptionsContainer>
            {canSetHouseholdToPrivate && (
              <CheckBoxContainerRow>
                <CheckBox
                  label={'Private'}
                  checked={householdInfo.is_private}
                  onChange={() =>
                    updateHouseholdInputs(
                      null,
                      'is_private',
                      !householdInfo.is_private
                    )
                  }
                  styling={{ fontSize: '14px' }}
                />
                <Tooltip
                  title={
                    'Marking this household private will hide it from search and limit access to only the lead advisor and sub-advisors'
                  }
                  position="top"
                  trigger="mouseenter click"
                  followCursor="true"
                  delay={0}
                  arrow={false}
                  arrowSize={'small'}
                  animation={'fade'}
                  animateFill={false}
                  transitionFlip={false}
                  size={'small'}
                >
                  <TooltipIcon
                    src={hint}
                    alt="hint"
                    data-image={`tooltip-hint-private-hh`}
                  />
                </Tooltip>
              </CheckBoxContainerRow>
            )}
            <FormChunk
              type={householdInfoForm.type}
              inputs={householdInfoForm.inputs}
              value={householdInfoForm.value}
              onChange={householdInfoForm.onChange}
              borderBottom={householdInfoForm.borderBottom}
              errorFields={errorFields}
              hasErrorDisplay={householdInfoForm.hasErrorDisplay}
              errorMessages={householdInfoForm.errorMessages}
            />
          </HouseholdInfoFormContainer>
          <ToolbarContainer>
            <ButtonsRowContainer $padding="0">
              <LeftButtons>
                {cantDeleteHousehold || (
                  <ButtonIcon onClick={() => setShowDeleteModal(true)}>
                    <img src={trashDark} alt="delete" />
                  </ButtonIcon>
                )}
                {canExportHousehold && (
                  <ButtonIconExternal
                    href={`/api/v3/household/${householdId}/export`}
                    rel="noopener noreferrer"
                  >
                    <img src={fileExport} alt="export" />
                  </ButtonIconExternal>
                )}
              </LeftButtons>
              <RightButtons>
                <Button
                  text={'Save'}
                  onClick={() => saveHouseholdInfo()}
                  loadingText="Saving"
                  showLoading={isSavingHousehold}
                />
              </RightButtons>
            </ButtonsRowContainer>
          </ToolbarContainer>
        </SidebarContent>
      ) : (
        <PageContent>
          <HouseholdInfoFormContainer>
            <LeftContent>
              {!isConsumer && (
                <TopContent>
                  <StatusOptionsContainer>
                    <InputLabel>Status</InputLabel>
                    <StatusContainer>
                      {householdTypes.map((type, index) => {
                        return (
                          <StatusOption
                            key={index}
                            onClick={() =>
                              updateHouseholdInputs(null, 'status', type.value)
                            }
                            data-active={isActive(type.value)}
                            style={
                              isActive(type.value)
                                ? {
                                    color: colors.white,
                                    background: colors.blue,
                                    border: `1px solid ${colors.blue}`,
                                  }
                                : null
                            }
                          >
                            {type.name}
                          </StatusOption>
                        );
                      })}
                    </StatusContainer>
                  </StatusOptionsContainer>
                  {canSetHouseholdToPrivate && (
                    <CheckBoxContainer
                      title={
                        'Marking this household private will hide it from search and limit access to only the lead advisor and sub-advisors'
                      }
                    >
                      <CheckBox
                        label={'Private'}
                        checked={householdInfo.is_private}
                        onChange={() =>
                          updateHouseholdInputs(
                            null,
                            'is_private',
                            !householdInfo.is_private
                          )
                        }
                        styling={{ fontSize: '14px' }}
                      />
                      <Tooltip
                        title={
                          'Marking this household private will hide it from search and limit access to only the lead advisor and sub-advisors'
                        }
                        position="top"
                        trigger="mouseenter click"
                        followCursor="true"
                        delay={0}
                        arrow={true}
                        arrowSize={'small'}
                        animation={'fade'}
                        animateFill={false}
                        transitionFlip={false}
                        size={'small'}
                      >
                        <TooltipIcon
                          src={hint}
                          alt="hint"
                          data-image={`tooltip-hint-private-hh`}
                        />
                      </Tooltip>
                    </CheckBoxContainer>
                  )}
                </TopContent>
              )}
              <FormChunk
                type={householdInfoForm.type}
                inputs={householdInfoForm.inputs}
                value={householdInfoForm.value}
                onChange={householdInfoForm.onChange}
                borderBottom={householdInfoForm.borderBottom}
                errorFields={errorFields}
                hasErrorDisplay={householdInfoForm.hasErrorDisplay}
                errorMessages={householdInfoForm.errorMessages}
              />
            </LeftContent>
            <RightContent>
              <TextEditor
                label="Description"
                id="description"
                showClear={true}
                value={householdDescription}
                onChange={(e) => setHouseholdDescription(e)}
              />
            </RightContent>
          </HouseholdInfoFormContainer>
          <ButtonsRow>
            <LeftButtons $marginRight="10px">
              {cantDeleteHousehold || (
                <Button
                  text={'Delete Household'}
                  theme={ButtonThemes.error}
                  onClick={() => setShowDeleteModal(true)}
                />
              )}
              {canExportHousehold && (
                <ButtonLinkExternal
                  href={`/api/v3/household/${householdId}/export`}
                  rel="noopener noreferrer"
                >
                  Export Household
                </ButtonLinkExternal>
              )}
            </LeftButtons>
            <RightButtons>
              <ButtonLink to={`/household/${householdId}/summary`}>
                Back to Summary
              </ButtonLink>
              <Button
                text={'Save Household'}
                onClick={() => saveHouseholdInfo()}
                loadingText="Saving"
                showLoading={isSavingHousehold}
              />
            </RightButtons>
          </ButtonsRow>
        </PageContent>
      )}
    </>
  );
};

const ButtonIcon = styled.button`
  height: 35px;
  width: 35px;
  border-radius: 50px;
  ${FlexCenterAll};
  cursor: pointer;
  img {
    width: 16px;
    height: 16px;
  }
  &:hover {
    transform: scale(1.01);
    background: ${colors.lightGrey};
  }
`;

const ButtonIconExternal = styled(ButtonIcon).attrs({ as: 'a' })`
  img {
    margin-left: 2px;
  }
`;

const SidebarContent = styled.div``;

const HouseholdInfoFormContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  align-items: flex-start;
  justify-content: flex-start;
  padding: ${(props) => (props.$padding ? props.$padding : null)};
`;

const LeftContent = styled.div`
  flex: 1 1 600px;
`;

const TopContent = styled.div`
  display: flex;
  align-content: center;
  align-items: flex-end;
  justify-content: flex-start;
  margin-bottom: 15px;
`;

const StatusOptionsContainer = styled.div``;

const StatusContainer = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  flex: 0 0 auto;
`;

const StatusOption = styled.div`
  flex: 1 1 auto;
  padding: ${(props) => (props.$padding ? props.$padding : '8px 15px')};
  font-size: ${(props) => (props.$fontSize ? props.$fontSize : '13px')};
  background: white;
  border: 1px solid ${inputColors.border};
  border-radius: 3px;
  margin-right: 8px;
  cursor: pointer;
  font-weight: ${fonts.semiBold};
  &:hover {
    transform: scale(1.01);
  }
`;

const CheckBoxContainerRow = styled.div`
  ${FlexCenterStart};
  margin: 10px 0 10px -4px;
  font-size: 12px !important;
  position: relative;
  span {
    font-size: 12px;
  }
`;

const CheckBoxContainer = styled.div`
  flex: 1 1 auto;
  text-align: right;
`;

const RightContent = styled.div`
  @media (max-width: 1078px) {
    margin-left: 0px;
    flex: 1 1 340px;
  }
  flex: 1 1 300px;
  margin-left: 40px;
  ${EditorContainer} {
    margin-top: 3px;
  }
`;

const ButtonsRow = styled.div`
  margin: ${(props) => (props.$margin ? props.$margin : '10px 0 0 0')};
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap-reverse;
`;

const LeftButtons = styled.div`
  flex: 1 1 auto;
  display: flex;
  button {
    margin-right: ${(props) => (props.$marginRight ? props.$marginRight : '0')};
  }
`;

const RightButtons = styled.div`
  flex: 1 1 auto;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-end;
  ${ButtonLink} {
    margin-right: 10px;
    background: ${buttonColors.inactive};
  }
`;

HouseholdDetails.propTypes = {
  householdId: PropTypes.string,
  isConsumer: PropTypes.bool,
  isSidebar: PropTypes.bool,
};

export default HouseholdDetails;
