/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { LoadingAnimation } from '..';
import {
  FlexCenterStart,
  LoadingContainer,
} from '../../styles/library/layoutStyles';
import { cancel, chevronDownDark, plusCircleDark } from '../../assets';
import {
  CountLabel,
  ExpandableItem,
  ExpandedDetails,
  ExpandHeadingContainer,
  ExpandHeadingLabel,
  ExpandIcon,
  LabelText,
} from '../../styles/library/sidebarStyles';
import {
  applyStencil,
  clearStencil,
  getStencilLibraryOptions,
  // getStencilMembers,
  getStencilOptions,
  updateHouseholdStoreValue,
} from '../../store/actions';
import {
  // HtmlToString,
  SendTrackingEvent,
} from '../../utils';
import { colors, fonts, messageColors } from '../../styles/variables';

const StencilsSidebar = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const householdId = params?.id;
  const {
    householdStencils,
    // applyingStencil,
    // stencilAmrImage,
    // refreshedImage,
    // householdStencilMembers,
    // householdmembers,
    user,
    showStencilLibrary,
    hasAssetmapPlusStencilLibrary,
    hasCustomerStencilLibrary,
    // clonedStencil,
    // applyStencilError,
    invocationId,
  } = useSelector((state) => ({
    householdStencils: state.households.householdStencils,
    // applyingStencil: state.households.applyingStencil,
    // stencilAmrImage: state.households.stencilAmrImage,
    // refreshedImage: state.households.refreshedImage,
    // householdStencilMembers: state.households.stencilMembers,
    // householdmembers: state.households.currentHousehold.householdmembers,
    user: state.user.user,
    showStencilLibrary: state.user.showStencilLibrary,
    hasAssetmapPlusStencilLibrary: state.user.hasAssetmapPlusStencilLibrary,
    hasCustomerStencilLibrary: state.user.hasCustomerStencilLibrary,
    // clonedStencil: state.households.clonedStencil,
    // applyStencilError: state.households.applyStencilError,
    invocationId: state.configs.invocationId,
  }));
  const [loadingStencils, setLoadingStencils] = useState(true);
  const [stencilTypes, setStencilTypes] = useState([
    {
      type: 'personal',
      label: 'My Stencils',
      visible: true,
      count: 0,
      isExpanded: false,
      children: [],
    },
    {
      type: 'assetmap',
      label: 'Public Library',
      visible: true,
      count: 0,
      isExpanded: false,
      children: [],
    },
    {
      type: 'assetmap_plus',
      label: 'Asset-Map+',
      visible: false,
      count: 0,
      isExpanded: false,
      children: [],
    },
    {
      type: 'shared',
      label: 'Shared',
      visible: false,
      count: 0,
      isExpanded: false,
      children: [],
    },
  ]);
  const [activeStencilType, setActiveStencilType] = useState();
  const [activeStencil, setActiveStencil] = useState();
  // const [applyEntireHousehold, setApplyEntireHousehold] = useState(true);
  // const [householdMembers, setHouseholdMembers] = useState([]);
  // const [selectedHouseholdMember, setSelectedHouseholdMember] = useState();
  // const [stencilMembers, setStencilMembers] = useState([]);
  // const [selectedStencilMember, setSelectedStencilMember] = useState();

  useEffect(() => {
    setTimeout(() => {
      setLoadingStencils(false);
    }, 500);
    return () => {
      dispatch(updateHouseholdStoreValue('stencilAmrImage', null));
    };
  }, []);

  useEffect(() => {
    if (stencilTypes) {
      const activeStencilType = stencilTypes.find(
        (stencil) => stencil.isExpanded
      );
      setActiveStencilType(activeStencilType?.type);
    }
  }, [stencilTypes]);

  useEffect(() => {
    if (user) {
      if (showStencilLibrary) {
        const updatedTabs = stencilTypes.map((tab) => {
          if (tab.type === 'assetmap_plus') {
            tab.visible = hasAssetmapPlusStencilLibrary;
          } else if (tab.type === 'shared') {
            tab.visible = hasCustomerStencilLibrary;
          }
          return tab;
        });
        setStencilTypes(updatedTabs);
      } else {
        dispatch(getStencilOptions());
      }
    }
  }, [user, showStencilLibrary]);

  useEffect(() => {
    if (activeStencilType && showStencilLibrary) {
      // setApplyEntireHousehold(true);
      dispatch(getStencilLibraryOptions(activeStencilType));
      // setStencilError(false);
      SendTrackingEvent(
        invocationId,
        'click',
        'stencil_library_tab',
        'household_amr',
        {
          stencil_library_tab: activeStencilType,
        }
      );
    }
  }, [activeStencilType, showStencilLibrary]);

  useEffect(() => {
    if (householdStencils) {
      const updatedTypes = stencilTypes.map((stencil) => {
        if (stencil.type === activeStencilType) {
          stencil.count = householdStencils.length;
          stencil.children = householdStencils;
        }
        return stencil;
      });
      setStencilTypes(updatedTypes);
    }
  }, [householdStencils]);

  // useEffect(() => {
  //   if (stencilAmrImage?.url) {
  //     viewStencil.image = {
  //       url: stencilAmrImage.url,
  //       fresh: stencilAmrImage.fresh,
  //       is_error: stencilAmrImage.is_error,
  //       refresh: stencilAmrImage.refresh,
  //       reason: stencilAmrImage.reason,
  //       generated_utc: stencilAmrImage.generated_utc,
  //       is_default: stencilAmrImage?.default || false,
  //     };
  //     dispatch(
  //       toggleShowStencilImagePreviewModal(true, {
  //         ...viewStencil,
  //       })
  //     );
  //   }
  // }, [stencilAmrImage?.url]);

  // useEffect(() => {
  //   if (applyStencilError) {
  //     setStencilError(applyStencilError);
  //     setButtonLoading(false);
  //     dispatch(updateHouseholdStoreValue('applyStencilError', null));
  //   }
  // }, [applyStencilError]);

  // useEffect(() => {
  //   if (clonedStencil) {
  //     setActiveTab('personal');
  //     dispatch(updateHouseholdStoreValue('clonedStencil', false));
  //   }
  // }, [clonedStencil]);

  // useEffect(() => {
  //   if (householdmembers) {
  //     setHouseholdMembers(
  //       householdmembers.map((member) => {
  //         member.label = HtmlToString(member.name);
  //         member.value = member.id;
  //         return member;
  //       })
  //     );
  //   }
  // }, [householdmembers]);

  // useEffect(() => {
  //   if (selectedStencil && selectedStencil.hasOwnProperty('value')) {
  //     dispatch(getStencilMembers(selectedStencil.value));
  //     setApplyEntireHousehold(true);
  //     // setStencilError('');
  //   }
  // }, [selectedStencil]);

  // useEffect(() => {
  //   if (householdStencilMembers) {
  //     setStencilMembers(
  //       householdStencilMembers.map((member) => {
  //         member.label = HtmlToString(member.name);
  //         member.value = member.id;
  //         return member;
  //       })
  //     );
  //   }
  // }, [householdStencilMembers]);

  // useEffect(() => {
  //   if (!applyEntireHousehold) {
  //     setSelectedStencilMember(stencilMembers[0]);
  //     const firstMember = householdMembers[0];
  //     if (firstMember && firstMember?.name && firstMember?.id) {
  //       setSelectedHouseholdMember({
  //         label: firstMember.name,
  //         value: firstMember.id,
  //       });
  //     }
  //   }
  // }, [applyEntireHousehold]);

  const applyStencilFunction = (stencil) => {
    // if (!applyingStencil) {
    if (stencil) {
      if (activeStencil === stencil.id) {
        dispatch(clearStencil(householdId));
        setActiveStencil();
      } else {
        const data = {
          stencil: stencil.id,
          source: '',
          target: '',
        };

        // if (!applyEntireHousehold) {
        //   if (selectedStencilMember) {
        //     data.source = selectedStencilMember.value;
        //   }
        //   if (selectedHouseholdMember) {
        //     data.target = selectedHouseholdMember.value;
        //   }
        // }
        setActiveStencil(stencil.id);
        dispatch(applyStencil(householdId, data));
      }
    }
    // }
  };

  // const cloneStencil = (stencil) => {
  //   dispatch(toggleShowCloneStencilModal(true, stencil));
  // };

  // const viewStencilImage = (stencil) => {
  //   if (stencil?.image?.fresh || stencil?.image?.reason === 'disabled') {
  //     dispatch(
  //       toggleShowStencilImagePreviewModal(true, {
  //         ...stencil,
  //       })
  //     );
  //   } else {
  //     dispatch(
  //       toggleShowStencilImagePreviewModal(true, {
  //         ...stencil,
  //         imageLoading: true,
  //       })
  //     );
  //     // refresh the image
  //     dispatch(getStencilAssetMapImage(stencil?.id, true));
  //   }
  //   setViewStencil(stencil);
  // };

  const onExpandStencil = (type) => {
    setStencilTypes(
      stencilTypes.map((stencil) => {
        if (stencil.type === type) {
          stencil.isExpanded = !stencil.isExpanded;
        } else {
          stencil.isExpanded = false;
        }
        return stencil;
      })
    );
  };

  return (
    <>
      {loadingStencils ? (
        <LoadingContainer>
          <LoadingAnimation />
        </LoadingContainer>
      ) : (
        <>
          {stencilTypes.map((stencil) => {
            return (
              stencil.visible && (
                <ExpandableItem key={stencil.type}>
                  <ExpandHeadingContainer
                    onClick={() => onExpandStencil(stencil.type)}
                    $isExpanded={stencil.isExpanded}
                  >
                    <ExpandHeadingLabel>
                      <LabelText>{stencil.label}</LabelText>
                    </ExpandHeadingLabel>
                    <CountLabel>{stencil.count}</CountLabel>
                    <ExpandIcon
                      // onClick={() => onExpandStencil(signal.key)}
                      $isExpanded={stencil.isExpanded}
                    >
                      <img src={chevronDownDark} alt="down" />
                    </ExpandIcon>
                  </ExpandHeadingContainer>
                  {stencil.isExpanded && (
                    <ExpandedDetails $padding="0">
                      {stencil.children.map((option, index) => {
                        return (
                          <StencilDetailsContainer
                            key={option.id}
                            $last={stencil.children.length - 1 === index}
                            $active={activeStencil === option.id}
                          >
                            <LeftDetails>
                              <SelectName>{option.name}</SelectName>
                              <StencilDescription
                                dangerouslySetInnerHTML={{
                                  __html: option.description,
                                }}
                              />
                            </LeftDetails>
                            <ExpandIcon
                              onClick={() => applyStencilFunction(option)}
                            >
                              <img
                                src={
                                  activeStencil === option.id
                                    ? cancel
                                    : plusCircleDark
                                }
                                alt="add"
                              />
                            </ExpandIcon>
                          </StencilDetailsContainer>
                        );
                      })}
                    </ExpandedDetails>
                  )}
                </ExpandableItem>
              )
            );
          })}
        </>
      )}
    </>
  );
};

const StencilDetailsContainer = styled.div`
  border-bottom: ${(props) =>
    props.$last ? null : `1px solid ${colors.lightGrey}`};
  background: ${(props) => props.$active && messageColors.successLabelBg};
  padding ${(props) => (props.$last ? '15px 20px' : '15px 20px')};
  min-height: 24px;
  ${FlexCenterStart};
`;

const LeftDetails = styled.div`
  flex: 1 1 auto;
`;

const SelectName = styled.p`
  font-size: 14px;
  font-weight: ${fonts.semiBold};
`;

const StencilDescription = styled.p`
  font-size: 11px;
  color: ${colors.paleGrey};
`;

export default StencilsSidebar;
