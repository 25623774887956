/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { ChromePicker } from 'react-color';
import { Tooltip } from 'react-tippy';
import PropTypes from 'prop-types';
import Countdown from 'react-countdown';
import { TextInput, Button, Select, Toggle, TextEditor } from '../';
import { errorCircleRed, hint } from '../../assets';
import * as styles from '../../styles/variables';
import { FlexCenterStart } from '../../styles/library/layoutStyles';
import { DateFormat, DateFormatUTC, UseOutsideClick } from '../../utils';

const FormChunk = ({
  type = 'form',
  header,
  headers,
  formText,
  formTextLink,
  inputs,
  value,
  onChange,
  onClick,
  button,
  link,
  borderBottom = true,
  style,
  extraData,
  checkFunction,
  errorFields,
  showRecurly,
  hasErrorDisplay = false,
  errorMessages = [],
}) => {
  const pickerRef = useRef();
  const [showColorPicker, setShowColorPicker] = useState(false);

  UseOutsideClick(pickerRef, () => {
    if (showColorPicker) {
      setShowColorPicker(false);
    }
  });

  const hasError = (field) => {
    if (errorFields && errorFields.length >= 1) {
      let updatedErrorFields = errorFields.map((error) => {
        return error.field;
      });
      return updatedErrorFields.includes(field.name);
    } else {
      return false;
    }
  };
  const createErrorMessage = (field) => {
    const matchingError = errorFields.find((error) => error.field === field);
    if (matchingError) {
      return matchingError?.message || matchingError?.messages;
    }
  };

  const generateInputLabel = (input) => {
    return (
      <InputLabel htmlFor={input.id}>
        <span>{input.label ? input.label : input.placeholder}</span>
        {input.required && <RequiredStar>*</RequiredStar>}
        {hasError(input) && (
          <Tooltip
            title={createErrorMessage(input.name)}
            position="top"
            trigger="mouseenter click"
            followCursor="true"
            delay={0}
            arrow={true}
            arrowSize={'small'}
            animation={'fade'}
            animateFill={false}
            transitionFlip={false}
            size={'small'}
          >
            <TooltipIconError
              src={errorCircleRed}
              alt="error"
              data-image={`tooltip-error-${input.name}`}
            />
          </Tooltip>
        )}
        {input.tooltip && !hasError(input) && (
          <Tooltip
            title={input.tooltip}
            position="top"
            trigger="mouseenter click"
            followCursor="true"
            delay={0}
            arrow={true}
            arrowSize={'small'}
            animation={'fade'}
            animateFill={false}
            transitionFlip={false}
            size={'small'}
          >
            <TooltipIcon
              src={hint}
              alt="hint"
              data-image={`tooltip-hint-${input.name}`}
            />
          </Tooltip>
        )}
      </InputLabel>
    );
  };

  const generateInputContainer = (key, input) => {
    return (
      <TextInputContainer
        key={key}
        style={{
          width: input.width,
          margin: input.margin ? input.margin : null,
          visibility: input.hidden ? 'hidden' : null,
        }}
      >
        {generateInputLabel(input)}
        <TextInput
          disabled={input.disabled}
          type={input.type}
          name={input.name}
          id={input.id}
          hasError={hasError(input)}
          required={input.required}
          placeholder={input.placeholder}
          value={value[input.name]}
          onChange={onChange}
          withIcon={input.withIcon}
          ageDate={input.ageDate}
          iconSrc={input.iconSrc}
          lastpassIgnore={input.lastpassIgnore}
          recurlyData={
            showRecurly && !input.recurlyIgnore
              ? input.recurly
                ? input.recurly
                : input.name
              : null
          }
        />
      </TextInputContainer>
    );
  };

  const generateSingleTextArea = (index, input) => {
    if (input.editor) {
      return (
        <React.Fragment key={index}>
          {generateInputLabel(input)}
          <TextEditor
            value={input.value}
            onChange={input.onChange}
            error={input.hasError}
            showClear={input.showClear}
            style={{ width: input.width, margin: '0 8px 10px 8px' }}
            fullToolbar={input?.fullToolbar}
          />
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment key={index}>
          {generateInputLabel(input)}
          <TextInputContainer
            style={{ width: input.width }}
            margin={input.margin}
          >
            <TextArea
              value={input.value}
              onChange={input.onChange}
              minHeight={input.minHeight}
              hasError={hasError(input)}
            />
          </TextInputContainer>
        </React.Fragment>
      );
    }
  };

  const generateTextareaContainer = () => {
    return inputs.map((input, index) => {
      if (input.editor) {
        return (
          <TextEditor
            key={index}
            value={input.value}
            onChange={input.onChange}
            error={input.hasError}
            id={input.id}
            style={{ width: input.width }}
            showClear={input.showClear}
            showUseDefault={input.showUseDefault}
            defaultText={input.defaultText}
            fullToolbar={input?.fullToolbar}
          />
        );
      } else {
        return (
          <TextInputContainer key={index} style={{ width: input.width }}>
            <TextArea value={input.value} onChange={input.onChange} />
          </TextInputContainer>
        );
      }
    });
  };

  const generateToggle = (item) => {
    return (
      <ToggleContainer
        style={
          item.width === '100%' || item.showLabel
            ? { width: '100%', margin: '6px 6px' }
            : { width: item.width, margin: item.margin }
        }
      >
        <Toggle
          props={{
            name: item.name,
            theme: item.theme,
            value: value[item.name],
            text: item.text,
          }}
          onClick={item.onClick}
        />
      </ToggleContainer>
    );
  };

  const generateToggleContainer = (key, item) => {
    return (
      <React.Fragment key={key}>
        {item.showLabel ? (
          <ToggleWithLabelContainer margin={item.margin} width={item.width}>
            {generateInputLabel(item)}
            {generateToggle(item)}
          </ToggleWithLabelContainer>
        ) : (
          generateToggle(item)
        )}
      </React.Fragment>
    );
  };

  const generateSelectContainer = (key, input) => {
    return (
      !input.is_hidden && (
        <SelectContainer
          key={key}
          style={
            input.width === '100%'
              ? {
                  width: input.width,
                  margin: input.margin ? input.margin : '0 8px 15px 8px',
                }
              : { width: input.width, margin: input.margin }
          }
        >
          {generateInputLabel(input)}
          <Select
            selectedOption={input.selectedOption}
            onChange={input.onChange}
            options={input.options}
            isSearchable={true}
            placeholder={input.placeholder}
            autoFocus={false}
            isDisabled={input.isDisabled}
          />
        </SelectContainer>
      )
    );
  };

  const generateIconContainer = () => {
    return (
      <IconSelectContainer>
        <IconsContainer>
          {inputs.map((icon, index) => {
            return (
              <Icon
                key={index}
                onClick={() => onClick(icon.value)}
                src={icon.src}
                alt={icon.value}
                data-image={icon.value}
                style={
                  icon.value === value
                    ? {
                        border: `1px solid ${styles.colors.green}`,
                        boxShadow: styles.boxShadows.boxShadowSoft,
                      }
                    : null
                }
              />
            );
          })}
        </IconsContainer>
      </IconSelectContainer>
    );
  };

  const generateCountButtonContainer = () => {
    return button?.text ? (
      <>
        <ButtonContainer>
          {extraData?.supportEnd && (
            <div
              style={{
                marginRight: '10px',
                fontSize: '14px',
                color: styles.colors.paleGrey,
                fontWeight: styles.fonts.semiBold,
              }}
            >
              <Countdown
                date={DateFormatUTC(extraData.supportEnd)}
                daysInHours={true}
                zeroPadTime={2}
              />
            </div>
          )}
          <Button
            text={button.text}
            onClick={() => button.onClick(button.value)}
            theme={button.theme}
          />
        </ButtonContainer>
      </>
    ) : null;
  };

  const generateAccountContainer = () => {
    const billingManagers = extraData?.managers?.billing;
    const teamManagers = extraData?.managers?.team;
    let displayBilling = [];
    let displayTeam = [];
    if (billingManagers && billingManagers.length !== 0) {
      displayBilling = billingManagers.slice(0, 2);
    }
    if (teamManagers && teamManagers.length !== 0) {
      displayTeam = teamManagers.slice(0, 2);
    }
    return (
      <>
        <AccountSettingsFlex>
          <SettingsFlex>
            <SettingsLabel>Type</SettingsLabel>
            {extraData?.loading ? (
              <SettingsValuePlaceholder />
            ) : (
              <SettingsValue>
                {extraData?.type_display ? extraData?.type_display : 'None'}
              </SettingsValue>
            )}
          </SettingsFlex>
          <SettingsFlex>
            <SettingsLabel>State</SettingsLabel>
            {extraData?.loading ? (
              <SettingsValuePlaceholder />
            ) : (
              <SettingsValue>
                {extraData?.state_display ? extraData?.state_display : 'None'}
              </SettingsValue>
            )}
          </SettingsFlex>
          <SettingsFlex>
            <SettingsLabel>End Date</SettingsLabel>
            {extraData?.loading ? (
              <SettingsValuePlaceholder />
            ) : (
              <SettingsValue>
                {extraData?.end_date
                  ? DateFormat(extraData.end_date, 'MMMM d, YYYY')
                  : 'None'}
              </SettingsValue>
            )}
          </SettingsFlex>
        </AccountSettingsFlex>
        <ManagerList>
          <SettingsLabel>Subscription Manager(s)</SettingsLabel>
          {extraData?.loading ? (
            <SettingsValuePlaceholder style={{ width: '220px' }} />
          ) : extraData?.showAllBilling ? (
            <div>
              {billingManagers.map((member, index) => {
                return (
                  <ManagerValue key={index} href={`mailto:${member?.email}`}>
                    {member?.name} ({member?.email})
                  </ManagerValue>
                );
              })}
              <ShowAllText onClick={() => onClick('showAllBilling', false)}>
                Show Less
              </ShowAllText>
            </div>
          ) : displayBilling && displayBilling.length !== 0 ? (
            <div>
              {displayBilling.map((member, index) => {
                return (
                  <ManagerValue key={index} href={`mailto:${member?.email}`}>
                    {member?.name} ({member?.email})
                  </ManagerValue>
                );
              })}
              {billingManagers && billingManagers.length > 2 && (
                <ShowAllText onClick={() => onClick('showAllBilling', true)}>
                  Show All
                </ShowAllText>
              )}
            </div>
          ) : (
            <SettingsValue>None</SettingsValue>
          )}
        </ManagerList>
        <ManagerList style={{ marginBottom: '0' }}>
          <SettingsLabel>Team Manager(s)</SettingsLabel>
          {extraData?.loading ? (
            <SettingsValuePlaceholder style={{ width: '220px' }} />
          ) : extraData?.showAllTeam ? (
            <div>
              {teamManagers.map((member, index) => {
                return (
                  <ManagerValue key={index} href={`mailto:${member?.email}`}>
                    {member?.name} ({member?.email})
                  </ManagerValue>
                );
              })}
              <ShowAllText onClick={() => onClick('showAllTeam', false)}>
                Show Less
              </ShowAllText>
            </div>
          ) : displayTeam && displayTeam.length !== 0 ? (
            <div>
              {displayTeam.map((member, index) => {
                return (
                  <ManagerValue key={index} href={`mailto:${member?.email}`}>
                    {member?.name} ({member?.email})
                  </ManagerValue>
                );
              })}
              {teamManagers && teamManagers.length > 2 && (
                <ShowAllText onClick={() => onClick('showAllTeam', true)}>
                  Show All
                </ShowAllText>
              )}
            </div>
          ) : (
            <SettingsValue>None</SettingsValue>
          )}
        </ManagerList>
      </>
    );
  };

  const generateTableContainer = () => {
    return (
      <Table>
        <TableHeader>
          <tr>
            {headers.map((header, index) => {
              return (
                <td key={index} style={{ textAlign: header.align }}>
                  {header.text}
                </td>
              );
            })}
          </tr>
        </TableHeader>
        <TableBody>
          {inputs.map((row, index) => {
            return (
              <tr key={index}>
                <td>{row.label}</td>
                <td style={{ textAlign: 'center' }}>
                  <CheckboxItem
                    src={checkFunction(row.notification_type, 'email')}
                    alt="box"
                    data-image={`checkbox-email`}
                    onClick={() => {
                      onClick(row.notification_type, 'email');
                    }}
                  />
                </td>
                <td style={{ textAlign: 'center' }}>
                  <CheckboxItem
                    src={checkFunction(row.notification_type, 'inapp')}
                    alt="box"
                    data-image={`checkbox-inapp`}
                    onClick={() => {
                      onClick(row.notification_type, 'inapp');
                    }}
                  />
                </td>
              </tr>
            );
          })}
        </TableBody>
      </Table>
    );
  };

  const generateColorPickerContainer = (key, input) => {
    return (
      <TextInputContainer
        key={key}
        style={{
          width: input.width,
          margin: input.margin ? input.margin : null,
          visibility: input.hidden ? 'hidden' : null,
        }}
      >
        {generateInputLabel(input)}
        <ColorPickerContainer>
          <ColorBox
            color={value[input.name]}
            onClick={() => setShowColorPicker(!showColorPicker)}
            id="color-picker"
          />
          {showColorPicker && (
            <PickerContainer ref={pickerRef}>
              <ChromePicker
                color={value[input.name]}
                onChange={input.onChange}
                disableAlpha={true}
              />
            </PickerContainer>
          )}
        </ColorPickerContainer>
      </TextInputContainer>
    );
  };

  const generateRadioContainer = (key, input) => {
    return (
      <TextInputContainer
        key={key}
        style={{
          width: input.width,
          margin: input.margin ? input.margin : null,
          visibility: input.hidden ? 'hidden' : null,
        }}
      >
        {generateInputLabel(input)}
        <RadioInputsContainer>
          {input.options.map((option, index) => {
            return (
              <RadioOption key={index}>
                <label htmlFor={option.value}>
                  <input
                    type="radio"
                    id={option.value}
                    name={input.name}
                    data-recurly={input.name}
                    value={option.value}
                    checked={input.value === option.value}
                    onChange={(e) => input.onChange(e, option.value)}
                  />
                  {option.label}
                </label>
              </RadioOption>
            );
          })}
        </RadioInputsContainer>
      </TextInputContainer>
    );
  };

  return (
    <>
      {type === 'noHeader' ? (
        <FormContainer
          style={
            !borderBottom ? { borderBottom: 'none', paddingBottom: '0' } : null
          }
          isEmpty={type === 'empty'}
        >
          <FieldsContainer style={{ ...style }}>
            {inputs.map((input, index) => {
              if (input.type === 'select') {
                return generateSelectContainer(index, input);
              } else if (input.type === 'toggle') {
                return generateToggleContainer(index, input);
              } else {
                return generateInputContainer(index, input);
              }
            })}
          </FieldsContainer>
          {button && (
            <ButtonContainer>
              <Button
                text={button.text}
                onClick={() => button.onClick(button.value)}
              />
            </ButtonContainer>
          )}
        </FormContainer>
      ) : (
        <FormContainer
          style={
            !borderBottom ? { borderBottom: 'none', ...style } : { ...style }
          }
          isEmpty={type === 'empty'}
        >
          <FormHeader
            id={header}
            style={formText ? { marginBottom: '5px' } : null}
          >
            {header}
          </FormHeader>
          {formText && (
            <FormText>
              {formText}{' '}
              {formTextLink && (
                <FormTextLink href={formTextLink?.link} target="_blank">
                  {formTextLink?.text}
                </FormTextLink>
              )}
            </FormText>
          )}
          {link && (
            <LinkContainer>
              {link?.isExternal ? (
                <a href={link?.link} target="_blank" rel="noopener noreferrer">
                  {link?.text}
                </a>
              ) : (
                <Link to={link?.link}>{link?.text}</Link>
              )}
            </LinkContainer>
          )}
          {type === 'form' ? (
            <>
              <FieldsContainer>
                {inputs &&
                  inputs.map((input, index) => {
                    if (input.type === 'select') {
                      return generateSelectContainer(index, input);
                    } else if (input.type === 'toggle') {
                      return generateToggleContainer(index, input);
                    } else if (input.type === 'textarea') {
                      return generateSingleTextArea(index, input);
                    } else if (input.type === 'color') {
                      return generateColorPickerContainer(index, input);
                    } else if (input.type === 'radio') {
                      return generateRadioContainer(index, input);
                    } else {
                      return generateInputContainer(index, input);
                    }
                  })}
              </FieldsContainer>
              {button && (
                <ButtonContainer>
                  <Button
                    text={button.text}
                    onClick={() => button.onClick(button.value)}
                  />
                </ButtonContainer>
              )}
            </>
          ) : type === 'icon' ? (
            generateIconContainer()
          ) : type === 'countdown' ? (
            generateCountButtonContainer()
          ) : type === 'account' ? (
            generateAccountContainer()
          ) : type === 'table' ? (
            <>
              {generateTableContainer()}
              {button && (
                <ButtonContainer>
                  <Button
                    text={button.text}
                    onClick={() => button.onClick(button.value)}
                  />
                </ButtonContainer>
              )}
            </>
          ) : type === 'textarea' ? (
            generateTextareaContainer()
          ) : null}
          {hasErrorDisplay && (
            <ErrorMessageContainer>
              {errorMessages &&
                errorMessages.length >= 1 &&
                errorMessages.map((message, index) => {
                  return <ErrorMessage key={index}>{message}</ErrorMessage>;
                })}
            </ErrorMessageContainer>
          )}
        </FormContainer>
      )}
    </>
  );
};

const FormContainer = styled.div`
  padding-bottom: ${(props) => (props.isEmpty ? '0' : '25px')};
  margin-bottom: ${(props) => (props.isEmpty ? '0' : '20px')};
  border-bottom: 1px solid ${styles.colors.lightGrey};
`;

const FormHeader = styled.h3`
  font-weight: ${styles.fonts.semiBold};
  margin-bottom: 20px;
`;

const FieldsContainer = styled.div`
  flex-wrap: wrap;
  ${FlexCenterStart}
  margin: 0 -8px;
`;

const InputLabel = styled.label`
  margin: 0 0 5px 10px;
  font-size: 11px;
  font-weight: ${styles.fonts.semiBold};
  color: ${styles.inputColors.label};
`;

const TextInputContainer = styled.div`
  @media ${styles.maxDevice.sideNav} {
    input {
      padding: 10.5px 8px;
      font-size: 10px;
      margin: 0 5px;
    }
  }
  display: flex;
  flex-direction: column;
  margin: ${(props) => (props.margin ? props.margin : '0 0 10px 0')};
  input {
    flex: 1 1 auto;
    margin: 0 8px;
    min-width: 30px;
  }
`;

const RequiredStar = styled.span`
  color: ${styles.messageColors.error};
  margin-left: 2px;
`;

const ToggleContainer = styled.div`
  margin: 0 10px;
`;

const ToggleWithLabelContainer = styled.div`
  margin: ${(props) => (props.margin ? props.margin : 0)};
  width: ${(props) => (props.width ? props.width : '100%')};
`;

const SelectContainer = styled.div`
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  ${InputLabel} {
    margin: 0 0 5px 0;
  }
`;

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-end;
  button {
    min-width: 130px;
  }
`;

const LinkContainer = styled.div`
  width: 100%;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-end;
  a {
    color: ${styles.colors.lightBlue};
    font-size: 14px;
    &:hover {
      color: ${styles.colors.blue};
    }
  }
`;

const FormText = styled.p`
  @media ${styles.maxDevice.mobileL} {
    font-size: 11px;
  }
  margin-bottom: 20px;
  font-size: 12px;
  color: ${styles.colors.paleGrey};
`;

const FormTextLink = styled.a`
  @media ${styles.maxDevice.mobileL} {
    font-size: 9px;
  }
  font-size: 10px;
  color: ${styles.colors.lightBlue};
  margin-left: 5px;
  &:hover {
    color: ${styles.colors.blue};
  }
`;

const IconSelectContainer = styled.div`
  width: 100%;
  margin: 0;
  p {
    margin-bottom: 5px;
  }
`;

const IconsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Icon = styled.img`
  @media ${styles.maxDevice.tablet} {
    max-height: 60px;
    max-width: 60px;
    margin-right: 15px;
    padding: 10px 15px;
  }
  @media ${styles.maxDevice.mobileL} {
    max-height: 50px;
    max-width: 50px;
    padding: 10px 10px;
  }
  @media ${styles.maxDevice.mobileM} {
    max-height: 50px;
    max-width: 50px;
    margin-right: 10px;
  }
  flex: 1 1 auto;
  cursor: pointer;
  padding: 15px 20px;
  border: 1px solid ${styles.colors.lightGrey};
  border-radius: 3px;
  width: 100%;
  max-height: 80px;
  max-width: 80px;
  margin-right: 20px;
`;

const TextArea = styled.textarea`
  border: 1px solid
    ${(props) =>
      props.hasError ? styles.messageColors.error : styles.inputColors.border};
  color: ${styles.colors.darkGrey};
  resize: none;
  font-size: 14px;
  border-radius: 6px;
  height: 100%;
  max-height: 500px;
  min-height: ${(props) => (props.minHeight ? props.minHeight : '200px')};
  padding: 12px 15px;
  margin: 0 8px;
`;

const Table = styled.table`
  width: 100%;
  font-size: 13px;
  margin-bottom: 30px;
`;

const TableHeader = styled.thead`
  font-weight: ${styles.fonts.semiBold};
  tr {
    border-bottom: 1px solid ${styles.colors.lightGrey};
    td {
      padding-bottom: 8px;
    }
  }
`;

const TableBody = styled.tbody`
  tr {
    border-bottom: 1px solid ${styles.colors.lightGrey};
    td {
      padding: 8px 0;
      img {
        width: 12px;
        height: 12px;
      }
    }
  }
`;

const CheckboxItem = styled.img`
  cursor: pointer;
  display: inline-block;
  &:hover {
    transform: scale(1.03);
    opacity: 0.8;
  }
`;

const ErrorMessageContainer = styled.div`
  margin-top: 15px;
  margin-bottom: -5px;
  font-size: 13px;
  text-align: right;
  color: ${styles.messageColors.error};
`;

const ErrorMessage = styled.p`
  &::first-letter {
    text-transform: capitalize;
  }
`;

const TooltipIcon = styled.img`
  width: 10px;
  height: 10px;
  margin-left: 5px;
  margin-bottom: -1px;
  opacity: 0.75;
`;

const TooltipIconError = styled(TooltipIcon)`
  opacity: 1;
`;

const AccountSettingsFlex = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 15px;
  max-width: 600px;
`;

const SettingsFlex = styled.div`
  flex: 1 1 auto;
  width: 200px;
`;

const SettingsLabel = styled.p`
  font-size: 12px;
  font-weight: ${styles.fonts.semiBold};
  color: ${styles.inputColors.label};
  margin-bottom: 2px;
`;

const SettingsValue = styled.p`
  font-size: 15px;
  height: 20px;
  /* color: ${styles.colors.paleGrey}; */
`;

const SettingsValuePlaceholder = styled.p`
  background: ${styles.colors.lightGrey};
  height: 20px;
  width: 100px;
`;

const ManagerValue = styled.a`
  font-size: 14px;
  color: ${styles.colors.lightBlue};
  cursor: pointer;
  display: block;
`;

const ShowAllText = styled.p`
  font-size: 12px;
  color: ${styles.colors.paleGrey};
  cursor: pointer;
`;

const ManagerList = styled.div`
  margin-bottom: 15px;
`;

const ColorPickerContainer = styled.div`
  position: relative;
  margin: 0 0 0 10px;
`;

const PickerContainer = styled.div`
  position: absolute;
  z-index: 5;
  top: 40px;
  left: 0;
`;

const ColorBox = styled.div`
  padding: 0px;
  background-color: ${(props) => props.color};
  border: none;
  width: 80px;
  height: 35px;
  cursor: pointer;
  border-radius: 6px;
`;

const RadioInputsContainer = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin: 0 0 0 10px;
`;

const RadioOption = styled.div`
  flex: 0 0 auto;
  font-size: 14px;
  margin: 0 20px 0 0;
  label {
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: ${styles.colors.darkGrey};
  }
  input {
    min-width: auto;
    margin: 0 8px 0 0;
    cursor: pointer;
  }
`;

FormChunk.propTypes = {
  type: PropTypes.string,
  header: PropTypes.string,
  headers: PropTypes.array,
  formText: PropTypes.string,
  formTextLink: PropTypes.object,
  inputs: PropTypes.array,
  value: PropTypes.any,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  checkFunction: PropTypes.func,
  button: PropTypes.object,
  link: PropTypes.object,
  borderBottom: PropTypes.bool,
  style: PropTypes.object,
  extraData: PropTypes.object,
  errorFields: PropTypes.array,
  hasErrorDisplay: PropTypes.bool,
  errorMessages: PropTypes.array,
  showRecurly: PropTypes.bool,
};

export default FormChunk;
