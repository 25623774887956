import Calculator from 'awesome-react-calculator';
import styled from 'styled-components';
import { colors, fonts, heights } from '../../styles/variables';
import { useState } from 'react';

const CalculatorSidebar = () => {
  const [history, setHistory] = useState([]);
  const handleInput = (input) => {
    // console.log(
    //   `${input.expression} is shown in the calculator, User clicked the ${input.key}`
    // );
  };

  const onResultChange = (newResult) => {
    if (newResult?.expression) {
      setHistory([...history, newResult]);
    }
    // console.log(`${newResult.expression} is validated as ${newResult.result} `);
  };

  return (
    <>
      <CalculatorContainer>
        <Calculator onNewInput={handleInput} onResultChange={onResultChange} />
      </CalculatorContainer>
      <HistoryContainer>
        <SidebarHeading>History</SidebarHeading>
        <HistoryList>
          {history.length ? (
            history.map((entry, index) => {
              return (
                <HistoryEntry
                  key={index}
                  $isLast={history.length - 1 === index}
                >
                  {entry.expression} {entry.result}
                </HistoryEntry>
              );
            })
          ) : (
            <HistoryEntry $isLast={true}>No Entries</HistoryEntry>
          )}
        </HistoryList>
      </HistoryContainer>
    </>
  );
};

const CalculatorContainer = styled.div`
  height: 450px;
  width: 100%;
  .react-calculator {
    font-family: 'Open Sans', sans-serif;
  }
`;

const HistoryContainer = styled.div`
  margin-top: 20px;
  padding: 0 15px;
  height: calc(100vh - ${heights.navBar} - 450px - 20px - 55px);
  overflow: auto;
`;

const SidebarHeading = styled.p`
  font-size: 15px;
  font-weight: ${fonts.semiBold};
  border-bottom: 1px solid ${colors.paleGrey};
  padding: 8px 2px;
`;

const HistoryList = styled.div`
  /* border: 1px solid green; */
`;

const HistoryEntry = styled.div`
  border-bottom: ${(props) =>
    props.$isLast ? null : `1px solid ${colors.lightGrey}`};
  padding: 10px 5px;
  font-size: 14px;
`;

export default CalculatorSidebar;
