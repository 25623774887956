/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import styled, { ThemeProvider } from 'styled-components';
import Cookies from 'js-cookie';
import Chart from 'chart.js/auto';
import 'chartjs-adapter-dayjs';
import { Tooltip } from 'react-tippy';
import { LoadingAnimation, Select, Button, FileStorageExplorer } from '..';
import {
  linkMemberFolder,
  unlinkMemberFolder,
  linkInstrumentFolder,
  unlinkInstrumentFolder,
  getMemberStorageFolder,
  getInstrumentStorageFolder,
  toggleShowUploadFileModal,
} from '../../store/actions';
import { errorCircleRed, launchLink, user, info } from '../../assets';
import {
  colors,
  messageColors,
  inputColors,
  fonts,
} from '../../styles/variables';
import { TabContent } from '../../styles/library/modalStyles';
import { ButtonThemes, selectThemes } from '../../styles/themes';
import {
  integrationOptions,
  FormatCurrency,
  IsNegative,
  FolderOption,
  SendTrackingEvent,
  HtmlToString,
  DateNow,
  DateFromNowUTC,
  DateFormat,
  DateFormatUTC,
  DateStartOf,
  DateSubtract,
  insertIf,
  CapitalizeFirstLetter,
} from '../../utils';

const IntegrationTab = ({
  integration,
  content,
  loading,
  household,
  type,
  member,
  role,
  instrument,
  error,
  onClick,
  onClickLoading,
  isSidebar = false,
  lastHouseholdSync = false,
}) => {
  const chartRef = useRef();
  const dispatch = useDispatch();
  const periodOptions = [
    { label: 'QTD', value: 'qtd' },
    { label: 'YTD', value: 'ytd' },
    { label: 'Last Quarter', value: 'lq' },
    { label: 'Last Year', value: 'ly' },
    { label: '1 Year', value: '1yr' },
    { label: '3 Year', value: '3yr' },
    { label: '5 Year', value: '5yr' },
  ];

  const { integrationTabFolder, integrationTabFolderError, invocationId } =
    useSelector((state) => ({
      integrationTabFolder: state.members.integrationTabFolder,
      integrationTabFolderError: state.members.integrationTabFolderError,
      invocationId: state.configs.invocationId,
    }));

  const [widgetOptions, setWidgetOptions] = useState([]);
  const [selectedPeriod, setSelectedPeriod] = useState(periodOptions[2]);
  const [selectedWidget, setSelectedWidget] = useState({
    label: 'Performance Summary',
    value: 'portPerformanceEntity',
    noPeriod: false,
  });
  const [isFileStorage, setIsFileStorage] = useState(false);
  const [folderOptions, setFolderOptions] = useState([]);
  const [selectedFolder, setSelectedFolder] = useState();
  const [isLinking, setIsLinking] = useState(false);
  const [isUnlinking, setIsUnlinking] = useState(false);
  const [loadingFolderContent, setLoadingFolderContent] = useState(true);
  const [loadingFolderError, setLoadingFolderError] = useState(false);
  const [integrationFolder, setIntegrationFolder] = useState();
  const [hasExternalLink, setHasExternalLink] = useState(false);

  const fileStorageIntegrations = integrationOptions.filter(
    (option) => option.file_storage
  );

  const widgetList = [
    {
      label: 'Activity Compare',
      value: 'portActivityCompare',
      noPeriod: false,
    },
    { label: 'Activity Summary', value: 'portActivitySummary', noPeriod: true },
    {
      label: 'Activity Transactions',
      value: 'portTransactionScatter',
      noPeriod: false,
    },
    { label: 'AUM Over Time', value: 'portAUMOverTime', noPeriod: false },
    { label: 'Balance Sheet', value: 'portBalanceSheet', noPeriod: true },
    { label: 'Documents', value: 'portDocuments', noPeriod: true },
    { label: 'Gains/Losses', value: 'portGainsLosses', noPeriod: true },
    { label: 'Holdings Summary', value: 'portHoldings', noPeriod: true },
    { label: 'Model Tolerance', value: 'portModelTolerance', noPeriod: true },
    {
      label: 'Performance Chart',
      value: 'portPerformanceChart',
      noPeriod: false,
    },
    {
      label: 'Performance Summary',
      value: 'portPerformanceEntity',
      noPeriod: false,
    },
    {
      label: 'Performance Detail',
      value: 'portPerformanceGrouped',
      noPeriod: true,
    },
    { label: 'Profile', value: 'portProfile', noPeriod: true },
    { label: 'Reports', value: 'portReports', noPeriod: true },
    { label: 'RMD', value: 'portRMD', noPeriod: true },
    { label: 'Suitability', value: 'portSuitability', noPeriod: true },
    { label: 'Systematics', value: 'portSystematics', noPeriod: true },
  ];

  useEffect(() => {
    if (content && integration.id === 'orion') {
      if (content.hasOwnProperty('widgets') && content?.widgets?.length !== 0) {
        setWidgetOptions(
          content.widgets.map((widget) => {
            return {
              label: widget.name,
              value: widget.value,
              noPeriod: widget.no_period,
            };
          })
        );
      } else {
        setWidgetOptions(widgetList);
      }
    } else if (
      content &&
      fileStorageIntegrations.find((int) => int.id === integration.id)
    ) {
      if (content?.linked) {
        setLoadingFolderContent(true);
        setIntegrationFolder([]);
        setIsUnlinking(false);
        if (type === 'Member' || type === 'Entity') {
          dispatch(
            getMemberStorageFolder(
              household?.uuid,
              member.uuid ? member.uuid : member.id,
              integration.id
            )
          );
        } else {
          dispatch(
            getInstrumentStorageFolder(
              household?.uuid,
              instrument?.uuid,
              integration.id
            )
          );
        }
      } else {
        setIsLinking(false);

        let transformedFolders = [];
        if (content?.available_folders) {
          transformedFolders = content?.available_folders.map((folder) => {
            folder.label = HtmlToString(folder.name);
            folder.value = folder.id;
            return folder;
          });
        }
        setSelectedFolder({
          label: `Create New Folder for ${type}`,
          value: 'new',
        });
        return setFolderOptions([
          {
            label: `Create New Folder for ${type}`,
            value: 'new',
          },
          ...transformedFolders,
        ]);
      }
    }
  }, [content, integration]);

  useEffect(() => {
    if (content) {
      if (content?.external_url && typeof content?.external_url === 'string') {
        setHasExternalLink(true);
      }
    }
  }, [content]);

  useEffect(() => {
    if (integration) {
      setIsFileStorage(
        fileStorageIntegrations.find((int) => int.id === integration.id)
      );
    }
  }, [integration]);

  useEffect(() => {
    if (integrationTabFolder) {
      setLoadingFolderContent(false);
      if (integrationTabFolder?.linked) {
        setLoadingFolderError(false);
      }
      // else {
      //   setLoadingFolderError(true);
      // }
      setIntegrationFolder(integrationTabFolder);
    }
  }, [integrationTabFolder]);

  useEffect(() => {
    if (integrationTabFolderError) {
      setLoadingFolderContent(false);
      setLoadingFolderError(true);
      setIntegrationFolder(integrationTabFolderError);
    }
  }, [integrationTabFolderError]);

  useEffect(() => {
    if (widgetOptions && widgetOptions.length !== 0) {
      setSelectedWidget(widgetOptions[10]);
    }
  }, [widgetOptions]);

  const generateIntegrationValue = (
    object,
    key,
    secondKey = null,
    nullReturn = 'None'
  ) => {
    if (object) {
      if (secondKey) {
        if (object?.hasOwnProperty(key) && object?.[key] !== null) {
          if (object?.[key]?.[secondKey]) {
            return object?.[key]?.[secondKey];
          } else {
            return nullReturn;
          }
        } else {
          return nullReturn;
        }
      }
      if (object?.hasOwnProperty(key)) {
        if (object?.[key]) {
          return object?.[key];
        } else {
          return nullReturn;
        }
      }
    }
    return nullReturn;
  };

  const generateIntegrationBool = (
    object,
    key,
    trueReturn = 'Yes',
    falseReturn = 'No',
    nullReturn = 'None'
  ) => {
    if (object) {
      if (object.hasOwnProperty(key)) {
        if (object[key] === true) {
          return trueReturn;
        }
        if (object[key] === false) {
          return falseReturn;
        }
      }
    }
    return nullReturn;
  };

  const generateCombinedValues = (
    object,
    primaryKey,
    secondaryKey,
    primarySubkey = null,
    secondarySubKey = null,
    space = '-',
    nullReturn = 'None'
  ) => {
    let partOne = '';
    let partTwo = '';
    if (object) {
      if (
        !object.hasOwnProperty(primaryKey) ||
        object[primaryKey] == null ||
        (Array.isArray(object[primaryKey]) && object[primaryKey].length
          ? true
          : false) ||
        object[primaryKey] === 0
      ) {
        return nullReturn;
      }

      if (typeof object[primaryKey] === 'string') {
        partOne = object[primaryKey];
      }

      if (primarySubkey) {
        if (object[primaryKey].hasOwnProperty(primarySubkey)) {
          partOne = object[primaryKey][primarySubkey];
        }
      }

      if (
        !object.hasOwnProperty(secondaryKey) ||
        object[secondaryKey] == null ||
        (Array.isArray(object[secondaryKey]) && object[secondaryKey].length
          ? false
          : true)
      ) {
        return partOne;
      }

      if (typeof object[secondaryKey] === 'string') {
        partTwo = object[secondaryKey];
      }

      if (secondarySubKey) {
        if (Array.isArray(object[secondaryKey])) {
          object[secondaryKey] = object[secondaryKey][0];
        }
        if (object[secondaryKey].hasOwnProperty(secondarySubKey)) {
          partTwo = object[secondaryKey][secondarySubKey];
        }
      }

      let returnVal = partOne;
      if (partTwo) {
        returnVal = `${partOne} ${space} ${partTwo}`;
      }

      return returnVal || nullReturn;
    }
  };

  const isZero = (object, key, secondKey) => {
    if (object && object.hasOwnProperty(key)) {
      if (object[key][secondKey]) {
        if (Math.abs(Math.round(object[key][secondKey])) === 0) {
          return true;
        } else {
          return false;
        }
      }
    }
    return true;
  };

  const checkNegative = (object, key, secondKey) => {
    if (object && object.hasOwnProperty(key)) {
      if (object[key][secondKey]) {
        return IsNegative(object[key][secondKey]);
      } else {
        return false;
      }
    }
    return true;
  };

  const generateIntegrationCurrency = (
    object,
    key,
    secondKey = false,
    isUS = false,
    nullReturn = '--'
  ) => {
    if (object) {
      if (object?.[key]) {
        if (secondKey) {
          if (object?.[key]?.[secondKey]) {
            return FormatCurrency(object?.[key]?.[secondKey]);
          } else {
            return nullReturn;
          }
        } else {
          return FormatCurrency(object?.[key]);
        }
      } else {
        return nullReturn;
      }
    }
    return nullReturn;
    // todo if isUs false
  };

  const generateList = (object, key, labelKey, valueKey) => {
    if (object && object.hasOwnProperty(key)) {
      const matchingValue = object[key];
      if (matchingValue && matchingValue.length !== 0) {
        return matchingValue.map((value) => {
          return {
            label: value[labelKey],
            value: value[valueKey],
          };
        });
      }
      return [{ label: 'None', value: '' }];
    } else {
      return [{ label: 'None', value: '' }];
    }
  };

  const generateAddressItem = (value, nextValue, showComma = true) => {
    if (nextValue && nextValue !== '') {
      if (showComma) {
        return `${value}, `;
      }
      return `${value} `;
    }
    if (value && value !== '') {
      return value;
    } else {
      return '';
    }
  };

  const generateAddress = (
    object,
    key,
    type,
    address,
    address2,
    city,
    state,
    zip
  ) => {
    if (object.hasOwnProperty(key)) {
      const matchingValue = object[key];
      if (matchingValue && matchingValue.length !== 0) {
        return matchingValue.map((value) => {
          return {
            label: value[type],
            value: `${generateAddressItem(
              value[address],
              value[address2]
            )}${generateAddressItem(
              value[address2],
              value[city]
            )}${generateAddressItem(
              value[city],
              value[state]
            )}${generateAddressItem(value[state], value[zip], false)} ${
              value[zip]
            }`,
          };
        });
      }
      return [{ label: 'None', value: '' }];
    } else {
      return [{ label: 'None', value: '' }];
    }
  };

  function get_date_range(range, format) {
    var fmt = null;

    if (typeof format != 'undefined') {
      fmt = format;
    }

    var start;
    var end;

    switch (range) {
      case 'qtd':
      default:
        // qtd
        start = DateStartOf('quarter');
        end = DateNow();
        break;

      case 'ytd':
        start = DateStartOf('year');
        end = DateNow();
        break;

      case 'lq':
        start = DateStartOf('quarter', 1, 'day', 'quarter');
        end = DateStartOf('quarter', 1, 'day');
        break;

      case 'ly':
        start = DateStartOf('quarter', 1, 'day', 'year');
        end = DateStartOf('year', 1, 'day');
        break;

      case '1yr':
        start = DateSubtract(undefined, 1);
        end = DateNow();
        break;

      case '3yr':
        start = DateSubtract(undefined, 3);
        end = DateNow();
        break;

      case '5yr':
        start = DateSubtract(undefined, 5);
        end = DateNow();
        break;
    }

    if (fmt) {
      return { start: start.format(fmt), end: end.format(fmt) };
    }
    return { start: start, end: end };
  }

  const load_iframe = () => {
    var value = selectedWidget.value;
    var range = get_date_range(selectedPeriod.value, 'YYYY-MM-DD');
    var startDate = range['start'];
    var endDate = range['end'];
    const p = encodeURIComponent(
      `/dashboardembed?entityEnum=5&entityId=${content.id}&widgetCodes=${value}&startDate=${startDate}&endDate=${endDate}`
    );
    const url = `/integration/partner/orion/go/insight?p=${p}`;
    return url;
  };

  const generateHolistiplanContent = () => {
    if (content && content.hasOwnProperty('partner_data')) {
      let income_tax = generateIntegrationValue(
        content?.partner_data?.household,
        'income_tax',
        content?.id
      );
      let input_year = generateIntegrationValue(
        income_tax,
        'input_year',
        '',
        'No'
      );
      let average_rate = generateIntegrationValue(
        income_tax,
        'average_rate',
        '',
        'None'
      );
      let char_max_length = 22;
      let filing_status = generateFloatValue(income_tax, 'filing_status');

      return [
        {
          label: input_year
            .toString()
            .concat(' ', content?.partner_data?.tax_report_name),
          value: 'basic',
          position: 'center',
        },
        {
          label: 'Key Figures',
          value: 'basic',
          position: 'center',
          leftInfo: [
            {
              label: 'Total Income',
              value: `${generateFloatValue(
                income_tax,
                'total_income',
                0,
                '$'
              )}`,
            },
            {
              label: 'AGI',
              value: `${generateFloatValue(income_tax, 'agi', 0, '$')}`,
            },
            {
              label: 'Deductions',
              value: `${generateFloatValue(
                income_tax,
                'total_deductions',
                0,
                '$'
              )}`,
            },
            {
              label: 'Taxable Income',
              value: `${generateFloatValue(
                income_tax,
                'taxable_income',
                0,
                '$'
              )}`,
            },
            {
              label: 'Total Tax',
              value: `${generateFloatValue(income_tax, 'total_tax', 0, '$')}`,
            },
          ],
          rightInfo: [
            {
              label: 'Filing Status',
              value: `${filing_status}`,
              tooltip: `${filing_status?.length > char_max_length}`,
              title: `${filing_status.substring(0, char_max_length)}...`,
            },
            {
              label: 'Marginal Rate',
              value: `${generatePercent(
                income_tax?.marginal_bracket,
                'None',
                100,
                1
              )}`,
            },
            {
              label: `Marginal Rate LTG`,
              value: `${generatePercent(
                income_tax?.marginal_cap_gains_bracket,
                'None',
                100,
                1
              )}`,
            },
            {
              label: 'Average Rate',
              value: `${
                average_rate === 'None'
                  ? average_rate
                  : generatePercent(average_rate, 'None', 1, 1)
              }`,
            },
            {
              label: 'Tax Exempt Int.',
              value: `${generateFloatValue(
                income_tax,
                'tax_exempt_interest',
                0,
                '$'
              )}`,
            },
          ],
        },
      ];
    }
  };

  const generatePlannrContent = () => {
    if (content && content.hasOwnProperty('partner_data')) {
      let view = [];

      if (content?.field_config?.sections?.show.includes('core_details')) {
        view.push({
          label: 'Core Details',
          value: 'basic',
          leftInfo: [
            {
              label: 'Title:',
              value: `${generateIntegrationValue(content.partner_data, 'title')}`,
            },
            {
              label: 'Name:',
              value: `${generateIntegrationValue(content.partner_data, 'name')}`,
            },
            {
              label: 'Middle Name:',
              value: `${generateIntegrationValue(
                content.partner_data,
                'middle_name'
              )}`,
            },
            {
              label: 'Known as:',
              value: `${generateIntegrationValue(
                content.partner_data,
                'nickname'
              )}`,
            },
            {
              label: 'DoB:',
              value:
                generateIntegrationValue(
                  content.partner_data,
                  'date_of_birth'
                ) !== 'None'
                  ? DateFormat(
                      generateIntegrationValue(
                        content.partner_data,
                        'date_of_birth'
                      ),
                      'll'
                    )
                  : 'None',
            },
            {
              label: 'Gender:',
              value:
                `${generateIntegrationValue(content.partner_data, 'gender')}`
                  .replace('_', ' ')
                  .toLowerCase()
                  .split(' ')
                  .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
                  .join(' '),
            },
            {
              label: 'Regulatory Classification:',
              value: CapitalizeFirstLetter(
                `${generateIntegrationValue(
                  content.partner_data,
                  'client_regulatory_classification'
                )}`
              ),
            },
          ],
          rightInfo: [
            {
              label: 'Nationality:',
              value: `${generateIntegrationValue(
                content.partner_data,
                'nationalities'
              )}`,
            },
            {
              label: 'Procedure adaptation:',
              value: `${generateIntegrationBool(
                content.partner_data,
                'procedure_adaptation'
              )}`,
            },
            {
              label: 'Adaptation notes:',
              value: `${generateIntegrationValue(
                content.partner_data,
                'adaption_details_notes'
              )}`,
            },
            {
              label: 'Vulnerable:',
              value: `${generateIntegrationValue(
                content.partner_data,
                'is_vulnerable'
              )}`,
            },
            {
              label: 'Vulnerability Details:',
              value: `${generateIntegrationValue(
                content.partner_data,
                'vulnerability_details'
              )}`,
            },
            {
              label: 'National Insurance:',
              value: `${generateIntegrationValue(
                content.partner_data,
                'ni_number'
              )}`,
            },
            {
              label: 'Current Status:',
              value: `${generateIntegrationValue(
                content.partner_data,
                'status',
                'name'
              )}`,
            },
          ],
        });
      }

      if (
        content?.field_config?.sections?.show.includes('included_in') &&
        content?.partner_data.hasOwnProperty('included_in')
      ) {
        let included_in = {
          label: 'Included In',
          value: 'panel',
          listInfo: content.partner_data.included_in.data.map((i) => {
            return {
              value: i['name'],
              ...(content?.field_config?.sections?.links.includes(
                'included_in'
              ) && { external_url: i['external_url'] }),
            };
          }),
        };
        view.push(included_in);
      }

      if (
        content?.field_config?.sections?.show.includes('related_clients') &&
        content?.partner_data.hasOwnProperty('related_clients')
      ) {
        let related_clients = {
          label: 'Related Clients',
          value: 'panel',
          listInfo: content.partner_data.related_clients.data.map((i) => {
            return {
              value: i['name'],
              sub_value: i['relationship'],
              ...(content?.field_config?.sections?.links.includes(
                'related_clients'
              ) && { external_url: i['external_url'] }),
            };
          }),
        };
        view.push(related_clients);
      }

      if (
        content?.field_config?.sections?.show.includes('cases') &&
        content?.partner_data.hasOwnProperty('cases')
      ) {
        let related_clients = {
          label: 'Cases',
          value: 'panel',
          listInfo: content.partner_data.cases.data.map((i) => {
            return {
              value: i['name'],
              sub_value: `With ${i['participants_count']} participants`,
              ...(content?.field_config?.sections?.links.includes('cases') && {
                external_url: i['external_url'],
              }),
            };
          }),
        };
        view.push(related_clients);
      }
      return view;
    }
  };

  const generatePlannrInstrumentContent = () => {
    let view = [];
    if (content && content.hasOwnProperty('partner_data')) {
      if (content?.fields_config?.sections?.show.includes('plan_details')) {
        view.push({
          label: 'Plan Details',
          sub_label: 'Core Details',
          value: 'basic',
          leftInfo: [
            {
              label: 'Wrap Product:',
              value: `${generateIntegrationBool(content.partner_data, 'wrap', 'Yes', 'No', 'No')}`,
            },
            {
              label: 'Provider:',
              value: `${generateIntegrationValue(content.partner_data, 'provider', 'name')}`,
            },
            {
              label: 'Policy Number:',
              value: `${generateIntegrationValue(content.partner_data, 'policy_number')}`,
            },
            {
              label: 'Proposal Reference:',
              value: `${generateIntegrationValue(content.partner_data, 'proposal_reference')}`,
            },
            {
              label: 'Opened at:',
              value:
                generateIntegrationValue(content.partner_data, 'opened_at') !==
                'None'
                  ? DateFormat(
                      generateIntegrationValue(
                        content.partner_data,
                        'opened_at'
                      ),
                      'll'
                    )
                  : 'None',
            },
          ],
          rightInfo: [
            {
              label: 'Type:',
              value: CapitalizeFirstLetter(
                `${generateIntegrationValue(content.partner_data, 'sub_type_label')}`
              ),
            },
            {
              label: 'Active Adviser:',
              value: `${generateIntegrationValue(content.partner_data, 'active_adviser', 'name')}`,
            },
            {
              label: 'Last Valued:',
              value: `${content.partner_data?.latest_valuations[0]?.value?.formatted || 'No Valuations Yet'}`,
            },
            {
              label: 'Plan Status:',
              value: CapitalizeFirstLetter(
                `${generateIntegrationValue(content.partner_data, 'status')}`
              ),
            },
            ...insertIf(content.partner_data.total_benefit_amount, {
              label: 'Total Benefit Amount:',
              value: generateIntegrationValue(
                content.partner_data,
                'total_benefit_amount',
                'formatted'
              ),
            }),
          ],
        });
      }
    }

    if (
      ['group_protection', 'protection_policy'].includes(
        content.partner_data?.type
      )
    ) {
      view.push({
        label: 'Insurance Details',
        listInfo: [
          {
            label: 'Total Benefit Amount for Critical Illness',
            value: generateIntegrationValue(
              content.partner_data?.total_benefits,
              'critical_illness',
              'formatted'
            ),
          },
          {
            label: 'Total Benefit Amount for Income Insurance',
            value: generateIntegrationValue(
              content.partner_data?.total_benefits,
              'income_insurance',
              'formatted'
            ),
          },
          {
            label: 'Total Benefit Amount for Life Assurance',
            value: generateIntegrationValue(
              content.partner_data?.total_benefits,
              'life_assurance',
              'formatted'
            ),
          },
        ],
      });
    }
    return view;
  };

  const generateOrionContent = () => {
    return (
      <IntegrationInfoContainer>
        <SelectRowContainer>
          <SelectContainer style={{ width: '35%', marginRight: '8px' }}>
            <InputLabel>Period</InputLabel>
            <Select
              selectedOption={selectedPeriod}
              onChange={(e) => setSelectedPeriod(e)}
              options={periodOptions}
              isSearchable={true}
              placeholder={'Select Period'}
              autoFocus={false}
              closeMenuOnSelect={true}
              isDisabled={selectedWidget.noPeriod}
            />
          </SelectContainer>
          <SelectContainer style={{ width: '65%' }}>
            <InputLabel>Widget</InputLabel>
            <Select
              selectedOption={selectedWidget}
              onChange={(e) => setSelectedWidget(e)}
              options={widgetOptions}
              isSearchable={true}
              placeholder={'Select Widget'}
              autoFocus={false}
              closeMenuOnSelect={true}
            />
          </SelectContainer>
        </SelectRowContainer>
        <WidgetContainer>
          <iframe
            width="100%"
            height="500"
            id="orion_viewport"
            frameBorder="0"
            src={load_iframe()}
            title="orion"
          />
        </WidgetContainer>
      </IntegrationInfoContainer>
    );
  };

  const generateChart = () => {
    try {
      const data = content.account_history.map(function (i) {
        return {
          t: DateFormat(i['asOfDate'] + ' 12:00:00', 'YYYY.MM.DD HH:mm:ss'),
          y: i['value'],
        };
      });
      if (data.length > 0) {
        const labels = data.map(function (i) {
          return i['t'];
        });
        const values = data.map(function (i) {
          return Math.round(i['y']);
        });
        if (chartRef?.current) {
          const myChartRef = chartRef.current.getContext('2d');
          // eslint-disable-next-line no-unused-vars
          const myChart = new Chart(myChartRef, {
            type: 'line',
            data: {
              labels: labels,
              datasets: [
                {
                  data: values,
                  fill: true,
                  borderColor: 'rgb(48, 123, 246)',
                  backgroundColor: 'rgb(132,189,255)',
                  lineTension: 0.1,
                },
              ],
            },
            options: {
              plugins: {
                legend: {
                  display: false,
                },
                tooltip: {
                  callbacks: {
                    label: function (tooltipItem) {
                      return FormatCurrency(tooltipItem.raw);
                    },
                  },
                },
              },
              scales: {
                x: {
                  type: 'time',
                  distribution: 'linear',
                  time: {
                    displayFormats: {
                      quarter: 'MMM YYYY',
                    },
                    tooltipFormat: 'MM/YYYY',
                    unit: 'quarter',
                  },
                },
                y: {
                  ticks: {
                    callback: function (label, index, labels) {
                      return FormatCurrency(label);
                    },
                  },
                },
              },
            },
          });
        }
      } else {
        console.log('no data available');
      }
    } catch (e) {
      console.log('chart error', e);
    }
  };

  const generateOrionInstrumentContent = () => {
    return (
      <IntegrationInfoContainer>
        <IntegrationName>Historical Account Value</IntegrationName>
        {content.account_history ? (
          <div
            style={{
              height: '413px',
              width: isSidebar ? '310px' : '420px',
              marginLeft: '-10px',
            }}
          >
            <canvas
              id="chartContainer"
              ref={chartRef}
              width={isSidebar ? '300' : '400'}
              height="400"
            />
            {generateChart()}
          </div>
        ) : (
          <IntegrationErrorMessage>
            <img src={errorCircleRed} alt="error" data-image="error" />
            Error: Cannot access account history
          </IntegrationErrorMessage>
        )}
      </IntegrationInfoContainer>
    );
  };

  const generateSalesforceContent = () => {
    if (member && member?.id && role && role?.value) {
      if (role.value === 'primary') {
        return (
          <SalesforceInfoContainer>
            <SalesforceButtonContainer>
              <Button
                text="Unlink Household"
                theme={ButtonThemes.error}
                onClick={() => onClick()}
                showLoading={onClickLoading}
                loadingText="Unlinking"
              />
            </SalesforceButtonContainer>
            <HouseholdUnlinkText>
              <RequiredStar>*</RequiredStar> This Member is the Primary,
              unlinking them will unlink the entire household.
            </HouseholdUnlinkText>
          </SalesforceInfoContainer>
        );
      }
    }
    return (
      <SalesforceInfoContainer>
        <SalesforceButtonContainer>
          <Button
            text="Remove Link"
            theme={ButtonThemes.error}
            onClick={() => onClick()}
            showLoading={onClickLoading}
            loadingText="Unlinking"
          />
        </SalesforceButtonContainer>
      </SalesforceInfoContainer>
    );
  };

  const generateRedtailContent = () => {
    if (content && content.hasOwnProperty('partner_data')) {
      return [
        {
          label: 'Basic Information',
          value: 'basic',
          leftInfo: [
            {
              label: 'Name',
              value: `${generateIntegrationValue(
                content.partner_data.ContactRecord,
                'Firstname'
              )} ${generateIntegrationValue(
                content.partner_data.ContactRecord,
                'Lastname'
              )}`,
            },
            {
              label: 'Nickname',
              value: generateIntegrationValue(
                content.partner_data.ContactRecord,
                'Nickname'
              ),
            },
            {
              label: 'DOB',
              value:
                generateIntegrationValue(
                  content.partner_data.ContactRecord,
                  'DateOfBirth'
                ) !== 'None'
                  ? DateFormat(
                      generateIntegrationValue(
                        content.partner_data.ContactRecord,
                        'DateOfBirth'
                      ),
                      'll'
                    )
                  : 'None',
            },
          ],
          rightInfo: [
            {
              label: 'Family',
              value: generateIntegrationValue(
                content.partner_data.ContactRecord,
                'Familyname'
              ),
            },
            {
              label: 'Marital Status',
              value: generateIntegrationValue(
                content.partner_data.ContactRecord,
                'MaritalStatus'
              ),
            },
            {
              label: 'Marital Date',
              value:
                generateIntegrationValue(
                  content.partner_data.ContactRecord,
                  'MaritalDate'
                ) !== 'None'
                  ? DateFormat(
                      generateIntegrationValue(
                        content.partner_data.ContactRecord,
                        'MaritalDate'
                      ),
                      'll'
                    )
                  : 'None',
            },
          ],
        },
        {
          label: 'Address',
          value: 'address',
          listInfo: generateAddress(
            content.partner_data,
            'Address',
            'Type',
            'Address1',
            'Address2',
            'City',
            'State',
            'Zip'
          ),
        },
        {
          label: 'Phone',
          value: 'phone',
          listInfo: generateList(
            content.partner_data,
            'Phone',
            'Type',
            'Number_Formatted'
          ),
        },
        {
          label: 'Internet',
          value: 'Email',
          listInfo: generateList(
            content.partner_data,
            'Internet',
            'Type',
            'Address'
          ),
        },
      ];
    }
  };

  const generateRedtailAccountFlagsField = (object, keys) => {
    let flags = keys.filter((key) => {
      return object.hasOwnProperty(key) && object[key] === true;
    });
    let flag_list = flags.map((flag) => flag.replace('_', '-'));
    if (flag_list.length === 0) {
      flag_list = '-';
    }
    return flag_list;
  };

  const generateRedtailAccountContent = () => {
    const basic = {
      leftInfo: [
        {
          label: 'Account #',
          value: generateIntegrationValue(
            content.partner_data,
            'number',
            null,
            '-'
          ),
        },
        {
          label: 'Company',
          value: generateIntegrationValue(
            content.partner_data,
            'company',
            null,
            '-'
          ),
        },
        {
          label: 'Status',
          value: generateIntegrationValue(content.partner_data, 'status'),
        },
        {
          label: 'Balance',
          value: generateIntegrationCurrency(
            content.partner_data,
            'balance',
            null,
            true
          ),
        },
      ],
      rightInfo: [
        {
          label: 'Type',
          value: generateIntegrationValue(content.partner_data, 'account_type'),
        },
        {
          label: 'Tax Qualified Type',
          value: generateIntegrationValue(
            content.partner_data,
            'taxqualified_type'
          ),
        },
        {
          label: 'Product',
          value: generateIntegrationValue(content.partner_data, 'product'),
        },
        {
          label: 'Flags',
          value: generateRedtailAccountFlagsField(content.partner_data, [
            'discretionary',
            'managed',
            'held_away',
          ]),
        },
      ],
    };

    const assetValues = content.partner_data['assets']
      .slice(0, 10)
      .map((asset, index) => {
        return {
          name: generateIntegrationValue(asset, 'name', null, '-'),
          ticker: generateIntegrationValue(asset, 'ticker', null, '-'),
          cusip: generateIntegrationValue(asset, 'cusip', null, '-'),
          shares: {
            count: parseInt(
              generateIntegrationValue(asset, 'shares', null, '-')
            ),
            as_of:
              generateIntegrationValue(asset, 'shares_asof') !== 'None'
                ? DateFormatUTC(
                    generateIntegrationValue(asset, 'shares_asof'),
                    'MM/DD/YY'
                  )
                : '-',
          },
          price: {
            amount: generateIntegrationCurrency(asset, 'price', null, true),
            as_of:
              generateIntegrationValue(asset, 'price_asof') !== 'None'
                ? DateFormatUTC(
                    generateIntegrationValue(asset, 'price_asof'),
                    'MM/DD/YY'
                  )
                : '-',
          },
          balance: generateIntegrationCurrency(asset, 'balance', null, true),
        };
      });

    return (
      <RedtailContainer>
        <RedtailInformationChunk style={{ marginBottom: '15px' }}>
          <RedtailInformationHeading>
            Basic Information
          </RedtailInformationHeading>
          <ContentContainer>
            <LeftContainer>
              {basic.leftInfo.map((info, index) => {
                return (
                  <RedtailInfoContainer key={index}>
                    {info.value instanceof Array ? (
                      <>
                        <InfoLabel>{info.label}</InfoLabel>
                        <InfoContent>
                          {info.value.map((val, index) => (
                            <RedtailTag key={index}>{val}</RedtailTag>
                          ))}
                        </InfoContent>
                      </>
                    ) : (
                      <>
                        {info.value !== 'None' && (
                          <>
                            <InfoLabel>{info.label}</InfoLabel>
                            <InfoContent>{info.value}</InfoContent>
                          </>
                        )}
                      </>
                    )}
                  </RedtailInfoContainer>
                );
              })}
            </LeftContainer>
            <RightContainer>
              {basic.rightInfo.map((info, index) => {
                return (
                  <RedtailInfoContainer key={index}>
                    {info.value instanceof Array ? (
                      <>
                        <InfoLabel>{info.label}</InfoLabel>
                        <InfoContent>
                          {info.value.map((val, index) => (
                            <RedtailTag key={index}>{val}</RedtailTag>
                          ))}
                        </InfoContent>
                      </>
                    ) : (
                      <>
                        {info.value !== 'None' && (
                          <>
                            <InfoLabel>{info.label}</InfoLabel>
                            <InfoContent>{info.value}</InfoContent>
                          </>
                        )}
                      </>
                    )}
                  </RedtailInfoContainer>
                );
              })}
            </RightContainer>
          </ContentContainer>
        </RedtailInformationChunk>
        <RedtailInformationChunk>
          <RedtailInformationHeading>Account Assets</RedtailInformationHeading>
          <RedtailTable $isSidebar={isSidebar}>
            <thead>
              <tr>
                <th>Asset Name</th>
                <th>Symbol</th>
                <th>CUSIP</th>
                <th>Shares</th>
                <th>Price</th>
                <th>Balance</th>
              </tr>
            </thead>
            {assetValues.length === 0 ? (
              <tbody></tbody>
            ) : (
              <tbody>
                {assetValues.map((option, index) => {
                  return (
                    <tr key={index}>
                      <td>{option.name}</td>
                      <td>{option.ticker}</td>
                      <td>{option.cusip}</td>
                      <td
                        style={{
                          paddingRight: '10px',
                        }}
                      >
                        <div>{option.shares.count}</div>
                        <div>{option.shares.as_of}</div>
                      </td>
                      <td
                        style={{
                          paddingRight: '10px',
                        }}
                      >
                        <div>{option.price.amount}</div>
                        <div>{option.price.as_of}</div>
                      </td>
                      <td>{option.balance}</td>
                    </tr>
                  );
                })}
              </tbody>
            )}
          </RedtailTable>
          {assetValues.length === 0 && (
            <div
              style={{
                textAlign: 'center',
                fontSize: 'smaller',
                fontWeight: 600,
                borderTop: colors.paleGrey,
                borderTopStyle: 'solid',
                borderTopWidth: '1px',
                padding: '10px',
              }}
            >
              No account assets available to display.
            </div>
          )}
        </RedtailInformationChunk>
      </RedtailContainer>
    );
  };

  const generateRedtailInstrumentContent = () => {
    if (content?.partner_data?.['redtail_instrument_type'] === 'account') {
      return generateRedtailAccountContent();
    } else {
      return (
        <RedtailContainer>
          <RedtailInformationChunk>
            <RedtailInformationHeading>
              {content.partner_data.redtail_instrument_type}
            </RedtailInformationHeading>
            <RedtailTable $isSidebar={isSidebar}>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Type</th>
                  <th>Balance</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{content.partner_data.name}</td>
                  <td>{content.partner_data.financial_type}</td>
                  <td>
                    {generateIntegrationCurrency(
                      content.partner_data,
                      'amount',
                      null,
                      true
                    )}
                  </td>
                </tr>
              </tbody>
            </RedtailTable>
          </RedtailInformationChunk>
        </RedtailContainer>
      );
    }
  };

  const generateMorningstarAWSContent = () => {
    if (content && content.hasOwnProperty('partner_data')) {
      if (content?.partner_data?.gauge_image_url) {
        return (
          <MorningstarAWSContainer>
            <Gauge
              src={content?.partner_data?.gauge_image_url}
              alt="target"
              data-image="msaws"
            />
            <Legend
              src={integration?.legend}
              alt="target"
              data-image="msaws-legend"
            />
          </MorningstarAWSContainer>
        );
      } else {
        return (
          <MorningstarAWSContainer>
            No Risk Profile Found
          </MorningstarAWSContainer>
        );
      }
    }
  };

  const generateMorningstarContent = () => {
    if (content && content.hasOwnProperty('partner_data')) {
      return [
        {
          label: 'Basic Information',
          value: 'basic',
          leftInfo: [
            {
              label: 'Name',
              value: `${generateIntegrationValue(
                content.partner_data,
                'firstName'
              )} ${generateIntegrationValue(content.partner_data, 'lastName')}`,
            },
            {
              label: 'DOB',
              value:
                generateIntegrationValue(
                  content.partner_data,
                  'dateOfBirth'
                ) !== 'None'
                  ? DateFormat(
                      generateIntegrationValue(
                        content.partner_data,
                        'dateOfBirth'
                      ),
                      'll'
                    )
                  : 'None',
            },
          ],
          rightInfo: [
            {
              label: 'Relationship',
              value: `${generateIntegrationValue(
                content.partner_data,
                'relationshipName'
              )}`,
            },
            {
              label: 'Desired Retirement Age',
              value: `${generateIntegrationValue(
                content.partner_data,
                'desiredRetirementAge'
              )}`,
            },
          ],
        },
        {
          label: 'Address',
          value: 'address',
          listInfo: [
            {
              label: 'Home Address',
              value: content.partner_data.hasOwnProperty('address1')
                ? `${generateAddressItem(
                    content.partner_data.address1,
                    content.partner_data.city
                  )}${generateAddressItem(
                    content.partner_data.city,
                    content.partner_data.state
                  )}${generateAddressItem(
                    content.partner_data.state,
                    content.partner_data.zipCode,
                    false
                  )} ${content.partner_data.zipCode}`
                : '',
            },
          ],
        },
        {
          label: 'Contact Information',
          value: 'contact',
          listInfo: [
            {
              label: 'Home Phone',
              value: `${generateIntegrationValue(
                content.partner_data,
                'homePhone'
              )}`,
            },
            {
              label: 'Work Phone',
              value: `${generateIntegrationValue(
                content.partner_data,
                'workPhone'
              )}`,
            },
            {
              label: 'Email',
              value: `${generateIntegrationValue(
                content.partner_data,
                'email'
              )}`,
            },
          ],
        },
      ];
    }
  };

  const generateSchwabContent = () => {
    if (content && content.hasOwnProperty('partner_data')) {
      return [
        {
          label: 'Basic Information',
          value: 'basic',
          leftInfo: [
            {
              label: 'Name',
              value: `${generateIntegrationValue(
                content?.partner_data,
                'name'
              )}`,
            },
            {
              label: 'DOB',
              value:
                generateIntegrationValue(
                  content?.partner_data,
                  'dateOfBirth'
                ) !== 'None'
                  ? DateFormat(
                      generateIntegrationValue(
                        content?.partner_data,
                        'dateOfBirth'
                      ),
                      'll'
                    )
                  : 'None',
            },
          ],
          rightInfo: [
            {
              label: 'Role',
              value: `${generateIntegrationValue(
                content?.partner_data,
                'role'
              )}`,
            },
            {
              label: 'Employment',
              value: `${generateIntegrationValue(
                content?.partner_data,
                'employment'
              )}`,
            },
          ],
        },
        {
          label: 'Address',
          value: 'address',
          listInfo: [
            {
              label: 'Home Address',
              value: content?.partner_data?.hasOwnProperty('address1')
                ? `${generateAddressItem(
                    content?.partner_data?.address1,
                    content?.partner_data?.city
                  )}${generateAddressItem(
                    content?.partner_data?.city,
                    content?.partner_data?.state
                  )}${generateAddressItem(
                    content?.partner_data?.state,
                    content?.partner_data?.zipCode,
                    false
                  )} ${content?.partner_data?.zipCode}`
                : '',
            },
          ],
        },
        {
          label: 'Contact Information',
          value: 'contact',
          listInfo: [
            {
              label: 'Home Phone',
              value: `${generateIntegrationValue(
                content?.partner_data,
                'homePhone'
              )}`,
            },
            {
              label: 'Email',
              value: `${generateIntegrationValue(
                content?.partner_data,
                'email'
              )}`,
            },
          ],
        },
      ];
    }
  };

  const generateFloatValue = (obj, key, decimal_places = 0, symbol = null) => {
    let val = generateIntegrationValue(obj, key);
    let float_val = parseFloat(val)
      .toFixed(decimal_places)
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    return isNaN(parseFloat(val))
      ? val
      : symbol
        ? symbol.concat(float_val)
        : float_val;
  };

  const generateMorningstarValue = (obj, key) => {
    let val = generateIntegrationValue(obj, key);
    return isNaN(parseFloat(val))
      ? val
      : parseFloat(val)
          .toFixed(2)
          .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  };

  const generateMorningstarInstrumentContent = () => {
    const basic = {
      leftInfo: [
        {
          label: 'Title',
          value: generateIntegrationValue(content.partner_data, 'name'),
        },
        {
          label: 'Type',
          value: generateIntegrationValue(
            content.partner_data,
            'accountTypeName'
          ),
        },
      ],
      rightInfo: [
        (() => {
          let label = 'Market Value';
          try {
            label = `Market Value(${content.partner_data.views.basic.outputCurrency})`;
          } catch (err) {
            // no outputCurrency
          }
          return {
            label: label,
            value: generateMorningstarValue(
              content.partner_data.views.basic,
              'marketValue'
            ),
          };
        })(),
        {
          label: 'Account Owners',
          value: content.partner_data.views.basic.accountOwners
            .split(';')
            .filter((el, ind) => ind % 2 === 1)
            .join(', '),
        },
      ],
    };
    const topHoldings = content.partner_data.hasOwnProperty('topHoldingList')
      ? content.partner_data.topHoldingList[
          Object.keys(content.partner_data.topHoldingList)[0]
        ]['top-holdings']
      : [];

    const valueOptions = topHoldings.slice(0, 10).map((holding, index) => {
      return {
        number: index + 1,
        label:
          holding.hasOwnProperty('symbol') & (holding['symbol'] !== '')
            ? holding['symbol']
            : holding['security-name'],
        tooltip: holding['security-name'],
        weight: generateMorningstarValue(holding, 'percentage-of-asset'),
        value: generateMorningstarValue(holding, 'market-value'),
        quantity: generateMorningstarValue(holding, 'quantity'),
        price: generateMorningstarValue(holding, 'price'),
      };
    });

    return (
      <IntegrationInfoContainer>
        <InformationChunk>
          <MorningstarInformationHeading>
            Account Analysis
            <span>
              {content.partner_data.views.basic.marketValueDate} (Day End)
            </span>
          </MorningstarInformationHeading>
          <ContentContainer $isSidebar={isSidebar}>
            <LeftContainer>
              {basic.leftInfo.map((info, index) => {
                return (
                  <InfoContainer key={index}>
                    <InfoLabel
                      style={
                        info.label === 'None'
                          ? {
                              color: colors.paleGrey,
                              fontStyle: 'italic',
                              fontWeight: fonts.regular,
                            }
                          : null
                      }
                    >
                      {info.label}
                    </InfoLabel>
                    <InfoContent>{info.value}</InfoContent>
                  </InfoContainer>
                );
              })}
            </LeftContainer>
            <RightContainer>
              {basic.rightInfo.map((info, index) => {
                return (
                  <InfoContainer key={index}>
                    <InfoLabel
                      style={
                        info.label === 'None'
                          ? {
                              color: colors.paleGrey,
                              fontStyle: 'italic',
                              fontWeight: fonts.regular,
                            }
                          : null
                      }
                    >
                      {info.label}
                    </InfoLabel>
                    <InfoContent>{info.value}</InfoContent>
                  </InfoContainer>
                );
              })}
            </RightContainer>
          </ContentContainer>
        </InformationChunk>
        <InformationChunk>
          <MorningstarInformationHeading>
            Top {Object.keys(valueOptions).length} Holdings
          </MorningstarInformationHeading>
          <MorningstarTable>
            <thead>
              <tr>
                <th></th>
                <th>Weight %</th>
                <th>Market Value(USD)</th>
                <th>Number of Shares</th>
                <th>Share Price</th>
              </tr>
            </thead>
            <tbody>
              {valueOptions.map((option, index) => {
                return (
                  <tr key={index}>
                    <MorningstarTableLabel>
                      {`${option.number} ${option.label}`}
                      <Tooltip
                        title={option.tooltip}
                        position="left"
                        trigger="mouseenter click"
                        followCursor="true"
                        delay={0}
                        arrow={true}
                        arrowSize={'small'}
                        animation={'fade'}
                        animateFill={false}
                        transitionFlip={false}
                        size={'small'}
                      >
                        <TooltipIcon
                          src={info}
                          alt="info"
                          data-image="tooltip-info"
                        />
                      </Tooltip>
                    </MorningstarTableLabel>
                    <td
                      style={{
                        color: isZero(option, 'weight')
                          ? colors.paleGrey
                          : checkNegative(option, 'weight')
                            ? messageColors.error
                            : null,
                      }}
                    >
                      {option.weight}
                    </td>
                    <td
                      style={{
                        paddingRight: '10px',
                        color: isZero(option, 'value')
                          ? colors.paleGrey
                          : checkNegative(option, 'value')
                            ? messageColors.error
                            : null,
                      }}
                    >
                      {option.value}
                    </td>
                    <td
                      style={{
                        paddingRight: '10px',
                        color: isZero(option, 'quantity')
                          ? colors.paleGrey
                          : checkNegative(option, 'quantity')
                            ? messageColors.error
                            : null,
                      }}
                    >
                      {option.quantity}
                    </td>
                    <td
                      style={{
                        paddingRight: '10px',
                        color: isZero(option, 'price')
                          ? colors.paleGrey
                          : checkNegative(option, 'price')
                            ? messageColors.error
                            : null,
                      }}
                    >
                      {option.price}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </MorningstarTable>
        </InformationChunk>
        <LastUpdatedValue>
          Data as of {DateFromNowUTC(content.partner_data_as_of)}
        </LastUpdatedValue>
      </IntegrationInfoContainer>
    );
  };

  const generateSchwabInstrumentContent = () => {
    const topHoldings = content?.partner_data?.hasOwnProperty('topHoldings')
      ? content?.partner_data.topHoldings
      : [];

    let outputCurrency = '$';
    if ('outputCurrency' in content?.partner_data) {
      outputCurrency = content?.partner_data?.outputCurrency;
    }

    const valueOptions = topHoldings.slice(0, 10).map((holding, index) => {
      return {
        number: index + 1,
        label:
          holding.hasOwnProperty('symbol') & (holding['symbol'] !== '')
            ? holding['symbol']
            : holding['security-name'],
        tooltip: holding['security-name'],
        weight: holding['percentage-of-asset'] + '%',
        value: outputCurrency + holding['market-value'],
        quantity: holding['quantity'],
        price: outputCurrency + holding['price'],
      };
    });

    return (
      <IntegrationInfoContainer>
        <InformationChunk>
          <SchwabInformationHeading>
            Top {Object.keys(valueOptions)?.length} Holdings
          </SchwabInformationHeading>
          <SchwabTable $isSidebar={isSidebar}>
            <thead>
              <tr>
                <th style={{ minWidth: '40px' }}></th>
                <th>Weight %</th>
                <th>Market Value(USD)</th>
                <th>Number of Shares</th>
                <th>Share Price</th>
              </tr>
            </thead>
            <tbody>
              {valueOptions.map((option, index) => {
                return (
                  <tr key={index}>
                    <SchwabTableLabel>
                      <Tooltip
                        title={option.tooltip}
                        position={'top'}
                        trigger="mouseenter click"
                        followCursor={!isSidebar}
                        delay={0}
                        arrow={!isSidebar}
                        arrowSize={'small'}
                        animation={'fade'}
                        animateFill={false}
                        transitionFlip={false}
                        size={'small'}
                      >
                        {`${option.number} ${option.label}`}
                        {!isSidebar && (
                          <TooltipIcon
                            src={info}
                            alt="info"
                            data-image="tooltip-info"
                          />
                        )}
                      </Tooltip>
                    </SchwabTableLabel>
                    <td
                      style={{
                        color: isZero(option, 'weight')
                          ? colors.paleGrey
                          : checkNegative(option, 'weight')
                            ? messageColors.error
                            : null,
                      }}
                    >
                      {option.weight}
                    </td>
                    <td
                      style={{
                        color: isZero(option, 'value')
                          ? colors.paleGrey
                          : checkNegative(option, 'value')
                            ? messageColors.error
                            : null,
                      }}
                    >
                      {option.value}
                    </td>
                    <td
                      style={{
                        color: isZero(option, 'quantity')
                          ? colors.paleGrey
                          : checkNegative(option, 'quantity')
                            ? messageColors.error
                            : null,
                      }}
                    >
                      {option.quantity}
                    </td>
                    <td
                      style={{
                        color: isZero(option, 'price')
                          ? colors.paleGrey
                          : checkNegative(option, 'price')
                            ? messageColors.error
                            : null,
                      }}
                    >
                      {option.price}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </SchwabTable>
        </InformationChunk>
        <LastUpdatedValue>
          Data as of {DateFromNowUTC(content.partner_data_as_of)}
        </LastUpdatedValue>
      </IntegrationInfoContainer>
    );
  };

  const generateVeoContent = () => {
    if (content && content.hasOwnProperty('partner_data')) {
      return [
        {
          label: 'Basic Information',
          value: 'basic',
          leftInfo: [
            {
              label: 'First Name',
              value: generateIntegrationValue(
                content?.partner_data,
                'firstName'
              ),
            },
          ],
          rightInfo: [
            {
              label: 'Last Name',
              value: generateIntegrationValue(
                content?.partner_data,
                'lastName'
              ),
            },
          ],
        },
        {
          label: 'Address',
          value: 'address',
          listInfo: [
            {
              label: 'Address',
              value: content?.partner_data?.hasOwnProperty('address')
                ? `${generateAddressItem(
                    content?.partner_data?.address?.address1,
                    content?.partner_data?.address?.city
                  )}${generateAddressItem(
                    content?.partner_data?.address?.city,
                    content?.partner_data?.address?.state
                  )}${generateAddressItem(
                    content?.partner_data?.address?.state,
                    content?.partner_data?.address?.zip,
                    false
                  )} ${content?.partner_data?.address?.zip}`
                : '',
            },
          ],
        },
        {
          label: 'Phone',
          value: 'phone',
          listInfo: [
            {
              label: 'Primary',
              value: generateIntegrationValue(
                content.partner_data,
                'address',
                'phone'
              ),
            },
            {
              label: 'Secondary',
              value: generateIntegrationValue(
                content.partner_data,
                'address',
                'secondaryPhone'
              ),
            },
          ],
        },
        {
          label: 'Internet',
          value: 'Email',
          listInfo: [
            {
              label: 'Email',
              value: generateIntegrationValue(
                content.partner_data,
                'address',
                'email'
              ),
            },
          ],
        },
      ];
    }
  };

  const generateVeoInstrumentContent = () => {
    const basic = [
      {
        label: 'Title',
        value: generateIntegrationValue(content.partner_data, 'accountTitle'),
      },
      {
        label: 'Type',
        value: generateIntegrationValue(content.partner_data, 'accountType'),
      },
    ];

    const valueOptions = [
      {
        label: 'Account',
        value: 'accountValue',
        netChange: 'accountValueNetChange',
      },
      {
        label: 'Accrued Interest',
        value: 'accruedInterest',
        netChange: 'accruedInterestNetChange',
      },
      {
        label: 'Bonds',
        value: 'bondsValue',
        netChange: 'bondValueNetChange',
      },
      {
        label: 'Cash',
        value: 'cashNetBalance',
        netChange: 'cashBalanceNetChange',
      },
      {
        label: 'Cash Equivalent',
        value: 'cashEquivalent',
        netChange: 'cashEquivalentNetChange',
      },
      {
        label: 'Long Positions',
        value: 'longPositions',
        netChange: 'longPositionsNetChange',
      },
      {
        label: 'Money Market',
        value: 'moneyMarket',
        netChange: 'moneyMarketNetChange',
      },
      {
        label: 'Mutual Fund',
        value: 'mutualFundValue',
        netChange: 'mutualFundValue',
      },
      {
        label: 'Options',
        value: 'optionsValue',
        netChange: 'optionsValueNetChange',
      },
      {
        label: 'Pending Deposits',
        value: 'pendingDeposits',
        netChange: 'pendingDepositsNetChange',
      },
      {
        label: 'Short Options',
        value: 'shortOptionValue',
        netChange: 'shortOptionValueNetChange',
      },
      {
        label: 'Short Positions',
        value: 'shortPositions',
        netChange: 'shortPositionsNetChange',
      },
      {
        label: 'Stocks',
        value: 'stocksValue',
        netChange: 'stocksValueNetChange',
      },
    ];

    return (
      <IntegrationInfoContainer>
        <InformationChunk>
          <InformationHeading>Account Info</InformationHeading>
          <ContentContainer $isSidebar={isSidebar}>
            <LeftContainer>
              {basic.map((info, index) => {
                return (
                  <InfoContainer key={index}>
                    <InfoLabel
                      style={
                        info.label === 'None'
                          ? {
                              color: colors.paleGrey,
                              fontStyle: 'italic',
                              fontWeight: fonts.regular,
                            }
                          : null
                      }
                    >
                      {info.label}
                    </InfoLabel>
                    <InfoContent>{info.value}</InfoContent>
                  </InfoContainer>
                );
              })}
            </LeftContainer>
          </ContentContainer>
        </InformationChunk>
        <InformationChunk>
          <VeoTable>
            <thead>
              <tr>
                <th></th>
                <th>Value</th>
                <th style={{ paddingRight: '10px' }}>Net Change</th>
              </tr>
            </thead>
            <tbody>
              {valueOptions.map((option, index) => {
                return (
                  <tr key={index}>
                    <VeoTableLabel>{option.label}</VeoTableLabel>
                    <td
                      style={{
                        color: isZero(
                          content.partner_data,
                          'balance',
                          option.value
                        )
                          ? colors.paleGrey
                          : checkNegative(
                                content.partner_data,
                                'balance',
                                option.value
                              )
                            ? messageColors.error
                            : null,
                      }}
                    >
                      {generateIntegrationCurrency(
                        content.partner_data,
                        'balance',
                        option.value
                      )}
                    </td>
                    <td
                      style={{
                        paddingRight: '10px',
                        color: isZero(
                          content.partner_data,
                          'balance',
                          option.netChange
                        )
                          ? colors.paleGrey
                          : checkNegative(
                                content.partner_data,
                                'balance',
                                option.netChange
                              )
                            ? messageColors.error
                            : null,
                      }}
                    >
                      {generateIntegrationCurrency(
                        content.partner_data,
                        'balance',
                        option.netChange
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </VeoTable>
        </InformationChunk>
        <LastUpdatedValue>
          Data as of {DateFromNowUTC(content.partner_data_as_of)}
        </LastUpdatedValue>
      </IntegrationInfoContainer>
    );
  };

  const generateWealthboxContent = () => {
    if (
      content &&
      content.hasOwnProperty('partner_data') &&
      content.partner_data
    ) {
      return [
        {
          label: 'Basic Information',
          value: 'basic',
          leftInfo: [
            {
              label: 'Name',
              value: generateIntegrationValue(content.partner_data, 'name'),
            },
            {
              label: 'DOB',
              value:
                generateIntegrationValue(content.partner_data, 'birth_date') !==
                'None'
                  ? DateFormat(
                      generateIntegrationValue(
                        content.partner_data,
                        'birth_date'
                      ),
                      'll'
                    )
                  : generateIntegrationValue(
                      content.partner_data,
                      'birth_date'
                    ),
            },
            {
              label: 'Gender',
              value: generateIntegrationValue(content.partner_data, 'gender'),
            },
            {
              label: 'Household',
              value: `${generateIntegrationValue(
                content.partner_data,
                'household',
                'name'
              )} (${generateIntegrationValue(
                content.partner_data,
                'household',
                'title'
              )})`,
            },
          ],
        },
        {
          label: 'Address',
          value: 'address',
          listInfo: generateList(
            content.partner_data,
            'street_addresses',
            'kind',
            'address'
          ),
        },
        {
          label: 'Phone',
          value: 'phone',
          listInfo: generateList(
            content.partner_data,
            'phone_numbers',
            'kind',
            'address'
          ),
        },
        {
          label: 'Email',
          value: 'Email',
          listInfo: generateList(
            content.partner_data,
            'email_addresses',
            'kind',
            'address'
          ),
        },
      ];
    }
  };

  const generateRiskalyzeContent = (isInstrument) => {
    return isInstrument ? (
      <div>
        <RiskalyzeImageContainer>
          <img src={content.risk_url} alt="target" data-image="riskalyze" />
        </RiskalyzeImageContainer>
        <TypeText>Account</TypeText>
        <RiskalyzeInfoContainer>
          <LeftContainer>
            <IntegrationLink
              href={content.contact_external_url}
              target="_blank"
              rel="noopener noreferrer"
              $fontSize={isSidebar ? '11px' : null}
            >
              <img src={launchLink} alt="link" data-image="launch-client" />
              View Nitrogen Client
            </IntegrationLink>
          </LeftContainer>
          <RightContainer>
            <IntegrationLink
              href={content.current_portfolio_url}
              target="_blank"
              rel="noopener noreferrer"
              $fontSize={isSidebar ? '11px' : null}
            >
              <img src={launchLink} alt="link" data-image="launch-portfolio" />
              View Nitrogen Portfolio
            </IntegrationLink>
          </RightContainer>
        </RiskalyzeInfoContainer>
      </div>
    ) : (
      <RiskalyzeInfoContainer>
        <LeftContainer>
          <RiskalyzeImageContainer>
            <img src={content.risk_url} alt="target" data-image="riskalyze" />
          </RiskalyzeImageContainer>
          <TypeText>Target</TypeText>
          <IntegrationLink
            href={content.external_url}
            target="_blank"
            rel="noopener noreferrer"
            $fontSize={isSidebar ? '11px' : null}
          >
            <img src={launchLink} alt="link" data-image="launch-client" />
            View Nitrogen Client
          </IntegrationLink>
        </LeftContainer>
        <RightContainer>
          <RiskalyzeImageContainer>
            <img
              src={content.current_portfolio_risk_url}
              alt="portfolio"
              data-image="portfolio"
            />
          </RiskalyzeImageContainer>
          <TypeText>Portfolio</TypeText>
          <IntegrationLink
            href={content.current_portfolio_url}
            target="_blank"
            rel="noopener noreferrer"
            $fontSize={isSidebar ? '11px' : null}
          >
            <img src={launchLink} alt="link" data-image="launch-portfolio" />
            View Nitrogen Portfolio
          </IntegrationLink>
        </RightContainer>
      </RiskalyzeInfoContainer>
    );
  };

  const generateBlackDiamondInstrumentContent = () => {
    const basic = {
      leftInfo: [
        {
          label: 'Name',
          value: generateIntegrationValue(
            content?.partner_data,
            'displayName',
            null,
            '--'
          ),
        },
        {
          label: 'Account Type',
          value: generateIntegrationValue(
            content?.partner_data,
            'accountType',
            'identifier',
            '--'
          ),
          addTitle: true,
        },
        {
          label: 'Custodian',
          value: generateIntegrationValue(
            content?.partner_data?.custodian,
            'name',
            null,
            '--'
          ),
        },
        {
          label: 'Owner',
          value: generateCombinedValues(
            content?.partner_data,
            'accountOwnerType',
            'accountOwners',
            null,
            'fullName'
          ),
        },
      ],
      rightInfo: [
        {
          label: 'Market Value',
          value: generateIntegrationCurrency(
            content?.partner_data,
            'currentEmv',
            'emv'
          ),
        },
        {
          label: 'Registration Type',
          value: generateIntegrationValue(
            content?.partner_data,
            'accountRegistrationType',
            'rmdTypeDisplayName',
            false
          )
            ? generateIntegrationValue(
                content?.partner_data,
                'accountRegistrationType',
                'rmdTypeDisplayName',
                '--'
              )
            : generateIntegrationValue(
                content?.partner_data,
                'accountRegistrationType',
                'identifier',
                '--'
              ),
        },
        {
          label: 'Position Only',
          value: generateIntegrationBool(
            content?.partner_data,
            'positionOnly',
            'Yes',
            'No'
          ),
        },
        {
          label: 'Category',
          value: generateIntegrationValue(
            content?.partner_data,
            'subCategory',
            'name',
            '--'
          ),
        },
      ],
    };

    const valueOptions = content?.partner_data?.holdings
      .slice(0, 10)
      .map((holding) => {
        return {
          name: generateIntegrationValue(holding, 'asset', 'name'),
          symbol: generateIntegrationValue(holding, 'asset', 'identifier'),
          value: generateIntegrationCurrency(holding, 'returnInfo', 'emv'),
          units: generateIntegrationValue(holding, 'returnInfo', 'units', '--'),
          date: generateIntegrationValue(
            holding,
            'returnInfo',
            'returnDate',
            false
          )
            ? DateFormat(
                generateIntegrationValue(holding, 'returnInfo', 'returnDate'),
                'MM/DD/YYYY'
              )
            : '--',
        };
      });

    return (
      <IntegrationInfoContainer>
        <InformationChunk>
          <BlackdiamondInformationHeading>
            Account Information as of{' '}
            {DateFormat(
              generateIntegrationValue(content?.partner_data, 'dataAsOfDate'),
              'MM/DD/YYYY'
            )}
          </BlackdiamondInformationHeading>
          <ContentContainer style={{ flexWrap: 'wrap' }} $isSidebar={isSidebar}>
            <LeftContainer style={{ width: '25%', boxSizing: 'border-box' }}>
              {basic.leftInfo.map((info, index) => {
                return (
                  <InfoContainer key={index}>
                    <InfoLabel
                      style={
                        info.label === 'None'
                          ? {
                              color: colors.paleGrey,
                              fontStyle: 'italic',
                              fontWeight: fonts.regular,
                            }
                          : null
                      }
                    >
                      {info.label}
                    </InfoLabel>
                    <InfoContent
                      style={{
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        width: '45%',
                      }}
                      {...(info?.value?.length > 15 && { title: info?.value })}
                    >
                      {info?.value}
                    </InfoContent>
                  </InfoContainer>
                );
              })}
            </LeftContainer>
            <RightContainer style={{ width: '25%', boxSizing: 'border-box' }}>
              {basic.rightInfo.map((info, index) => {
                return (
                  <InfoContainer key={index}>
                    <InfoLabel
                      style={
                        info.label === 'None'
                          ? {
                              color: colors.paleGrey,
                              fontStyle: 'italic',
                              fontWeight: fonts.regular,
                            }
                          : null
                      }
                    >
                      {info.label}
                    </InfoLabel>
                    <InfoContent
                      style={{
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        width: '45%',
                      }}
                      {...(info?.value.length > 15 && { title: info.value })}
                    >
                      {info?.value}
                    </InfoContent>
                  </InfoContainer>
                );
              })}
            </RightContainer>
          </ContentContainer>
        </InformationChunk>
        {valueOptions?.length > 0 && (
          <InformationChunk>
            <BlackdiamondInformationHeading>
              Top {Object.keys(valueOptions)?.length} Holdings
            </BlackdiamondInformationHeading>
            <ContentContainer $isSidebar={isSidebar}>
              <BlackdiamondTable>
                <thead>
                  <tr>
                    <th>Asset</th>
                    <th>Market Value</th>
                    <th>Units</th>
                    <th>Last Value Change</th>
                  </tr>
                </thead>
                <tbody>
                  {valueOptions.map((option, index) => {
                    return (
                      <tr key={index}>
                        <td>
                          {option?.symbol}
                          <Tooltip
                            title={option?.name}
                            position="left"
                            trigger="mouseenter click"
                            followCursor="true"
                            delay={0}
                            arrow={true}
                            arrowSize={'small'}
                            animation={'fade'}
                            animateFill={false}
                            transitionFlip={false}
                            size={'small'}
                          >
                            <TooltipIcon
                              src={info}
                              alt="info"
                              data-image="tooltip-info"
                            />
                          </Tooltip>
                        </td>
                        <td>{option?.value}</td>
                        <td>{option?.units}</td>
                        <td>{option?.date}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </BlackdiamondTable>
            </ContentContainer>
          </InformationChunk>
        )}
      </IntegrationInfoContainer>
    );
  };

  const generateBlackDiamondContent = () => {
    return (
      <BlackDiamondInfoContainer>
        {content &&
        content?.hasOwnProperty('partner_data') &&
        content?.partner_data ? (
          <BlackDiamondWarning>
            <strong>Note:</strong> Any posts to this timeline may also be
            visible to the following user(s) who are also part of the{' '}
            <strong>
              &quot;{content.partner_data?.relationship_name}&quot;
            </strong>{' '}
            relationship on Black Diamond:
          </BlackDiamondWarning>
        ) : (
          <BlackDiamondWarning>Error fetching partner data</BlackDiamondWarning>
        )}
        {content &&
        content?.hasOwnProperty('partner_contacts') &&
        content?.partner_contacts ? (
          <BlackDiamondMembersList>
            {content.partner_contacts.map((contact, index) => {
              return (
                <BlackDiamondMember key={index}>
                  <ContactIcon src={user} alt="user" data-image="user" />
                  <ContactName>
                    {contact?.firstName} {contact?.lastName}
                  </ContactName>
                </BlackDiamondMember>
              );
            })}
          </BlackDiamondMembersList>
        ) : (
          <BlackDiamondMembersList>
            <BlackDiamondMember>
              <ContactIcon src={user} alt="user" />
              {content &&
              content?.hasOwnProperty('partner_data') &&
              content?.partner_data ? (
                <ContactName>
                  {content.partner_data?.first_name}{' '}
                  {content.partner_data?.last_name}
                </ContactName>
              ) : (
                <ContactName>No Member</ContactName>
              )}
            </BlackDiamondMember>
          </BlackDiamondMembersList>
        )}
        {content &&
          content?.hasOwnProperty('partner_data') &&
          content?.partner_data?.timeline_url && (
            <BlackDiamondLink
              href={content.partner_data.timeline_url}
              target="_blank"
              rel="noopener noreferrer"
              onClick={(e) => download_pdf(e)}
            >
              Post to Black Diamond Timeline
            </BlackDiamondLink>
          )}
      </BlackDiamondInfoContainer>
    );
  };

  const renderAddeparRow = (row, index, tableHeaders) => {
    // holding class
    let theme = {
      fw: 700,
    };
    if (row.class === 'asset') {
      theme = {
        fw: 700,
        ti: '10px',
      };
    }
    if (row.class === 'security') {
      theme = {
        fw: 400,
        ti: '20px',
      };
    }

    let cn = null;
    if (row.value.length > 1 && row.value.substring(1, 2) === '-') {
      row.value = `(${row.value.replace('-', '')})`;
      cn = 'negative';
    }

    return (
      <ThemeProvider theme={theme}>
        <AddeparRow key={index} $isSidebar={isSidebar}>
          <th className={index === 0 ? 'holding' : null}>
            <Tooltip
              title={row.account}
              position="top"
              trigger="mouseenter click"
              followCursor="true"
              delay={0}
              arrow={true}
              arrowSize={'small'}
              animation={'fade'}
              animateFill={false}
              transitionFlip={false}
              size={'small'}
            >
              {row.account}
            </Tooltip>
          </th>
          <td className={cn}>{row.value}</td>
          <td className={cn}>{row.percent_of_portfolio}</td>
        </AddeparRow>
      </ThemeProvider>
    );
  };

  const generateValue = (value) => {
    if (!value && value !== 0) {
      return '';
    }
    return (
      '$' +
        value.toLocaleString('en-US', {
          maximumFractionDigits: 0,
          minimumFractionDigits: 0,
        }) || '0'
    );
  };

  const generatePercent = (
    value,
    nullReturn,
    multiplier = 100,
    decimal = 2
  ) => {
    if (!value && value !== 0) {
      return !nullReturn ? '0.00%' : nullReturn;
    }
    return (
      (value * multiplier).toLocaleString('en-US', {
        maximumFractionDigits: decimal,
        minimumFractionDigits: decimal,
      }) + '%'
    );
  };

  const generateAddeparContent = () => {
    const tableHeaders = content?.partner_data?.fields_config;
    const tableRows = [];
    content?.partner_data?.account_holdings?.forEach((account) => {
      // top level
      if (
        account?.grouping === 'top_level_holding_account' ||
        account?.grouping === 'holding_account'
      ) {
        tableRows.push({
          account: account?.name || '',
          model_type: account?.columns?.model_type || '',
          value: generateValue(account?.columns?.value),
          percent_of_portfolio: generatePercent(
            account?.columns?.percent_of_portfolio
          ),
          class: 'holding',
        });
      }
      // asset class grouping level
      if (account?.children?.length) {
        account.children.forEach((child) => {
          tableRows.push({
            class: 'asset',
            model_type: '',
            percent_of_portfolio: generatePercent(
              child?.columns?.percent_of_portfolio,
              '-'
            ),
            value: generateValue(child?.columns?.value),
            account: child.name || '',
          });

          if (child?.children?.length) {
            child.children.forEach((child) => {
              tableRows.push({
                class: 'security',
                model_type: child?.columns?.investment_type || '',
                percent_of_portfolio: generatePercent(
                  child?.columns?.percent_of_portfolio
                ),
                value: generateValue(child?.columns?.value),
                account: child.name || '',
              });
            });
          }
        });
      }
    });

    const tableFooter = {
      account: 'Total',
      model_type: '',
      value: generateValue(
        content?.partner_data?.account_holdings[0]?.columns?.value
      ),
      percent_of_portfolio: generatePercent(
        content?.partner_data?.account_holdings[0]?.columns
          ?.percent_of_portfolio
      ),
      class: 'total',
    };

    return (
      <IntegrationInfoContainer>
        {content &&
        content.hasOwnProperty('partner_data') &&
        content.partner_data ? (
          <InformationChunk>
            {tableRows.length > 0 && (
              <>
                <AddeparHeader>Asset Table</AddeparHeader>
                <AddeparTable $isSidebar={isSidebar}>
                  <thead>
                    <tr>
                      {tableHeaders.map((header, index) => (
                        <th key={index}>
                          <div>
                            <div>{header.display_name}</div>
                          </div>
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {tableRows.map((row, index) =>
                      renderAddeparRow(row, index, tableHeaders)
                    )}
                  </tbody>
                  <tfoot>
                    {renderAddeparRow(tableFooter, 0, tableHeaders)}
                  </tfoot>
                </AddeparTable>
              </>
            )}
          </InformationChunk>
        ) : (
          <div>Error fetching partner data</div>
        )}
      </IntegrationInfoContainer>
    );
  };

  function download_pdf(e) {
    e.preventDefault();
    document.getElementById('generate_pdf_form').innerHTML = '';

    const formContent = `
      <input type="hidden" name="csrfmiddlewaretoken" value="${Cookies.get(
        'csrftoken'
      )}" />
      <input type="hidden" name="assetmap" value="1" />
      <input type="hidden" name="internal" value="1" />
      <input type="hidden" name="type" value="assetmap" />

      <input type="hidden" name="assetmap_choices" value="${member.id}" />
      <input type="hidden" name="redirect" value="${
        content.partner_data.timeline_url
      }" />
      `;

    document.getElementById('generate_pdf_form').innerHTML = formContent;
    document.getElementById('generate_pdf_form').submit();
  }

  const enableFileStorage = () => {
    setIsLinking(true);
    if (type === 'Member' || type === 'Entity') {
      dispatch(
        linkMemberFolder(
          household,
          member,
          integration.id,
          selectedFolder?.value,
          type
        )
      );
      SendTrackingEvent(
        invocationId,
        'action',
        selectedFolder.id
          ? 'integration_householdmember_link'
          : 'integration_householdmember_create',
        'integration_households',
        {
          integration: integration.id,
          household: household.uuid,
          householdmember: member.uuid ? member.uuid : member.id,
        }
      );
    } else {
      dispatch(
        linkInstrumentFolder(
          household,
          instrument,
          integration.id,
          selectedFolder?.value,
          type
        )
      );
      SendTrackingEvent(
        invocationId,
        'action',
        selectedFolder.id
          ? 'integration_instrument_link'
          : 'integration_instrument_create',
        'integration_households',
        {
          integration: integration.id,
          household: household.uuid,
          instrument: instrument.uuid ? instrument.uuid : instrument.id,
        }
      );
    }
  };

  const unlinkFileStorage = () => {
    setIsUnlinking(true);
    if (type === 'Member' || type === 'Entity') {
      dispatch(unlinkMemberFolder(household, member, integration.id, type));
      SendTrackingEvent(
        invocationId,
        'action',
        'integration_householdmember_unlink',
        'integration_households',
        {
          integration: integration.id,
          household: household.uuid,
          member: member.uuid ? member.uuid : member.id,
        }
      );
    } else {
      dispatch(
        unlinkInstrumentFolder(household, instrument, integration.id, type)
      );
      SendTrackingEvent(
        invocationId,
        'action',
        'integration_instrument_unlink',
        'integration_households',
        {
          integration: integration.id,
          household: household.uuid,
          instrument: instrument.uuid ? instrument.uuid : instrument.id,
        }
      );
    }
  };

  const fetchFolderContent = (integration, folderId) => {
    setLoadingFolderContent(true);
    if (type === 'Member' || type === 'Entity') {
      dispatch(
        getMemberStorageFolder(
          household?.uuid,
          member.uuid ? member.uuid : member.id,
          integration,
          folderId
        )
      );
    } else {
      dispatch(
        getInstrumentStorageFolder(
          household?.uuid,
          instrument?.uuid,
          integration,
          folderId
        )
      );
    }
  };

  const generateFileStorageContent = () => {
    return content?.linked ? (
      <FileStorageContentContainer>
        <FileStorageExplorer
          isSidebar={isSidebar}
          integration={integration?.id}
          folder={integrationFolder}
          getFolder={fetchFolderContent}
          loadingContent={loadingFolderContent}
          loadingError={loadingFolderError}
          toggleDisplayModal={() =>
            dispatch(
              toggleShowUploadFileModal(
                true,
                integration?.id,
                integrationFolder?.id,
                type
              )
            )
          }
        />
        <ButtonContainer>
          <Button
            text={`Unlink ${type}`}
            theme={ButtonThemes.inactiveBorderFull}
            onClick={() => unlinkFileStorage()}
            showLoading={isUnlinking}
            loadingText={`Unlinking ${type}`}
          />
        </ButtonContainer>
        {/* {integrationTabFolderError && (
          <IntegrationErrorMessage>
            <img src={errorCircleRed} alt="error" />
            Error: Please check that the folder has not been deleted. <br />
            If problem persists, try unlinking and relinking.
          </IntegrationErrorMessage>
        )} */}
      </FileStorageContentContainer>
    ) : (
      <FileStorageContentContainer>
        <SelectContainer>
          <InputLabel>Select Folder</InputLabel>
          <Select
            selectedOption={selectedFolder}
            onChange={(e) => setSelectedFolder(e)}
            options={folderOptions}
            components={{ Option: FolderOption }}
            isSearchable={true}
            placeholder={'Select Folder'}
            closeMenuOnSelect={true}
            autoFocus={false}
            theme={selectThemes.inputSearch}
          />
        </SelectContainer>
        <ButtonContainer>
          <Button
            text={`Link to ${integration.name}`}
            onClick={() => enableFileStorage()}
            loadingText={`Linking to ${integration.name}`}
            theme={ButtonThemes.primaryFull}
            showLoading={isLinking}
          />
        </ButtonContainer>
      </FileStorageContentContainer>
    );
  };

  const generateCustomContent = (isInstrument = false) => {
    let generatedContent = null;
    if (integration.id === 'riskalyze') {
      return generateRiskalyzeContent(isInstrument);
    }
    if (integration.id === 'blackdiamond') {
      if (isInstrument) {
        return generateBlackDiamondInstrumentContent();
      }
      return generateBlackDiamondContent();
    }
    if (integration.id === 'orion') {
      if (isInstrument) {
        return generateOrionInstrumentContent();
      } else {
        return generateOrionContent();
      }
    }
    if (integration.id === 'axasf' && !instrument) {
      return generateSalesforceContent();
    }
    if (integration.id === 'redtail') {
      if (isInstrument) {
        return generateRedtailInstrumentContent();
      } else {
        generatedContent = generateRedtailContent();
      }
    }
    if (integration.id === 'td') {
      if (isInstrument) {
        return generateVeoInstrumentContent();
      }
      generatedContent = generateVeoContent();
    }
    if (integration.id === 'wealthbox') {
      generatedContent = generateWealthboxContent();
    }
    if (integration.id === 'morningstar') {
      if (isInstrument) {
        return generateMorningstarInstrumentContent();
      }
      generatedContent = generateMorningstarContent();
    }
    if (integration.id === 'schwab') {
      if (isInstrument) {
        return generateSchwabInstrumentContent();
      }
      generatedContent = generateSchwabContent();
    }
    if (integration.id === 'msaws') {
      return generateMorningstarAWSContent();
    }
    if (integration.id === 'addepar') {
      return generateAddeparContent();
    }
    if (integration.id === 'plannr') {
      if (isInstrument) {
        generatedContent = generatePlannrInstrumentContent();
      } else {
        generatedContent = generatePlannrContent();
      }
    }
    if (integration.id === 'holistiplan') {
      generatedContent = generateHolistiplanContent();
    }
    if (isFileStorage) {
      return generateFileStorageContent();
    }

    if (generatedContent) {
      return (
        <IntegrationInfoContainer>
          {generatedContent.map((chunk, index) => {
            if (chunk.value === 'basic') {
              return (
                <InformationChunk key={index}>
                  <InformationHeading
                    style={
                      chunk?.position ? { textAlign: chunk.position } : null
                    }
                  >
                    {chunk.label}
                  </InformationHeading>
                  <InformationSubHeading>
                    {chunk.sub_label}
                  </InformationSubHeading>
                  <ContentContainer $isSidebar={isSidebar}>
                    {chunk?.hasOwnProperty('leftInfo') && (
                      <LeftContainer>
                        {chunk.leftInfo.map((info, index) => {
                          return (
                            <InfoContainer key={index}>
                              <InfoLabel>{info.label}</InfoLabel>
                              <InfoContent
                                style={
                                  info.value === 'None'
                                    ? {
                                        color: colors.paleGrey,
                                        fontStyle: 'italic',
                                      }
                                    : null
                                }
                              >
                                {info.value}
                              </InfoContent>
                            </InfoContainer>
                          );
                        })}
                      </LeftContainer>
                    )}
                    {chunk?.hasOwnProperty('rightInfo') && (
                      <RightContainer>
                        {chunk.rightInfo.map((info, index) => {
                          return (
                            <InfoContainer key={index}>
                              <InfoLabel>{info.label}</InfoLabel>
                              {info.tooltip === 'true' ? (
                                <InfoContent>
                                  <Tooltip
                                    title={info.value}
                                    position="right"
                                    trigger="mouseenter click"
                                    followCursor="true"
                                    delay={0}
                                    arrow={true}
                                    arrowSize={'small'}
                                    animation={'fade'}
                                    animateFill={false}
                                    transitionFlip={false}
                                    size={'small'}
                                    style={{
                                      overflow: 'hidden',
                                      whiteSpace: 'nowrap',
                                    }}
                                  >
                                    {info?.title}
                                  </Tooltip>
                                </InfoContent>
                              ) : (
                                <InfoContent
                                  style={
                                    info.value === 'None'
                                      ? {
                                          color: colors.paleGrey,
                                          fontStyle: 'italic',
                                        }
                                      : {
                                          overflow: 'hidden',
                                          whiteSpace: 'nowrap',
                                        }
                                  }
                                >
                                  {info.value}
                                </InfoContent>
                              )}
                            </InfoContainer>
                          );
                        })}
                      </RightContainer>
                    )}
                  </ContentContainer>
                </InformationChunk>
              );
            } else if (chunk.value === 'panel') {
              return (
                <InformationChunk key={index}>
                  <InformationHeading>{chunk.label}</InformationHeading>
                  <ContentContainer key={index} $isSidebar={isSidebar}>
                    <FullContainer>
                      {chunk.listInfo.map((info, index) => {
                        return info.hasOwnProperty('external_url') ? (
                          <IntegrationLeftLink
                            key={index}
                            href={info?.external_url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <InfoContainerColumn>
                              <InfoContent>{info?.value}</InfoContent>
                              <InfoSubContent>{info?.sub_value}</InfoSubContent>
                            </InfoContainerColumn>
                          </IntegrationLeftLink>
                        ) : (
                          <InfoContainerColumn key={index}>
                            <InfoContent>{info?.value}</InfoContent>
                            <InfoSubContent>{info?.sub_value}</InfoSubContent>
                          </InfoContainerColumn>
                        );
                      })}
                    </FullContainer>
                  </ContentContainer>
                </InformationChunk>
              );
            } else {
              return (
                <InformationChunk key={index}>
                  <InformationHeading>{chunk.label}</InformationHeading>
                  <ContentContainer $isSidebar={isSidebar}>
                    <LeftContainer>
                      {chunk.listInfo.map((info, index) => {
                        return (
                          <InfoContainer key={index}>
                            <InfoLabel
                              style={
                                info.label === 'None'
                                  ? {
                                      color: colors.paleGrey,
                                      fontStyle: 'italic',
                                      fontWeight: fonts.regular,
                                    }
                                  : null
                              }
                            >
                              {info.label}
                            </InfoLabel>
                            <InfoContent
                              style={
                                info.value === 'None'
                                  ? {
                                      color: colors.paleGrey,
                                      fontStyle: 'italic',
                                      fontWeight: fonts.regular,
                                    }
                                  : null
                              }
                            >
                              {info.value}
                            </InfoContent>
                          </InfoContainer>
                        );
                      })}
                    </LeftContainer>
                  </ContentContainer>
                </InformationChunk>
              );
            }
          })}
        </IntegrationInfoContainer>
      );
    }
  };

  const generateSubText = (isInstrument = false) => {
    if (integration.id === 'addepar') {
      return (
        <IntegrationSubText>
          Click the link below to open Addepar for additional, or more
          up-to-date information than what has previously been synchronized to
          your Asset-Map account.
        </IntegrationSubText>
      );
    }
    if (integration.id === 'orion' || integration.id === 'plannr') {
      return null;
    }
    if (integration.id === 'msaws') {
      if (instrument) {
        return (
          <IntegrationSubText
            style={{ color: colors.darkGrey, textAlign: 'center' }}
          >{`The Morningstar Portfolio Risk Score (MPRS) measures the overall risk of portfolios. MPRS uses Morningstar Risk Model's holdings-based analysis to derive a risk estimate and score for each portfolio, and Morningstar's multi-asset Target Allocation Indexes to define the following risk ranges:`}</IntegrationSubText>
        );
      } else {
        return (
          <IntegrationSubText
            style={{ color: colors.darkGrey, textAlign: 'center' }}
          >{`Based on your risk tolerance test results, we calculated a Portfolio Risk Score Range (a "Risk Comfort Range") that you'd generally be comfortable taking when you have at least seven years to achieve a given goal. Your Risk Comfort Range might be lower for goals occurring in less than seven years.`}</IntegrationSubText>
        );
      }
    }
    if (integration.id === 'riskalyze') {
      if (instrument) {
        return (
          <IntegrationSubText>
            {`Below is the Risk Number associated with the corresponding account found in the household's current portfolio within Nitrogen. Use the View Nitrogen Client link to launch Nitrogen and view their Client Screen. Use the View Nitrogen Portfolio to launch Nitrogen and view their client's current portfolio.`}
          </IntegrationSubText>
        );
      } else {
        return (
          <IntegrationSubText>
            {`Linking household members with their account in Nitrogen allows you
          to view two key insights within Asset-Map. Below you'll see the client's
          Target Risk Number and their portfolio's Risk Number. Use the View
          Nitrogen Client link to launch Nitrogen and view their Client
          Screen. Use the View Nitrogen Portfolio to launch Nitrogen and view
          their client's current portfolio.`}
          </IntegrationSubText>
        );
      }
    } else if (integration.id === 'blackdiamond' && !isInstrument) {
      return (
        <IntegrationSubText>
          {`This Asset-Map Member record is currently linked to a Black Diamond Client User. From this tab you have the ability to post a copy of this Member's Asset-Map Report directly to their associated Black Diamond Timeline.`}
        </IntegrationSubText>
      );
    } else if (integration.id === 'axasf') {
      return (
        <IntegrationSubText>
          This Asset-Map {instrument ? 'Instrument' : 'Member'} is currently
          linked to a Salesforce {instrument ? 'Contract' : 'Record'}.
        </IntegrationSubText>
      );
    } else if (isFileStorage) {
      return (
        !content?.linked && (
          <IntegrationSubText>
            You may create a new folder or choose an existing folder to begin
            saving files to your {integration.name} account for this{' '}
            <span style={{ textTransform: 'lowercase' }}>{type}</span>.
          </IntegrationSubText>
        )
      );
    } else if (
      content.hasOwnProperty('partner_data_cached') &&
      !content?.partner_data_cached
    ) {
      return (
        <IntegrationSubText>
          This page has been generated with data retrieved directly from{' '}
          {integration.name} and may have more up-to-date information than what
          has previously been synchronized to your Asset-Map account.
        </IntegrationSubText>
      );
    } else {
      return (
        <IntegrationSubText style={{ textAlign: 'center' }}>
          This page has been generated with cached data from {integration.name}.
        </IntegrationSubText>
      );
    }
  };

  const generateExternalLink = (isInstrument = false) => {
    let itemValue = `View Record on ${integration.name}`;
    let linkValue = content.external_url;
    if (integration.id === 'td') {
      if (instrument) {
        itemValue = `View Account on ${integration.name}`;
      } else {
        itemValue = `View Client on ${integration.name}`;
      }
    }
    if (integration.id === 'axasf') {
      if (instrument) {
        itemValue = `View ${integration.name} Contract`;
      } else {
        itemValue = `View Record on ${integration.name}`;
      }
    }
    if (integration.id === 'orion') {
      itemValue = `View ${integration.name} Dashboard`;
      linkValue = content.dashboard_url;
    }
    if (integration.id === 'orion') {
      return null;
    }
    if (isFileStorage) {
      return null;
    }
    if (integration.id === 'redtail') {
      linkValue =
        linkValue +
        '_' +
        content.contact_id +
        '_' +
        content.partner_data.redtail_instrument_type;
    }
    if (integration.id === 'schwab') {
      return null;
    }
    if (integration.id !== 'riskalyze' && integration.id !== 'blackdiamond') {
      return (
        <IntegrationLink
          href={linkValue}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={launchLink} alt="link" data-image="launch" />
          {itemValue}
        </IntegrationLink>
      );
    }
    if (integration.id === 'blackdiamond' && isInstrument) {
      return (
        <IntegrationLink
          href={linkValue}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={launchLink} alt="link" data-image="launch" />
          {itemValue}
        </IntegrationLink>
      );
    }
  };

  const generateSyncTimestamp = () => {
    // TODO
    return (
      <IntegrationSynced>
        Household last synced:
        {lastHouseholdSync ? ' ' + DateFromNowUTC(lastHouseholdSync) : ' Never'}
      </IntegrationSynced>
    );
  };

  return (
    <TabContent>
      {integration.id !== 'orion' && (
        <ImageContainer
          style={{
            maxWidth: integration?.maxWidth || null,
          }}
        >
          <img
            src={integration.image}
            alt={integration.id}
            data-image={integration.id}
          />
        </ImageContainer>
      )}
      {loading ? (
        <LoadingContainer>
          <LoadingAnimation />
        </LoadingContainer>
      ) : error ? (
        <IntegrationContent>
          <IntegrationErrorMessage>
            <img src={errorCircleRed} alt="error" data-image="error" />
            Error communicating with {integration.name}&apos;s API
          </IntegrationErrorMessage>
        </IntegrationContent>
      ) : content ? (
        instrument ? (
          <IntegrationContent>
            {generateSubText(true)}
            {generateCustomContent(true)}
            {hasExternalLink && generateExternalLink(true)}
            {!isFileStorage && generateSyncTimestamp()}
          </IntegrationContent>
        ) : member ? (
          <IntegrationContent>
            {generateSubText()}
            {generateCustomContent()}
            {content?.hasOwnProperty('partner_data_cached') &&
              content?.partner_data_cached &&
              integration.id === 'wealthbox' && (
                <IntegrationErrorMessage>
                  <img src={errorCircleRed} alt="error" data-image="error" />
                  Error communicating with {integration.name}&apos;s API.
                  Displaying cached data.
                </IntegrationErrorMessage>
              )}
            {hasExternalLink && generateExternalLink()}
            {!isFileStorage && generateSyncTimestamp()}
          </IntegrationContent>
        ) : null
      ) : null}
    </TabContent>
  );
};

const AddeparHeader = styled.h3`
  font-size: 13px;
  font-weight: 300;
  color: #999;
  border-bottom: 1px solid #e9e9e9;
  border-top: 1px solid #e9e9e9;
  height: 32px;
  line-height: 32px;
  padding: 8px 0 2px;
`;

const AddeparTable = styled.table`
  display: block;
  max-width: -moz-fit-content;
  max-width: fit-content;
  margin: 0 auto;
  white-space: nowrap;
  overflow: auto;
  max-height: ${(props) => (props.$isSidebar ? '500px' : '300px')};
  border-collapse: separate;
  &::-webkit-scrollbar {
    width: 4px;
    height: 8px;
    margin-right: 2px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background: #ababab;
  }
  &::-webkit-scrollbar-track {
    box-shadow: none;
    background: #d8d8d8;
    border: none;
  }
  thead {
    position: static;
    font-size: ${(props) => (props.$isSidebar ? '7px' : '10px')};
    text-transform: uppercase;
    border-bottom: 1px solid #e9e9e9;
    th:first-child {
      z-index: 5;
      max-width: ${(props) => (props.$isSidebar ? '160px' : '180px')};
      text-align: left;
      padding-left: 4px;
    }
    th {
      left: 0px;
      top: 0px;
      position: sticky;
      background: white;
      max-width: 120px;
      width: 120px;
      white-space: initial;
      text-align: right;
      div {
        display: flex;
        flex-wrap: wrap;
        height: 100%;
        letter-spacing: 1px;
        padding: 5px 0;
        flex-direction: row-reverse;
        flex-wrap: wrap-reverse;
        div {
          color: #666;
          flex: 1;
          display: flex;
          align-items: flex-end;
        }
      }
      td {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
    th:first-child {
      div {
        flex-direction: row;
        flex-wrap: wrap;
      }
    }
  }
  tbody {
    tr:first-child {
      background: #f4f4f4;
    }
    tr {
      th {
        position: sticky;
        left: 0;
        z-index: 4;
        background: white;
        padding: 4px;
        border-bottom: 1px solid #e9e9e9;
        max-width: ${(props) => (props.$isSidebar ? '160px' : '180px')};
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .holding {
        background: #f4f4f4;
      }
      td:last-child {
        padding-right: 5px;
      }
      .negative {
        color: #cb323b;
      }
    }
  }
  tfoot {
    tr {
      border-top: 2px solid #e9e9e9;
      position: sticky;
      bottom: 0;
      z-index: 6;
      color: #666;
      background: white;
      font-weight: 400;
      th {
        position: sticky;
        left: 0;
        z-index: 4;
        background: white;
        padding: 4px;
        border-bottom: 1px solid #e9e9e9;
        max-width: 150px;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
`;

const AddeparRow = styled.tr`
  font-weight: ${(props) => props.theme.fw || 700};
  font-family: Univers-Tabular;
  text-align: left;
  padding-left: 4px;
  font-size: ${(props) => (props.$isSidebar ? '10px' : '13px')};
  line-height: 1.6796875;
  width: 8px;
  height: 8px;
  margin-right: 2px;
  th:first-child {
    text-indent: ${(props) => props.theme.ti || ''};
  }
  td {
    padding-top: 4px;
    padding-bottom: 4px;
    border-bottom: 1px solid #e9e9e9;
    text-align: right;
  }
`;

const MorningstarAWSContainer = styled.div`
  flex: 1 1 auto;
  margin: 10px auto;
  display: flex;
  justify-content: center;
  font-style: normal;
  font-size: 23px;
  font-weight: 300;
  font-family: 'Helvetica Neue';
`;

const Gauge = styled.img`
  margin-top: 20px;
  margin-right: 15px;
  width: 40%;
`;

const Legend = styled.img`
  margin-top: 20px;
  width: 57%;
`;

const MorningstarInformationHeading = styled.p`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: ${fonts.semiBold};
  margin-bottom: 5px;
  background-color: #e5e5e5;
  padding: 2px 8px;
  color: ${colors.darkGrey};
  span {
    font-weight: ${fonts.regular};
    ::before {
      content: ' • ';
      color: #ababab;
    }
  }
`;

const MorningstarTable = styled.table`
  width: 100%;
  font-size: 12px;
  text-align: right;
  table-layout: fixed;
  thead {
    font-weight: ${fonts.semiBold};
    tr {
      td {
        padding-bottom: 5px;
      }
    }
  }
  tbody {
    tr {
      border-bottom: 1px solid ${colors.lightGrey};
      padding: 5px 10px;
    }
  }
`;

const MorningstarTableLabel = styled.td`
  font-weight: ${fonts.semiBold};
  text-align: left;
  padding: 2px 6px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const TooltipIcon = styled.img`
  width: 10px;
  height: 10px;
  margin-left: 5px;
  margin-bottom: -1px;
  opacity: 0.75;
`;

const RedtailContainer = styled.div`
  padding: 10px;
  text-align: left;
  background-color: #eee;
  overflow: auto;
  max-height: 350px;
  margin-bottom: 15px;
`;

const RedtailInformationHeading = styled.p`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #800;
  margin-bottom: 15px;
  font-weight: 500;
  background-color: #fff;
  text-transform: capitalize;
  span {
    font-weight: ${fonts.regular};
    ::before {
      content: ' • ';
      color: #ababab;
    }
  }
`;

const RedtailInfoContainer = styled.div`
  display: flex;
  align-content: center;
  align-items: start;
  justify-content: flex-start;
  font-size: 11px;
  gap: 5px;
  margin-bottom: 2px;
`;

const RedtailTag = styled.span`
  background-color: #777;
  padding: 0.2em 0.6em 0.3em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25em;
  margin-right: 2px;
  margin-bottom: 2px;
  float: left;
  text-transform: capitalize;
`;

const RedtailTable = styled.table`
  width: 100%;
  font-size: ${(props) => (props.$isSidebar ? '11px' : '12px')};
  table-layout: fixed;
  thead {
    font-weight: ${fonts.semiBold};
    tr {
      th {
        text-transform: uppercase;
        font-size: 85%;
        padding-bottom: 8px;
      }
      td {
        padding: 8px 0px;
      }
    }
  }
  tbody {
    tr {
      border-top: 1px solid ${colors.lightGrey};
      padding: 8px 0px;
    }
    td {
      padding: 8px 0px;
    }
  }
`;

const RedtailInformationChunk = styled.div`
  padding: 10px;
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 0 1px 1px rgb(0 0 0 / 5%);
`;

const SchwabInformationHeading = styled.p`
  overflow: hidden;
  text-overflow: ellipsis;
  text-color: wh
  white-space: nowrap;
  font-weight: ${fonts.semiBold};
  margin-bottom: 5px;
  background-color: #2fa1e0;
  padding: 2px 8px;
  color: ${colors.white};
  span {
    font-weight: ${fonts.regular};
    ::before {
      content: ' • ';
      color: #ababab;
    }
  }
`;

const SchwabTable = styled.table`
  width: 100%;
  font-size: ${(props) => (props.$isSidebar ? '10px' : '12px')};
  text-align: right;
  table-layout: fixed;
  th,
  td {
    padding: 5px 5px;
  }
  thead {
    font-weight: ${fonts.semiBold};
  }
  tbody {
    tr {
      border-bottom: 1px solid ${colors.lightGrey};
    }
  }
`;

const SchwabTableLabel = styled.td`
  font-weight: ${fonts.semiBold};
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const VeoTable = styled.table`
  width: 100%;
  font-size: 12px;
  text-align: right;
  thead {
    font-weight: ${fonts.semiBold};
    tr {
      td {
        padding-bottom: 5px;
      }
    }
  }
  tbody {
    tr {
      padding: 5px 10px;
    }
  }
`;

const VeoTableLabel = styled.td`
  font-weight: ${fonts.semiBold};
  text-align: left;
  padding: 2px 6px;
`;

const SelectRowContainer = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
`;

const InputLabel = styled.label`
  text-align: left;
  margin: 0 0 3px 3px;
  font-size: 11px;
  font-weight: ${fonts.semiBold};
  color: ${inputColors.label};
`;

const SelectContainer = styled.div`
  margin-bottom: 10px;
  display: flex;
  text-align: left;
  flex-direction: column;
  ${InputLabel} {
    margin: 0 0 5px 0;
  }
  div {
    div {
      div {
        font-size: 13px;
      }
    }
  }
`;

const IntegrationName = styled.p`
  text-align: center;
  font-size: 13px;
  font-weight: ${fonts.semiBold};
  margin-bottom: 10px;
`;

const LastUpdatedValue = styled.p`
  text-align: center;
  font-size: 11px;
  color: ${colors.paleGrey};
`;

const IntegrationErrorMessage = styled.div`
  color: ${messageColors.error};
  font-size: 11px;
  margin: 10px 0;
  display: flex;
  align-content: center;
  align-items: center;
  text-align: left;
  justify-content: center;
  img {
    width: 11px;
    margin-right: 10px;
  }
`;

const BlackDiamondInfoContainer = styled.div`
  margin: 10px 0;
`;

const BlackDiamondMembersList = styled.div`
  margin: 10px;
`;

const BlackdiamondInformationHeading = styled.p`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: ${fonts.semiBold};
  margin-bottom: 5px;
  padding: 2px 8px;
  background-color: ${colors.lighterGrey};
`;

const BlackdiamondTable = styled.table`
  font-size: 12px;
  text-align: left;
  max-height: 200px;
  table-layout: auto;
  width: 100%;
  thead {
    font-weight: ${fonts.semiBold};
    tr {
      td {
        padding-bottom: 5px;
      }
    }
  }
  tbody {
    tr {
      border-bottom: 1px solid ${colors.lightGrey};
      padding: 5px 10px;
      td {
        color: ${fonts.darkGrey};
      }
    }
    tr:nth-child(even) {
      background-color: ${colors.lighterGrey};
    }
  }
`;

const WidgetContainer = styled.div`
  margin: -15px;
`;

const BlackDiamondLink = styled.a`
  color: ${colors.lightBlue};
  cursor: pointer;
  font-size: 12px;
  text-transform: uppercase;
  &:hover {
    color: ${colors.blue};
  }
`;

const BlackDiamondMember = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  margin: 8px 0;
`;

const ContactIcon = styled.img`
  width: 12px;
  margin-right: 12px;
`;

const ContactName = styled.p`
  font-size: 12px;
`;

const BlackDiamondWarning = styled.p`
  font-size: 12px;
  text-align: left;
`;

const IntegrationInfoContainer = styled.div`
  margin: 10px 0;
  text-align: left;
`;

const SalesforceInfoContainer = styled.div`
  margin: 20px 0;
  text-align: center;
`;

const HouseholdUnlinkText = styled.div`
  font-size: 10px;
  margin: 10px 0;
  color: ${colors.paleGrey};
`;

const SalesforceButtonContainer = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
`;

const RequiredStar = styled.span`
  color: ${messageColors.error};
  margin-left: 2px;
`;

const RiskalyzeInfoContainer = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  margin: 20px 0 10px 0;
`;

const RiskalyzeImageContainer = styled.div`
  max-width: 100px;
  flex: 1 1 auto;
  margin: 0 auto;
  img {
    width: 100%;
    height: auto;
  }
`;

const TypeText = styled.p`
  text-align: center;
  font-weight: ${fonts.semiBold};
  margin: 10px 0 20px 0;
`;

const InformationChunk = styled.div`
  margin-bottom: 15px;
`;

const InformationHeading = styled.p`
  font-weight: ${fonts.semiBold};
  margin-bottom: 5px;
`;

const InformationSubHeading = styled.p`
  font-weight: ${fonts.normal};
  font-size: 12px;
  margin-bottom: 10px;
  margin-top: -5px;
`;

const InfoLabel = styled.p`
  font-weight: ${fonts.semiBold};
  flex: 0 0 auto;
  width: 100px;
`;

const InfoContent = styled.p``;

const ContentContainer = styled.div`
  display: flex;
  align-content: center;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin: ${(props) => (props.$isSidebar ? '0 4px' : '0 8px')};
  ${InfoLabel} {
    width: ${(props) => (props.$isSidebar ? '90px' : '100px')};
  }
  ${InfoContent} {
    min-width: ${(props) => (props.$isSidebar ? '50px' : null)};
  }
`;

const LeftContainer = styled.div`
  flex: 1 1 50%;
`;

const RightContainer = styled.div`
  flex: 1 1 50%;
`;

const FullContainer = styled.div`
  flex: 1 1 100%;
`;

const InfoContainer = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  font-size: 11px;
  gap: 5px;
`;

const InfoContainerColumn = styled.div`
  display: flex;
  align-content: left;
  align-items: left;
  justify-content: flex-start;
  font-size: 11px;
  flex-direction: column;
  gap: 2px;
  margin-top: 5px;
`;

const InfoSubContent = styled.p`
  color: ${colors.paleGrey};
  font-style: italic;
  font-weight: ${fonts.regular};
`;

const ImageContainer = styled.div`
  width: 370px;
  max-width: 200px;
  height: 50px;
  margin: 0 auto;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

const IntegrationContent = styled.div`
  width: 100%;
  color: ${colors.darkGrey};
  text-align: center;
`;

const IntegrationSubText = styled.p`
  font-size: 11px;
  color: ${colors.paleGrey};
  text-align: left;
  margin: 10px 0;
`;

const LoadingContainer = styled.div`
  width: 100%;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
`;

const IntegrationLink = styled.a`
  color: ${colors.lightBlue};
  cursor: pointer;
  font-size: ${(props) => (props.$fontSize ? props.$fontSize : '12px')};
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  img {
    width: 10px;
    margin-right: 5px;
  }
`;

const IntegrationLeftLink = styled.a`
  color: ${colors.lightBlue};
  cursor: pointer;
  font-size: 12px;
  display: flex;
  align-content: left;
  align-items: left;
  justify-content: left;
  img {
    width: 10px;
    margin-right: 5px;
  }
`;
const FileStorageContentContainer = styled.div`
  margin: 15px 0;
`;

const ButtonContainer = styled.div`
  margin-top: 10px;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
`;

const IntegrationSynced = styled.div`
  color: ${colors.paleGrey};
  text-align: left;
  margin-top: 10px;
  font-size: 12px;
`;

IntegrationTab.propTypes = {
  integration: PropTypes.object,
  content: PropTypes.object,
  loading: PropTypes.bool,
  household: PropTypes.object,
  member: PropTypes.object,
  role: PropTypes.object,
  instrument: PropTypes.object,
  error: PropTypes.object,
  onClick: PropTypes.func,
  onClickLoading: PropTypes.bool,
  type: PropTypes.string,
  showSync: PropTypes.func,
  lastHouseholdSync: PropTypes.string,
};

export default IntegrationTab;
