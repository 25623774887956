import styled from 'styled-components';
import { colors, fonts } from '../variables';
import { FlexCenterAll, FlexCenterEnd, FlexCenterStart } from './layoutStyles';
import { ButtonsRowContainer } from './modalStyles';
import { ErrorContainer } from '../../components/Error';
import { SuccessContainer } from '../../components/Success';

export const ModalSidebarContainer = styled.div`
  position: relative;
  padding-bottom: ${(props) => (props.$showingError ? '100px' : '62px')};
  padding-bottom: ${(props) =>
    props.$paddingBottom ? props.$paddingBottom : '62px'};
`;

export const ToolbarContainer = styled.div`
  height: 55px;
  background: ${colors.white};
  padding: 0 15px;
  border-top: 1px solid ${colors.lightGrey};
  ${FlexCenterEnd};
  position: fixed;
  bottom: -1px;
  width: 290px;
  z-index: 4;
  ${ButtonsRowContainer} {
    flex: 1 1 auto;
  }
`;

export const SidebarMessageContainer = styled.div`
  ${FlexCenterAll};
  position: fixed;
  bottom: ${(props) => (props.$bottom ? props.$bottom : '55px')};
  width: 319px;
  z-index: 4;
  ${ErrorContainer} {
    flex: 1 1 auto;
  }
  ${SuccessContainer} {
    flex: 1 1 auto;
  }
`;

export const InputsHeading = styled.p`
  font-size: 14px;
  padding: 0 0 15px 0;
  font-weight: ${fonts.semiBold};
  width: 100%;
`;

export const InputsContainer = styled.div`
  padding: ${(props) => (props.$padding ? props.$padding : '20px 15px')};
  background ${(props) => (props.$background ? props.$background : null)};
  border-top ${(props) => (props.$showBorder ? `1px solid ${colors.hoverLightGrey}` : null)};
  border-bottom ${(props) => (props.$showBorder ? `1px solid ${colors.hoverLightGrey}` : null)};
  display: flex;
  flex-wrap: wrap;
  align-content: flex-end;
  align-items: flex-end;
  justify-content: space-between;
`;

export const LoadingContainer = styled.div`
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 70vh;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
`;

export const ExpandableItem = styled.div`
  /* border: 1px solid green; */
  filter: ${(props) =>
    props.$inFocusMode ? (props.$isFocused ? null : `grayscale(1)`) : null};
  opacity: ${(props) =>
    props.$inFocusMode ? (props.$isFocused ? null : `.5`) : null};
`;

export const ExpandIcon = styled.button`
  flex: 0 0 auto;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  margin-left: 5px;
  margin: 0 -5px 0 5px;
  ${FlexCenterAll};
  &:hover {
    background: ${colors.lightGrey};
  }
  img {
    width: 15px;
    height: 15px;
    transform: ${(props) => (props.$isExpanded ? 'rotate(180deg)' : null)};
  }
`;

export const ExpandHeadingContainer = styled.div`
  border-bottom: 1px solid ${colors.lightGrey};
  padding: ${(props) => (props.$padding ? props.$padding : '15px 18px')};
  font-size: 14px;
  font-weight: ${fonts.semiBold};
  ${FlexCenterStart};
  position: relative;
  cursor: pointer;
  z-index: 1;
  background: ${(props) =>
    props.$background ? props.$background : colors.white};
  border-color: ${(props) =>
    props.$borderColor ? props.$borderColor : colors.lightGrey};
  position: ${(props) => (props.$isSticky ? `sticky` : null)};
  top: 0;
  box-shadow: ${(props) =>
    props.$isExpanded ? `0px 4px 10px -4px rgba(0, 0, 0, 0.1)` : null};
  &:hover {
    opacity: ${(props) => (props.$isExpanded ? null : 0.9)};
    background: ${(props) =>
      props.$background ? props.$background : colors.lighterGrey};
    transform: scale(1.01);
    ${ExpandIcon} {
      background: ${(props) =>
        props.$isProposal ? `${colors.pink}30` : colors.lightGrey};
    }
  }
  ${ExpandIcon} {
    background: ${(props) =>
      props.$isExpanded
        ? props.$isProposal
          ? `${colors.pink}30`
          : colors.lightGrey
        : null};
  }
`;

export const ModalExpandHeadingContainer = styled(ExpandHeadingContainer)`
  border-bottom: 1px solid ${colors.lightGrey};
  padding: ${(props) => (props.$padding ? props.$padding : '10px 15px')};
  font-size: 13px;
  font-weight: ${fonts.semiBold};
  ${FlexCenterStart};
  position: relative;
  z-index: 1;
  box-shadow: ${(props) =>
    props.$isExpanded ? `0px 4px 10px -4px rgba(0, 0, 0, 0.1)` : null};
`;

export const ExpandHeadingLabel = styled.div`
  ${FlexCenterStart};
  flex: 1 1 auto;
  cursor: pointer;
`;

export const LabelIcon = styled.img`
  flex: 0 0 auto;
  width: ${(props) => (props.$width ? props.$width : '22px')};
  height: ${(props) => (props.$width ? props.$width : '22px')};
  margin: ${(props) => (props.$margin ? props.$margin : '0 15px 0 0')};
`;

export const LabelText = styled.p`
  ${FlexCenterStart};
  flex: 1 1 auto;
`;

export const CountLabel = styled.span`
  background: ${colors.lightGrey};
  color: ${colors.darkGrey};
  margin: 0 5px;
  padding: 5px;
  border-radius: 20px;
  min-width: 18px;
  height: 18px;
  opacity: 0.7;
  font-size: 13px;
  ${FlexCenterAll};
`;

export const ExpandedDetails = styled.div`
  background: ${colors.lighterGrey};
  padding: ${(props) => (props.$padding ? props.$padding : '20px')};
  font-size: 12px;
  position: relative;
  max-width: 320px;
`;

export const EmptyContainer = styled.div`
  ${FlexCenterAll};
  flex-direction: column;
`;

export const NotFoundImage = styled.img`
  max-height: 430px;
  max-width: 280px;
  margin: 40px 20px 20px 20px;
`;

export const NotFoundText = styled.p`
  margin: ${(props) => (props.$margin ? props.$margin : '5px 20px 15px 20px')};
  font-size: ${(props) => (props.$fontSize ? props.$fontSize : '20px')};
  font-weight: ${fonts.bold};
  text-align: center;
  color: ${colors.paleGrey};
`;

export const IntegrationsSidebarContainer = styled.div`
  padding: 20px 15px;
  overflow-x: auto;
  ${'' /* max-width: 278px; */}
  min-height: 350px;
`;

export const ProposalsSidebarContainer = styled.div`
  padding: 20px 15px;
  overflow-x: auto;
`;
