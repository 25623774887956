/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled, { ThemeProvider } from 'styled-components';
import PropTypes from 'prop-types';
import { Tooltip } from 'react-tippy';
import {
  submitAuthCode,
  confirmAuthFactor,
  showLoginScreen,
  showResendAuthCodeScreen,
  clearAuthCodeError,
  validateOtpAuthFactor,
} from '../../store/actions';
import {
  CheckBox,
  Button,
  DetectModalKeys,
  SimpleModal,
} from '../../components';
import { CenterFlexContainer } from '../../styles/library/layoutStyles';
import { LinkTextSmall } from '../../styles/library/fontStyles';
import { ButtonThemes } from '../../styles/themes';
import {
  colors,
  inputColors,
  messageColors,
  maxDevice,
  fonts,
  assetMapColors,
} from '../../styles/variables';
import { DetectEnterKeyPress } from '../../utils';

const AuthCodeModal = ({
  isAdd,
  authValue,
  authId,
  setScreenValue,
  selectedOption,
  removeAuthMethod,
  isLogin = false,
  isAuthApp = false,
  noHide = false,
  setAuthScreenValue,
}) => {
  const dispatch = useDispatch();
  const { mfa_uuid, authCodeError, mfaMaxAttemptError, managers, authFactors } =
    useSelector((state) => ({
      mfa_uuid: state.user.mfa_uuid,
      authCodeError: state.user.authCodeError,
      mfaMaxAttemptError: state.user.mfaMaxAttemptError,
      managers: state.user.managers,
      authFactors: state.user.authFactors,
    }));
  const [buttonText, setButtonText] = useState('Enter Code');
  const [authCode, setAuthCode] = useState({
    auth1: '',
    auth2: '',
    auth3: '',
    auth4: '',
    auth5: '',
    auth6: '',
  });
  const [authCodeEntered, setAuthCodeEntered] = useState(false);
  const [isTrusted, setIsTrusted] = useState(true);
  const [managersLabel, setManagersLabel] = useState(
    <ManagersTooltip>
      <li>
        <span>Contact Support</span>
        <a href="mailto:support@asset-map.com">support@asset-map.com</a>
      </li>
    </ManagersTooltip>
  );
  const [showConfirmAuthApp, setShowConfirmAuthApp] = useState(false);

  useEffect(() => {
    if (authCodeEntered) {
      setButtonText(isAdd ? 'Verify Authentication' : 'Sign In');
    }
  }, [authCodeEntered]);

  useEffect(() => {
    if (managers) {
      if (managers.length !== 0) {
        const firstManagers = managers.slice(0, 4);
        const tooltipHTML = (
          <ManagersTooltip>
            <li>
              <strong>Contact an Asset-Map admin to reset your MFA</strong>
            </li>
            {firstManagers.map((manager, index) => {
              return (
                <li key={index}>
                  <span>{manager?.name || 'Unknown'}</span>
                  <a href={`mailto:${manager?.email || 'unknown'}`}>
                    {manager?.email || 'unknown'}
                  </a>
                </li>
              );
            })}
          </ManagersTooltip>
        );
        setManagersLabel(tooltipHTML);
      }
    }
  }, [managers]);

  useEffect(() => {
    if (authCodeError) {
      setButtonText('Wrong Code');
    } else if (authCodeEntered) {
      setButtonText(isAdd ? 'Verify Authentication' : 'Sign In');
    } else {
      setButtonText('Enter Code');
    }
  }, [authCodeError]);

  useEffect(() => {
    if (authCode) {
      const hasEmptyValue = Object.values(authCode).some((x) => x === '');
      setAuthCodeEntered(!hasEmptyValue);
      dispatch(clearAuthCodeError());
    }
  }, [authCode]);

  const onSubmitForm = () => {
    if (authCodeError) {
      return;
    }
    if (
      isAuthApp &&
      authFactors.some((factor) => factor.type !== 'auth') &&
      !showConfirmAuthApp
    ) {
      setShowConfirmAuthApp(true);
      return;
    }
    setShowConfirmAuthApp(false);
    if (mfaMaxAttemptError) {
      dispatch(showLoginScreen());
    }
    if (setAuthCodeEntered && !authCodeError) {
      const values = Object.values(authCode);
      const token = values.join('');
      const data = {
        is_remembered: isTrusted,
        token,
      };
      if (isAdd) {
        if (isAuthApp) {
          return dispatch(validateOtpAuthFactor(authId, token));
        }
        return dispatch(confirmAuthFactor(authId, token));
      } else {
        dispatch(submitAuthCode(mfa_uuid, data));
      }
    }
  };

  const generateConfirmContent = () => {
    return {
      heading: 'Confirm Auth App',
      text: 'This will disable any existing Multi-factor Authentication methods.',
      marginTop: '12vh',
      minWidth: '335px',
      buttons: [
        {
          text: 'Close',
          function: () => setShowConfirmAuthApp(false),
          theme: ButtonThemes.cancel,
        },
        {
          text: 'Confirm',
          function: () => onSubmitForm(),
          theme: ButtonThemes.primary,
        },
      ],
    };
  };

  const handleChange = (e) => {
    const { maxLength, value, name } = e.target;
    // eslint-disable-next-line no-unused-vars
    const [fieldName, fieldIndex] = name.split('-');

    // Check if they hit the max character length
    if (value.length >= maxLength) {
      e.target.select();
      // Check if it's not the last input field
      if (parseInt(fieldIndex, 6) < 6) {
        const nextField = document.querySelector(
          `input[name=auth-${parseInt(fieldIndex, 6) + 1}]`
        );
        if (nextField !== null) {
          nextField.focus();
        }
      }
    }
    setAuthCode({
      ...authCode,
      [`auth${fieldIndex}`]: value,
    });
  };

  const handleFocus = (e) => e.target.select();

  const onPaste = (e) => {
    e.preventDefault();
    const pasteData = e.clipboardData.getData('Text');
    if (!isNaN(parseInt(pasteData))) {
      setAuthCode({
        auth1: pasteData[0],
        auth2: pasteData[1],
        auth3: pasteData[2],
        auth4: pasteData[3],
        auth5: pasteData[4],
        auth6: pasteData[5],
      });
    }
  };

  return (
    <>
      {showConfirmAuthApp && (
        <SimpleModal
          hide={() => setShowConfirmAuthApp(false)}
          content={generateConfirmContent()}
        />
      )}
      <ThemeProvider
        theme={{
          error: authCodeError,
          success: authCodeEntered,
        }}
      >
        <DetectModalKeys onEnter={onSubmitForm} />
        {!isAuthApp && (
          <IconContainer>
            {authCodeEntered && !authCodeError ? (
              <svg
                width="33"
                height="42"
                viewBox="0 0 33 42"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M28.875 21H8.25V13.1251C8.25 7.33366 10.4328 2.62507 16.5 2.62507C22.5672 2.62507 24.75 7.33366 24.75 13.1251V14.7657C24.75 15.3071 25.2141 15.75 25.7812 15.75H26.4688C27.0359 15.75 27.5 15.3071 27.5 14.7657V13.2481C27.5 5.99655 24.1398 0.0246861 16.543 7.67322e-05C8.9375 -0.0245326 5.5 5.8735 5.5 13.1251V21H4.125C1.84766 21 0 22.7637 0 24.9375V38.0625C0 40.2363 1.84766 42 4.125 42H28.875C31.1523 42 33 40.2363 33 38.0625V24.9375C33 22.7637 31.1523 21 28.875 21ZM30.25 38.0625C30.25 38.7844 29.6313 39.375 28.875 39.375H4.125C3.36875 39.375 2.75 38.7844 2.75 38.0625V24.9375C2.75 24.2157 3.36875 23.625 4.125 23.625H28.875C29.6313 23.625 30.25 24.2157 30.25 24.9375V38.0625ZM16.5 35.4375C15.7437 35.4375 15.125 34.8469 15.125 34.125V28.875C15.125 28.1532 15.7437 27.5625 16.5 27.5625C17.2563 27.5625 17.875 28.1532 17.875 28.875V34.125C17.875 34.8469 17.2563 35.4375 16.5 35.4375Z"
                  fill={colors.green}
                />
              </svg>
            ) : (
              <svg
                width="33"
                height="40"
                viewBox="0 0 33 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16.5 32.8125C15.6897 32.8125 15.0268 32.1094 15.0268 31.25V26.25C15.0268 25.3907 15.6897 24.6875 16.5 24.6875C17.3103 24.6875 17.9732 25.3907 17.9732 26.25V31.25C17.9732 32.1094 17.3103 32.8125 16.5 32.8125ZM33 21.25V36.25C33 38.3203 31.4163 40 29.4643 40H3.53571C1.58371 40 0 38.3203 0 36.25V21.25C0 19.1797 1.58371 17.5 3.53571 17.5H4.71429V12.5C4.71429 5.59381 10.0179 -0.0233644 16.5368 7.30782e-05C23.0484 0.0235105 28.2857 5.711 28.2857 12.6172V17.5H29.4643C31.4163 17.5 33 19.1797 33 21.25ZM7.07143 17.5H25.9286V12.5C25.9286 6.98444 21.7004 2.50007 16.5 2.50007C11.2996 2.50007 7.07143 6.98444 7.07143 12.5V17.5ZM30.6429 36.25V21.25C30.6429 20.5625 30.1125 20 29.4643 20H3.53571C2.8875 20 2.35714 20.5625 2.35714 21.25V36.25C2.35714 36.9375 2.8875 37.5 3.53571 37.5H29.4643C30.1125 37.5 30.6429 36.9375 30.6429 36.25Z"
                  fill={authCodeError ? messageColors.error : colors.lightBlue}
                />
              </svg>
            )}
          </IconContainer>
        )}
        {isAdd ? (
          isAuthApp ? (
            <InfoText>
              Enter the 6-digit code from your authenticator app
            </InfoText>
          ) : (
            <>
              <AuthInfoText>
                Enter the 6-digit code that was sent to
              </AuthInfoText>
              <AuthValue>{authValue}</AuthValue>
            </>
          )
        ) : (
          <InfoText>
            Enter the 6-digit code that was sent to your device
          </InfoText>
        )}

        <AuthCodeContainer>
          {[1, 2, 3, 4, 5, 6].map((input, index) => {
            return (
              <input
                key={index}
                type="text"
                name={`auth-${input}`}
                value={authCode[`auth${input}`]}
                maxLength={1}
                onChange={handleChange}
                autoFocus={input === 1}
                onFocus={handleFocus}
                onKeyPress={(e) => DetectEnterKeyPress(e, onSubmitForm)}
                onPaste={input === 1 ? onPaste : null}
                disabled={mfaMaxAttemptError}
              />
            );
          })}
        </AuthCodeContainer>
        {isAdd && !isAuthApp && (
          <OptionRow>
            <CheckBox
              label={'Trust this device for 30 days'}
              labelStyling={{
                fontSize: '11px',
                color: assetMapColors.expected,
              }}
              onChange={() => setIsTrusted(!isTrusted)}
              checked={isTrusted}
            />
            {isLogin && (
              <HelpOption>
                <Tooltip
                  html={managersLabel}
                  position="top"
                  trigger="mouseenter click"
                  interactive={true}
                  followCursor={false}
                  delay={0}
                  arrow={true}
                  arrowSize={'small'}
                  animation={'fade'}
                  animateFill={false}
                  transitionFlip={false}
                  size={'small'}
                >
                  Need Help?
                </Tooltip>
              </HelpOption>
            )}
          </OptionRow>
        )}
        {!mfaMaxAttemptError && (
          <Button
            onClick={onSubmitForm}
            type="submit"
            text={buttonText}
            theme={
              authCodeError
                ? ButtonThemes.errorFull
                : authCodeEntered
                  ? ButtonThemes.primaryFull
                  : ButtonThemes.inactiveFull
            }
          />
        )}
        <CenterFlexContainer>
          {noHide ? (
            <LinkTextSmall>
              <span
                style={{ flex: '1 1 auto', textAlign: 'left' }}
                onClick={() => {
                  setAuthScreenValue('setup');
                }}
              >
                Back
              </span>
            </LinkTextSmall>
          ) : (
            <LinkTextSmall>
              <span
                onClick={() => {
                  isAdd
                    ? removeAuthMethod(selectedOption, true)
                    : dispatch(showLoginScreen());
                }}
              >
                {isAdd && !isAuthApp
                  ? `Wrong ${selectedOption?.deleteLabel}?`
                  : 'Cancel'}
              </span>
            </LinkTextSmall>
          )}
          {mfaMaxAttemptError ? (
            <LinkTextSmall>
              <span
                onClick={() => {
                  dispatch(showLoginScreen());
                }}
              >
                Return to Login
              </span>
            </LinkTextSmall>
          ) : (
            <LinkTextSmall>
              <span
                onClick={() => {
                  isAdd
                    ? setScreenValue('resend')
                    : dispatch(showResendAuthCodeScreen());
                }}
              >
                {!isAuthApp ? 'Resend Code' : ''}
              </span>
            </LinkTextSmall>
          )}
        </CenterFlexContainer>
      </ThemeProvider>
    </>
  );
};

const AuthCodeContainer = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  margin: 20px -6px;
  input {
    @media ${maxDevice.tablet} {
      width: 30px;
      margin: 0 5px;
    }
    flex: 1 1 auto;
    width: 40px;
    margin: 0 8px;
    border-radius: 6px;
    height: 50px;
    text-align: center;
    font-size: 24px;
    color: ${colors.darkGrey};
    border: ${(props) =>
      props.theme.error
        ? `1px solid ${messageColors.error}`
        : `1px solid ${inputColors.border}`};
  }
`;

const IconContainer = styled.div`
  margin: 15px auto;
  height: 100px;
  width: 100px;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  border-radius: 100px;
  background: ${(props) =>
    props.theme.error
      ? `rgba(235, 77, 75, .15)`
      : props.theme.success
        ? `rgba(120, 190, 32, .15)`
        : `rgba(62, 177, 200, 0.15)`};
`;

const InfoText = styled.p`
  text-align: center;
  margin: 0 auto;
  font-size: 15px;
  max-width: 275px;
  color: ${colors.darkGrey};
`;

const AuthInfoText = styled.p`
  text-align: center;
  margin: 0 auto;
  font-size: 15px;
  max-width: 275px;
  color: ${colors.darkGrey};
  font-weight: ${fonts.semiBold};
`;

const AuthValue = styled.p`
  text-align: center;
  margin: 0 auto;
  font-size: 15px;
  max-width: 275px;
  color: ${colors.darkGrey};
  font-weight: ${fonts.light};
  margin-top: 5px;
`;

const OptionRow = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
  color: ${assetMapColors.expected};
  font-size: 11px;
  margin-bottom: 15px;
`;

const HelpOption = styled.div`
  flex: 1 1 auto;
  text-align: right;
  cursor: pointer;
`;

const ManagersTooltip = styled.ul`
  font-size: 11px;
  li {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: flex-start;
    span {
      flex: 1 1 auto;
      text-algin: left !important;
      margin-right: 10px;
      display: flex;
      align-items: center;
      align-content: center;
      justify-content: flex-start;
    }
    a {
      color: ${colors.lightBlue};
      flex: 0 0 auto;
      text-algin: right;
    }
  }
`;

AuthCodeModal.propTypes = {
  isAdd: PropTypes.bool,
  authValue: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  authId: PropTypes.string,
  setScreenValue: PropTypes.func,
  selectedOption: PropTypes.object,
  removeAuthMethod: PropTypes.func,
  isLogin: PropTypes.bool,
  noHide: PropTypes.bool,
  setAuthScreenValue: PropTypes.func,
};

export default AuthCodeModal;
