/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import PropTypes from 'prop-types';
import { RelationshipSidebar, RelationshipModal, SimpleModal } from '..';

const RelationshipDisplayContainer = ({
  isModal = true,
  name,
  member,
  isLoading,
  invisible,
  hide,
  isNew,
  viewOnly = false,
  modalTabOptions,
  activeTab,
  setActiveTab,
  household,
  showProposalMode,
  proposalContent,
  essentialInputs,
  detailInputs,
  notesInput,
  onEnter,
  buttonContent,
  removeContent,
  copyPrimary,
  modalError,
  preferences,
  categories,
  integrationContent,
  allMemberCategories,
  members,
  itemDetails,
  isConsumer,
}) => {
  return isModal ? (
    <RelationshipModal
      isModal={isModal}
      name={name}
      member={member}
      viewOnly={viewOnly}
      isLoading={isLoading}
      invisible={invisible}
      hide={hide}
      isNew={isNew}
      modalTabOptions={modalTabOptions}
      activeTab={activeTab}
      setActiveTab={setActiveTab}
      household={household}
      showProposalMode={showProposalMode}
      proposalContent={proposalContent}
      essentialInputs={essentialInputs}
      detailInputs={detailInputs}
      notesInput={notesInput}
      onEnter={onEnter}
      buttonContent={buttonContent}
      removeContent={removeContent}
      copyPrimary={copyPrimary}
      modalError={modalError}
      integrationContent={integrationContent}
      isConsumer={isConsumer}
    />
  ) : (
    <>
      {removeContent?.showing && (
        <SimpleModal
          hide={removeContent?.hide}
          content={removeContent?.content}
        />
      )}
      <RelationshipSidebar
        item={itemDetails}
        isEdit={!isNew}
        isLoading={isLoading}
        modalTabOptions={modalTabOptions}
        setActiveTab={setActiveTab}
        essentialInputs={essentialInputs}
        detailInputs={detailInputs}
        notesInput={notesInput}
        buttonContent={buttonContent}
        preferences={preferences}
        categories={categories}
        household={household}
        showProposalMode={showProposalMode}
        proposalContent={proposalContent}
        integrationContent={integrationContent}
        members={members}
        allMemberCategories={allMemberCategories}
        error={modalError}
        isConsumer={isConsumer}
      />
    </>
  );
};

RelationshipDisplayContainer.propTypes = {
  name: PropTypes.string,
  member: PropTypes.object,
  isLoading: PropTypes.bool,
  invisible: PropTypes.bool,
  hide: PropTypes.func,
  isNew: PropTypes.bool,
  viewOnly: PropTypes.bool,
  showProposalMode: PropTypes.bool,
  proposalContent: PropTypes.object,
  showUndo: PropTypes.bool,
  modalTabOptions: PropTypes.array,
  defaultTab: PropTypes.string,
  activeTab: PropTypes.string,
  setActiveTab: PropTypes.func,
  onEnter: PropTypes.func,
  household: PropTypes.object,
  essentialInputs: PropTypes.array,
  detailInputs: PropTypes.array,
  notesInput: PropTypes.object,
  buttonContent: PropTypes.object,
  modalError: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  errorTitle: PropTypes.string,
  removeContent: PropTypes.object,
  copyPrimary: PropTypes.object,
  allMemberCategories: PropTypes.array,
  members: PropTypes.array,
  isConsumer: PropTypes.bool,
};

export default RelationshipDisplayContainer;
