/* eslint-disable react-hooks/exhaustive-deps */
import { useRef, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import {
  GenerateInput,
  ModalTabs,
  ModalButtons,
  Error,
  LoadingAnimation,
  DetectModalKeys,
} from '..';
import {
  ModalBackground,
  ModalContainer,
  ModalOverlay,
  ModalContentContainer,
  ModalHeadingContainer,
  ModalHeading,
  CloseButton,
  TabContent,
  ModalLoadingOverlayContainer,
  LoadingOverlayContainer,
  LoadingOverlay,
} from '../../styles/library/modalStyles';
import { timesWhite } from '../../assets';
import {
  GetStates,
  SetDefaultItem,
  CheckHasError,
  GenerateError,
  states,
  modalTabs,
  HtmlToString,
  DetectEnterKeyPress,
  clientModalTabs,
} from '../../utils';
import {
  createProfessional,
  updateProfessional,
  toggleShowDeleteModal,
  updateMemberStoreValue,
} from '../../store/actions';

const ProfessionalModal = ({
  viewOnly = false,
  hide,
  isNew,
  householdId,
  defaultTab,
  invisible,
  isConsumer,
}) => {
  const ref = useRef();
  const dispatch = useDispatch();
  const {
    professional,
    loadedMemberFromAPI,
    professionalError,
    professionalCategories,
    countries,
    currentHousehold,
  } = useSelector((state) => ({
    professional: state.members.professional,
    loadedMemberFromAPI: state.members.loadedMemberFromAPI,
    professionalError: state.members.professionalError,
    professionalCategories: state.configs.professionalCategories,
    countries: state.configs.countries,
    currentHousehold: state.households.currentHousehold,
  }));
  // const [modalTabOptions, setModalTabOptions] = useState(modalTabs);
  const [activeTab, setActiveTab] = useState('essentials');
  const [currentProfessional, setCurrentProfessional] = useState({
    business_name: '',
    first_name: '',
    last_name: '',
    role: '',
    website: '',
    address: '',
    address2: '',
    city: '',
    zip: '',
    phone_number: '',
    email: '',
    notes: '',
  });
  const [loadedProfessional, setLoadedProfessional] = useState(false);
  const [selectedRole, setSelectedRole] = useState();
  const [selectedCountry, setSelectedCountry] = useState();
  const [selectedState, setSelectedState] = useState();
  const [stateDisabled, setStateDisabled] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [memberRoles, setMemberRoles] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [modalError, setModalError] = useState('');
  const [modalErrors, setModalErrors] = useState();

  useEffect(() => {
    return () => {
      dispatch(updateMemberStoreValue('professional', null));
      dispatch(updateMemberStoreValue('loadedMemberFromAPI', null));
    };
  }, []);

  useEffect(() => {
    if (professionalError) {
      setButtonLoading(false);
      setLoadedProfessional(true);
      if (professionalError?.data) {
        if (professionalError.data?.message) {
          return setModalError(professionalError.data?.message);
        }
        setModalErrors(professionalError?.data);
        const length = Object.keys(professionalError?.data).length;
        if (length === 1) {
          return setModalError(
            professionalError.data[Object.keys(professionalError.data)[0]]
          );
        }
      } else {
        setModalError('Please fix errors and try saving again.');
      }
    }
  }, [professionalError]);

  useEffect(() => {
    if (professionalCategories && professionalCategories.length !== 0) {
      setMemberRoles(professionalCategories);
    }
  }, [professionalCategories]);

  useEffect(() => {
    if (countries && countries.length !== 0) {
      setCountryList(
        countries.map((country) => {
          country.label = country.name;
          country.value = country.code;
          return country;
        })
      );
    }
  }, [countries]);

  useEffect(() => {
    if (currentHousehold && currentHousehold.hasOwnProperty('id')) {
      if (isNew) {
        setLoadedProfessional(true);
        SetDefaultItem(countries, currentHousehold.country, setSelectedCountry);
      }
    }
  }, [currentHousehold, isNew]);

  useEffect(() => {
    if (selectedCountry && selectedCountry.value !== 'US') {
      setSelectedState(null);
      setStateDisabled(true);
    } else {
      setStateDisabled(false);
    }
  }, [selectedCountry]);

  useEffect(() => {
    if (selectedRole) {
      setModalError('');
      setModalErrors();
    }
  }, [selectedRole]);

  const matchRole = (role) => {
    let matched = memberRoles.find((mem) => mem.value === role);
    if (matched) {
      return setSelectedRole(matched);
    }
  };

  const matchState = (stateId) => {
    let matched = states.find((state) => state.code === stateId);
    if (matched) {
      return setSelectedState({ label: matched.name, value: matched.code });
    }
  };

  const matchCountry = (countryId, isCountry = false) => {
    let matched = countryList.find((country) => country.value === countryId);
    if (matched) {
      if (isCountry) {
        return setSelectedCountry(matched);
      }
    }
  };

  //If age is under 18 set is_dependent to true

  useEffect(() => {
    if (!isNew) {
      if (professional && professional.hasOwnProperty('id')) {
        setCurrentProfessional({
          id: professional.id,
          uuid: professional?.uuid,
          category: matchRole(professional.category),
          business_name: HtmlToString(professional.business_name),
          first_name: HtmlToString(professional.first_name),
          last_name: HtmlToString(professional.last_name),
          role: HtmlToString(professional.role),
          website: HtmlToString(professional.website),
          address: HtmlToString(professional.address),
          address2: HtmlToString(professional.address2),
          city: HtmlToString(professional.city),
          state: matchState(professional.state),
          country: matchCountry(professional.country, true),
          zip: professional.zip,
          phone_number: professional.phone_number,
          email: professional.email,
          notes: professional.notes,
        });
        setLoadedProfessional(loadedMemberFromAPI);
        if (defaultTab) {
          setActiveTab(defaultTab);
        }
      }
    }
  }, [professional]);

  const saveProfessional = () => {
    if (selectedRole === undefined) {
      setModalErrors({
        ...modalErrors,
        category: ['Please select a Relationship.'],
      });
      return setModalError('Please select a Relationship.');
    }
    if (currentProfessional.business_name === '') {
      setModalErrors({
        ...modalErrors,
        business_name: ['Please enter a Business Name.'],
      });
      return setModalError('Please enter a Business Name.');
    }
    let state = '';
    if (selectedState && selectedState.value) {
      state = selectedState.value;
    }
    let country = '';
    if (selectedCountry && selectedCountry.value) {
      country = selectedCountry.value;
    }

    const professionalObj = {
      category: selectedRole.value,
      business_name: currentProfessional.business_name,
      first_name: currentProfessional.first_name,
      last_name: currentProfessional.last_name,
      role: currentProfessional.role,
      website: currentProfessional.website,
      address: currentProfessional.address,
      address2: currentProfessional.address2,
      city: currentProfessional.city,
      state,
      country,
      zip: currentProfessional.zip,
      phone_number: currentProfessional.phone_number,
      email: currentProfessional.email,
      notes: currentProfessional.notes,
    };
    setButtonLoading(true);
    if (isNew) {
      dispatch(createProfessional(householdId, professionalObj, isConsumer));
    } else {
      dispatch(
        updateProfessional(
          householdId,
          professional.id,
          professionalObj,
          isConsumer
        )
      );
    }
  };

  const saveOnEnter = () => {
    if (buttonLoading || activeTab === 'notes') {
      return;
    }
    return saveProfessional();
  };

  const openDeleteProfessional = () => {
    dispatch(
      toggleShowDeleteModal(
        true,
        'professional',
        professional.business_name,
        professional.id
      )
    );
  };

  const updateCurrentProfessional = (e, isSelect = false, fieldName = null) => {
    setModalError('');
    setModalErrors();
    if (isSelect) {
      setCurrentProfessional({
        ...currentProfessional,
        [fieldName]: e,
      });
    } else {
      setCurrentProfessional({
        ...currentProfessional,
        [e.currentTarget.name]: e.currentTarget.value,
      });
    }
  };

  const isDisabled = () => {
    if (viewOnly) {
      return true;
    }
    return false;
  };

  const essentialInputs = [
    {
      type: 'select',
      label: 'Relationship',
      name: 'category',
      required: true,
      placeholder: 'Select Relationship',
      value: selectedRole,
      options: memberRoles,
      width: '100%',
      isVisible: true,
      disabled: isDisabled('category'),
      onChange: (e) => setSelectedRole(e),
      onEnter: saveOnEnter,
      hasError: CheckHasError(modalErrors, 'category'),
      errorMessage: GenerateError(modalErrors, 'category'),
    },
    {
      type: 'text',
      label: 'Relationship to Display',
      maxLength: '100',
      name: 'role',
      width: '100%',
      required: false,
      placeholder:
        'Financial Advisor, Accountant, Business Partner, Attorney, etc.',
      value: HtmlToString(currentProfessional.role),
      isVisible: true,
      disabled: isDisabled('role'),
      onChange: (e) => updateCurrentProfessional(e),
      onKeyPress: (e) => DetectEnterKeyPress(e, saveOnEnter),
      hasError: CheckHasError(modalErrors, modalErrors, 'role'),
      errorMessage: GenerateError(modalErrors, 'role'),
    },
    {
      type: 'text',
      label: 'Business Name',
      maxLength: '200',
      name: 'business_name',
      width: '100%',
      required: true,
      placeholder: 'Business Name',
      value: HtmlToString(currentProfessional.business_name),
      isVisible: true,
      disabled: isDisabled('business_name'),
      onChange: (e) => updateCurrentProfessional(e),
      onKeyPress: (e) => DetectEnterKeyPress(e, saveOnEnter),
      hasError: CheckHasError(modalErrors, 'business_name'),
      errorMessage: GenerateError(modalErrors, 'business_name'),
    },
    {
      type: 'text',
      label: 'Website',
      maxLength: '200',
      name: 'website',
      width: '100%',
      required: false,
      placeholder: 'Website',
      value: HtmlToString(currentProfessional.website),
      isVisible: true,
      disabled: isDisabled('website'),
      onChange: (e) => updateCurrentProfessional(e),
      onKeyPress: (e) => DetectEnterKeyPress(e, saveOnEnter),
      hasError: CheckHasError(modalErrors, 'website'),
      errorMessage: GenerateError(modalErrors, 'website'),
    },
    {
      type: 'text',
      label: 'First Name',
      maxLength: '45',
      name: 'first_name',
      width: '48%',
      required: false,
      placeholder: 'First Name',
      value: HtmlToString(currentProfessional.first_name),
      isVisible: true,
      disabled: isDisabled('first_name'),
      onChange: (e) => updateCurrentProfessional(e),
      onKeyPress: (e) => DetectEnterKeyPress(e, saveOnEnter),
      hasError: CheckHasError(modalErrors, 'first_name'),
      errorMessage: GenerateError(modalErrors, 'first_name'),
    },
    {
      type: 'text',
      label: 'Last Name',
      maxLength: '45',
      name: 'last_name',
      width: '48%',
      required: false,
      placeholder: 'Last Name',
      value: HtmlToString(currentProfessional.last_name),
      isVisible: true,
      disabled: isDisabled('last_name'),
      onChange: (e) => updateCurrentProfessional(e),
      onKeyPress: (e) => DetectEnterKeyPress(e, saveOnEnter),
      hasError: CheckHasError(modalErrors, 'last_name'),
      errorMessage: GenerateError(modalErrors, 'last_name'),
    },
    // {
    //   type: 'checkbox',
    //   label: 'Include in My Household',
    //   name: 'is_dependent',
    //   required: false,
    //   value: currentMember.is_dependent,
    //   isVisible: true,
    //   disabled: isDisabled('is_dependent'),
    //   onChange: () =>
    //     setCurrentMember({
    //       ...currentMember,
    //       is_dependent: !currentMember.is_dependent,
    //     }),
    //   // hasError: layoutNameFieldError,
    // },
  ];

  const detailInputs = [
    {
      type: 'text',
      label: 'Address',
      name: 'address',
      width: '100%',
      required: false,
      placeholder: 'Address',
      value: HtmlToString(currentProfessional.address),
      isVisible: true,
      disabled: isDisabled('address'),
      onChange: (e) => updateCurrentProfessional(e),
      onKeyPress: (e) => DetectEnterKeyPress(e, saveOnEnter),
      hasError: CheckHasError(modalErrors, 'address'),
      errorMessage: GenerateError(modalErrors, 'address'),
    },
    {
      type: 'text',
      name: 'address2',
      width: '100%',
      required: false,
      placeholder: 'Address Additional',
      value: HtmlToString(currentProfessional.address2),
      isVisible: true,
      disabled: isDisabled('address2'),
      onChange: (e) => updateCurrentProfessional(e),
      onKeyPress: (e) => DetectEnterKeyPress(e, saveOnEnter),
      hasError: CheckHasError(modalErrors, 'address2'),
      errorMessage: GenerateError(modalErrors, 'address2'),
    },
    {
      type: 'text',
      label: 'City',
      maxLength: '45',
      name: 'city',
      width: '40%',
      required: false,
      placeholder: 'City',
      value: HtmlToString(currentProfessional.city),
      isVisible: true,
      disabled: isDisabled('city'),
      onChange: (e) => updateCurrentProfessional(e),
      onKeyPress: (e) => DetectEnterKeyPress(e, saveOnEnter),
      hasError: CheckHasError(modalErrors, 'city'),
      errorMessage: GenerateError(modalErrors, 'city'),
    },
    {
      type: 'select',
      label: 'State',
      name: 'state',
      width: '30%',
      required: false,
      placeholder: 'State',
      options: GetStates(states),
      value: selectedState,
      isVisible: true,
      disabled: isDisabled('state') || stateDisabled,
      onChange: (e) => setSelectedState(e),
      onEnter: saveOnEnter,
      hasError: CheckHasError(modalErrors, 'state'),
      errorMessage: GenerateError(modalErrors, 'state'),
    },
    {
      type: 'text',
      label: 'Zip Code',
      name: 'zip',
      width: '25%',
      required: false,
      placeholder: 'Zip Code',
      value: currentProfessional.zip,
      isVisible: true,
      disabled: isDisabled('zip'),
      onKeyPress: (e) => DetectEnterKeyPress(e, saveOnEnter),
      onChange: (e) => updateCurrentProfessional(e),
      hasError: CheckHasError(modalErrors, 'zip'),
      errorMessage: GenerateError(modalErrors, 'zip'),
    },
    {
      type: 'select',
      label: 'Country',
      name: 'country',
      required: false,
      placeholder: 'Country',
      value: selectedCountry,
      options: countryList,
      width: '100%',
      isVisible: true,
      disabled: isDisabled('country'),
      onChange: (e) => setSelectedCountry(e),
      onEnter: saveOnEnter,
      hasError: CheckHasError(modalErrors, 'country'),
      errorMessage: GenerateError(modalErrors, 'country'),
    },
    {
      type: 'text',
      label: 'Phone',
      maxLength: '45',
      name: 'phone_number',
      width: '48%',
      required: false,
      placeholder: 'Phone',
      value: currentProfessional.phone_number,
      isVisible: true,
      disabled: isDisabled('phone'),
      onKeyPress: (e) => DetectEnterKeyPress(e, saveOnEnter),
      onChange: (e) => updateCurrentProfessional(e),
      hasError: CheckHasError(modalErrors, 'phone'),
      errorMessage: GenerateError(modalErrors, 'phone'),
    },
    {
      type: 'text',
      label: 'Email',
      maxLength: '45',
      name: 'email',
      width: '48%',
      required: false,
      placeholder: 'Email',
      value: currentProfessional.email,
      isVisible: true,
      disabled: isDisabled('email'),
      onKeyPress: (e) => DetectEnterKeyPress(e, saveOnEnter),
      onChange: (e) => updateCurrentProfessional(e),
      hasError: CheckHasError(modalErrors, 'email'),
      errorMessage: GenerateError(modalErrors, 'email'),
    },
  ];

  const notesInput = {
    type: 'textarea',
    label: 'Notes',
    name: 'notes',
    showClear: true,
    id: 'member',
    value: currentProfessional.notes,
    onChange: (e) => updateCurrentProfessional(e, true, 'notes'),
  };

  const showModalButtons = () => {
    return !viewOnly && activeTab !== 'connect';
  };

  return ReactDOM.createPortal(
    <>
      <DetectModalKeys onEnter={saveOnEnter} onEsc={hide} />
      <ModalBackground invisible={invisible} />
      <ModalOverlay invisible={invisible}>
        <ModalContainer role="dialog" ref={ref}>
          <ModalHeadingContainer>
            <ModalHeading>
              {viewOnly
                ? 'Professional Details'
                : isNew
                  ? 'Add Professional'
                  : 'Edit Professional'}
            </ModalHeading>
            <CloseButton
              src={timesWhite}
              onClick={() => hide()}
              alt="close"
              data-image="close"
            />
          </ModalHeadingContainer>
          <ModalLoadingOverlayContainer>
            {!loadedProfessional && !isNew && (
              <LoadingOverlayContainer>
                <LoadingOverlay>
                  <LoadingAnimation />
                </LoadingOverlay>
              </LoadingOverlayContainer>
            )}
            <ModalTabs
              tabs={viewOnly ? clientModalTabs : modalTabs}
              activeTab={activeTab}
              onClick={setActiveTab}
            />
            <ModalContentContainer>
              {activeTab === 'essentials' ? (
                <TabContent>
                  {essentialInputs.map((input, index) => {
                    return GenerateInput(input, index, null, viewOnly);
                  })}
                </TabContent>
              ) : activeTab === 'details' ? (
                <TabContent>
                  {detailInputs.map((input, index) => {
                    return GenerateInput(input, index);
                  })}
                </TabContent>
              ) : activeTab === 'notes' ? (
                <TabContent>{GenerateInput(notesInput, 0)}</TabContent>
              ) : null}
              {showModalButtons() && (
                <ModalButtons
                  isNew={isNew}
                  showCopy={false}
                  hide={hide}
                  deleteFunction={openDeleteProfessional}
                  saveFunction={saveProfessional}
                  showLoading={buttonLoading}
                  loadingText={isNew ? 'Creating' : 'Saving'}
                />
              )}
            </ModalContentContainer>
          </ModalLoadingOverlayContainer>
          {modalError && modalError !== '' && (
            <Error errorMessage={modalError} />
          )}
        </ModalContainer>
      </ModalOverlay>
    </>,
    document.body
  );
};

ProfessionalModal.propTypes = {
  viewOnly: PropTypes.bool,
  hide: PropTypes.func,
  isNew: PropTypes.bool,
  householdId: PropTypes.string,
  defaultTab: PropTypes.string,
  invisible: PropTypes.bool,
  isConsumer: PropTypes.bool,
};

export default ProfessionalModal;
