/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useRef, forwardRef } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import styled from 'styled-components';
import { colors, fonts, boxShadows } from '../../styles/variables.js';
import { chevronRight, calendar, chevronDown } from '../../assets';
import PropTypes from 'prop-types';
import { DateFormat, DateIsValid } from '../../utils/dates.js';

const DatePickerIcon = ({
  value,
  textValue,
  onChange,
  maxDate,
  dateFormat = 'MM/dd/yyyy',
  beforeYearRange = 120,
  afterYearRange = 80,
  isCenter = true,
}) => {
  const [startDate, setStartDate] = useState(new Date());
  const [years, setYears] = useState([]);
  const currentYear = new Date().getFullYear();
  const ref = useRef();

  const updateDateFormat = () => {
    if (dateFormat === 'YYYY/MM/DD') {
      return 'yyyy/MM/dd';
    }
    if (dateFormat === 'MM/DD/YYYY') {
      return 'MM/dd/yyyy';
    }
    if (dateFormat === 'DD/MM/YYYY') {
      return 'dd/MM/yyyy';
    }
    return dateFormat;
  };

  useEffect(() => {
    if (value) {
      let transformed = DateFormat(value, dateFormat, true);
      let parts = transformed.split('-');
      let stringDate = new Date(parts[0], parts[1] - 1, parts[2]);
      setStartDate(stringDate);
    } else {
      setStartDate();
    }
  }, [value]);

  useEffect(() => {
    if (textValue) {
      const isValid = DateIsValid(textValue, dateFormat, true);
      if (isValid) {
        let transformed = DateFormat(textValue, dateFormat, true);
        let parts = transformed.split('-');
        let stringDate = new Date(parts[0], parts[1] - 1, parts[2]);
        setStartDate(stringDate);
      } else {
        setStartDate();
      }
    }
  }, [textValue]);

  useEffect(() => {
    const range = (start, stop, step) =>
      Array.from(
        { length: (stop - start) / step + 1 },
        (_, i) => start + i * step
      );
    let years = range(
      currentYear + afterYearRange,
      currentYear - beforeYearRange,
      -1
    );
    setYears(years);
  }, []);

  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  const dateOnChange = (date) => {
    setStartDate(date);
    onChange(date);
  };

  const CustomInputIcon = forwardRef(({ onClick, value }, ref) => (
    <Icon
      src={calendar}
      value={value}
      alt="calendar"
      onClick={onClick}
      ref={ref}
      data-image="input-calendar"
    />
  ));
  CustomInputIcon.displayName = 'CustomInputIcon';

  return (
    <DateIconContainer $isCenter={isCenter}>
      <DatePicker
        showPopperArrow={false}
        todayButton={'Today'}
        renderCustomHeader={({
          date,
          changeYear,
          dateFormat,
          maxDate,
          changeMonth,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
        }) => (
          <MonthYearContainer>
            <ArrowButtonLeft
              onClick={decreaseMonth}
              disabled={prevMonthButtonDisabled}
            >
              <img src={chevronRight} alt="left" data-image="arrow-left" />
            </ArrowButtonLeft>
            <SelectContainer>
              <MonthSelect
                value={months[date.getMonth()]}
                onChange={({ target: { value } }) =>
                  changeMonth(months.indexOf(value))
                }
              >
                {months.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </MonthSelect>
              <YearSelect
                value={date.getFullYear()}
                onChange={(e) => changeYear(e.currentTarget.value)}
              >
                {years.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </YearSelect>
            </SelectContainer>
            <ArrowButton
              onClick={increaseMonth}
              disabled={nextMonthButtonDisabled}
            >
              <img src={chevronRight} alt="right" data-image="arrow-right" />
            </ArrowButton>
          </MonthYearContainer>
        )}
        selected={startDate}
        onChange={(date) => dateOnChange(date)}
        dateFormat={updateDateFormat()}
        maxDate={maxDate}
        customInput={<CustomInputIcon ref={ref} />}
      />
    </DateIconContainer>
  );
};

const DateIconContainer = styled.div`
  position: relative;
  .react-datepicker {
    border: 1px solid ${colors.lightGrey};
    box-shadow: ${boxShadows.boxShadowDropdown};
    left: 0;
    transform: ${(props) => (props.$isCenter ? 'translateX(-50%)' : null)};
    .react-datepicker__today-button {
      color: ${colors.darkGrey};
      font-weight: ${fonts.semiBold};
      border-top: 1px solid transparent;
    }
  }
  .react-datepicker__header {
    background: ${colors.lightGrey};
    border-bottom: 1px solid ${colors.lightGrey};
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    .react-datepicker__day-names {
      .react-datepicker__day-name {
        color: ${colors.darkGrey};
        font-weight: ${fonts.semiBold};
      }
    }
  }
  .react-datepicker-popper {
    z-index: 100 !important;
  }
  .react-datepicker__month {
    .react-datepicker__week {
      color: ${colors.darkGrey};
      .react-datepicker__day {
        color: ${colors.darkGrey};
      }
      .react-datepicker__day--outside-month {
        color: ${colors.paleGrey};
      }
      .react-datepicker__day--selected,
      .react-datepicker__day--keyboard-selected {
        background: ${colors.lightBlue};
        color: ${colors.white};
      }
    }
  }
  .react-datepicker-wrapper {
    flex: 1 1 auto;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    height: 100%;
    .react-datepicker__input-container {
      display: flex;
      align-content: center;
      align-items: center;
      justify-content: center;
      height: 100%;
    }
  }
`;

const Icon = styled.img`
  width: 18px;
  height: 18px;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  opacity: 0.6;
  display: block;
  position: absolute;
  justify-content: center;
  align-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.1s ease;
  z-index: 1;
  &:hover {
    opacity: 0.8;
  }
`;

const MonthYearContainer = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  margin: 10px 0;
`;

const SelectArrow = styled.select`
  border: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url(${chevronDown});
  background-color: transparent;
  background-size: 17px;
  background-position-x: right;
  background-position-y: center;
  background-repeat: no-repeat;
  color: ${colors.darkGrey};
  font-family: 'Open Sans';
  option {
    text-align: center;
  }
`;

const MonthSelect = styled(SelectArrow)`
  padding: 0 15px 0 0;
  flex: 0 0 auto;
  margin-right: 15px;
`;

const YearSelect = styled(SelectArrow)`
  flex: 0 0 auto;
  padding: 0 20px 0 0;
`;

const SelectContainer = styled.div`
  flex: 1 1 auto;
`;

const ArrowButton = styled.button`
  flex: 0 0 auto;
  img {
    width: 10px;
    height: 10px;
  }
`;
const ArrowButtonLeft = styled(ArrowButton)`
  img {
    width: 10px;
    height: 10px;
    transform: rotate(180deg);
  }
`;

DatePickerIcon.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  dateFormat: PropTypes.string,
  textValue: PropTypes.string,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  maxDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  afterYearRange: PropTypes.number,
  beforeYearRange: PropTypes.number,
  isCenter: PropTypes.bool,
};

export default DatePickerIcon;
