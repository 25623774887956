/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Button, FormChunk, SimpleModal } from '../../components';
import {
  updateDiscoveryTeamSettings,
  getUserInfo,
  updateUserStoreValue,
} from '../../store/actions';
import { colors, maxDevice, messageColors } from '../../styles/variables';
import { ButtonThemes } from '../../styles/themes';
import { SendTrackingEvent } from '../../utils';

const ManagerDiscoveryContainer = ({ customerId }) => {
  const dispatch = useDispatch();
  const {
    teamSettings,
    loadedConfigs,
    customerSettingsUpdated,
    customerSettingsError,
    hasDiscoverySettings,
    hasDiscoverySettingsInbound,
    teamHasDiscoveryInboundEnabled,
    showDiscoveryConsentSettings,
    showDiscoveryConnectSettings,
    showDiscoveryTheme,
    showDiscoveryDuration,
    currentDiscoveryDuration,
    discoveryDurationChoices,
    invocationId,
  } = useSelector((state) => ({
    teamSettings: state.user.teamSettings,
    loadedConfigs: state.configs.loadedConfigs,
    customerSettingsUpdated: state.user.customerSettingsUpdated,
    customerSettingsError: state.user.customerSettingsError,
    hasDiscoverySettings: state.user.teamHasDiscoverySettings,
    hasDiscoverySettingsInbound: state.user.teamHasDiscoverySettingsInbound,
    teamHasDiscoveryInboundEnabled: state.user.teamHasDiscoveryInboundEnabled,
    showDiscoveryConsentSettings: state.user.showDiscoveryConsentSettings,
    showDiscoveryConnectSettings: state.user.showDiscoveryConnectSettings,
    showDiscoveryTheme: state.user.showDiscoveryTheme,
    showDiscoveryDuration: state.user.showDiscoveryDuration,
    currentDiscoveryDuration: state.user.discoveryDuration,
    discoveryDurationChoices: state.configs.discoveryDurationChoices,
    invocationId: state.configs.invocationId,
  }));
  const [accountSettings, setAccountSettings] = useState({
    discovery_redirect_url: '',
    is_allow_discovery_lead: false,
    feature_discovery_consent_setting: false,
    is_discovery_consent_enabled: false,
    feature_connect_discovery_signup_setting: false,
    is_discovery_connect_signup_enabled: false,
    discovery_theme_color: '',
    consent_label: '',
    can_set_discovery_duration: false,
  });
  const [discovery, setDiscovery] = useState('');
  const [consentFull, setConsentFull] = useState('');
  const [consentShort, setConsentShort] = useState('');
  const [errorFields, setErrorFields] = useState([]);
  const [errorMessages, setErrorMessages] = useState([]);
  const [discoveryText, setDiscoveryText] = useState(
    'Here you can update the Discovery Disclaimer and change the Redirect URL after Discovery interview completion.'
  );
  const [showingConnectWarning, setShowingConnectWarning] = useState(false);
  const [discoveryDuration, setDiscoveryDuration] = useState();
  const [discoveryDurationOptions, setDiscoveryDurationOptions] = useState();

  useEffect(() => {
    if (discoveryDurationChoices && discoveryDurationChoices.length > 0) {
      setDiscoveryDurationOptions(discoveryDurationChoices);
    }

    if (currentDiscoveryDuration && discoveryDurationChoices) {
      const filteredResult = discoveryDurationChoices.find(
        (d) => d.value === currentDiscoveryDuration
      );
      if (filteredResult) {
        setDiscoveryDuration(filteredResult);
      }
    }
  }, [discoveryDurationChoices, currentDiscoveryDuration]);

  useEffect(() => {
    if (hasDiscoverySettings !== null && hasDiscoverySettingsInbound !== null) {
      if (
        hasDiscoverySettings &&
        (hasDiscoverySettingsInbound || teamHasDiscoveryInboundEnabled)
      ) {
        setDiscoveryText(
          'Here you can update the Discovery Disclaimer, change the Redirect URL after Discovery interview completion and toggle whether advisors inbound discovery link is enabled.'
        );
      } else if (hasDiscoverySettings) {
        setDiscoveryText(
          'Here you can update the Discovery Disclaimer and change the Redirect URL after Discovery interview completion.'
        );
      } else {
        setDiscoveryText(
          "If you're interested in Discovery, please reach out to your sales rep to discuss options."
        );
      }
    }
  }, [
    hasDiscoverySettings,
    hasDiscoverySettingsInbound,
    teamHasDiscoveryInboundEnabled,
  ]);

  useEffect(() => {
    if (customerSettingsError && customerSettingsError.hasOwnProperty('data')) {
      let errors = [];

      for (const [key, value] of Object.entries(customerSettingsError.data)) {
        errors.push({ field: key, message: value });
      }
      setErrorFields(errors);
      const transformDict = {
        consent_short: 'Short Discovery Consent',
        consent_full: 'Full Discovery Consent',
      };
      const errorMessages = errors.map((error) => {
        let fieldName = error.field.replaceAll('_', ' ');
        if (transformDict[error.field]) {
          fieldName = transformDict[error.field];
        }
        return `${fieldName}: ${error.message}`;
      });
      setErrorMessages(errorMessages);
    }
  }, [customerSettingsError]);

  useEffect(() => {
    if (customerSettingsUpdated) {
      dispatch(getUserInfo());
      dispatch(updateUserStoreValue('customerSettingsUpdated', false));
    }
  }, [customerSettingsUpdated]);

  useEffect(() => {
    if (teamSettings && loadedConfigs) {
      if (teamSettings.discovery_disclaimer) {
        setDiscovery(teamSettings.discovery_disclaimer);
      }
      if (teamSettings?.discovery_consent?.full) {
        setConsentFull(teamSettings?.discovery_consent?.full);
      }
      if (teamSettings?.discovery_consent?.short) {
        setConsentShort(teamSettings?.discovery_consent?.short);
      }
      setAccountSettings({
        ...accountSettings,
        is_discovery_consent_enabled:
          teamSettings?.feature_discovery_consent_setting === 'selected',
        discovery_redirect_url: teamSettings.discovery_redirect_url,
        discovery_theme_color: teamSettings.discovery_theme_color,
        consent_label: teamSettings?.discovery_consent?.label || '',
        is_allow_discovery_lead: teamSettings.is_allow_discovery_lead,
        feature_discovery_consent_setting:
          teamSettings?.feature_discovery_consent_setting,
        feature_connect_discovery_signup_setting:
          teamSettings?.feature_connect_discovery_signup_setting === 'selected',
        can_set_discovery_duration: showDiscoveryDuration,
      });
    }
  }, [teamSettings, loadedConfigs]);

  const checkIsAvailable = (value) => {
    if (value === 'inbound') {
      if (
        (hasDiscoverySettingsInbound || teamHasDiscoveryInboundEnabled) &&
        hasDiscoverySettings
      ) {
        return true;
      }
    } else {
      return hasDiscoverySettings;
    }
  };

  const updateToggle = (e) => {
    setAccountSettings({
      ...accountSettings,
      [e.name]: !e.value,
    });
  };

  const updateDiscoveryConnect = (e) => {
    if (!e.value) {
      setShowingConnectWarning(true);
    } else {
      updateToggle(e);
    }
  };

  const discoverySettingsInputs = [
    {
      name: 'discovery',
      placeholder: 'Discovery Disclaimer',
      type: 'textarea',
      editor: true,
      showClear: true,
      showUseDefault: true,
      fullToolbar: false,
      defaultText: teamSettings?.default_texts?.discovery_disclaimer,
      id: 'discovery',
      width: '100%',
      value: discovery,
      available: checkIsAvailable(),
      tooltip:
        'Set the text that appears on the bottom of the self sign up page.',
      onChange: (e) => setDiscovery(e),
    },
    {
      name: 'discovery_redirect_url',
      label: 'Discovery Redirect URL',
      placeholder: 'https://www.asset-map.com',
      type: 'text',
      available: checkIsAvailable(),
      tooltip:
        'Set the URL to redirect to after Discovery completion. Empty field will use the Asset-Map Default.',
      width: '100%',
    },
    {
      name: 'discovery_theme_color',
      label: 'Discovery Custom Color',
      placeholder: colors.blue,
      type: 'color',
      available: showDiscoveryTheme,
      tooltip:
        'This color will be the new accent color for Discovery. Please select or enter a valid hex value.',
      width: '100%',
      onChange: (e) => {
        setAccountSettings({
          ...accountSettings,
          discovery_theme_color: e?.hex,
        });
      },
    },
    {
      name: 'is_allow_discovery_lead',
      theme: {},
      type: 'toggle',
      available: checkIsAvailable('inbound'),
      showLabel: true,
      label: 'Discovery Inbound Link',
      tooltip:
        'Toggle whether advisors custom inbound discovery link is active.',
      width: '100%',
      text: 'Inbound Discovery Link Enabled',
      onClick: updateToggle,
    },
    {
      name: 'feature_connect_discovery_signup_setting',
      theme: {},
      margin: '10px 0 0 0',
      type: 'toggle',
      available: showDiscoveryConnectSettings,
      showLabel: true,
      label: 'Discovery Client Account',
      tooltip:
        'Toggle whether a client can create their own account after completion of a discovery interview. This will override all other redirect settings.',
      width: '100%',
      text: 'Allow Client Account Creation after Discovery',
      onClick: updateDiscoveryConnect,
    },
    {
      name: 'is_discovery_consent_enabled',
      theme: {},
      margin: accountSettings?.is_discovery_consent_enabled
        ? '10px 0'
        : '10px 0 0 0',
      type: 'toggle',
      available: showDiscoveryConsentSettings,
      showLabel: true,
      label: 'Discovery Consent Configuration',
      tooltip: 'Toggle whether Discovery Consent appears on Discoveries.',
      width: '100%',
      text: 'Discovery Consent Enabled',
      onClick: updateToggle,
    },
    {
      name: 'consent_label',
      type: 'text',
      available: accountSettings?.is_discovery_consent_enabled,
      label: 'Discovery Consent Label',
      tooltip:
        'The language that will appear next to the checkbox when asking for consent.',
      width: '100%',
    },
    {
      name: 'consent_short',
      type: 'textarea',
      editor: false,
      required: true,
      available: accountSettings?.is_discovery_consent_enabled,
      showLabel: true,
      minHeight: '95px',
      label: 'Short Discovery Consent',
      value: consentShort,
      tooltip:
        'A summarized consent that will be displayed to the user to accept.',
      width: '100%',
      text: 'Discovery Consent Short',
      onChange: (e) => setConsentShort(e.currentTarget.value),
    },
    {
      name: 'consent_full',
      type: 'textarea',
      editor: false,
      available: accountSettings?.is_discovery_consent_enabled,
      showLabel: true,
      required: true,
      margin: '0 0 25px 0',
      minHeight: '152px',
      label: 'Full Discovery Consent',
      value: consentFull,
      tooltip:
        'The full text of the Consent Form users need to agree to before starting Discovery.',
      width: '100%',
      text: 'Discovery Consent Full',
      onChange: (e) => setConsentFull(e.currentTarget.value),
    },
    {
      name: 'discovery_set_expiry',
      type: 'select',
      label: 'Discovery Duration',
      showLabel: true,
      available: accountSettings?.can_set_discovery_duration,
      width: '30%',
      tooltip: 'Set how long Discovery interviews last before they expire.',
      margin: '10px 10px',
      options: discoveryDurationOptions,
      selectedOption: discoveryDuration,
      onChange: (e) => setDiscoveryDuration(e),
    },
  ];

  const updateSettingsValues = (e) => {
    setAccountSettings({
      ...accountSettings,
      [e.currentTarget.name]: e.currentTarget.value,
    });
  };

  const updateInfo = () => {
    let discovery_consent = null;
    if (showDiscoveryConsentSettings) {
      discovery_consent = 'available';
      if (accountSettings?.is_discovery_consent_enabled) {
        discovery_consent = 'selected';
      }
    }
    let discovery_connect = null;
    if (showDiscoveryConnectSettings) {
      discovery_connect = 'available';
      if (accountSettings?.feature_connect_discovery_signup_setting) {
        discovery_connect = 'selected';
      }
    }
    const obj = {
      ...accountSettings,
      discovery_disclaimer: discovery,
      feature_discovery_consent_setting: discovery_consent,
      consent_label: accountSettings?.consent_label,
      consent_short: consentShort,
      consent_full: consentFull,
      feature_connect_discovery_signup_setting: discovery_connect,
    };

    if (showDiscoveryDuration) {
      obj.discovery_duration = discoveryDuration.value;
    }

    if (
      showDiscoveryConnectSettings &&
      discovery_connect !==
        teamSettings.feature_connect_discovery_signup_setting
    ) {
      SendTrackingEvent(
        invocationId,
        'action',
        'discovery_client_account_toggle',
        null,
        {
          value: discovery_connect,
        }
      );
    }
    dispatch(updateDiscoveryTeamSettings(customerId, obj));
    setErrorFields([]);
    setErrorMessages([]);
  };

  const discoverySettings = {
    header: 'Discovery Settings',
    formText: discoveryText,
    value: accountSettings,
    inputs: discoverySettingsInputs.filter((input) => input.available),
    onChange: updateSettingsValues,
    errorMessages: errorMessages,
    hasErrorDisplay: true,
    borderBottom: false,
  };

  const generateConnectWarningContent = () => {
    return {
      heading: 'Allow Client Account Creation?',
      text: `Are you sure you want to allow clients to be able to create an account after completing a discovery interview.<br/><br/><strong>Allowing this feature will override all other redirect settings.</strong>`,
      buttons: [
        {
          text: 'Close',
          function: () => setShowingConnectWarning(false),
          theme: ButtonThemes.cancel,
        },
        {
          text: 'Yes, Allow',
          function: () => {
            updateToggle({
              name: 'feature_connect_discovery_signup_setting',
              value: false,
            });
            setShowingConnectWarning(false);
          },
          theme: ButtonThemes.primary,
        },
      ],
    };
  };

  return (
    <>
      {showingConnectWarning && (
        <SimpleModal
          hide={() => setShowingConnectWarning(!showingConnectWarning)}
          content={generateConnectWarningContent()}
        />
      )}
      <ContainerLayout>
        <LeftContent>
          <FormChunk
            type={discoverySettings.type}
            header={discoverySettings.header}
            discoverySettingsText={discoverySettings.discoverySettingsText}
            value={discoverySettings.value}
            inputs={discoverySettings.inputs}
            onChange={discoverySettings.onChange}
            button={discoverySettings.button}
            hasErrorDisplay={discoverySettings.hasErrorDisplay}
            errorFields={errorFields}
            errorMessages={discoverySettings.errorMessages}
            borderBottom={discoverySettings.borderBottom}
          />
          <ButtonContainer>
            <Button text={'Update'} onClick={updateInfo} />
          </ButtonContainer>
          {errorMessages && errorMessages.length !== 0 && (
            <ErrorMessageContainer>
              {errorMessages.map((error, index) => {
                return <ErrorMessage key={index}>{error}</ErrorMessage>;
              })}
            </ErrorMessageContainer>
          )}
        </LeftContent>
        <RightContent />
      </ContainerLayout>
    </>
  );
};

const ContainerLayout = styled.div`
  display: flex;
  flex-wrap: wrap-reverse;
  align-content: center;
  align-items: flex-end;
  justify-content: center;
  margin: -25px 0 0 0;
  background: ${colors.lighterGrey};
`;

const FormContainer = styled.div`
  padding-bottom: 25px;
  margin-bottom: 20px;
  border-bottom: 1px solid ${colors.lightGrey};
`;

const LeftContent = styled.div`
  @media ${maxDevice.laptopL} {
    max-width: 50%;
  }
  @media ${maxDevice.laptopAlt} {
    width: 100%;
    max-width: 100%;
  }
  @media ${maxDevice.sideNav} {
    padding: 20px 20px;
    overflow-x: hidden;
  }
  flex: 1 1 auto;
  max-width: 50%;
  background: white;
  padding: 30px 40px 100px 40px;
`;

const RightContent = styled.div`
  @media ${maxDevice.laptop} {
    max-width: 50%;
  }
  @media ${maxDevice.laptopAlt} {
    width: 100%;
    max-width: 100%;
    padding: 0;
    ${FormContainer} {
      border: none;
      padding-bottom: 0;
    }
  }
  flex: 1 1 auto;
  max-width: 50%;
  padding: 30px 20px 20px 40px;
  /* position: relative; */
`;

const ButtonContainer = styled.div`
  width: 100%;
  margin-top: 5px;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-end;
  button {
    width: 130px;
  }
`;

const ErrorMessageContainer = styled.div`
  margin-top: 15px;
  margin-bottom: -5px;
  font-size: 13px;
  text-align: right;
  color: ${messageColors.error};
`;

const ErrorMessage = styled.p`
  &::first-letter {
    text-transform: capitalize;
  }
`;

ManagerDiscoveryContainer.propTypes = {
  customerId: PropTypes.string,
};

export default ManagerDiscoveryContainer;
