import styled from 'styled-components';
import PropTypes from 'prop-types';
// import { Tooltip } from 'react-tippy';
// import { Select } from '.';
import { GenerateReportIcon } from './Reports/ReportListItem';
// import { lock } from '../assets';
// import { selectThemes } from '../styles/themes';
import { colors } from '../styles/variables';
import { LoadingPlaceholder } from '../styles/library/fontStyles';
import CheckBox from './Inputs/CheckBox';

const PermissionListItem = ({
  isInvite = false,
  permission,
  onChange,
  showValue = false,
  viewOnly = false,
  isLoading = false,
}) => {
  const generatePermissionValues = (permission) => {
    const { values } = permission;
    if (values.length === 0) {
      return 'Not Available';
    } else {
      const justValues = values.map((value) => {
        const split = value.split('.');
        let permission = split.slice(2);
        if (permission.length > 1) {
          permission = permission.join(' ');
        }
        return permission;
      });
      const display = justValues.join(', ');
      return display;
    }
  };

  const hasValues = (permission) => {
    return permission.values.length !== 0;
  };

  return isLoading ? (
    <PermissionItem>
      <PermissionName>
        <LoadingPlaceholder margin="0" width="190px" height="18px" />
      </PermissionName>
      {showValue && (
        <PermissionValue>
          <LoadingPlaceholder margin="0" width="16.5px" height="16.5px" />
        </PermissionValue>
      )}
    </PermissionItem>
  ) : isInvite ? (
    hasValues(permission) &&
    (permission.value === 'other' ? (
      permission.values.map((value, index) => {
        return (
          <PermissionItem key={index}>
            <PermissionName>{value}</PermissionName>
          </PermissionItem>
        );
      })
    ) : (
      <PermissionItem>
        <PermissionName data-values={permission.values}>
          <PermissionImage
            src={GenerateReportIcon(
              permission.icon ? permission.icon : permission.value
            )}
            alt={permission.value}
            title={permission.values}
          />
          {permission.name}
        </PermissionName>
        <PermissionValues locked={permission.locked}>
          {generatePermissionValues(permission)}
        </PermissionValues>
      </PermissionItem>
    ))
  ) : (
    <PermissionItem>
      <PermissionName>{permission.name}</PermissionName>
      {showValue && (
        <PermissionValue>
          <CheckBox
            onChange={() => onChange(permission)}
            checked={permission.selected}
            disabled={viewOnly}
          />
        </PermissionValue>
      )}
    </PermissionItem>
  );
};
//   return isLoading ? (
//     <PermissionItem>
//       <PermissionName>
//         <LoadingPlaceholder
//           round={true}
//           width="16.5px"
//           height="16.5px"
//           margin="0"
//         />
//         <LoadingPlaceholder margin="0px 0 0 5px" width="90px" height="16.5px" />
//       </PermissionName>
//       <PermissionValueContainer>
//         <LoadingPlaceholder width="80px" height="16.5px" margin="0px" />
//       </PermissionValueContainer>
//     </PermissionItem>
//   ) : (
//     <PermissionItem locked={permission.locked}>
//       <PermissionName>
//         <PermissionImage
//           src={GenerateReportIcon(permission.icon)}
//           alt={permission.id}
//         />
//         {permission.name}
//       </PermissionName>
//       {permission.locked && (
//         <Tooltip
//           title={`${permission.name} is required for all users`}
//           position="right"
//           trigger="mouseenter click"
//           delay={0}
//           arrow={true}
//           arrowSize={'small'}
//           animation={'fade'}
//           animateFill={false}
//           transitionFlip={false}
//           size={'small'}
//         >
//           <LockIcon src={lock} alt="lock" />
//         </Tooltip>
//       )}
//       <PermissionValueContainer>
//         {viewOnly ? (
//           <PermissionValue locked={permission.locked}>
//             {generatePermissionValues(permission.value)}
//           </PermissionValue>
//         ) : (
//           <Select
//             selectedOption={permission.value}
//             onChange={(e) => onChange(permission.id, e)}
//             options={permission.options}
//             isSearchable={false}
//             // placeholder={input.placeholder}
//             autoFocus={false}
//             isDisabled={permission.locked}
//             // hasError={input.hasError}
//             // onEnter={input.onEnter}
//             closeMenuOnSelect={true}
//             styles={selectThemes.permissions}
//           />
//         )}
//       </PermissionValueContainer>
//     </PermissionItem>
//   );
// };

const PermissionName = styled.p`
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
  margin-right: 5px;
`;

const PermissionImage = styled.img`
  width: 15px;
  height: 15px;
  flex: 0 0 auto;
  margin-right: 10px;
`;

// const PermissionValueContainer = styled.div`
//   flex: 1 1 auto;
//   display: flex;
//   align-items: center;
//   align-content: center;
//   justify-content: flex-end;
// `;

const PermissionValues = styled.p`
  text-align: right;
  flex: 1 1 auto;
  text-transform: capitalize;
  color: ${colors.paleGrey};
`;

// const LockIcon = styled.img`
//   width: 10px;
//   height: 10px;
//   opacity: 0.5;
// `;

const PermissionValue = styled.div`
  text-align: right;
  flex: 0 0 auto;
  text-transform: capitalize;
  color: ${(props) => (props.locked ? colors.paleGrey : colors.darkGrey)};
`;

const PermissionItem = styled.div`
  font-size: 12px;
  border-bottom: 1px solid ${colors.lightGrey};
  padding: 10px 5px;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
  ${PermissionName} {
    color: ${(props) => (props.locked ? colors.paleGrey : colors.darkGrey)};
  }
  ${PermissionImage} {
    opacity: ${(props) => (props.locked ? '.5' : '1')};
  }
`;

PermissionListItem.propTypes = {
  permission: PropTypes.object,
  onChange: PropTypes.func,
  showValue: PropTypes.bool,
  viewOnly: PropTypes.bool,
  isLoading: PropTypes.bool,
};

export default PermissionListItem;
