/* eslint-disable react-hooks/exhaustive-deps */
import { useRef, useEffect } from 'react';
import ReactDOMServer from 'react-dom/server';
import ReactQuill from 'react-quill';
import PropTypes from 'prop-types';
import 'react-quill/dist/quill.snow.css';
import {
  EditorContainer,
  InputLabel,
  ClearButton,
  UseDefaultTextButton,
} from '../../styles/library/inputStyles';
import { textEditorColors } from '../../styles/variables';

const TextEditor = ({
  label,
  value,
  onChange,
  onBlur,
  error,
  showClear = false,
  showUseDefault = false,
  defaultText = ' ',
  id = '1',
  height,
  style,
  theme = 'snow',
  fullToolbar = true,
}) => {
  const ref = useRef();

  useEffect(() => {
    if (ref && showClear) {
      const button = document.createElement('span');
      button.classList.add('ql-formats');
      button.innerHTML = htmlString;
      button.addEventListener('click', clearContent);
      const toolbar = document.querySelector(
        `.editor-container-${id} .quill .ql-toolbar`
      );
      toolbar.appendChild(button);
    }
  }, [ref, showClear]);

  useEffect(() => {
    if (ref && showUseDefault) {
      const button = document.createElement('span');
      button.classList.add('ql-formats');
      button.innerHTML = defaultTextHtml;
      button.addEventListener('click', useDefaultContent);
      const toolbar = document.querySelector(
        `.editor-container-${id} .quill .ql-toolbar`
      );
      toolbar.appendChild(button);
    }
  }, [ref, showUseDefault]);

  const clearContent = () => {
    onChange(' ');
  };

  const useDefaultContent = () => {
    onChange(defaultText);
  };

  const modules = {
    toolbar: [
      [
        'bold',
        'italic',
        'underline',
        'strike',
        // { color: [] },
        { color: textEditorColors },
        'link',
      ],
      [{ list: 'ordered' }, { list: 'bullet' }],
    ],
  };

  const partialModules = {
    toolbar: [['bold', 'italic', 'underline', 'strike', 'link']],
  };

  const formats = [
    'bold',
    'italic',
    'link',
    'strike',
    'underline',
    'list',
    'color',
  ];

  const partialFormats = ['bold', 'italic', 'link', 'strike', 'underline'];

  const htmlString = ReactDOMServer.renderToStaticMarkup(
    <ClearButton title="Clear Content">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
        <path
          d="M207.6 256l107.72-107.72c6.23-6.23 6.23-16.34 0-22.58l-25.03-25.03c-6.23-6.23-16.34-6.23-22.58 0L160 208.4 52.28 100.68c-6.23-6.23-16.34-6.23-22.58 0L4.68 125.7c-6.23 6.23-6.23 16.34 0 22.58L112.4 256 4.68 363.72c-6.23 6.23-6.23 16.34 0 22.58l25.03 25.03c6.23 6.23 16.34 6.23 22.58 0L160 303.6l107.72 107.72c6.23 6.23 16.34 6.23 22.58 0l25.03-25.03c6.23-6.23 6.23-16.34 0-22.58L207.6 256z"
          fill="#444"
        />
      </svg>
    </ClearButton>
  );

  // const handleBlur = (val) => {
  //   return onBlur(val);
  // };

  const defaultTextHtml = ReactDOMServer.renderToStaticMarkup(
    <UseDefaultTextButton>Use Default Text</UseDefaultTextButton>
  );
  return (
    <>
      {label && <InputLabel>{label}</InputLabel>}
      <EditorContainer
        error={error}
        style={style ? style : null}
        height={height}
        className={`editor-container-${id}`}
      >
        <ReactQuill
          ref={ref}
          value={value}
          onChange={onChange}
          theme={theme}
          modules={fullToolbar ? modules : partialModules}
          formats={fullToolbar ? formats : partialFormats}
          // onBlur={(range, source, quill) => {
          //   handleBlur(quill.getHTML(), quill);
          // }}
        />
      </EditorContainer>
    </>
  );
};

TextEditor.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  error: PropTypes.bool,
  showClear: PropTypes.bool,
  showUseDefault: PropTypes.bool,
  defaultText: PropTypes.string,
  id: PropTypes.string,
  height: PropTypes.string,
  style: PropTypes.object,
  theme: PropTypes.string,
};

export default TextEditor;
