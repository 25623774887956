/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Axios from 'axios';
import Cookies from 'js-cookie';
import {
  SmallTable,
  Pagination,
  SubTabMenu,
  Button,
  TypeaheadSearch,
} from '../../../components';
import {
  getDeletedHouseholdItems,
  restoreDeletedHouseholdItems,
  getHouseholdInfo,
  getConsumerHouseholdInfo,
} from '../../../store/actions';
import { colors, maxDevice } from '../../../styles/variables';
import { CONSUMER_API as consumerAPI } from '../../../utils/constants';

const HouseholdDeletedItems = ({ householdId, isConsumer }) => {
  const dispatch = useDispatch();
  const { deletedHouseholdItems, justRestoredDeletedItems, limit, totalItems } =
    useSelector((state) => ({
      deletedHouseholdItems: state.households.deletedHouseholdItems?.items,
      justRestoredDeletedItems: state.households.justRestoredDeletedItems,
      limit: state.configs?.pagination?.default_limit || 10,
      totalItems: state.households.deletedHouseholdItems?.len,
    }));
  const [activeTab, setActiveTab] = useState('household_members');
  const [householdItems, setHouseholdItems] = useState([]);
  const [loadedHouseholdItems, setLoadedHouseholdItems] = useState(false);
  const [selectedHouseholdItems, setSelectedHouseholdItems] = useState([]);
  const [sortValue, setSortValue] = useState('-modified_utc');
  const [query, setQuery] = useState('');
  const [offset, setOffset] = useState(0);
  const [noOptionMessage, setNoOptionMessage] = useState(
    'Start typing to begin search'
  );
  const [restoringItems, setRestoringItems] = useState(false);
  //SUB TAB OPTIONS FOR DELETED ITEMS
  const tabs = [
    {
      text: 'Members',
      value: 'household_members',
    },
    {
      text: 'Financials',
      value: 'financial_tools',
    },
  ];

  //FUNCTION TO UPDATE TABLE HEADINGS TO DISPLAY SORT DIRECTION
  const headingOnClick = (value) => {
    setTableHeadings(
      tableHeadings.map((heading) => {
        if (heading.value === value.value) {
          heading.ascending = !heading.ascending;
        }
        heading.active = heading.value === value.value;
        return heading;
      })
    );
  };

  const [tableHeadings, setTableHeadings] = useState([
    {
      text: ' ',
      value: 'is_selected',
      display: true,
      sortable: false,
      onClick: headingOnClick,
      ascending: true,
    },
    {
      text: 'Name',
      value: 'name',
      display: true,
      sortable: true,
      onClick: headingOnClick,
      ascending: true,
    },
    {
      text: 'Type',
      value: 'item_type',
      display: true,
      sortable: true,
      onClick: headingOnClick,
      ascending: true,
    },
    {
      text: 'Category',
      value: 'category',
      display: true,
      sortable: true,
      onClick: headingOnClick,
      ascending: true,
    },
    {
      text: 'Last Modified',
      value: 'last_modified',
      display: true,
      sortable: true,
      onClick: headingOnClick,
      ascending: true,
      active: true,
    },
  ]);

  //HOOK TO FETCH DELETED ITEMS ON CONTAINER LOAD
  useEffect(() => {
    if (householdId) {
      dispatch(
        getDeletedHouseholdItems(
          householdId,
          isConsumer,
          activeTab,
          offset,
          '-modified_utc',
          limit
        )
      );
    }
  }, [householdId]);

  //HOOK TO SET TRANSFORM AND SET DELETED HOUSEHOLD ITEMS FROM API DATA
  useEffect(() => {
    if (deletedHouseholdItems) {
      const transformedHouseholdItems = deletedHouseholdItems.map((item) => {
        item.label = item.name;
        item.value = item.uuid;
        item.id = item.uuid;
        if (item.type === 'income') {
          item.type = 'Cash Flow';
        }
        item.is_selected = false;
        return item;
      });
      setHouseholdItems(transformedHouseholdItems);
      setLoadedHouseholdItems(true);
    }
  }, [deletedHouseholdItems]);

  //HOOK TO MAKE API CALL TO GET DELETED ITEMS WHEN TAB IS CLICKED AFTER INIT LOAD
  useEffect(() => {
    if (activeTab && loadedHouseholdItems) {
      dispatch(
        getDeletedHouseholdItems(
          householdId,
          isConsumer,
          activeTab,
          0,
          sortValue,
          limit
        )
      );
    }
  }, [activeTab]);

  //HOOK TO GET UPDATED HOUSEHOLD INFO AFTER ITEMS HAVE BEEN RESTORED
  useEffect(() => {
    if (justRestoredDeletedItems) {
      setRestoringItems(false);
      setSelectedHouseholdItems([]);
      isConsumer
        ? dispatch(getConsumerHouseholdInfo(householdId))
        : dispatch(getHouseholdInfo(householdId));
    }
  }, [justRestoredDeletedItems]);

  //HOOK TO MAKE API CALL TO GET DELETED ITEMS IF OFFSET HAS CHANGED AND INIT HAS LOADED
  useEffect(() => {
    if ((offset || offset === 0) && loadedHouseholdItems) {
      dispatch(
        getDeletedHouseholdItems(
          householdId,
          isConsumer,
          activeTab,
          offset,
          sortValue,
          limit
        )
      );
    }
  }, [offset]);

  //HOOK THAT RUNS WHEN TABLE HEADING SORT CHANGES AND INIT HAS LOADED
  useEffect(() => {
    if (tableHeadings && loadedHouseholdItems) {
      const activeHeading = tableHeadings.find((heading) => heading.active);
      let newSortValue = activeHeading?.value;
      if (!activeHeading?.ascending) {
        newSortValue = `-${activeHeading?.value}`;
      }
      if (newSortValue !== sortValue) {
        dispatch(
          getDeletedHouseholdItems(
            householdId,
            isConsumer,
            activeTab,
            0,
            newSortValue,
            limit
          )
        );
        setSortValue(newSortValue);
      }
    }
  }, [tableHeadings]);

  //HOOK TO SET SELECTED HOUSEHOLD ITEMS LIST EVERY TIME THERE IS A CHANGE TO HOUSEHOLD ITEMS
  useEffect(() => {
    if (householdItems && loadedHouseholdItems) {
      setSelectedHouseholdItems(householdItems.filter((hi) => hi.is_selected));
    }
  }, [householdItems]);

  //FUNCTION THAT MAKES ASYNC SEARCH CALL FOR SEARCH BAR
  const loadOptions = () => {
    if (query) {
      const CSRFToken = Cookies.get('csrftoken');
      Axios.defaults.headers.common['X-CSRFToken'] = CSRFToken;
      Axios.defaults.baseURL = window.location.origin + '/api/v3';
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: { query: query, type: activeTab },
      };
      return Axios.post(
        `${
          isConsumer ? consumerAPI : ''
        }/household/${householdId}/deleted_items_search`,
        requestOptions
      ).then((res) => res.data);
    }
  };

  //FUNCTION TO CHANGE NO OPTION METHOD AS WELL TO UPDATE QUERY VALUE TO USERS INPUT
  const onChangeSearch = (newQuery) => {
    if (newQuery === null || newQuery === '' || newQuery === ' ') {
      setQuery('');
      setNoOptionMessage('Start typing to begin search');
    } else {
      setQuery(newQuery);
      setNoOptionMessage('No results');
    }
  };

  //FUNCTION THAT UPDATES SELECT HOUSEHOLD ITEMS WHEN USER CLICKS ON VALUE FROM SEARCH SELECT
  const onSelectSearchResult = (selections) => {
    let selectedIds = [];
    if (selections) {
      selectedIds = selections?.map((sel) => sel?.value);
    }
    setHouseholdItems(
      householdItems.map((hi) => {
        hi.is_selected = selectedIds.includes(hi.value);
        return hi;
      })
    );
  };

  //FUNCTION TO TOGGLE WHETHER HOUSEHOLD ITEM IS SELECTED
  const toggleSelected = (item) => {
    setHouseholdItems(
      householdItems.map((hi) => {
        if (hi.value === item.value) {
          hi.is_selected = !hi.is_selected;
        }
        return hi;
      })
    );
  };

  //FUNCTION TO MAKE API CALL TO RESTORE HOUSEHOLD ITEMS
  const restoreSelectedHouseholdItems = () => {
    if (selectedHouseholdItems.length > 0) {
      const memberIds = [];
      const instrumentIds = [];
      const legalIds = [];
      selectedHouseholdItems.forEach((item) => {
        if (item.classification === 'member') {
          memberIds.push(item.value);
        } else if (item.classification === 'instrument') {
          instrumentIds.push(item.value);
        } else if (item.classification === 'legal') {
          legalIds.push(item.value);
        }
      });
      setRestoringItems(true);
      dispatch(
        restoreDeletedHouseholdItems(
          householdId,
          memberIds,
          instrumentIds,
          legalIds,
          activeTab,
          isConsumer
        )
      );
    }
  };

  return (
    <BackgroundPageContent>
      <SubTabMenu tabs={tabs} activeTab={activeTab} onClick={setActiveTab} />
      <ItemsContainer>
        <TopContent>
          <SelectContainer>
            <TypeaheadSearch
              onChange={(e) => onChangeSearch(e)}
              label={'Search by Name or Category'}
              loadOptions={loadOptions}
              showSearchIcon={true}
              isMulti={true}
              value={selectedHouseholdItems}
              placeholder={'Search by Name or Category'}
              noOptionMessage={noOptionMessage}
              onSelectChange={(e) => onSelectSearchResult(e)}
            />
          </SelectContainer>
          {selectedHouseholdItems && selectedHouseholdItems.length !== 0 && (
            <ButtonContainer>
              <Button
                text="Restore Selected"
                showLoading={restoringItems}
                loadingText="Restoring"
                onClick={() => restoreSelectedHouseholdItems()}
              />
            </ButtonContainer>
          )}
        </TopContent>
        <TableContent>
          <SmallTable
            columnHeadings={tableHeadings}
            type="deleted_household_items"
            customEmptyResults={true}
            items={householdItems}
            loaded={loadedHouseholdItems}
            toggleFunction={toggleSelected}
          />
          {totalItems > limit && (
            <Pagination
              clickNext={() => setOffset(offset + limit)}
              clickPrev={() => setOffset(offset - limit)}
              goToPage={(page) => setOffset(limit * (page - 1))}
              offset={offset}
              count={householdItems ? totalItems : null}
              limit={limit}
              paddingBottom={'30px'}
            />
          )}
        </TableContent>
      </ItemsContainer>
    </BackgroundPageContent>
  );
};

const BackgroundPageContent = styled.div`
  @media ${maxDevice.sideNav} {
    padding: 20px 20px 100px 20px;
  }
  padding: 25px 40px 100px 40px;
  background: ${colors.lighterGrey};
  ${'' /* height: 50px; */}
`;

const ItemsContainer = styled.div`
  background: white;
  margin: 0 -40px;
  padding: 40px 40px 0 40px;
`;

const TopContent = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
`;

const SelectContainer = styled.div`
  width: 100%;
  max-width: 450px;
`;

const TableContent = styled.div`
  background: white;
  margin: 0 -40px;
  padding: 40px 40px 0 40px;
`;

const ButtonContainer = styled.div`
  flex: 1 1 auto;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-end;
`;

HouseholdDeletedItems.propTypes = {
  householdId: PropTypes.string,
  isConsumer: PropTypes.bool,
};

export default HouseholdDeletedItems;
