import styled, { ThemeProvider } from 'styled-components';
import PropTypes from 'prop-types';
import { fonts, maxDevice } from '../styles/variables';
import { ErrorThemes } from '../styles/themes';
import { FlexCenterAll } from '../styles/library/layoutStyles';
import { timesRed } from '../assets';

const Error = ({
  errorMessage,
  title,
  theme = ErrorThemes.modalDefault,
  maxWidth,
  dismissOnClick = null,
}) => {
  return (
    <ThemeProvider theme={{ ...theme, maxWidth }}>
      <ErrorContainer>
        <ErrorImg
          src={theme.imgSrc}
          alt="error"
          title={title ? title : 'Error'}
          data-image="error"
        />
        <ErrorText
          dangerouslySetInnerHTML={{ __html: errorMessage }}
          title={errorMessage}
        />
        {dismissOnClick && (
          <CloseButton onClick={dismissOnClick}>
            <CloseImage
              src={timesRed}
              alt="close"
              data-image={`remove-error`}
            />
          </CloseButton>
        )}
      </ErrorContainer>
    </ThemeProvider>
  );
};

const CloseButton = styled.button`
  ${FlexCenterAll};
  padding: 0;
`;

const CloseImage = styled.img`
  width: 8px;
  height: 8px;
`;

export const ErrorContainer = styled.div`
  @media ${maxDevice.sideNav} {
    border-radius: 2px;
  }
  height: ${(props) => props.theme.height};
  background: ${(props) => props.theme.bg};
  border-radius: ${(props) => props.theme.borderRadius};
  padding: ${(props) => props.theme.padding};
  max-width: ${(props) => (props.theme.maxWidth ? props.theme.maxWidth : '')};
  margin: ${(props) => (props.theme.margin ? props.theme.margin : '')};
  border: ${(props) => (props.theme.border ? props.theme.border : '')};
  ${FlexCenterAll};
`;

const ErrorText = styled.p`
  @media ${maxDevice.tablet} {
    font-size: ${fonts.error};
  }
  color: ${(props) => props.theme.txtColor};
  flex: 1 1 auto;
  text-align: left;
  font-size: ${fonts.error};
  margin-right: 10px;
  width: 100%;
  max-width: ${(props) =>
    props.theme.maxWidthText ? props.theme.maxWidthText : ''};
  white-space: ${(props) => (props.theme.isSidebar ? 'nowrap' : '')};
  overflow: ${(props) => (props.theme.isSidebar ? 'hidden' : 'auto')};
  text-overflow: ${(props) => (props.theme.isSidebar ? 'ellipsis' : '')};
  a {
    color: ${(props) => props.theme.txtColor};
    text-decoration: underline;
  }
`;

export const ErrorImg = styled.img`
  flex: 0 0 auto;
  width: 15px;
  margin-right: 15px;
`;

Error.propTypes = {
  errorMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  title: PropTypes.string,
  theme: PropTypes.object,
  maxWidth: PropTypes.string,
  dismissOnClick: PropTypes.func,
};

export default Error;
