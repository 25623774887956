/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import PropTypes from 'prop-types';
import { LegalModal, LegalSidebar } from '..';

const LegalDisplayContainer = ({
  isModal = true,
  isNew,
  hide,
  modalHeader,
  hasTabs,
  modalTabOptions,
  activeTab,
  setActiveTab,
  loadedData,
  legalsList,
  handleClickLegal,
  hasCategories,
  categoryOptions,
  handleClickCategory,
  selectedLegal,
  showBackButton,
  essentialInputs,
  notesInput,
  handleClickBack,
  onSaveLegal,
  buttonLoading,
  onDeleteLegal,
  modalState,
  modalErrors,
  modalError,
  errorTitle,
  proposalContent,
}) => {
  return isModal ? (
    <LegalModal
      isNew={isNew}
      hide={hide}
      modalHeader={modalHeader}
      hasTabs={hasTabs}
      modalTabOptions={modalTabOptions}
      activeTab={activeTab}
      setActiveTab={setActiveTab}
      loadedData={loadedData}
      legalsList={legalsList}
      handleClickLegal={handleClickLegal}
      hasCategories={hasCategories}
      categoryOptions={categoryOptions}
      handleClickCategory={handleClickCategory}
      selectedLegal={selectedLegal}
      showBackButton={showBackButton}
      essentialInputs={essentialInputs}
      notesInput={notesInput}
      handleClickBack={handleClickBack}
      onSaveLegal={onSaveLegal}
      buttonLoading={buttonLoading}
      onDeleteLegal={onDeleteLegal}
      modalState={modalState}
      modalErrors={modalErrors}
      modalError={modalError}
      errorTitle={errorTitle}
      proposalContent={proposalContent}
    />
  ) : (
    <LegalSidebar
      isNew={isNew}
      loadedData={loadedData}
      handleClickLegal={handleClickLegal}
      hasCategories={hasCategories}
      categoryOptions={categoryOptions}
      handleClickCategory={handleClickCategory}
      essentialInputs={essentialInputs}
      notesInput={notesInput}
      onSaveLegal={onSaveLegal}
      buttonLoading={buttonLoading}
      onDeleteLegal={onDeleteLegal}
      modalError={modalError}
      errorTitle={errorTitle}
      proposalContent={proposalContent}
    />
  );
};

LegalDisplayContainer.propTypes = {
  isModal: PropTypes.bool,
  isNew: PropTypes.bool,
  hide: PropTypes.func,
  modalHeader: PropTypes.string,
  hasTabs: PropTypes.bool,
  modalTabOptions: PropTypes.array,
  activeTab: PropTypes.string,
  setActiveTab: PropTypes.func,
  loadedData: PropTypes.bool,
  legalsList: PropTypes.array,
  handleClickLegal: PropTypes.func,
  hasCategories: PropTypes.bool,
  handleClickCategory: PropTypes.func,
  selectedLegal: PropTypes.object,
  showBackButton: PropTypes.bool,
  hasGreyBackground: PropTypes.bool,
  legalInputs: PropTypes.array,
  handleClickBack: PropTypes.func,
  onSaveLegal: PropTypes.func,
  buttonLoading: PropTypes.bool,
  onDeleteLegal: PropTypes.func,
  modalState: PropTypes.string,
  modalErrors: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  modalError: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  errorTitle: PropTypes.string,
  proposalContent: PropTypes.object,
};

export default LegalDisplayContainer;
