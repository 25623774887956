import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { CSVLink } from 'react-csv';
import {
  colors,
  fonts,
  // messageColors,
  // fonts,
  inputColors,
} from '../../styles/variables';
import { TertiaryHeading } from '../../styles/library/fontStyles';
import { useSelector } from 'react-redux';
import { ErrorThemes } from '../../styles/themes';
import Error from '../Error';
import { fileExport } from '../../assets';
import {
  FlexCenterAll,
  FlexCenterStart,
} from '../../styles/library/layoutStyles';

const TargetMapAltModelOutput = () => {
  const { targetMapAltValue, targetMapAltError } = useSelector((state) => ({
    targetMapAltValue: state.targetMaps.targetMapAltValue,
    targetMapAltError: state.targetMaps.targetMapAltError,
  }));
  const [output, setOutput] = useState();
  const [modelError, setModelError] = useState();
  // const [csvList, setCsvList] = useState([]);
  const [csvList, setCsvList] = useState([]);

  useEffect(() => {
    if (targetMapAltValue) {
      setModelError();
      if (targetMapAltValue?.data?.csv) {
        const listOptions = targetMapAltValue?.data?.csv.map((file) => {
          const newFile = {};
          newFile.name = file;
          newFile.data = targetMapAltValue?.data?.content?.[file];
          return newFile;
        });
        setCsvList(listOptions);
      }
      let parsed = targetMapAltValue;
      try {
        parsed = JSON.parse(targetMapAltValue);
      } catch {}
      const jsonPretty = JSON.stringify(parsed, null, 2);
      setOutput(jsonPretty);
    }
  }, [targetMapAltValue]);

  useEffect(() => {
    if (targetMapAltError && targetMapAltError.hasOwnProperty('data')) {
      let errors = [];

      for (const [key, value] of Object.entries(targetMapAltError.data)) {
        errors.push({ field: key, message: value });
      }
      const errorMessages = errors.map((error) => {
        let fieldName = error.field.replaceAll('_', ' ');
        return `<strong>${fieldName}</strong>: ${error.message}<br/>`;
      });
      setModelError(errorMessages);
    }
  }, [targetMapAltError]);

  return (
    <AltModelContentContainer>
      <TertiaryHeading>CSV Files</TertiaryHeading>
      <CSVListContainer>
        {csvList.length ? (
          csvList.map((file, index) => {
            return (
              <CSVItemContainer key={index}>
                {file.name}
                <CSVButton>
                  <CSVLink filename={file?.name} data={file?.data}>
                    <img
                      src={fileExport}
                      alt="export"
                      data-image="export-csv"
                    />
                  </CSVLink>
                </CSVButton>
              </CSVItemContainer>
            );
          })
        ) : (
          <EmptyDisplay>No Files</EmptyDisplay>
        )}
      </CSVListContainer>
      <TertiaryHeading>API Response</TertiaryHeading>
      {modelError ? (
        <Error errorMessage={modelError} theme={ErrorThemes.inverted} />
      ) : (
        <APIResponseContainer>
          {output ? output : 'Submit data to see calculations.'}
        </APIResponseContainer>
      )}
    </AltModelContentContainer>
  );
};

const AltModelContentContainer = styled.div``;

const CSVListContainer = styled.div`
  margin-bottom: 40px;
  border-top: 1px solid ${colors.darkGrey};
`;

const CSVItemContainer = styled.div`
  ${FlexCenterStart};
  font-size: 14px;
  font-weight: ${fonts.semiBold};
  border-bottom: 1px solid ${colors.lightGrey};
  padding: 8px 0;
`;

const CSVButton = styled.button`
  background: ${colors.lightGrey};
  padding: 10px;
  border-radius: 100%;
  ${FlexCenterAll};
  width: 30px;
  height: 30px;
  margin-left: 10px;
  img {
    width: 12px;
    height: 12px;
    margin-right: -2px;
  }
  &:hover {
    transform: scale(1.01);
    opacity: 0.8;
  }
`;

const EmptyDisplay = styled.p`
  font-size: 14px;
  color: ${colors.paleGrey};
  padding: 8px 0;
`;

const APIResponseContainer = styled.pre`
  border: 1px solid ${inputColors.border};
  background: ${colors.lighterGrey};
  padding: 10px;
  border-radius: 6px;
  font-size: 12px;
`;

export default TargetMapAltModelOutput;
