/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {
  PageHeader,
  ShazamBar,
  SmallTable,
  EmptyImage,
  PageFooter,
  LoadingAnimation,
  ContextMenu,
  Legend,
} from '../../components';
import {
  getBeneficiaryReport,
  getBeneficiaryReportMember,
  getInstrumentInfo,
  toggleShowAssetModal,
  toggleShowCashFlowModal,
  toggleShowInsuranceModal,
  toggleShowLiabilityModal,
  updateHouseholdStoreValue,
} from '../../store/actions';
import {
  bars,
  cardGrid,
  layerCollapsed,
  layerOpen,
  noReports,
} from '../../assets';
import {
  assetMapColors,
  boxShadows,
  colors,
  fonts,
  maxDevice,
  messageColors,
} from '../../styles/variables';
import {
  UpdatePageTitle,
  headerContent,
  DownloadReport,
  HtmlToString,
  FormatCurrency,
  ErrorNotification,
  SendTrackingEvent,
  FilterMembersByContext,
} from '../../utils';
import {
  FlexCenterAll,
  // FlexCenterEnd,
  LoadingContainer,
} from '../../styles/library/layoutStyles';
import { ClipTextEllipsis } from '../../styles/library/fontStyles';

const HouseholdBeneficiaries = ({ isConsumer, canEdit, showPDF }) => {
  UpdatePageTitle('Beneficiaries');
  const dispatch = useDispatch();
  const params = useParams();
  const householdId = params.id;
  const {
    members,
    sideNavWidth,
    beneficiaryReport,
    beneficiaryReportError,
    loadedHouseholdFields,
    customLDD,
    isStencil,
    updatedInstrument,
    currency,
    loadedUser,
    hasBeneficiaryVisualization,
    currentBVExpandedState,
    invocationId,
  } = useSelector((state) => ({
    members: state.households.currentHousehold.allHouseholdMembers,
    sideNavWidth: state.user.sideNavWidth,
    beneficiaryReport: state.households.beneficiaryReport,
    beneficiaryReportError: state.households.beneficiaryReportError,
    loadedHouseholdFields: state.configs.loadedHouseholdFields,
    customLDD: state.user.customLDD,
    isStencil: state.households.isStencil,
    updatedInstrument: state.instruments.updatedInstrument,
    currency: state.households.householdCurrency,
    loadedUser: state.user.loadedUser,
    hasBeneficiaryVisualization: state.user.hasBeneficiaryVisualization,
    currentBVExpandedState: state.households.currentBVExpandedState,
    invocationId: state.configs.invocationId,
  }));
  const [selectedMember, setSelectedMember] = useState({ name: 'Loading' });
  const [beneficiaryType, setBeneficiaryType] = useState('beneficiary');
  const [membersList, setMembersList] = useState([]);
  const [loadedMembers, setLoadedMembers] = useState(false);
  const [loadedReport, setLoadedReport] = useState(false);
  const [loadedHeadings, setLoadedHeadings] = useState(false);
  const [beneficiaryResults, setBeneficiaryResults] = useState();
  const [beneficiaryMembers, setBeneficiaryMembers] = useState([]);
  const [incomes, setIncomes] = useState([]);
  const [incomesTotal, setIncomesTotal] = useState({});
  const [assets, setAssets] = useState([]);
  const [assetsTotal, setAssetsTotal] = useState({});
  const [liabilities, setLiabilities] = useState([]);
  const [liabilitiesTotal, setLiabilitiesTotal] = useState({});
  const [insurances, setInsurances] = useState([]);
  const [insurancesTotal, setInsurancesTotal] = useState({});
  const [otherInsurances, setOtherInsurances] = useState([]);
  const [otherInsurancesTotal, setOtherInsurancesTotal] = useState({});
  const [beneficiaryList, setBeneficiaryList] = useState([]);
  const [memberHeadings, setMemberHeadings] = useState([]);
  const [memberColumns, setMemberColumns] = useState([]);
  const [activeView, setActiveView] = useState('list');
  const [showContextMenu, setShowContextMenu] = useState(false);
  const [showErrorDisplay, setShowErrorDisplay] = useState(false);
  const [errorText, setErrorText] = useState('Unknown Error');
  // const [isExpandedAll, setIsExpandedAll] = useState(false);
  const selectedCategories = ['primary'];

  useEffect(() => {
    dispatch(getBeneficiaryReport(householdId, isConsumer));
    return () => {
      dispatch(updateHouseholdStoreValue('beneficiaryReport', null));
      dispatch(updateHouseholdStoreValue('currentBVExpandedState', null));
    };
  }, []);

  useEffect(() => {
    const savedView = localStorage.getItem('beneficiaryView');
    if (savedView) {
      setActiveView(savedView);
    } else {
      localStorage.setItem('beneficiaryView', 'list');
      setActiveView('list');
    }
  }, []);

  useEffect(() => {
    if (loadedUser && !hasBeneficiaryVisualization) {
      localStorage.removeItem('beneficiaryView');
      setActiveView('list');
    }
  }, [loadedUser]);

  useEffect(() => {
    if (beneficiaryReportError) {
      let errorText = 'Unknown Error';
      if (beneficiaryReportError?.data) {
        errorText = beneficiaryReportError?.data;
      } else if (beneficiaryReportError?.statusText) {
        errorText = beneficiaryReportError?.statusText;
      }
      setErrorText(errorText);
      setBeneficiaryResults(false);
      setShowErrorDisplay(true);
      setLoadedReport(true);
      dispatch(updateHouseholdStoreValue('beneficiaryReportError', null));
    }
  }, [beneficiaryReportError]);

  useEffect(() => {
    if (updatedInstrument) {
      dispatch(
        getBeneficiaryReportMember(
          householdId,
          selectedMember?.id,
          beneficiaryType,
          isConsumer
        )
      );
      setLoadedReport(false);
      setLoadedHeadings(false);
    }
  }, [updatedInstrument]);

  useEffect(() => {
    if (beneficiaryReport) {
      if (beneficiaryReport.hasOwnProperty('content')) {
        setBeneficiaryResults(beneficiaryReport.content);
        setShowErrorDisplay(false);
        setErrorText('Unknown Error');
      }
      if (beneficiaryReport?.error) {
        setBeneficiaryResults(false);
        setLoadedReport(true);
        setShowErrorDisplay(true);
        setErrorText(
          beneficiaryReport?.error ? beneficiaryReport?.error : 'Unknown Error'
        );
      }
    }
  }, [beneficiaryReport]);

  //IF ALREADY SELECTED MEMBER, KEEP THEM SELECTED, ELSE SELECT A PRIMARY MEMBER
  useEffect(() => {
    if (members) {
      let updatedMembers = members.map((member) => {
        member.name = HtmlToString(member.name);
        return member;
      });
      let selectedMember = membersList.find((member) => {
        return member.is_selected;
      });

      if (!selectedMember) {
        const primaryMembers = updatedMembers.filter((member) =>
          selectedCategories.includes(member.category)
        );
        if (primaryMembers.length === 0) {
          selectedMember = updatedMembers[0];
        } else {
          selectedMember = primaryMembers[0];
        }
      }
      updatedMembers = updatedMembers.map((member) => {
        member.is_selected = member.id === selectedMember.id;
        return member;
      });
      setMembersList(updatedMembers);
      setLoadedMembers(true);
    }
  }, [members]);

  useEffect(() => {
    if (membersList) {
      const selectedMember = membersList.find((member) => {
        return member.is_selected;
      });
      setSelectedMember(selectedMember);
    }
  }, [membersList]);

  const generateMemberNames = (instruments) => {
    return instruments.map((instrument) => {
      const transformedMembers = instrument?.owners.map((mem) => {
        const matchingMember = membersList.find((m) => m.id === mem.id);
        if (matchingMember) {
          mem.name = matchingMember.name;
        } else {
          mem.name = 'Unknown';
        }
        return mem;
      });
      instrument.members = transformedMembers;
      return instrument;
    });
  };

  useEffect(() => {
    if (beneficiaryResults && loadedHouseholdFields && loadedMembers) {
      setBeneficiaryMembers(beneficiaryResults?.beneficiary);
      const transformedIncomes = generateMemberNames(
        beneficiaryResults?.section?.income?.instrument
      );
      setIncomes(transformedIncomes);
      setIncomesTotal(beneficiaryResults?.section?.income?.total);
      const transformedAssets = generateMemberNames(
        beneficiaryResults?.section?.asset?.instrument
      );
      setAssets(transformedAssets);
      setAssetsTotal(beneficiaryResults?.section?.asset?.total);
      const transformedLiabilities = generateMemberNames(
        beneficiaryResults?.section?.liability?.instrument
      );
      setLiabilities(transformedLiabilities);
      setLiabilitiesTotal(beneficiaryResults?.section?.liability?.total);
      const transformedInsurance = generateMemberNames(
        beneficiaryResults?.section?.life?.instrument
      );
      setInsurances(transformedInsurance);
      setInsurancesTotal(beneficiaryResults?.section?.life?.total);
      const transformedOtherInsurance = generateMemberNames(
        beneficiaryResults?.section?.other?.instrument
      );
      setOtherInsurances(transformedOtherInsurance);
      setOtherInsurancesTotal(beneficiaryResults?.section?.other?.total);
      setLoadedReport(true);
    }
  }, [beneficiaryResults, loadedHouseholdFields, loadedMembers]);

  useEffect(() => {
    if (loadedReport && membersList.length !== 0) {
      const sortedMembers = beneficiaryReport?.content?.members?.order.filter(
        (v) => beneficiaryMembers.includes(v)
      );
      let matched = [];
      if (sortedMembers) {
        matched = sortedMembers.map((member) => {
          return { ...membersList.find((mem) => mem.id === member) };
        });
      }
      setBeneficiaryList(matched);

      // create current beneficiary visualization expanded state grid
      const memberCards = generateMemberColumns(matched);

      let bvExpandedStateGrid = currentBVExpandedState;

      // set initial state
      if (
        typeof currentBVExpandedState === 'undefined' ||
        currentBVExpandedState === null ||
        bvExpandedStateGrid.length !== memberCards.length
      ) {
        bvExpandedStateGrid = memberCards.map((mem) => {
          const column = mem.instruments.map((inst) => inst.isExpanded);
          return column;
        });
      }
      dispatch(
        updateHouseholdStoreValue('currentBVExpandedState', bvExpandedStateGrid)
      );

      // update the grid
      const updated = [...memberCards].map((mem, index) => {
        const updated = mem.instruments.map((cat, ind) => {
          const copied = { ...cat };
          copied.isExpanded = bvExpandedStateGrid[index][ind];
          return copied;
        });
        mem.instruments = [...updated];
        return mem;
      });

      setMemberColumns(updated);

      const memberHeadings = generateMemberHeadings(matched);
      setMemberHeadings(memberHeadings);
      setLoadedHeadings(true);
    }
  }, [loadedReport, membersList, beneficiaryResults]);

  const generateMemberHeadings = (matched) => {
    return matched.map((member) => {
      let headingText = member.name;
      if (member.member_type === 'member') {
        headingText = `${member.name} (${member.age})`;
      }
      return {
        text: headingText,
        name: member.name,
        value: 'member',
        id: member.id,
        display: true,
        align: 'right',
        width: `${50 / matched.length}%`,
        showTotal: true,
      };
    });
  };

  const generateMemberDisplay = (type) => {
    switch (type) {
      case 'life':
        return 'Policy Owner';
      case 'income':
        return 'Relevant Member';
      default:
        return 'Interested Member';
    }
  };

  const generateSourceDisplay = (type) => {
    switch (type) {
      case 'income':
        return 'Income Source';
      case 'asset':
        return 'Location';
      case 'liability':
        return 'Lender';
      case 'life':
        return 'Insurance Provider';
      default:
        return 'Carrier';
    }
  };

  const generateColumnHeadings = (type) => {
    const localMemberHeadings = loadedReport ? memberHeadings : ['1'];
    return [
      { text: 'Category', display: true, width: '10%', showTotal: false },
      { text: 'Name', display: true, width: '10%', showTotal: false },
      {
        text: generateSourceDisplay(type),
        display: true,
        width: '10%',
        showTotal: false,
      },
      {
        text: generateMemberDisplay(type),
        value: 'members',
        display: true,
        width: '10%',
        showTotal: false,
      },
      {
        text: 'Unassigned',
        value: 'unassigned',
        display: true,
        align: 'right',
        width: '10%',
        showTotal: true,
      },
      ...localMemberHeadings,
      {
        text: 'Total',
        value: 'total',
        display: true,
        align: 'right',
        width: '100px',
        minWidth: '100px',
        showTotal: true,
      },
    ];
  };

  const selectMember = (clickedMember) => {
    dispatch(
      getBeneficiaryReportMember(
        householdId,
        clickedMember.id,
        beneficiaryType,
        isConsumer
      )
    );
    setLoadedReport(false);
    setLoadedHeadings(false);
    setMembersList(
      membersList.map((member) => {
        if (member.id === clickedMember.id) {
          member.is_selected = true;
        } else {
          member.is_selected = false;
        }
        return member;
      })
    );
  };

  const selectBeneficiaryType = (type) => {
    setBeneficiaryType(type);
    dispatch(
      getBeneficiaryReportMember(
        householdId,
        selectedMember.id,
        type,
        isConsumer
      )
    );
    setLoadedReport(false);
    setLoadedHeadings(false);
  };

  const cashFlowEditFunction = (cashFlow) => {
    dispatch(getInstrumentInfo(householdId, cashFlow.hash, isConsumer));
    dispatch(toggleShowCashFlowModal(true, false, 'details'));
  };

  const assetEditFunction = (asset) => {
    dispatch(getInstrumentInfo(householdId, asset.hash, isConsumer));
    dispatch(toggleShowAssetModal(true, false, 'details'));
  };

  const liabilityEditFunction = (liability) => {
    dispatch(getInstrumentInfo(householdId, liability.hash, isConsumer));
    dispatch(toggleShowLiabilityModal(true, false, 'details'));
  };

  const insuranceEditFunction = (insurance) => {
    dispatch(getInstrumentInfo(householdId, insurance.hash, isConsumer));
    dispatch(toggleShowInsuranceModal(true, false, 'details'));
  };

  const generateTotal = (member, type) => {
    const region = beneficiaryResults?.section?.[type]?.total;
    if (region) {
      if (member === 'unassigned') {
        return region?.unassigned;
      } else if (region?.member?.[member]) {
        return region?.member?.[member];
      } else {
        return 0;
      }
    }
    return 0;
  };

  const instrumentCategories = [
    {
      name: 'Unearned Income',
      value: 'income',
      type: 'incomes_ben',
      items: incomes,
      total: incomesTotal,
      color: assetMapColors.cashFlow,
      borderRadius: '8px',
      editFunction: cashFlowEditFunction,
    },
    {
      name: 'Assets',
      value: 'asset',
      type: 'assets_ben',
      items: assets,
      total: assetsTotal,
      color: assetMapColors.nonRetirement,
      editFunction: assetEditFunction,
    },
    {
      name: 'Liabilities',
      value: 'liability',
      type: 'liabilities_ben',
      items: liabilities,
      total: liabilitiesTotal,
      color: assetMapColors.liability,
      isNegative: true,
      editFunction: liabilityEditFunction,
    },
    {
      name: 'Life Insurance',
      value: 'life',
      type: 'life_insurance_ben',
      items: insurances,
      total: insurancesTotal,
      color: assetMapColors.insurance,
      editFunction: insuranceEditFunction,
    },
    {
      name: 'Other Insurance',
      value: 'other',
      type: 'other_insurance_ben',
      items: otherInsurances,
      total: otherInsurancesTotal,
      color: assetMapColors.insurance,
      editFunction: insuranceEditFunction,
    },
  ];

  const unassigned = {
    id: 'unassigned',
    uuid: 'unassigned',
    name: 'Unassigned',
  };

  const isEmpty = (obj) => {
    return Object.keys(obj).length === 0;
  };

  const generateLinkedItems = (type, memberId) => {
    const region = beneficiaryResults?.section?.[type]?.instrument;
    if (region) {
      const filtered = region.reduce((acc, curr) => {
        const copied = { ...curr };
        if (curr.beneficiary[memberId]) {
          return [...acc, copied];
        } else if (memberId === 'unassigned') {
          //Only show unassigned if the value is not 0 or value is 0 and has no beneficiaries
          if (
            curr?.unassigned !== 0 ||
            (curr?.unassigned === 0 && isEmpty(curr?.beneficiary))
          ) {
            return [...acc, copied];
          }
        }
        return acc;
      }, []);
      return [...filtered];
    }
    return [];
  };

  const generateMemberColumns = (members) => {
    let list = [unassigned, ...members].map((member) => {
      const categories = instrumentCategories.map((category) => {
        const linked = generateLinkedItems(category.value, member.id);
        const copied = { ...category };
        copied.linked = [...linked];
        copied.canExpand = linked.length !== 0;
        copied.isExpanded = false;
        return copied;
      });
      return {
        name: member.name,
        id: member.id,
        uuid: member.uuid,
        instruments: categories,
      };
    });

    return list;
  };

  const generateItemTotal = (member, item) => {
    if (member === 'unassigned') {
      return item?.unassigned;
    } else if (item?.beneficiary?.[member]) {
      return item?.beneficiary?.[member]?.amount;
    } else {
      return 0;
    }
  };

  const onClickExpand = (member, category) => {
    let bvExpandedStateGrid = JSON.parse(
      JSON.stringify(currentBVExpandedState)
    );
    const updated = [...memberColumns].map((mem, index) => {
      if (mem.id === member.id) {
        const updated = mem.instruments.map((cat, ind) => {
          const copied = { ...cat };
          if (cat.value === category.value) {
            copied.isExpanded = !copied.isExpanded;
            bvExpandedStateGrid[index][ind] = copied.isExpanded;
          }
          return copied;
        });
        mem.instruments = [...updated];
      }
      return mem;
    });
    dispatch(
      updateHouseholdStoreValue('currentBVExpandedState', bvExpandedStateGrid)
    );
    setMemberColumns(updated);
    sendBeneficiaryTrackingEvent('beneficiaries_toggle_category', {
      member: member.id,
      category: category.value,
      isExpanded: !category.isExpanded,
    });
  };

  const onClickInstrument = (instrument) => {
    if (instrument.type === 'income') {
      return cashFlowEditFunction(instrument);
    }
    if (instrument.type === 'asset') {
      return assetEditFunction(instrument);
    }
    if (instrument.type === 'liability') {
      return liabilityEditFunction(instrument);
    }
    if (instrument.type === 'insurance') {
      return insuranceEditFunction(instrument);
    }
  };

  const toggleExpand = (isExpanded = true) => {
    let bvExpandedStateGrid = JSON.parse(
      JSON.stringify(currentBVExpandedState)
    );
    const updated = [...memberColumns].map((mem, index) => {
      const updatedIns = mem.instruments.map((cat, ind) => {
        const copied = { ...cat };
        copied.isExpanded = isExpanded;
        bvExpandedStateGrid[index][ind] = isExpanded;
        return copied;
      });
      mem.instruments = [...updatedIns];
      return mem;
    });
    dispatch(
      updateHouseholdStoreValue('currentBVExpandedState', bvExpandedStateGrid)
    );
    setMemberColumns(updated);
    sendBeneficiaryTrackingEvent(
      isExpanded ? 'beneficiaries_expand_all' : 'beneficiaries_collapse_all',
      null
    );
  };

  const closeMenu = (fun) => {
    setShowContextMenu(false);
    return fun;
  };

  const contextMenu = {
    heading: 'Actions',
    items: [
      {
        label: 'Expand All',
        image: layerOpen,
        name: 'expand-all',
        onClick: () => closeMenu(toggleExpand(true)),
      },
      {
        label: 'Collapse All',
        image: layerCollapsed,
        name: 'collapse-all',
        onClick: () => closeMenu(toggleExpand(false)),
      },
    ],
  };

  const sendBeneficiaryTrackingEvent = (verb, data) => {
    const isList = activeView === 'list';
    const source = isList ? null : 'household_beneficiaries_visualization';
    return SendTrackingEvent(invocationId, 'click', verb, source, data);
  };

  const onPDFClick = () => {
    if (activeView === 'card') {
      sendBeneficiaryTrackingEvent('beneficiary_visualization_pdf');
      return ErrorNotification('PDF only available for list view');
    } else {
      return DownloadReport({
        value: 'beneficiary',
        childValue: 'beneficiary_members',
        hasOptions: true,
        children: [{ selected: true, id: selectedMember.id }],
        beneficiaryType: beneficiaryType,
      });
    }
  };

  const isJoint = (item, member) => {
    const memberMatch = item.beneficiary[member.id];
    if (memberMatch) {
      if (memberMatch.type === 'joint') {
        return true;
      }
    }
    return false;
  };

  const viewOptions = [
    {
      value: 'list',
      label: 'List View',
      image: bars,
      dataTag: 'list-view',
    },
    {
      value: 'card',
      label: 'Visualization',
      image: cardGrid,
      dataTag: 'card-view',
    },
  ];

  const beneficiaryOptions = [
    { value: 'beneficiary', label: 'Primary Beneficiaries' },
    { value: 'contingent_beneficiary', label: 'Contingent Beneficiaries' },
  ];

  return (
    <>
      {activeView !== 'list' && (
        <ContextMenu
          menu={contextMenu}
          sideNavWidth={sideNavWidth}
          containerId={'beneficiary-container'}
          showMenu={showContextMenu}
          setShowMenu={setShowContextMenu}
        />
      )}
      <PageContainer id="beneficiary-container">
        <PageHeader
          content={{
            ...headerContent.beneficiaries,
            showButton: !isStencil && showPDF,
          }}
          buttonFunction={onPDFClick}
          showViews={hasBeneficiaryVisualization}
          views={viewOptions}
          activeView={activeView}
          toggleView={(value) => {
            localStorage.setItem('beneficiaryView', value);
            setActiveView(value);
            sendBeneficiaryTrackingEvent('beneficiaries_toggle_view', {
              view: value,
            });
          }}
        />
        {isStencil ? (
          <>
            <EmptyImage
              imageSrc={noReports}
              imageName="No Reports"
              text="Reports are not available for Stencil Households"
            />
          </>
        ) : (
          <PageContent>
            <ShazamBar
              items={FilterMembersByContext(membersList, 'shazaam')}
              onClick={selectMember}
              loaded={loadedMembers}
              isNew={activeView === 'card'}
            />
            <BeneficiarySelectContainer>
              <BeneficiaryRadioOptions>
                {beneficiaryOptions.map((option) => {
                  return (
                    <BeneficiaryRadioOption
                      key={option.value}
                      htmlFor={option.value}
                      $isSelected={beneficiaryType === option.value}
                    >
                      <input
                        type="radio"
                        id={option.value}
                        name="beneficiary"
                        value={option.value}
                        checked={beneficiaryType === option.value}
                        onChange={() => selectBeneficiaryType(option.value)}
                      />
                      {option.label}
                    </BeneficiaryRadioOption>
                  );
                })}
              </BeneficiaryRadioOptions>
            </BeneficiarySelectContainer>
            {showErrorDisplay ? (
              <EmptyImage
                imageSrc={noReports}
                imageName="No Reports"
                text={errorText}
              />
            ) : activeView === 'list' ? (
              <ContentContainer>
                {instrumentCategories.map((table) => {
                  return (
                    <SmallTable
                      key={table.type}
                      tableHeading={table.name}
                      columnHeadings={generateColumnHeadings(table.value)}
                      type={table.type}
                      items={table.items}
                      loaded={loadedReport}
                      memberList={beneficiaryList}
                      totalsObj={loadedReport ? table.total : {}}
                      marginBottom={'40px'}
                      editFunction={table.editFunction}
                      isHousehold={false}
                    />
                  );
                })}
              </ContentContainer>
            ) : (
              <>
                <ContentContainer>
                  {!loadedHeadings ? (
                    <LoadingContainer $minHeight="458px">
                      <LoadingAnimation />
                    </LoadingContainer>
                  ) : (
                    <>
                      <ColumnsContainer>
                        {memberColumns.map((mem, index) => {
                          return (
                            <ColumnContainer
                              key={mem.id}
                              data-name={`${mem.id}-column`}
                              $unassigned={
                                mem.id === 'unassigned' && index === 0
                              }
                            >
                              <MemberName title={mem.name}>
                                {mem.name}
                              </MemberName>

                              {mem.instruments.map((category) => {
                                return (
                                  <GroupedContainer key={category.value}>
                                    <CategoryContainer
                                      onClick={() =>
                                        category.canExpand
                                          ? onClickExpand(mem, category)
                                          : null
                                      }
                                      data-name={`${mem.id}-${category.value}`}
                                      data-is-expanded={category.isExpanded}
                                      $canExpand={category.canExpand}
                                      $color={category.color}
                                      $borderRadius={category.borderRadius}
                                      $unassigned={
                                        mem.id === 'unassigned' && index === 0
                                      }
                                    >
                                      <InstrumentName $isCategory={true}>
                                        {category.name}
                                      </InstrumentName>
                                      <InstrumentValue
                                        $isNegative={category.isNegative}
                                        $isCategory={true}
                                      >
                                        {category.isNegative
                                          ? `(${FormatCurrency(
                                              generateTotal(
                                                mem.id,
                                                category.value
                                              ),
                                              currency
                                            )})`
                                          : FormatCurrency(
                                              generateTotal(
                                                mem.id,
                                                category.value
                                              ),
                                              currency
                                            )}
                                      </InstrumentValue>
                                    </CategoryContainer>
                                    {category.isExpanded ? (
                                      category.linked.map((item) => {
                                        return (
                                          <ChildContainer
                                            key={item.hash}
                                            data-name={`${mem.id}-${category.value}-${item.hash}`}
                                            onClick={() =>
                                              onClickInstrument(item)
                                            }
                                            $color={category.color}
                                            $borderRadius={
                                              category.borderRadius
                                            }
                                            $category={item.category}
                                            $type={category.value}
                                          >
                                            <InstrumentName>
                                              {item.name}
                                            </InstrumentName>
                                            <InstrumentSource>
                                              {item.source}
                                              {!item.is_managed && '*'}
                                            </InstrumentSource>
                                            <InstrumentValue
                                              $isNegative={category.isNegative}
                                              $isJoint={isJoint(item, mem)}
                                            >
                                              {category.isNegative
                                                ? `(${FormatCurrency(
                                                    generateItemTotal(
                                                      mem.id,
                                                      item
                                                    ),
                                                    currency
                                                  )})`
                                                : FormatCurrency(
                                                    generateItemTotal(
                                                      mem.id,
                                                      item
                                                    ),
                                                    currency
                                                  )}
                                              <LightText>
                                                {isJoint(item, mem) && '(JT)'}
                                              </LightText>
                                            </InstrumentValue>
                                          </ChildContainer>
                                        );
                                      })
                                    ) : category.canExpand ? (
                                      <CategoryBackground
                                        $color={category.color}
                                        $borderRadius={category.borderRadius}
                                        $unassigned={
                                          mem.id === 'unassigned' && index === 0
                                        }
                                      />
                                    ) : null}
                                  </GroupedContainer>
                                );
                              })}
                            </ColumnContainer>
                          );
                        })}
                      </ColumnsContainer>
                    </>
                  )}
                </ContentContainer>
                <Legend trayWidth={'0px'} context="beneficiaries" />
              </>
            )}
            {customLDD?.disclaimer && (
              <PageFooter footerValue={customLDD?.disclaimer} />
            )}
          </PageContent>
        )}
      </PageContainer>
    </>
  );
};

const BeneficiarySelectContainer = styled.div`
  margin: 25px 0 15px 0;
  flex-wrap: wrap;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;
`;

const BeneficiaryRadioOptions = styled.div`
  ${FlexCenterAll};
  flex-wrap: wrap;
`;

const BeneficiaryRadioOption = styled.label`
  margin: 2px 10px;
  font-size: 14px;
  cursor: pointer;
  flex: 0 0 auto;
  font-weight: ${(props) =>
    props.$isSelected ? fonts.semiBold : fonts.regular};
  color: ${(props) => (props.$isSelected ? colors.darkGrey : colors.paleGrey)};
  ${FlexCenterAll};
  input {
    min-width: auto;
    margin: 0 8px 0 0;
    ${'' /* accent-color: ${colors.blue}; */}
  }
  &:hover {
    opacity: 0.9;
    transform: scale(1.01);
  }
`;

const MemberName = styled.p`
  font-weight: ${fonts.semiBold};
  margin-bottom: -20px;
  font-size: 14px;
  max-width: 145px;
  ${ClipTextEllipsis};
`;

const InstrumentName = styled.p`
  max-width: 135px;
  font-weight: ${fonts.semiBold};
  font-size: ${(props) => (props.$isCategory ? '14px' : '12px')};
  ${ClipTextEllipsis};
`;

const InstrumentSource = styled.p`
  max-width: 135px;
  font-weight: ${fonts.light};
  font-size: 11px;
  ${ClipTextEllipsis};
`;

const InstrumentValue = styled.p`
  max-width: 135px;
  font-weight: ${(props) => (props.$isJoint ? fonts.regular : fonts.semiBold)};
  font-size: ${(props) => (props.$isCategory ? '16px' : '14px')};
  color: ${(props) =>
    props.$isJoint
      ? colors.paleGrey
      : props.$isNegative
        ? messageColors.error
        : colors.darkGrey};
`;

const GroupedContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  ${FlexCenterAll}
  flex-direction: column;
`;

const CategoryContainer = styled.div`
  height: 55.5px;
  width: 100%;
  ${FlexCenterAll}
  flex-direction: column;
  background: ${colors.lighterGrey};
  border: ${(props) =>
    props.$unassigned
      ? `2px solid ${props.$color}`
      : `1.5px solid ${props.$color}`};
  border-radius: ${(props) =>
    props.$borderRadius ? props.$borderRadius : '2px'};
  position: relative;
  z-index: 1;
  cursor: ${(props) => (props.$canExpand ? 'pointer' : null)};
  text-align: center;
  padding: 6px 4px;
  &:hover {
    box-shadow: ${(props) => (props.$canExpand ? boxShadows.boxShadow : null)};
    transform: ${(props) => (props.$canExpand ? 'scale(1.01)' : null)};
  }
`;

const CategoryBackground = styled.div`
  background: white;
  border: ${(props) =>
    props.$unassigned
      ? `2px solid ${props.$color}`
      : `1.5px solid ${props.$color}`};
  border-radius: ${(props) =>
    props.$borderRadius ? props.$borderRadius : '2px'};
  z-index: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  left: -1.5px;
  top: 3px;
`;

const ChildContainer = styled(CategoryContainer)`
  position: relative;
  background: ${colors.white};
  margin-top: -1px;
  width: calc(100% - 10px);
  cursor: pointer;
  &:hover {
    box-shadow: ${boxShadows.boxShadow};
    transform: scale(1.01);
  }
  font-size: ${(props) => (props.$isCategory ? '14px' : '12px')};

  ${(props) => {
    if (props.$type === 'asset') {
      return `
        border: ${
          props.$category === 'retirement'
            ? `1.5px solid ${assetMapColors.retirement}`
            : props.$category === 'residence'
              ? `1.5px solid ${assetMapColors.realEstate}`
              : `1.5px solid ${assetMapColors.nonRetirement}`
        };
      `;
    }
    return '';
  }}
`;

const LightText = styled.span`
  font-weight: ${fonts.light};
  margin-left: 2px;
  font-size: 12px;
`;

const ColumnsContainer = styled.div`
  margin-top: 40px;
  gap: 45px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: nowrap;
  overflow: auto;
  min-height: 200px;
  padding: 0 20px 20px 20px;
`;

const ColumnContainer = styled.div`
  width: 100%;
  min-width: 145px;
  max-width: 145px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  gap: 30px;
  ${(props) =>
    props.$unassigned &&
    `
    position: relative;
    border-right: 1px solid ${colors.lightGrey};
    padding-right: 45px;
    &::before {
      content: '';
      position: absolute;
      top: 0;
      right: -1px;
      content: '';
      background-color: white;
      height: 28px;
      width: 1px;
      z-index: 1;
    }
  `}
`;

const PageContainer = styled.div`
  flex: 1 1 auto;
  overflow-y: auto;
`;

const PageContent = styled.div`
  @media ${maxDevice.mobileL} {
    margin: 0px 20px;
  }
  margin: 0px 40px;
`;

const ContentContainer = styled.div`
  margin: 15px 0 100px 0;
`;

HouseholdBeneficiaries.propTypes = {
  isConsumer: PropTypes.bool,
  canEdit: PropTypes.bool,
  showPDF: PropTypes.bool,
};

export default HouseholdBeneficiaries;
