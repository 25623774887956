import * as variables from './variables';
import { errorCircle, errorCircleRed, checkCircle } from '../assets';

export const selectThemes = {
  default: {
    menuList: (provided) => ({
      ...provided,
      padding: '0',
      fontSize: '13px',
      maxHeight: '200px',
    }),
    option: (provided, state) =>
      state?.data?.class === 'disabled'
        ? {
            ...provided,
            cursor: 'not-allowed',
            color: variables.colors.paleGrey,
            background: variables.colors.lightGrey,
            fontStyle: 'italic',
          }
        : { ...provided },
    indicatorsContainer: (provided) => ({
      ...provided,
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
    }),
    group: (provided) => ({
      ...provided,
    }),
    groupHeader: (provided) => ({
      ...provided,
    }),
    input: (provided) => ({
      ...provided,
    }),
    container: (provided) => ({
      ...provided,
      fontSize: '13px',
      textAlign: 'left',
    }),
    loadingIndicator: (provided) => ({
      ...provided,
      fontSize: '4px !important',
      marginRight: '0',
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    control: (provided, { isDisabled, isFocused }) => ({
      ...provided,
      borderRadius: '6px',
      border: isDisabled
        ? `1px solid ${variables.inputColors.border}`
        : isFocused
          ? `1px solid ${variables.inputColors.focusBorder}`
          : `1px solid ${variables.inputColors.border}`,
      background: isDisabled
        ? variables.colors.lightGrey
        : isFocused
          ? variables.colors.lighterGrey
          : variables.colors.white,
      boxShadow: `none`,
      '&:hover': {
        border: isDisabled
          ? `1px solid ${variables.inputColors.border}`
          : `1px solid ${variables.inputColors.focusBorder}`,
        background: isDisabled
          ? variables.colors.lightGrey
          : variables.colors.lighterGrey,
        boxShadow: `none`,
      },
    }),
    multiValue: (provided) => ({
      ...provided,
    }),
    multiValueLabel: (provided) => ({
      ...provided,
    }),
    noOptionsMessage: (provided) => ({
      ...provided,
    }),
    valueContainer: (provided) => ({
      ...provided,
    }),
    multiValueRemove: (provided) => ({
      ...provided,
    }),
    singleValue: (provided, state) => ({
      ...provided,
      overflow:
        state?.data?.value === 'date' || state?.data?.value === 'age'
          ? 'visible'
          : 'hidden',
    }),
    placeholder: (provided) => ({
      ...provided,
      color: variables.colors.paleGrey,
      fontSize: '12px',
      padding: '0 3px',
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: '12',
    }),
    menuPortal: (provided) => ({
      ...provided,
    }),
  },
  defaultError: {
    menuList: (provided) => ({
      ...provided,
      padding: '0',
      fontSize: '13px',
    }),
    option: (provided) => ({
      ...provided,
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
    }),
    group: (provided) => ({
      ...provided,
    }),
    groupHeader: (provided) => ({
      ...provided,
    }),
    input: (provided) => ({
      ...provided,
    }),
    container: (provided) => ({
      ...provided,
      fontSize: '13px',
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    control: (provided, { isFocused }) => ({
      ...provided,
      borderRadius: '6px',
      border: isFocused
        ? `1px solid ${variables.messageColors.error}`
        : `1px solid ${variables.messageColors.error}`,
      boxShadow: `none`,
      '&:hover': {
        border: `1px solid ${variables.messageColors.error}`,
        boxShadow: `none`,
      },
    }),
    multiValue: (provided) => ({
      ...provided,
    }),
    multiValueLabel: (provided) => ({
      ...provided,
    }),
    noOptionsMessage: (provided) => ({
      ...provided,
    }),
    valueContainer: (provided) => ({
      ...provided,
    }),
    multiValueRemove: (provided) => ({
      ...provided,
    }),
    singleValue: (provided) => ({
      ...provided,
    }),
    placeholder: (provided) => ({
      ...provided,
      color: variables.colors.paleGrey,
      fontSize: '11px',
      padding: '0 3px',
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: '12',
    }),
    menuPortal: (provided) => ({
      ...provided,
    }),
  },
  inputSearch: {
    menuList: (provided) => ({
      ...provided,
      padding: '0',
      fontSize: '13px',
    }),
    option: (provided) => ({
      ...provided,
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
    }),
    group: (provided) => ({
      ...provided,
    }),
    groupHeader: (provided) => ({
      ...provided,
    }),
    input: (provided) => ({
      ...provided,
    }),
    container: (provided) => ({
      ...provided,
      fontSize: '13px',
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    control: (provided, { isFocused }) => ({
      ...provided,
      borderRadius: '6px',
      border: isFocused
        ? `1px solid ${variables.inputColors.focusBorder}`
        : `1px solid ${variables.inputColors.border}`,
      background: isFocused ? variables.colors.lighterGrey : `none`,
      boxShadow: `none`,
      '&:hover': {
        border: `1px solid ${variables.inputColors.focusBorder}`,
        background: variables.colors.lighterGrey,
        boxShadow: `none`,
      },
    }),
    multiValue: (provided) => ({
      ...provided,
    }),
    multiValueLabel: (provided) => ({
      ...provided,
    }),
    noOptionsMessage: (provided) => ({
      ...provided,
    }),
    valueContainer: (provided) => ({
      ...provided,
    }),
    multiValueRemove: (provided) => ({
      ...provided,
    }),
    singleValue: (provided) => ({
      ...provided,
    }),
    placeholder: (provided) => ({
      ...provided,
      color: variables.colors.paleGrey,
      fontSize: '13px',
      padding: '0 3px',
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: '12',
    }),
    menuPortal: (provided) => ({
      ...provided,
    }),
  },
  permissions: {
    menuList: (provided) => ({
      ...provided,
      padding: '0',
    }),
    container: (provided) => ({
      ...provided,
      fontSize: '12px',
      display: 'flex',
      justifyContent: 'flex-end',
      textAlign: 'right',
    }),
    option: (provided) => ({
      ...provided,
      fontSize: '12px',
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      height: '20px',
      minHeight: '20px',
      width: '20px',
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      padding: '0px',
      '&:hover': {
        padding: '0px',
      },
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: '2px 4px',
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    placeholder: (provided) => ({
      ...provided,
      fontSize: '12px',
      color: variables.colors.darkGrey,
    }),
    control: (provided, { isFocused, isDisabled }) => ({
      ...provided,
      height: '20px',
      minHeight: '20px',
      fontSize: '12px',
      width: '106px',
      border: isFocused ? `none` : `none`,
      background: isDisabled ? 'none' : isFocused ? 'none' : `none`,
      color: isDisabled
        ? variables.colors.paleGrey
        : isFocused
          ? variables.colors.darkGrey
          : variables.colors.darkGrey,
      boxShadow: `none`,
      '&:hover': {
        border: 'none',
        boxShadow: `none`,
      },
    }),
  },
  wideMenu: {
    menuList: (provided) => ({
      ...provided,
      padding: '0',
    }),
    container: (provided) => ({
      ...provided,
      fontSize: '13px',
      minWidth: '270px',
      width: '270px',
    }),
    option: (provided) => ({
      ...provided,
      fontSize: '12px',
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      height: '28px',
      minHeight: '28px',
      width: '33px',
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    placeholder: (provided) => ({
      ...provided,
      fontSize: '13px',
      color: variables.colors.darkGrey,
    }),
    control: (provided, { isFocused }) => ({
      ...provided,
      width: '142px',
      height: '30px',
      minHeight: '30px',
      fontSize: '12px',
      boxShadow: `none`,
      border: isFocused
        ? `1px solid ${variables.inputColors.focusBorder}`
        : `1px solid ${variables.inputColors.border}`,
      background: isFocused ? variables.colors.lighterGrey : `none`,
      '&:hover': {
        border: `1px solid ${variables.inputColors.focusBorder}`,
        background: variables.colors.lighterGrey,
        boxShadow: `none`,
      },
    }),
  },
  layoutMenu: {
    menuList: (provided) => ({
      ...provided,
      padding: '0',
    }),
    container: (provided) => ({
      ...provided,
      fontSize: '13px',
      minWidth: '220px',
      width: '220px',
    }),
    option: (provided) => ({
      ...provided,
      fontSize: '12px',
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      height: '28px',
      minHeight: '28px',
      width: '33px',
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    placeholder: (provided) => ({
      ...provided,
      fontSize: '13px',
      color: variables.colors.darkGrey,
    }),
    control: (provided, { isFocused }) => ({
      ...provided,
      width: '170px',
      height: '30px',
      minHeight: '30px',
      fontSize: '12px',
      boxShadow: `none`,
      border: isFocused
        ? `1px solid ${variables.inputColors.focusBorder}`
        : `1px solid ${variables.inputColors.border}`,
      background: isFocused ? variables.colors.lighterGrey : `none`,
      '&:hover': {
        border: `1px solid ${variables.inputColors.focusBorder}`,
        background: variables.colors.lighterGrey,
        boxShadow: `none`,
      },
    }),
  },
  householdIndex: {
    menuList: (provided) => ({
      ...provided,
      padding: '0',
    }),
    option: (provided) => ({
      ...provided,
      fontSize: '12px',
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      height: '28px',
      minHeight: '28px',
      width: '33px',
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    control: (provided, { isFocused }) => ({
      ...provided,
      height: '30px',
      minHeight: '30px',
      fontSize: '12px',
      boxShadow: `none`,
      border: isFocused
        ? `1px solid ${variables.inputColors.focusBorder}`
        : `1px solid ${variables.inputColors.border}`,
      background: isFocused ? variables.colors.lighterGrey : `none`,
      '&:hover': {
        border: `1px solid ${variables.inputColors.focusBorder}`,
        background: variables.colors.lighterGrey,
        boxShadow: `none`,
      },
    }),
  },
  smaller: {
    menuList: (provided) => ({
      ...provided,
      padding: '0',
      fontSize: '13px',
    }),
    option: (provided) => ({
      ...provided,
    }),
    container: (provided) => ({
      ...provided,
      fontSize: '12px',
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    control: (provided, { isDisabled, isFocused }) => ({
      display: 'flex',
      height: '27px',
      borderRadius: '5px',
      border: isDisabled
        ? `1px solid ${variables.inputColors.border}`
        : isFocused
          ? `1px solid ${variables.inputColors.focusBorder}`
          : `1px solid ${variables.inputColors.border}`,
      background: isDisabled
        ? variables.colors.lightGrey
        : isFocused
          ? variables.colors.lighterGrey
          : `none`,
      boxShadow: `none`,
      '&:hover': {
        border: isDisabled
          ? `1px solid ${variables.inputColors.border}`
          : `1px solid ${variables.inputColors.focusBorder}`,
        background: isDisabled
          ? variables.colors.lightGrey
          : variables.colors.lighterGrey,
        boxShadow: `none`,
      },
    }),
    placeholder: (provided) => ({
      ...provided,
      fontSize: '13px',
      padding: '0px',
      color: variables.colors.paleGrey,
    }),
    valueContainer: (provided) => ({
      ...provided,
    }),
    menu: (provided) => ({
      zIndex: '12',
      ...provided,
    }),
    menuPortal: (provided) => ({
      ...provided,
    }),
  },
  smallest: {
    menuList: (provided) => ({
      ...provided,
      padding: '0',
      fontSize: '11px',
    }),
    option: (provided) => ({
      ...provided,
      padding: '5px',
    }),
    container: (provided) => ({
      ...provided,
      fontSize: '11px',
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      padding: '0px',
    }),
    control: (provided, { isDisabled, isFocused }) => ({
      display: 'flex',
      height: '18px',
      borderRadius: '3px',
      border: `1px solid ${variables.inputColors.border}`,
      background: isDisabled
        ? variables.colors.lightGrey
        : isFocused
          ? variables.colors.lighterGrey
          : `none`,
      boxShadow: `none`,
    }),
    placeholder: (provided) => ({
      ...provided,
      fontSize: '11px',
      padding: '0px',
      color: variables.colors.paleGrey,
    }),
    input: (provided) => ({
      ...provided,
      paddingBottom: '0',
      paddingTop: '0',
      margin: '0',
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: '0 2px',
    }),
    menu: (provided) => ({
      zIndex: '12',
      ...provided,
    }),
    menuPortal: (provided) => ({
      ...provided,
    }),
  },
  smallerDisabled: {
    menuList: (provided) => ({
      ...provided,
      padding: '0',
      fontSize: '13px',
    }),
    option: (provided) => ({
      ...provided,
    }),
    container: (provided) => ({
      ...provided,
      fontSize: '12px',
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    control: (provided, state) => ({
      background: state.isDisabled ? 'rgb(242, 242, 242);' : null,
      cursor: state.isDisabled ? 'not-allowed;' : null,
      border: `1px solid ${variables.messageColors.error}`,
      display: 'flex',
      height: '27px',
      borderRadius: '5px',
    }),
    placeholder: (provided) => ({
      ...provided,
      fontSize: '13px',
      padding: '0px',
    }),
    valueContainer: (provided) => ({
      ...provided,
    }),
    menu: (provided) => ({
      zIndex: '12',
      ...provided,
    }),
    menuPortal: (provided) => ({
      ...provided,
    }),
  },
  dashboard: {
    menuList: (provided) => ({
      ...provided,
      padding: 0,
    }),
    option: (provided, state) => ({
      ...provided,
      padding: '0',
      overflowX: 'hidden',
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      height: '60px',
      margin: '0',
      width: '70px',
      // background: variables.colors.lightGrey,
      background: variables.colors.paleGrey,
      borderRadius: '0 3px 3px 0',
      display: 'flex',
      alignContent: 'center',
      justifyContent: 'center',
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    container: (provided) => ({
      ...provided,
      padding: '0',
      width: '100%',
      boxShadow: variables.boxShadows.boxShadowDropdown,
      border: 'none',
      borderRadius: '3px',
      height: '60px',
      marginBottom: '10px',
    }),
    control: (provided, { isFocused }) => ({
      ...provided,
      borderRadius: '6px',
      padding: '0 0 0 10px',
      height: '60px',
      fontSize: '15px',
      border: isFocused ? `none` : `none`,
      boxShadow: `none`,
      '&:hover': {
        border: `none`,
        boxShadow: `none`,
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      margin: '0 -12px',
    }),
  },
  navbar: {
    menuList: (provided) => ({
      ...provided,
      padding: 0,
    }),
    option: (provided) => ({
      ...provided,
      padding: '0',
      overflowX: 'hidden',
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    container: (provided) => ({
      ...provided,
      padding: '0',
      maxWidth: '282px',
      width: '100%',
    }),
    control: (provided, { isFocused }) => ({
      ...provided,
      borderRadius: '50px',
      padding: '0 0 0 5px',
      border: isFocused ? '1px solid transparent' : '1px solid transparent',
      boxShadow: `none`,
      '&:hover': {
        border: '1px solid transparent',
        boxShadow: `none`,
      },
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = 'opacity 300ms';
      return { ...provided, opacity, transition };
    },
  },
  filterSearch: {
    container: (provided) => ({
      ...provided,
      minWidth: '240px',
      maxWidth: '366px',
    }),
    control: (provided, { isFocused }) => ({
      ...provided,
      borderRadius: '6px',
      border: isFocused
        ? `1px solid ${variables.inputColors.focusBorder}`
        : `1px solid ${variables.inputColors.border}`,
      background: isFocused ? variables.colors.lighterGrey : `none`,
      boxShadow: `none`,
      '&:hover': {
        border: `1px solid ${variables.inputColors.focusBorder}`,
        background: variables.colors.lighterGrey,
        boxShadow: `none`,
      },
    }),
  },
};

export const InputThemes = {
  outlineInput: {
    border: `1px solid ${variables.inputColors.border}`,
    focusBorder: variables.colors.paleGrey,
    bg: variables.colors.white,
    fullWidth: false,
  },
  dropdownInput: {
    border: `1px solid transparent`,
    bg: variables.colors.white,
    fullWidth: false,
    borderRadius: '50px',
    padding: '6px 15px',
  },
  backgroundInput: {
    bg: variables.inputColors.background,
    border: '1px solid transparent',
    fullWidth: false,
    focusBorder: variables.inputColors.border,
  },
  backgroundInputFull: {
    bg: variables.inputColors.background,
    border: '1px solid transparent',
    fullWidth: true,
    focusBorder: variables.inputColors.border,
  },
  bulkSearch: {
    bg: variables.colors.white,
    border: `1px solid ${variables.inputColors.border}`,
    fullWidth: true,
    focusBorder: variables.colors.paleGrey,
    padding: '6px 10px',
    borderRadius: '4px',
    fontSize: variables.fonts.small,
  },
};

export const ButtonThemes = {
  primary: {
    color: variables.buttonColors.primary,
    alignment: 'center',
  },
  primaryFull: {
    color: variables.buttonColors.primary,
    width: '100%',
    alignment: 'center',
  },
  bigButtonPrimary: {
    color: variables.buttonColors.primary,
  },
  secondary: {
    color: variables.buttonColors.secondary,
    alignment: 'center',
  },
  secondaryFull: {
    color: variables.buttonColors.secondary,
    width: '100%',
    alignment: 'center',
  },
  bigButtonSecondary: {
    color: variables.buttonColors.secondary,
  },
  inactive: {
    border: `1px solid ${variables.buttonColors.inactive}`,
    color: variables.colors.white,
    cursor: 'not-allowed',
    fontColor: variables.buttonColors.inactive,
    transform: 'scale(1)',
  },
  inactiveBorder: {
    border: `1px solid ${variables.buttonColors.inactive}`,
    color: variables.colors.white,
    fontColor: variables.buttonColors.inactive,
    loadingColor: variables.buttonColors.inactive,
    transform: 'scale(1)',
  },
  inactiveBorderFull: {
    border: `1px solid ${variables.buttonColors.inactive}`,
    color: variables.colors.white,
    fontColor: variables.buttonColors.inactive,
    loadingColor: variables.buttonColors.inactive,
    transform: 'scale(1)',
    width: '100%',
  },
  cancel: {
    border: `1px solid ${variables.buttonColors.inactive}`,
    color: variables.buttonColors.inactive,
    fontColor: variables.colors.white,
  },
  inactiveFull: {
    border: `1px solid ${variables.buttonColors.inactive}`,
    color: variables.colors.white,
    cursor: 'not-allowed',
    width: '100%',
    fontColor: variables.buttonColors.inactive,
    transform: 'scale(1)',
  },
  error: {
    color: variables.messageColors.error,
    alignment: 'center',
  },
  errorFull: {
    border: `1px solid ${variables.messageColors.error}`,
    color: variables.messageColors.error,
    cursor: 'not-allowed',
    width: '100%',
    fontColor: variables.colors.white,
    transform: 'scale(1)',
  },
  warning: {
    color: variables.messageColors.warning,
    alignment: 'center',
  },
  info: {
    color: variables.messageColors.info,
    alignment: 'center',
  },
};

const baseDropdownTheme = {
  top: variables.heights.navBar,
  caretColor: variables.colors.darkGrey,
};

const floatRight = {
  float: 'right',
  left: 'auto',
  mobileLeft: 'auto',
  caretRight: '10px',
};

const floatLeft = {
  float: 'left',
  right: 'auto',
  mobileRight: 'auto',
  caretLeft: '10px',
};

export const DropdownThemes = {
  user: {
    ...baseDropdownTheme,
    ...floatRight,
    right: '28px',
    mobileRight: '22px',
    maxHeight: 'calc(100vh - 165px)',
  },
  advisor: {
    ...baseDropdownTheme,
    ...floatRight,
    right: '78px',
    tabletRight: '62px',
    mobileRight: '57px',
    maxHeight: 'calc(100vh - 165px)',
  },
  notifications: {
    ...baseDropdownTheme,
    ...floatRight,
    right: '85px',
    mobileRight: '73px',
    width: '400px',
    mobileWidth: '275px',
    listPadding: '0px',
    maxHeight: '410px',
    smallMaxHeight: 'calc(100vh - 165px)',
    // maxHeight: '410px',
  },
  marker: {
    ...baseDropdownTheme,
    ...floatRight,
    right: '182px',
    mobileRight: '161px',
  },
  household: {
    ...baseDropdownTheme,
    ...floatLeft,
    left: '21px',
    mobileLeft: '14px',
  },
  search: {
    ...baseDropdownTheme,
    ...floatLeft,
    left: '63px',
    mobileLeft: '53px',
  },
};

export const ErrorThemes = {
  modalDefault: {
    bg: variables.messageColors.error,
    borderRadius: '0 0 6px 6px',
    padding: '10px 20px',
    imgSrc: errorCircle,
    txtColor: variables.colors.white,
  },
  sidebar: {
    bg: variables.messageColors.error,
    borderRadius: '0',
    padding: '10px 15px',
    height: '18px',
    imgSrc: errorCircle,
    txtColor: variables.colors.white,
    maxWidthText: '250px',
    isSidebar: true,
  },
  inverted: {
    bg: variables.messageColors.errorBackground,
    borderRadius: '5px',
    padding: '10px',
    margin: '0 0 15px 0',
    border: `1px solid ${variables.messageColors.error}`,
    imgSrc: errorCircleRed,
    txtColor: variables.messageColors.error,
  },
};

export const SuccessThemes = {
  modalDefault: {
    bg: variables.messageColors.modalSuccessBackground,
    borderRadius: '0 0 6px 6px',
    padding: '10px 20px',
    imgSrc: checkCircle,
    txtColor: variables.messageColors.success,
    fontWeight: variables.fonts.semiBold,
  },
  inline: {
    bg: variables.messageColors.successBackground,
    borderRadius: '5px',
    padding: '10px',
    marginBottom: '15px',
    border: `1px solid ${variables.messageColors.success}`,
    imgSrc: checkCircle,
    txtColor: variables.messageColors.success,
  },
  sidebar: {
    bg: variables.messageColors.modalSuccessBackground,
    borderRadius: '0',
    padding: '10px 15px',
    height: '18px',
    fontWeight: variables.fonts.semiBold,
    imgSrc: checkCircle,
    txtColor: variables.messageColors.success,
    maxWidthText: '250px',
    isSidebar: true,
  },
};
