import styled from 'styled-components';
import { colors, fonts, maxDevice } from '../variables';

export const ModalCheck = styled.img`
  margin: 0 auto;
  margin-top: 15px;
  margin-bottom: -5px;
  width: 50px;
  height: 50px;
  display: block;
`;

export const MediumImage = styled.img`
  display: flex;
  margin: 15px 0;
  width: 40px;
`;

export const ImageIconBig = styled.img`
  width: 25px;
  height: 25px;
`;

export const ImageIcon = styled.img`
  width: 14px;
  height: 14px;
`;

export const CustomSVG = styled.svg`
  width: 24px;
  height: 24px;
  width: ${(props) => props.width};
  height: ${(props) => (props.height ? props.height : props.width)};
`;

export const CustomIcon = styled.img`
  width: 24px;
  height: 24px;
  width: ${(props) => props.width};
  height: ${(props) => (props.height ? props.height : props.width)};
`;

export const IconButton = styled.img`
  width: 18px;
  height: 18px;
  padding: 8px;
  flex: 1 1 auto;
  cursor: pointer;
`;

export const IconButtonSVG = styled(IconButton).attrs({
  as: 'svg',
})``;

export const ChevronImg = styled.img`
  width: 8px;
  height: 8px;
  margin-left: 10px;
`;

export const ChevronIcon = styled.img`
  width: 10px;
  height: 10px;
  flex: 0 0 auto;
`;

export const IconContainer = styled.span`
  flex: 0 0 auto;
  height: 35px;
  width: 35px;
  border-radius: 50px;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  background: ${colors.lightGrey};
  cursor: pointer;
  img {
    width: 16px;
    height: 16px;
  }
  &:hover {
    transform: scale(1.01);
    background: ${colors.hoverLightGrey};
  }
`;

export const AvatarPlaceholder = styled.div`
  @media ${maxDevice.tablet} {
    margin: ${(props) =>
      props.marginTablet ? props.marginTablet : '0 0 0 5px'};
  }
  border-radius: 100%;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  width: ${(props) => (props.width ? props.width : '40px')};
  height: ${(props) => (props.width ? props.width : '40px')};
  margin: ${(props) => (props.margin ? props.margin : '0 0 0 10px')};
  border: ${(props) => (props.border ? props.border : 'none')};
  background: ${(props) => (props.background ? props.background : 'none')};
  cursor: ${(props) => (props.clickable ? 'pointer' : null)};
  &:hover {
    opacity: ${(props) => (props.clickable ? '.9' : null)};
  }
`;

export const Initials = styled.span`
  text-align: center;
  color: ${(props) => (props.color ? props.color : colors.darkGrey)};
  font-weight: ${(props) =>
    props.fontWeight ? props.fontWeight : fonts.normal};
  font-size: ${(props) => (props.fontSize ? props.fontSize : '16px')};
`;

export const LightBackgroundIcon = styled.div`
  width: ${(props) => (props.width ? props.width : '50px')};
  height: ${(props) => (props.width ? props.width : '50px')};
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  background: ${(props) =>
    props.backgroundColor ? props.backgroundColor : colors.lightGrey};
  border-radius: 100%;
  img {
    width: ${(props) => (props.iconWidth ? props.iconWidth : '25px')};
    height: ${(props) => (props.iconWidth ? props.iconWidth : '25px')};
  }
`;

export const ProposalIconContainer = styled.div`
  position: absolute;
  z-index: 4;
  right: ${(props) => (props.$direction === 'horizontal' ? '-2px' : '-10px')};
  top: ${(props) =>
    props.$top ? props.$top : props.$isResidence ? '8px' : '-2px'};
  border-radius: 100%;
  width: 20px;
  height: 20px;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  img {
    width: 100%;
    height: 100%;
  }
`;
