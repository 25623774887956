/* eslint-disable react-hooks/exhaustive-deps */
import { useRef, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {
  Button,
  Error,
  EmptyImage,
  LoadingAnimation,
  GenerateInput,
  DetectModalKeys,
} from '../';
import {
  createTargetMapTemplate,
  updateTargetMapTemplate,
  toggleShowDeleteModal,
} from '../../store/actions';
import { timesWhite, trashDark, piggyBank } from '../../assets';
import {
  ModalBackground,
  ModalOverlay,
  ModalContainer,
  ButtonContainer,
  ButtonsRowContainer,
  ModalIcon,
  CloseButton,
} from '../../styles/library/modalStyles';
import { RowButton } from '../../styles/library/fontStyles';
import { colors, fonts } from '../../styles/variables';
import { ButtonThemes } from '../../styles/themes';
import {
  CheckHasValue,
  DetectEnterKeyPress,
  MatchSelectItem,
} from '../../utils';
import { darken } from 'polished';

const TargetMapTemplateModal = ({
  hide,
  isNew = true,
  templateRules = [],
  error,
  existingTemplate,
}) => {
  const ref = useRef();
  const dispatch = useDispatch();
  const { memberCategories, targetMapTemplateError } = useSelector((state) => ({
    memberCategories: state.configs.memberCategories,
    targetMapTemplateError: state.targetMaps.targetMapTemplateError,
  }));
  const [loadingWants, setLoadingWants] = useState(true);
  const [wantsList, setWantsList] = useState([]);
  const [templateName, setTemplateName] = useState('');
  const [templateNameError, setTemplateNameError] = useState(false);
  const [memberRoles, setMemberRoles] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [creatingTemplate, setCreatingTemplate] = useState(false);

  const applyAllOptions = [
    { label: 'Yes', value: true },
    { label: 'No', value: false },
  ];

  useEffect(() => {
    if (memberCategories && memberCategories.length !== 0) {
      setMemberRoles(memberCategories);
    }
  }, [memberCategories]);

  useEffect(() => {
    if (templateRules) {
      setCreatingTemplate(false);
      if (existingTemplate) {
        setTemplateName(existingTemplate.name);
        setTemplateNameError(false);
      } else {
        setTemplateName('');
      }
      setWantsList(
        templateRules.map((rule) => {
          rule.apply_all = MatchSelectItem(
            applyAllOptions,
            rule?.apply_all?.value || false
          );
          rule.member_category = MatchSelectItem(
            memberRoles,
            rule?.memberObj?.category || rule.member
          );
          rule.errors = [];
          return rule;
        })
      );
      setTimeout(() => setLoadingWants(false), 300);
    }
  }, [templateRules, memberRoles]);

  useEffect(() => {
    if (error) {
      setCreatingTemplate(false);
      setErrorMessage(
        error?.data?.detail ||
          `Error ${isNew ? 'creating' : 'updating'} template.`
      );
    }
  }, [error]);

  useEffect(() => {
    if (targetMapTemplateError) {
      setCreatingTemplate(false);
      if (targetMapTemplateError?.data) {
        const errors = Object.values(targetMapTemplateError?.data);
        setErrorMessage(errors);
      } else {
        setErrorMessage(
          error?.data?.detail ||
            `Error ${isNew ? 'creating' : 'updating'} template.`
        );
      }
    }
  }, [targetMapTemplateError]);

  const saveTemplate = () => {
    if (!templateName) {
      setErrorMessage('Template name is required.');
      return setTemplateNameError(true);
    }
    const validateList = wantsList.map((want) => {
      want.errors = [];
      if (!CheckHasValue(want.name, false)) {
        want.errors = [...want.errors, 'name'];
      }
      if (!CheckHasValue(want.amount, false)) {
        want.errors = [...want.errors, 'amount'];
      }
      if (!CheckHasValue(want?.member_category?.value, false)) {
        want.errors = [...want.errors, 'member_category'];
      }
      if (!CheckHasValue(want?.apply_all?.value, false)) {
        want.errors = [...want.errors, 'apply_all'];
      }
      return want;
    });
    const errorList = validateList.reduce((acc, curr) => {
      return [...acc, ...curr.errors];
    }, []);

    if (errorList.length !== 0) {
      setWantsList(validateList);
      setErrorMessage('Please fix errors to continue.');
    } else {
      const completeTemplateList = wantsList.map((want) => {
        return {
          name: want.name,
          amount: want.amount,
          apply_all: want.apply_all.value,
          member_category: want.member_category.value,
        };
      });
      const data = {
        name: templateName,
        data: completeTemplateList,
      };
      if (isNew) {
        dispatch(createTargetMapTemplate(data));
      } else {
        dispatch(updateTargetMapTemplate(existingTemplate.value, data));
      }
      setCreatingTemplate(true);
    }
  };

  const deleteWant = (want, index) => {
    setWantsList(wantsList.filter((item, i) => i !== index));
  };

  const inputOnChange = (value, name, index) => {
    setErrorMessage('');
    if (name === 'template_name') {
      setTemplateNameError(false);
      return setTemplateName(value);
    } else {
      return setWantsList(
        wantsList.map((item, i) => {
          if (index === i) {
            item[name] = value;
            item.errors = item.errors.filter((error) => error !== name);
          }
          return item;
        })
      );
    }
  };

  return ReactDOM.createPortal(
    <>
      <DetectModalKeys onEnter={saveTemplate} onEsc={hide} />
      <ModalBackground />
      <ModalOverlay>
        <ModalContainer role="dialog" ref={ref} $width="600px">
          <ModalHeadingContainer>
            <ModalHeading>
              {isNew ? 'Create' : 'Edit'} Target-Map Template
            </ModalHeading>
            <CloseButton
              src={timesWhite}
              onClick={() => hide()}
              alt="close"
              data-image="close"
            />
          </ModalHeadingContainer>
          <ModalContentContainer>
            <ModalText>
              Target-Map Templates allow you save a list of custom wants to be
              used to create a new Target-Map.
            </ModalText>
            <TemplateNameContainer>
              {GenerateInput({
                isVisible: true,
                label: 'Template name',
                type: 'text',
                value: templateName,
                placeholder: 'Template Name',
                hasError: templateNameError,
                errorMessage: 'Template name is required.',
                onChange: (e) =>
                  inputOnChange(e.currentTarget.value, 'template_name'),
              })}
            </TemplateNameContainer>
            <SectionHeading>Template Wants</SectionHeading>
            {loadingWants ? (
              <LoadingAnimationContainer>
                <LoadingAnimation />
              </LoadingAnimationContainer>
            ) : wantsList.length !== 0 ? (
              wantsList.map((want, index) => {
                return (
                  <WantRowContainer key={index}>
                    {GenerateInput({
                      isVisible: true,
                      label: 'Description',
                      type: 'text',
                      value: want.name,
                      placeholder: 'Description',
                      tooltip: 'Name or brief description of the want.',
                      margin: '0 5px 10px 0',
                      hasError: want?.errors.includes('name'),
                      errorMessage: 'Description is required.',
                      width: '100%',
                      onChange: (e) =>
                        inputOnChange(e.currentTarget.value, 'name', index),
                      onKeyPress: (e) => DetectEnterKeyPress(e, saveTemplate),
                    })}
                    {GenerateInput({
                      isVisible: true,
                      label: 'Amount',
                      type: 'text',
                      value: want.amount,
                      placeholder: 'Amount',
                      tooltip:
                        'This field supports a fixed number or equation. The equation uses the following variables (income, liability, insurance, residence, cash, invest, assets). Simply start the value with an = and wrap all variables in {}. Ex: ={income}*.8+{assets}',
                      hasError: want?.errors.includes('amount'),
                      errorMessage: 'Amount is required.',
                      margin: '0 5px 10px 0',
                      width: '280px',
                      onChange: (e) =>
                        inputOnChange(e.currentTarget.value, 'amount', index),
                      onKeyPress: (e) => DetectEnterKeyPress(e, saveTemplate),
                    })}
                    {GenerateInput({
                      isVisible: true,
                      label: 'Member Relationship',
                      type: 'select',
                      width: '358px',
                      value: want.member_category,
                      options: memberRoles,
                      placeholder: 'Relationship',
                      tooltip:
                        'Select which member relationship this want should apply to.',
                      hasError: want?.errors.includes('member_category'),
                      errorMessage: 'Relationship is required.',
                      onChange: (e) =>
                        inputOnChange(e, 'member_category', index),
                      margin: '0 5px 10px 0',
                    })}
                    {GenerateInput({
                      isVisible: true,
                      label: 'Apply to All',
                      type: 'select',
                      width: '225px',
                      hasError: want?.errors.includes('apply_all'),
                      errorMessage: 'Apply to all is required.',
                      tooltip:
                        'Apply to all members with this relationship, or just the first member with this relationship.',
                      value: want.apply_all,
                      options: applyAllOptions,
                      onChange: (e) => inputOnChange(e, 'apply_all', index),
                      placeholder: 'Apply to All',
                    })}
                    <TrashIconContainer
                      onClick={() => {
                        deleteWant(want, index);
                      }}
                    >
                      <TrashIcon
                        src={trashDark}
                        alt="trash"
                        data-image={`trash-tm-want-${index}`}
                      />
                    </TrashIconContainer>
                  </WantRowContainer>
                );
              })
            ) : (
              <EmptyImage
                imageSrc={piggyBank}
                imageName="No Target-Map Wants"
                text="No Target-Map Wants"
                styles={{
                  fontSize: '18px',
                  imageHeight: '230px',
                  containerMargin: '0 15px',
                  textMargin: '10px 40px 20px 40px',
                }}
              />
            )}
            <RowButton
              onClick={() =>
                setWantsList([
                  ...wantsList,
                  {
                    name: '',
                    amount: '',
                    member_category: null,
                    apply_all: applyAllOptions[1],
                    errors: [],
                  },
                ])
              }
            >
              + Add New Want
            </RowButton>
            <ButtonsRowContainer>
              {isNew || (
                <ModalIcon
                  src={trashDark}
                  alt="delete"
                  title="Delete"
                  data-image="delete"
                  onClick={() =>
                    dispatch(
                      toggleShowDeleteModal(
                        true,
                        'tm_template',
                        templateName,
                        existingTemplate.value
                      )
                    )
                  }
                />
              )}
              <ButtonContainer primaryButtonWidth="140px">
                <Button
                  text={'Cancel'}
                  onClick={() => hide()}
                  theme={ButtonThemes.cancel}
                />
                <Button
                  text={isNew ? 'Create Template' : 'Update Template'}
                  onClick={() => saveTemplate()}
                  showLoading={creatingTemplate}
                  loadingText={isNew ? 'Creating' : 'Updating'}
                />
              </ButtonContainer>
            </ButtonsRowContainer>
          </ModalContentContainer>
          {errorMessage && errorMessage !== '' && (
            <Error errorMessage={errorMessage} />
          )}
        </ModalContainer>
      </ModalOverlay>
    </>,
    document.body
  );
};

const TemplateNameContainer = styled.div`
  margin-bottom: 15px;
`;

const LoadingAnimationContainer = styled.div`
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
`;

const SectionHeading = styled.p`
  font-weight: ${fonts.semiBold};
  text-align: left;
  font-size: 13px;
  margin-bottom: 8px;
`;

const ModalHeadingContainer = styled.div`
  padding: 12px 22px;
  background: ${colors.darkGrey};
  border-radius: 6px 6px 0 0;
  position: relative;
`;

const ModalHeading = styled.h3`
  font-weight: ${fonts.semiBold};
  background: ${colors.darkGrey};
  color: ${colors.white};
  font-size: 15px;
`;

const ModalContentContainer = styled.div`
  padding: 15px 15px;
  /* max-height: 500px;
  overflow-y: auto; */
`;

const ModalText = styled.p`
  font-size: 12px;
  max-width: 375px;
  margin: 0 auto 10px auto;
`;

const WantRowContainer = styled.div`
  display: flex;
  align-content: center;
  justify-content: flex-start;
  align-items: flex-end;
  margin-bottom: 6px;
`;

const TrashIconContainer = styled.div`
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  padding: 8px;
  border-radius: 4px;
  margin-left: 5px;
  margin-bottom: 12px;
  background: ${colors.lightGrey};
  border: 1px solid ${colors.lightGrey};
  cursor: pointer;
  &:hover {
    transform: scale(1.01);
    border: 1px solid ${darken(0.05, colors.lightGrey)};
  }
`;

const TrashIcon = styled.img`
  width: 15px;
  height: 15px;
`;

TargetMapTemplateModal.propTypes = {
  hide: PropTypes.func,
  isNew: PropTypes.bool,
  templateRules: PropTypes.array,
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.array,
    PropTypes.bool,
  ]),
  existingTemplate: PropTypes.object,
};

export default TargetMapTemplateModal;
