/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { InviteModal, LoadingAnimation } from '../../components';
import {
  noHouseholds,
  plusSquareGreen,
  settingsBlue,
  // infoOrange,
} from '../../assets';
import {
  getInviteDetails,
  removeHouseholdInfo,
  toggleShowInviteModal,
  updateUserStoreValue,
} from '../../store/actions';
import { Page } from '../../styles/library/layoutStyles';
import {
  LoadingPlaceholder,
  SecondaryHeading,
  TertiaryHeading,
} from '../../styles/library/fontStyles';
import { colors, fonts, maxDevice, heights } from '../../styles/variables';
import { UpdatePageTitle, CloseDropdowns } from '../../utils';
// import { CONSUMER_BETA_LINK as consumerBetaLink } from '../../utils/constants';

const ConsumerDashboard = ({ redirected }) => {
  UpdatePageTitle('Dashboard');
  CloseDropdowns();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const {
    user,
    justLoggedIn,
    households,
    householdsError,
    consumerCanCreateHousehold,
    showingInviteModal,
    completedInvite,
  } = useSelector((state) => ({
    user: state.user.user,
    justLoggedIn: state.user.justLoggedIn,
    households: state.households.households,
    householdsError: state.households.householdsError,
    consumerCanCreateHousehold: state.user.consumerCanCreateHousehold,
    showingInviteModal: state.user.showingInviteModal,
    completedInvite: state.user.completedInvite,
  }));
  const [loadingHouseholds, setLoadingHouseholds] = useState(true);
  const [householdList, setHouseholdList] = useState([]);
  const [error, setError] = useState();
  const [inviteScreenValue, setInviteScreenValue] = useState('loading');

  useEffect(() => {
    return () => {
      dispatch(removeHouseholdInfo());
    };
  }, []);

  //LOGIC TO DETECT THE CONSUMER INVITE REDIRECT AND SHOW MODAL (LOGGED IN)
  useEffect(() => {
    if (redirected) {
      navigate(`/i/${params.id}`);
      dispatch(toggleShowInviteModal(true, 'loading'));
      dispatch(getInviteDetails(params.id));
      dispatch(updateUserStoreValue('consumerInviteId', params.id));
    }
  }, [redirected]);

  //ONCE INVITE MODAL IS CLOSED REDIRECT TO DASHBOARD
  useEffect(() => {
    if (showingInviteModal === false && params.id) {
      navigate('/dashboard');
      dispatch(updateUserStoreValue('consumerInviteId', null));
    }
  }, [showingInviteModal]);

  //IF INVITE IS ACCEPTED/REJECTED CLOSE INVITE MODAL AND UPDATE STORE
  useEffect(() => {
    if (completedInvite) {
      dispatch(toggleShowInviteModal(false, 'loading'));
      dispatch(updateUserStoreValue('completedInvite', false));
    }
  }, [completedInvite]);

  useEffect(() => {
    if (households) {
      setHouseholdList(households?.results);
      setLoadingHouseholds(false);
    }
  }, [households]);

  useEffect(() => {
    if (householdsError) {
      setError(householdsError);
      setLoadingHouseholds(false);
    }
  }, [householdsError]);

  const generateErrorDisplay = () => {
    let errorMessage = `No Households. Please contact your advisor.`;
    if (error) {
      if (error.hasOwnProperty('statusText')) {
        errorMessage = `Error: ${error.statusText}`;
      } else if (error.hasOwnProperty('status')) {
        if (error.hasOwnProperty('data')) {
          errorMessage = `${error.status} Error: ${
            Object.values(error.data)[0]
          }`;
        }
        errorMessage = `${error.status} Error`;
      }
    }
    return errorMessage;
  };

  return (
    <>
      {showingInviteModal && (
        <InviteModal
          screen={inviteScreenValue}
          hide={() => {
            dispatch(toggleShowInviteModal(false, 'loading'));
          }}
          setScreenValue={setInviteScreenValue}
        />
      )}
      <Page>
        {justLoggedIn ? (
          <LoadingScreen>
            <LoadingAnimation />
            <LoadingText>Fetching Info</LoadingText>
          </LoadingScreen>
        ) : (
          <ConsumerDashboardContainer>
            <GreetingContainer>
              {user?.name ? (
                <GreetingMessage>Welcome, {user?.name}</GreetingMessage>
              ) : (
                <GreetingMessage>
                  <LoadingPlaceholder
                    width="400px"
                    height="40px"
                    mobileWidth="300px"
                    margin="0"
                  />
                </GreetingMessage>
              )}
            </GreetingContainer>
            <HouseholdsContainer>
              <TertiaryHeading>Your Households</TertiaryHeading>
              <HouseholdsList>
                <HouseholdHeader>
                  <HouseholdName>Name</HouseholdName>
                  <HouseholdAdvisor>Managed By</HouseholdAdvisor>
                  <HouseholdRole>Relationship</HouseholdRole>
                </HouseholdHeader>
                {loadingHouseholds ? (
                  <>
                    {[0].map((index) => {
                      return (
                        <HouseholdItem key={index} $isLast={true}>
                          <HouseholdName>
                            <LoadingPlaceholder
                              width="200px"
                              mobileWidth="100px"
                              margin="0"
                            />
                          </HouseholdName>
                          <HouseholdAdvisor>
                            <LoadingPlaceholder
                              width="150px"
                              mobileWidth="75px"
                              margin="0"
                            />
                          </HouseholdAdvisor>
                          <HouseholdRole>
                            <LoadingPlaceholder mobileWidth="50px" margin="0" />
                          </HouseholdRole>
                        </HouseholdItem>
                      );
                    })}
                  </>
                ) : (
                  <>
                    {householdList.length !== 0 ? (
                      householdList.map((household, index) => {
                        return (
                          <HouseholdItem
                            key={index}
                            $isLast={householdList.length - 1 === index}
                          >
                            <HouseholdNameLink
                              to={`/household/${household?.household?.id}/assetmap`}
                            >
                              {household?.household?.name}
                            </HouseholdNameLink>
                            <HouseholdAdvisor>
                              {household?.household?.owner?.name}
                            </HouseholdAdvisor>
                            <HouseholdRole>
                              {household?.householdmember?.role?.name ||
                                household?.role}
                            </HouseholdRole>
                          </HouseholdItem>
                        );
                      })
                    ) : (
                      <EmptyResults>
                        <img
                          src={noHouseholds}
                          alt="No Households"
                          data-image="no-households"
                        />
                        <p>{generateErrorDisplay()}</p>
                      </EmptyResults>
                    )}
                  </>
                )}
              </HouseholdsList>
            </HouseholdsContainer>
            <QuickLinksContainer>
              <SecondaryHeading>Quick Links</SecondaryHeading>
              <QuickLinks>
                {consumerCanCreateHousehold && (
                  <QuickLink to={'/household/new'} $color={colors.green}>
                    <LinkIcon
                      src={plusSquareGreen}
                      alt="home"
                      title="Create Household"
                    />
                    <LinkText>Create Household</LinkText>
                  </QuickLink>
                )}
                <QuickLink to={'/user/preferences'} $color={colors.blue}>
                  <LinkIcon
                    src={settingsBlue}
                    alt="settings"
                    title="Profile Settings"
                  />
                  <LinkText>Profile Settings</LinkText>
                </QuickLink>
                {/* <QuickLinkExternal
                  href={consumerBetaLink}
                  target="_blank"
                  rel="noreferrer"
                  color={colors.orange}
                >
                  <LinkIcon src={infoOrange} alt="info" title="Learn More" />
                  <LinkText>Learn More</LinkText>
                </QuickLinkExternal> */}
              </QuickLinks>
            </QuickLinksContainer>
          </ConsumerDashboardContainer>
        )}
      </Page>
    </>
  );
};

const EmptyResults = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;
  padding: 40px 20px;
  height: 100%;
  img {
    max-width: 275px;
    width: 100%;
  }
  p {
    margin-top: 18px;
    font-size: 24px;
    font-weight: ${fonts.semiBold};
    color: ${colors.paleGrey};
  }
`;

const ConsumerDashboardContainer = styled.div`
  @media ${maxDevice.sideNav} {
    padding: 40px 30px;
  }
  padding: 60px 40px;
`;

const LoadingScreen = styled.div`
  width: 100%;
  height: 100%;
  height: ${`calc(100vh - ${heights.navBar})`};
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
`;

const LoadingText = styled.p`
  margin: 20px 40px 20px 40px;
  font-size: 24px;
  font-weight: ${fonts.bold};
  text-align: center;
  color: ${colors.paleGrey};
`;

const GreetingContainer = styled.div`
  @media ${maxDevice.sideNav} {
    flex-wrap: wrap;
  }
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-bottom: 60px;
`;

const GreetingMessage = styled.h1`
  @media ${maxDevice.sideNav} {
    width: 100%;
    margin-bottom: 10px;
    margin-right: 0;
  }
  font-size: 30px;
  color: ${colors.darkGrey};
  font-weight: 600;
  flex: 1 1 auto;
  min-height: 40px;
  margin-right: 15px;
`;

// const AccountLink = styled(Link)`
//   @media ${maxDevice.sideNav} {
//     width: 100%;
//   }
//   flex: 0 0 auto;
//   color: ${colors.lightBlue};
//   cursor: pointer;
//   font-size: 14px;
//   &:hover {
//     color: ${colors.blue};
//   }
// `;

const HouseholdsContainer = styled.div`
  margin-bottom: 60px;
`;

const HouseholdsList = styled.div`
  margin-top: 10px;
  width: 100%;
  border: 1px solid ${colors.lightGrey};
  border-radius: 3px;
  font-size: 14px;
`;

const HouseholdName = styled.div`
  flex: 1 1 auto;
  width: 40%;
  max-width: 400px;
  color: ${colors.darkGrey};
`;

const HouseholdNameLink = styled(HouseholdName).attrs({ as: Link })`
  color: ${colors.lightBlue};
  cursor: pointer;
  flex: 1 1 auto;
  &:hover {
    color: ${colors.blue};
  }
`;

const HouseholdAdvisor = styled.div`
  flex: 1 1 auto;
  width: 40%;
`;

const HouseholdRole = styled.div`
  flex: 1 1 auto;
  width: 20%;
  max-width: 150px;
  text-transform: capitalize;
  /* text-align: right; */
`;

const HouseholdHeader = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
  padding: 6px 15px;
  background: ${colors.lightGrey};
  border-radius: 3px 3px 0 0;
  div {
    font-weight: 600;
  }
`;

const HouseholdItem = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
  padding: 10px 15px;
  border-bottom: ${(props) =>
    props.$isLast ? `1px solid transparent` : `1px solid ${colors.lightGrey}`};
`;

const QuickLinksContainer = styled.div``;

const QuickLinks = styled.div`
  @media ${maxDevice.sideNav} {
    flex-wrap: wrap;
  }
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  margin: 10px -10px 0 -10px;
`;

const LinkText = styled.p`
  color: ${colors.darkGrey};
  margin-left: 18px;
  font-weight: ${fonts.semiBold};
`;

const LinkIcon = styled.img`
  width: 30px;
  height: 30px;
`;

const QuickLink = styled(Link)`
  @media ${maxDevice.sideNav} {
    flex: 1 1 auto;
    width: 100%;
    margin-bottom: 10px;
    height: 40px;
  }
  flex: 1 1 33%;
  width: 33%;
  min-width: 50px;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  text-align: center;
  padding: 20px;
  margin: 0 10px;
  border: 1px solid ${colors.lightGrey};
  border-radius: 3px;
  height: 60px;
  cursor: pointer;
  &:hover {
    transform: scale(1.01);
    border: 1px solid ${(props) => props.$color};
    background: ${(props) => (props.$color ? `${props.$color}05` : null)};
    ${LinkText} {
      color: ${(props) => props.$color};
    }
  }
`;

// const QuickLinkExternal = styled(QuickLink).attrs({
//   as: 'a',
// })``;

ConsumerDashboard.propTypes = {
  redirected: PropTypes.bool,
};

export default ConsumerDashboard;
