import { useRef, useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Button, Error, Success, TypeaheadSearch } from '..';
import { timesWhite } from '../../assets';
import {
  ModalBackground,
  ModalOverlay,
  ModalContainer,
  ModalHeadingContainer,
  ModalHeading,
  CloseButton,
  ModalContentContainer,
} from '../../styles/library/modalStyles';
import { InputLabel } from '../../styles/library/inputStyles';
import { colors } from '../../styles/variables';
import { selectThemes } from '../../styles/themes';
import { UseOutsideClick } from '../../utils';

const AdvisorAssignModal = ({
  hide,
  update,
  discovery,
  customer,
  updateError,
  updateSuccess,
}) => {
  const ref = useRef();

  const [advisor, setAdvisor] = useState();
  const [currentAdvisor, setCurrentAdvisor] = useState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [success, setSuccess] = useState();
  const [justUpdated, setJustUpdated] = useState(false);

  useEffect(() => {
    if (discovery) {
      setCurrentAdvisor(discovery.lead_advisor);
    }
  }, [discovery]);

  useEffect(() => {
    if (error) {
      setLoading(false);
    }
  }, [error]);

  useEffect(() => {
    if (updateError) {
      setError(updateError);
      setJustUpdated(false);
    }
    if (updateSuccess && !justUpdated) {
      setLoading(false);
      setSuccess(updateSuccess);
      setCurrentAdvisor(advisor);
      setJustUpdated(true);
    }
  }, [updateError, updateSuccess, advisor, justUpdated]);

  UseOutsideClick(ref, () => {
    hideFunction();
  });

  const hideFunction = () => {
    setSuccess();
    setError();
    hide();
  };

  const clickButton = () => {
    setSuccess();
    setError();
    if (advisor && advisor.label !== currentAdvisor.label) {
      setLoading(true);
      setJustUpdated(false);
      update(advisor, discovery);
    }
  };

  const loadOptions = (input) => {
    if (input) {
      return fetch(
        `/api/v3/customer/${customer.id}/advisor_search/${input}`
      ).then((res) => {
        return res.json();
      });
    }
  };

  return ReactDOM.createPortal(
    <>
      <ModalBackground />
      <ModalOverlay>
        <ModalContainer role="dialog" ref={ref} $width="350px">
          <ModalHeadingContainer>
            <ModalHeading>Assign Advisor</ModalHeading>
            <CloseButton
              src={timesWhite}
              onClick={() => hideFunction()}
              alt="close"
              data-image="close"
            />
          </ModalHeadingContainer>
          <ModalContentContainer>
            <ModalText>
              {'Reassign the '}
              <span>{discovery.name}</span>
              {' household to another lead advisor.'}
            </ModalText>
            {currentAdvisor && (
              <ModalInfoText>
                {'Current Lead Advisor: '}
                <span>{currentAdvisor?.name || currentAdvisor?.label}</span>
              </ModalInfoText>
            )}
            <SelectContainer>
              <InputLabel>{'Lead Advisor'}</InputLabel>
              <TypeaheadSearch
                showSearchIcon={true}
                largeIcon={false}
                loadOptions={loadOptions}
                placeholder={'Search for advisors by last name or email'}
                isMulti={false}
                styles={selectThemes.inputSearch}
                onSelectChange={(e) => setAdvisor(e)}
              />
            </SelectContainer>
            <ButtonsRowContainer>
              <ButtonContainer>
                <Button
                  text={'Assign'}
                  onClick={() => clickButton()}
                  showLoading={loading}
                  loadingText={'Assigning'}
                />
              </ButtonContainer>
            </ButtonsRowContainer>
          </ModalContentContainer>
          {error && error !== '' && <Error errorMessage={error} />}
          {success && success !== '' && <Success successMessage={success} />}
        </ModalContainer>
      </ModalOverlay>
    </>,
    document.body
  );
};

const ModalText = styled.p`
  font-size: 13px;
  margin: ${(props) => (props.margin ? props.margin : '0 0 15px 0')};
  color: ${colors.darkGrey};
  a {
    color: ${colors.lightBlue};
  }
  span {
    font-weight: 700;
  }
`;

const ModalInfoText = styled.p`
  font-size: 12px;
  margin-bottom: 15px;
  color: ${colors.darkGrey};
  span {
    font-weight: 700;
  }
`;

const SelectContainer = styled.div`
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  text-align: left;
  ${InputLabel} {
    margin: 0 0 5px 0;
  }
`;

const ButtonsRowContainer = styled.div`
  margin-top: 15px;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
`;

const ButtonContainer = styled.div`
  flex: 1 1 50%;
  button {
    width: 100%;
  }
`;

AdvisorAssignModal.propTypes = {
  hide: PropTypes.func,
  update: PropTypes.func,
  discovery: PropTypes.object,
  customer: PropTypes.object,
  updateError: PropTypes.string,
  updateSuccess: PropTypes.string,
};

export default AdvisorAssignModal;
