/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  AssetContainer,
  CashFlowContainer,
  InsuranceContainer,
  LiabilityContainer,
  MemberContainer,
  EntityContainer,
} from '../../containers';
import {
  ExpandableItem,
  ExpandedDetails,
  ExpandHeadingContainer,
  ExpandHeadingLabel,
  ExpandIcon,
  LabelIcon,
  LabelText,
} from '../../styles/library/sidebarStyles';
import {
  addEntity,
  blueCirclePlus,
  chevronDownDark,
  lightBlueCirclePlus,
  orangeCirclePlus,
  pinkCirclePlus,
  userAdd,
} from '../../assets';
import { SendTrackingEvent } from '../../utils';

const AssetMapCreateSidebar = ({
  householdId,
  currency,
  updatedInstrument,
  updatedMember,
  invocationId,
  expandedOption = null,
  showProposalMode,
  currentProposal,
}) => {
  //This is here to force the modals to reset after creation
  useEffect(() => {}, [updatedInstrument, updatedMember]);

  const options = [
    {
      value: 'income',
      name: 'Cash Flow',
      icon: lightBlueCirclePlus,
      isExpanded: false,
    },
    {
      value: 'asset',
      name: 'Asset',
      icon: pinkCirclePlus,
      isExpanded: false,
    },
    {
      value: 'liability',
      name: 'Liability',
      icon: orangeCirclePlus,
      isExpanded: false,
    },
    {
      value: 'insurance',
      name: 'Insurance',
      icon: blueCirclePlus,
      isExpanded: false,
    },
    {
      value: 'member',
      name: 'Member',
      icon: userAdd,
      isExpanded: false,
    },
    {
      value: 'entity',
      name: 'Entity',
      icon: addEntity,
      isExpanded: false,
    },
  ];
  const [createOptions, setCreateOptions] = useState(options);

  useEffect(() => {
    if (expandedOption) {
      onExpandOption(expandedOption);
    }
  }, [expandedOption]);

  const onExpandOption = (type) => {
    setCreateOptions(
      createOptions.map((option) => {
        if (option.value === type) {
          if (!option.isExpanded) {
            SendTrackingEvent(
              invocationId,
              'click',
              'assetmap_create_expand',
              'sidebar_amr',
              {
                create_type: type,
              }
            );
          }
          option.isExpanded = !option.isExpanded;
        } else {
          option.isExpanded = false;
        }
        return option;
      })
    );
  };

  return (
    <ModalsSidebarContainer>
      {createOptions.map((option) => {
        return (
          <ExpandableItem key={option.value}>
            <ExpandHeadingContainer
              onClick={() => onExpandOption(option.value)}
              $isExpanded={option.isExpanded}
              $isSticky={option.isExpanded}
            >
              <ExpandHeadingLabel>
                <LabelIcon
                  src={option.icon}
                  alt={option.value}
                  data-image={`add-${option.value}`}
                />
                <LabelText>{option.name}</LabelText>
              </ExpandHeadingLabel>
              <ExpandIcon $isExpanded={option.isExpanded}>
                <img src={chevronDownDark} alt="down" />
              </ExpandIcon>
            </ExpandHeadingContainer>
            {option.isExpanded && (
              <ExpandedDetails $padding="0">
                {option.value === 'income' ? (
                  <CashFlowContainer
                    isModal={false}
                    isNew={true}
                    householdId={householdId}
                    currency={currency}
                    key={updatedInstrument}
                    showProposalMode={showProposalMode}
                    proposalId={currentProposal?.id}
                  />
                ) : option.value === 'asset' ? (
                  <AssetContainer
                    isModal={false}
                    isNew={true}
                    householdId={householdId}
                    currency={currency}
                    key={updatedInstrument}
                    showProposalMode={showProposalMode}
                    proposalId={currentProposal?.id}
                  />
                ) : option.value === 'liability' ? (
                  <LiabilityContainer
                    isModal={false}
                    isNew={true}
                    householdId={householdId}
                    currency={currency}
                    key={updatedInstrument}
                    showProposalMode={showProposalMode}
                    proposalId={currentProposal?.id}
                  />
                ) : option.value === 'insurance' ? (
                  <InsuranceContainer
                    isModal={false}
                    isNew={true}
                    householdId={householdId}
                    currency={currency}
                    key={updatedInstrument}
                    showProposalMode={showProposalMode}
                    proposalId={currentProposal?.id}
                  />
                ) : option.value === 'member' ? (
                  <MemberContainer
                    isModal={false}
                    isNew={true}
                    householdId={householdId}
                    key={updatedMember}
                    showProposalMode={showProposalMode}
                    proposalId={currentProposal?.id}
                  />
                ) : option.value === 'entity' ? (
                  <EntityContainer
                    isModal={false}
                    isNew={true}
                    householdId={householdId}
                    key={updatedMember}
                    showProposalMode={showProposalMode}
                    proposalId={currentProposal?.id}
                  />
                ) : (
                  <>Not supported</>
                )}
              </ExpandedDetails>
            )}
          </ExpandableItem>
        );
      })}
    </ModalsSidebarContainer>
  );
};

const ModalsSidebarContainer = styled.div``;

export default AssetMapCreateSidebar;
