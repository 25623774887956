// import styled from 'styled-components';
import {
  // ModalButtons,
  Error,
  LoadingAnimation,
  GenerateInput,
  ProposalsTab,
  ModalButtons,
} from '..';
import {
  ExpandedDetails,
  ModalSidebarContainer,
  ToolbarContainer,
  SidebarMessageContainer,
  InputsContainer,
  InputsHeading,
} from '../../styles/library/sidebarStyles';
import { colors } from '../../styles/variables';
import {
  LoadingOverlay,
  LoadingOverlayContainer,
} from '../../styles/library/modalStyles';
import { ErrorThemes } from '../../styles/themes';

const LegalSidebar = ({
  isNew = false,
  loadedData,
  essentialInputs,
  notesInput,
  onSaveLegal,
  buttonLoading,
  onDeleteLegal,
  modalError,
  proposalContent,
}) => {
  const generateBottomPadding = () => {
    const hasError = modalError && modalError !== '';
    let height = 0;
    const errorHeight = 38;
    if (hasError) {
      height += errorHeight;
    }
    return `${height}px`;
  };

  //IF IS NEW WE DON'T WANT THE FIRST INPUT AND WE WANT TO SET THE VALUE TO THE CATEGORY!

  return (
    <ModalSidebarContainer $paddingBottom={generateBottomPadding()}>
      {!loadedData && !isNew && (
        <LoadingOverlayContainer>
          <LoadingOverlay>
            <LoadingAnimation />
          </LoadingOverlay>
        </LoadingOverlayContainer>
      )}
      <ExpandedDetails $padding="0">
        <InputsContainer $padding="15px">
          <InputsHeading>Essentials</InputsHeading>
          {essentialInputs?.map((input, index) => {
            return GenerateInput(input, index);
          })}
        </InputsContainer>
        <InputsContainer
          $padding="15px 15px 30px 15px"
          $background={colors.hoverLighterGrey}
          $showBorder={true}
        >
          <InputsHeading>Notes</InputsHeading>
          {notesInput?.map((input, index) => {
            return GenerateInput(input, index);
          })}
        </InputsContainer>
        {proposalContent && (
          <InputsContainer $padding="15px 15px 30px 15px">
            <ProposalsTab
              proposalContent={proposalContent}
              hasError={modalError && modalError !== ''}
              isConsumer={false}
            />
          </InputsContainer>
        )}
      </ExpandedDetails>
      {modalError && modalError !== '' && (
        <SidebarMessageContainer>
          <Error errorMessage={modalError} theme={ErrorThemes.sidebar} />
        </SidebarMessageContainer>
      )}
      <ToolbarContainer>
        <ModalButtons
          isNew={isNew}
          isSidebar={true}
          deleteFunction={onDeleteLegal}
          saveFunction={onSaveLegal}
          showLoading={buttonLoading}
          loadingText={'Saving'}
        />
      </ToolbarContainer>
    </ModalSidebarContainer>
  );
};

export default LegalSidebar;
