import { useRef } from 'react';
import ReactDOM from 'react-dom';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { colors, fonts } from '../../styles/variables';
import { timesWhite } from '../../assets';
import {
  DetermineSignalColor,
  GenerateSignalIcon,
  // GenerateRiskText,
  GenerateSignalColorIcon,
  signalDetails,
} from '../../utils';
import { toggleShowNewTargetMapModal } from '../../store/actions';
import {
  ModalBackground,
  ModalOverlay,
  ModalHeadingContainer,
  ModalHeading,
  CloseButton,
  SmallerModalContainer,
  ModalContentContainer,
} from '../../styles/library/modalStyles';

const SignalDetailsModal = ({ signal, hide }) => {
  const ref = useRef();
  const dispatch = useDispatch();

  return ReactDOM.createPortal(
    <>
      <ModalBackground />
      <ModalOverlay>
        <SmallerModalContainer role="dialog" ref={ref}>
          <ModalHeadingContainer>
            <ModalHeading>{signal?.label} Details</ModalHeading>
            <CloseButton
              src={timesWhite}
              onClick={() => hide()}
              alt="close"
              data-image="close"
            />
          </ModalHeadingContainer>
          <ModalContentContainer>
            <StatusContainer>
              <IconContainer
                style={{
                  border: `2px solid ${DetermineSignalColor(
                    signal?.state,
                    signal?.snoozed
                  )}`,
                }}
              >
                {GenerateSignalIcon(signal?.name, colors.darkGrey)}
                <ColorStatusContainer>
                  {GenerateSignalColorIcon(signal?.calculated, signal?.snoozed)}
                </ColorStatusContainer>
              </IconContainer>
              {/* <SignalStatus>
                {GenerateRiskText(signal?.calculated, true)}
              </SignalStatus> */}
            </StatusContainer>
            <SignalDetails>
              {signalDetails[signal?.name]?.details || 'Error: Missing Details'}
              <SignalAdditional>
                {' '}
                {signalDetails[signal?.name]?.additional}
              </SignalAdditional>
            </SignalDetails>
            <SignalConsiderations>
              <ConsiderationsHeading>Considerations</ConsiderationsHeading>
              <ConsiderationsList>
                {signalDetails[signal?.name]?.considerations?.map(
                  (consideration, index) => {
                    return (
                      <li key={index}>
                        {consideration?.targetMapType ? (
                          <ConsiderationLink
                            onClick={() => {
                              dispatch(
                                toggleShowNewTargetMapModal(
                                  true,
                                  consideration?.targetMapType
                                )
                              );
                            }}
                          >
                            {consideration?.text}
                          </ConsiderationLink>
                        ) : (
                          consideration?.text
                        )}
                      </li>
                    );
                  }
                )}
              </ConsiderationsList>
            </SignalConsiderations>
          </ModalContentContainer>
        </SmallerModalContainer>
      </ModalOverlay>
    </>,
    document.body
  );
};

const ConsiderationLink = styled.span`
  color: ${colors.lightBlue};
  cursor: pointer;
`;

const IconContainer = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 50px;
  width: 60px;
  height: 60px;
  flex: 0 0 auto;
  margin: 5px 0 15px 0;
`;

const ColorStatusContainer = styled.div`
  position: absolute;
  top: -4px;
  right: -9px;
`;

// const SignalStatus = styled.p`
//   font-size: 16px;
//   font-weight: ${fonts.semiBold};
//   text-align: center;
//   margin-bottom: 5px;
// `;

const StatusContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;
`;

const SignalDetails = styled.div`
  font-size: 12px;
  font-weight: ${fonts.semiBold}
  color: ${colors.darkGrey};
  text-align: left;
  margin: 0 0 10px 0;
`;

const SignalAdditional = styled.span`
  font-style: italic;
  font-weight: ${fonts.regular};
`;

const SignalConsiderations = styled.div`
  font-size: 11px;
  color: ${colors.darkGrey};
  text-align: left;
  margin: 25px 0 7px 0;
`;

const ConsiderationsHeading = styled.p`
  font-size: 12px;
  color: ${colors.darkGrey};
  font-weight: ${fonts.semiBold};
  margin-bottom: 5px;
`;

const ConsiderationsList = styled.ul`
  list-style-type: disc;
  padding-left: 20px;
  li {
    margin-bottom: 3px;
  }
`;

export default SignalDetailsModal;
