import {
  InstrumentTypes as INSTRUMENT,
  HouseholdTypes as HOUSEHOLD,
  ApiTypes as API,
} from '../actions';

const initialState = {
  showingCashFlowModal: false,
  isNewCashFlow: true,
  showingAssetModal: false,
  isNewAsset: true,
  showingLiabilityModal: false,
  isNewLiability: true,
  showingInsuranceModal: false,
  isNewInsurance: true,
  clonedInstrument: false,
};

const closeAllOtherModals = (field, show) => {
  const newState = {};
  for (const [key] of Object.entries(initialState)) {
    let newValue = false;
    if (field === key) {
      newValue = show;
    }
    newState[key] = newValue;
  }
  return newState;
};

const instruments = (state = initialState, action) => {
  switch (action.type) {
    case INSTRUMENT.UPDATE_INSTRUMENT_STORE_VALUE:
      return {
        ...state,
        [action.payload.fieldName]: action.payload.value,
      };
    case INSTRUMENT.CLOSE_ALL_INSTRUMENT_MODALS:
      return {
        ...state,
        ...initialState,
      };
    case INSTRUMENT.GET_INSTRUMENT_INFO:
      return {
        ...state,
        instrument: action.payload,
        instrumentError: null,
        instrumentIntegrationError: null,
        loadedInstrumentFromAPI: true,
      };
    case INSTRUMENT.CLEAR_INSTRUMENT_INFO:
      return {
        ...state,
        instrument: null,
        instrumentError: null,
        instrumentIntegrationError: null,
        loadedInstrumentFromAPI: null,
        clearedInstrumentInfo: true,
      };
    case HOUSEHOLD.GET_PROPOSAL_ITEM_INFO:
      return {
        ...state,
        instrument: action.payload,
        instrumentError: null,
        loadedInstrumentFromAPI: true,
      };
    case INSTRUMENT.CLONE_INSTRUMENT:
      return {
        ...state,
        instrument: action.payload?.instrument,
        instrumentAction: 'clone',
        clonedInstrument: true,
        updatedInstrument: action.payload?.instrument,
        instrumentActionId: action.payload?.instrument?.id,
        instrumentFromSidebar: action.payload?.isSidebar,
      };
    case INSTRUMENT.GET_INSTRUMENT_INTEGRATION_INFO:
      return {
        ...state,
        instrumentIntegration: action.payload,
        instrumentIntegrationError: null,
      };
    case INSTRUMENT.TOGGLE_SHOW_CASH_FLOW_MODAL:
      return {
        ...state,
        ...closeAllOtherModals(
          'showingCashFlowModal',
          action.payload.showModal
        ),
        showingCashFlowModal: action.payload.showModal,
        isNewCashFlow: action.payload.newInstrument,
        cashFlowDefaultTab: action.payload.defaultTab,
        instrumentError: null,
        loadedInstrumentFromAPI: action.payload.newInstrument,
      };
    case INSTRUMENT.TOGGLE_SHOW_ASSET_MODAL:
      return {
        ...state,
        ...closeAllOtherModals('showingAssetModal', action.payload.showModal),
        showingAssetModal: action.payload.showModal,
        isNewAsset: action.payload.newInstrument,
        assetDefaultTab: action.payload.defaultTab,
        instrumentError: null,
        loadedInstrumentFromAPI: action.payload.newInstrument,
      };
    case INSTRUMENT.TOGGLE_SHOW_LIABILITY_MODAL:
      return {
        ...state,
        ...closeAllOtherModals(
          'showingLiabilityModal',
          action.payload.showModal
        ),
        showingLiabilityModal: action.payload.showModal,
        isNewLiability: action.payload.newInstrument,
        liabilityDefaultTab: action.payload.defaultTab,
        instrumentError: null,
        loadedInstrumentFromAPI: action.payload.newInstrument,
      };
    case INSTRUMENT.TOGGLE_SHOW_INSURANCE_MODAL:
      return {
        ...state,
        ...closeAllOtherModals(
          'showingInsuranceModal',
          action.payload.showModal
        ),
        showingInsuranceModal: action.payload.showModal,
        isNewInsurance: action.payload.newInstrument,
        insuranceDefaultTab: action.payload.defaultTab,
        instrumentError: null,
        loadedInstrumentFromAPI: action.payload.newInstrument,
      };
    case INSTRUMENT.LOAD_INSTRUMENT_FOLDER:
      return {
        ...state,
        instrumentIntegrationError: null,
      };
    case HOUSEHOLD.GET_HOUSEHOLD_INFO:
      return {
        ...state,
        modifiedInstrument: false,
        updatedInstrument: false,
        instrumentAction: null,
        instrumentId: null,
        clonedInstrument: false,
      };
    case HOUSEHOLD.GET_PROPOSAL_HOUSEHOLD_INFO:
      return {
        ...state,
        modifiedInstrument: false,
        instrumentAction: null,
        instrumentId: null,
        clonedInstrument: false,
      };
    case HOUSEHOLD.REMOVE_HOUSEHOLD_INFO:
      return {
        ...state,
        showingCashFlowModal: false,
        isNewCashFlow: true,
        showingAssetModal: false,
        isNewAsset: true,
        showingLiabilityModal: false,
        isNewLiability: true,
        showingInsuranceModal: false,
        isNewInsurance: true,
        clonedInstrument: false,
        modifiedInstrument: false,
        instrumentAction: null,
        instrumentId: null,
        instrumentError: null,
        loadedInstrumentFromAPI: null,
        instrument: null,
        instrumentIntegrationError: null,
        updatedInstrument: false,
        instrumentIntegration: null,
      };
    case API.API_SUCCESS:
      switch (action.payload.label) {
        case INSTRUMENT.GET_INSTRUMENT_INFO:
          return {
            ...state,
            instrumentAction: 'read',
            instrumentId: action?.payload?.data?.id,
          };
        case INSTRUMENT.CREATE_INSTRUMENT:
        case HOUSEHOLD.CREATE_PROPOSAL_ITEM:
          return {
            ...state,
            instrumentAction: 'create',
            showingCashFlowModal: false,
            showingAssetModal: false,
            showingLiabilityModal: false,
            showingInsuranceModal: false,
            instrumentId: action?.payload?.data?.id,
            updatedInstrument: action?.payload?.data,
            instrumentFromSidebar: action?.payload?.data?.passSubId,
          };
        case INSTRUMENT.UPDATE_INSTRUMENT_INFO:
          return {
            ...state,
            instrumentAction: 'update',
            showingCashFlowModal: false,
            showingAssetModal: false,
            showingLiabilityModal: false,
            showingInsuranceModal: false,
            instrumentId: action?.payload?.data?.id,
            updatedInstrument: action?.payload?.data,
            instrumentFromSidebar: action?.payload?.data?.passSubId,
          };
        case HOUSEHOLD.UPDATE_PROPOSAL_ITEM_INFO:
        case HOUSEHOLD.TAKE_PROPOSAL_ACTION:
          return {
            ...state,
            showingCashFlowModal: false,
            showingAssetModal: false,
            showingLiabilityModal: false,
            showingInsuranceModal: false,
          };
        case INSTRUMENT.DELETE_INSTRUMENT:
          return {
            ...state,
            instrumentAction: 'delete',
            showingCashFlowModal: false,
            showingAssetModal: false,
            showingLiabilityModal: false,
            showingInsuranceModal: false,
            instrumentId: action?.payload?.data?.passSubId,
            updatedInstrument: action?.payload?.data,
            instrumentFromSidebar: action?.payload?.data?.passThirdParam,
          };
        case HOUSEHOLD.DELETE_PROPOSAL_ITEM:
          return {
            ...state,
            showingCashFlowModal: false,
            showingAssetModal: false,
            showingLiabilityModal: false,
            showingInsuranceModal: false,
          };
        case INSTRUMENT.LINK_INSTRUMENT_FOLDER:
          return {
            ...state,
            instrumentIntegrationError: null,
          };
        default:
          return {
            ...state,
          };
      }
    case API.API_START:
      switch (action.payload) {
        case HOUSEHOLD.TAKE_PROPOSAL_ACTION:
          return {
            ...state,
            instrumentError: false,
          };
        default:
          return { ...state };
      }
    case API.API_END:
      switch (action.payload) {
        case INSTRUMENT.CREATE_INSTRUMENT:
        case INSTRUMENT.UPDATE_INSTRUMENT_INFO:
        case INSTRUMENT.DELETE_INSTRUMENT:
          return {
            ...state,
            modifiedInstrument: true,
          };
        default:
          return {
            ...state,
          };
      }
    case API.API_ERROR:
      switch (action.payload.label) {
        case INSTRUMENT.CREATE_INSTRUMENT:
        case HOUSEHOLD.CREATE_PROPOSAL_ITEM:
        case INSTRUMENT.UPDATE_INSTRUMENT_INFO:
        case HOUSEHOLD.UPDATE_PROPOSAL_ITEM_INFO:
        case HOUSEHOLD.TAKE_PROPOSAL_ACTION:
        case INSTRUMENT.GET_INSTRUMENT_INFO:
          return {
            ...state,
            instrumentError: action.payload.error,
          };
        case INSTRUMENT.GET_INSTRUMENT_INTEGRATION_INFO:
        case INSTRUMENT.LINK_INSTRUMENT_FOLDER:
          return {
            ...state,
            instrumentIntegrationError: action.payload,
          };
        default:
          return state;
      }
    default:
      return state;
  }
};

export default instruments;
