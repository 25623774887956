/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Button, FormChunk, DropZone, SimpleModal } from '../../components';
import {
  updateTeamSettings,
  deleteTeamLogo,
  setOrgLogo,
  getUserInfo,
  updateUserStoreValue,
} from '../../store/actions';
import {
  colors,
  maxDevice,
  messageColors,
  fonts,
} from '../../styles/variables';

const ManagerAccountContainer = ({ customerId }) => {
  const dispatch = useDispatch();
  const {
    user,
    teamSettings,
    orgLogo,
    loadedConfigs,
    autoLogoutChoices,
    customerSettingsUpdated,
    customerSettingsError,
    showMFASettings,
    hasBranding,
  } = useSelector((state) => ({
    user: state.user.user,
    teamSettings: state.user.teamSettings,
    orgLogo: state.user.orgLogo,
    loadedConfigs: state.configs.loadedConfigs,
    autoLogoutChoices: state.configs.autoLogoutChoices,
    customerSettingsUpdated: state.user.customerSettingsUpdated,
    customerSettingsError: state.user.customerSettingsError,
    showMFASettings: state.user.showMFASettings,
    hasBranding: state.user.hasBranding,
  }));
  const [accountSettings, setAccountSettings] = useState({
    name: '',
    auto_logout_seconds: null,
    feature_branding_custom_org_name_setting: false,
    is_mfa_enabled: false,
    can_set_subtitle: false,
    can_set_rounding: false,
    feature_customer_mfa_setting: '',
  });
  const [loadedSettings, setLoadedSettings] = useState(false);
  const [avatarSubtitle, setAvatarSubtitle] = useState();
  const [autoLogoutTime, setAutoLogoutTime] = useState();
  const [autoLogoutOptions, setAutoLogoutOptions] = useState([]);
  const [instrumentRounding, setInstrumentRounding] = useState();
  const [disclosure, setDisclosure] = useState('');
  const [disclaimer, setDisclaimer] = useState('');
  const [organizationLogo, setOrganizationLogo] = useState();
  const [loadingOrgLogo, setLoadingOrgLogo] = useState(false);
  const [loadingOrgDeleteLogo, setLoadingDeleteOrgLogo] = useState(false);
  const [clearOrgLogo, setClearOrgLogo] = useState(false);
  const [managerHasMFA, setManagerHasMFA] = useState(false);
  const [showingMFAWarning, setShowingMFAWarning] = useState(false);
  const [errorFields, setErrorFields] = useState([]);
  const [errorMessages, setErrorMessages] = useState([]);
  const selectOptions = {
    avatarAge: [
      { value: 'default', label: 'Default' },
      { value: 'none', label: 'None' },
      { value: 'age', label: 'Age' },
      { value: 'date', label: 'Date of Birth' },
    ],
    instrumentRounding: [
      { value: 'default', label: 'Default' },
      { value: 'none', label: 'None' },
      { value: 'ten', label: 'Ten' },
      { value: 'hundred', label: 'Hundred' },
      { value: 'thousand', label: 'Thousand' },
    ],
  };

  const findMatchingSelect = (list, value) => {
    let selectedOption = selectOptions[list].filter((option) => {
      return option.value === value;
    });
    if (selectedOption.length === 1) {
      return selectedOption[0];
    } else {
      return null;
    }
  };

  useEffect(() => {
    if (user) {
      setManagerHasMFA(user?.is_mfa_enabled);
    }
  }, [user]);

  useEffect(() => {
    if (customerSettingsError && customerSettingsError.hasOwnProperty('data')) {
      let errors = [];

      for (const [key, value] of Object.entries(customerSettingsError.data)) {
        errors.push({ field: key, message: value });
      }
      setErrorFields(errors);
      const errorMessages = errors.map((error) => {
        let fieldName = error.field.replaceAll('_', ' ');
        return `${fieldName}: ${error.message}`;
      });
      setErrorMessages(errorMessages);
    }
  }, [customerSettingsError]);

  useEffect(() => {
    if (customerSettingsUpdated) {
      dispatch(getUserInfo());
      dispatch(updateUserStoreValue('customerSettingsUpdated', false));
    }
  }, [customerSettingsUpdated]);

  useEffect(() => {
    if (teamSettings && loadedConfigs) {
      if (teamSettings.avatar_subtitle) {
        let matchedAge = findMatchingSelect(
          'avatarAge',
          teamSettings.avatar_subtitle
        );
        setAvatarSubtitle(matchedAge);
      }
      if (teamSettings.instrument_rounding) {
        let matchedRounding = findMatchingSelect(
          'instrumentRounding',
          teamSettings.instrument_rounding
        );
        setInstrumentRounding(matchedRounding);
      }
      if (teamSettings.disclosure) {
        setDisclosure(teamSettings.disclosure);
      } else {
        setDisclosure(teamSettings?.default_texts?.disclosure);
      }
      if (teamSettings.disclaimer) {
        setDisclaimer(teamSettings.disclaimer);
      } else {
        setDisclaimer(teamSettings?.default_texts?.disclaimer);
      }
      if (teamSettings.logo) {
        setOrganizationLogo(teamSettings.logo);
      }
      if (autoLogoutChoices && autoLogoutChoices.length > 0) {
        setAutoLogoutOptions(autoLogoutChoices);
        const matchedLogout = autoLogoutChoices.find(
          (option) => option?.value === teamSettings?.auto_logout_seconds
        );
        setAutoLogoutTime(matchedLogout);
      }
      setLoadingOrgLogo(false);
      setLoadingDeleteOrgLogo(false);
      setClearOrgLogo(true);
      setAccountSettings({
        ...accountSettings,
        auto_logout_seconds: teamSettings?.auto_logout_seconds,
        name: teamSettings.name,
        feature_branding_custom_org_name_setting:
          teamSettings.feature_branding_custom_org_name_setting === 'selected',
        is_mfa_enabled:
          teamSettings.feature_customer_mfa_setting === 'selected',
        can_set_subtitle: teamSettings.can_set_subtitle,
        can_set_rounding: teamSettings.can_set_rounding,
        consent_label: teamSettings?.discovery_consent?.label || '',
        feature_customer_mfa_setting: teamSettings.feature_customer_mfa_setting,
      });
      setLoadedSettings(true);
    }
  }, [teamSettings, loadedConfigs]);

  useEffect(() => {
    if (orgLogo) {
      setOrganizationLogo(orgLogo);
      setClearOrgLogo(false);
    }
  }, [orgLogo]);

  const updateToggle = (e) => {
    if (e.name === 'is_mfa_enabled' && !managerHasMFA && !e.value) {
      return setShowingMFAWarning(true);
    }
    setAccountSettings({
      ...accountSettings,
      [e.name]: !e.value,
    });
  };

  const accountInputs = [
    {
      name: 'name',
      placeholder: 'Organization Name',
      type: 'text',
      width: '100%',
      required: true,
    },
    {
      name: 'feature_branding_custom_org_name_setting',
      theme: {},
      type: 'toggle',
      width: '100%',
      text: 'Team Members can edit organization name',
      onClick: updateToggle,
    },
    {
      name: 'auto_logout_seconds',
      placeholder: 'Auto Logout Time Limit',
      tooltip:
        'Users will be automatically logged out after this many minutes of inactivity. Users will receive a 2 minute warning with the ability to extend their activity before they are logged out.',
      type: 'select',
      width: 'calc(100% - 16px)',
      margin: '15px 8px 5px 8px',
      selectedOption: autoLogoutTime,
      onChange: (e) => setAutoLogoutTime(e),
      options: autoLogoutOptions,
    },
  ];

  const securityInputs = [
    {
      name: 'is_mfa_enabled',
      theme: {},
      type: 'toggle',
      width: '100%',
      text: 'Require MFA (Multi-Factor Authentication) for all users.',
      onClick: updateToggle,
    },
  ];

  const reportInputs = [
    {
      name: 'avatarSubtitle',
      placeholder: 'Avatar Subtitle',
      type: 'select',
      width: 'calc(50% - 16px)',
      margin: '0 8px 10px 8px',
      selectedOption: avatarSubtitle,
      onChange: (e) => setAvatarSubtitle(e),
      options: selectOptions.avatarAge,
    },
    {
      name: 'instrumentRounding',
      placeholder: 'Instrument Rounding',
      type: 'select',
      width: 'calc(50% - 16px)',
      margin: '0 8px 10px 8px',
      selectedOption: instrumentRounding,
      onChange: (e) => setInstrumentRounding(e),
      options: selectOptions.instrumentRounding,
    },
    {
      name: 'can_set_subtitle',
      theme: {},
      type: 'toggle',
      width: 'calc(50% - 16px)',
      text: 'Team Members can set subtitle',
      onClick: updateToggle,
      margin: '0 8px',
    },
    {
      name: 'can_set_rounding',
      theme: {},
      type: 'toggle',
      width: 'calc(50% - 16px)',
      text: 'Team Members can set rounding',
      onClick: updateToggle,
      margin: '0 8px',
    },
  ];

  let disclosureInput = [];
  if (loadedSettings) {
    disclosureInput = [
      {
        name: 'disclosure',
        placeholder: 'Report Disclosure',
        type: 'textarea',
        editor: true,
        showClear: true,
        fullToolbar: false,
        showUseDefault: true,
        defaultText: teamSettings?.default_texts?.disclosure,
        id: 'disclosure',
        width: '100%',
        value: disclosure,
        onChange: (e) => setDisclosure(e),
      },
    ];
  }

  let disclaimerInput = [];
  if (loadedSettings) {
    disclaimerInput = [
      {
        name: 'disclaimer',
        placeholder: 'Report Disclaimer',
        type: 'textarea',
        editor: true,
        showClear: true,
        fullToolbar: false,
        showUseDefault: true,
        defaultText: teamSettings?.default_texts?.disclaimer,
        id: 'disclaimer',
        width: '100%',
        value: disclaimer,
        onChange: (e) => setDisclaimer(e),
      },
    ];
  }

  const updateSettingsValues = (e) => {
    setAccountSettings({
      ...accountSettings,
      [e.currentTarget.name]: e.currentTarget.value,
    });
  };

  const updateInfo = (type) => {
    // if (!managerHasMFA && accountSettings.is_mfa_enabled) {
    //   return setShowingMFAWarning(true);
    // }
    let mfaValue = 'available';
    if (accountSettings.is_mfa_enabled) {
      mfaValue = 'selected';
    }
    let customOrgName = 'available';
    if (accountSettings?.feature_branding_custom_org_name_setting) {
      customOrgName = 'selected';
    }
    const obj = {
      ...accountSettings,
      auto_logout_seconds: autoLogoutTime.value,
      feature_branding_custom_org_name_setting: customOrgName,
      feature_customer_mfa_setting: mfaValue,
      avatar_subtitle: avatarSubtitle.value,
      instrument_rounding: instrumentRounding.value,
      disclosure,
      disclaimer,
    };
    dispatch(updateTeamSettings(customerId, obj));
    setErrorFields([]);
    setErrorMessages([]);
  };

  const updateOrganizationLogo = () => {
    if (orgLogo) {
      if (organizationLogo) {
        setLoadingOrgLogo(true);
        const obj = {
          ...accountSettings,
          avatar_subtitle: avatarSubtitle.value,
          instrument_rounding: instrumentRounding.value,
          disclosure,
          disclaimer,
          logo: organizationLogo,
          is_mfa_enabled: accountSettings.is_mfa_enabled,
          feature_customer_mfa_setting:
            accountSettings.feature_customer_mfa_setting,
          feature_branding_custom_org_name_setting:
            accountSettings.feature_branding_custom_org_name_setting
              ? 'selected'
              : 'available',
        };
        dispatch(updateTeamSettings(customerId, obj, true));
      }
    }
  };

  const deleteOrganizationLogo = () => {
    if (organizationLogo) {
      setLoadingDeleteOrgLogo(true);
      dispatch(deleteTeamLogo(customerId));
      setOrganizationLogo(null);
      setClearOrgLogo(true);
    }
  };

  const accountItems = [
    {
      header: 'Account Settings',
      value: accountSettings,
      inputs: accountInputs,
      onChange: updateSettingsValues,
    },
    {
      header: 'Security Settings',
      value: accountSettings,
      inputs: securityInputs,
      onChange: updateSettingsValues,
      hidden: !showMFASettings,
    },
    // {
    //   header: 'Discovery Redirect',
    //   formText:
    //     'What URL you would like users to be redirected to after completing their Discovery Interview? Keep this field blank if you want to keep the Asset-Map default.',
    //   value: accountSettings,
    //   inputs: discoveryRedirectInputs,
    //   onChange: updateSettingsValues,
    // },
    {
      header: 'Asset-Map Report Settings',
      formText:
        'Set the value to display below the user avatar and whether the instruments should be rounded.',
      value: accountSettings,
      inputs: reportInputs,
    },
    {
      type: 'textarea',
      header: 'Report Disclosure',
      inputs: disclosureInput,
      borderBottom: false,
    },
    // {
    //   type: 'empty',
    //   borderBottom: false,
    // },
    {
      type: 'textarea',
      header: 'Report Disclaimer',
      inputs: disclaimerInput,
      borderBottom: false,
    },
  ];

  const generateMFAWarningContent = () => {
    return {
      heading: 'Cannot Require MFA',
      text: `MFA cannot be required for all users until you have have set it up for yourself.`,
      internalLink: {
        plainText: 'You can enable MFA for your account ',
        link: '/member/preferences?panel=security',
        linkText: 'here',
        margin: '0 0 5px 0',
      },
    };
  };

  return (
    <>
      {showingMFAWarning && (
        <SimpleModal
          hide={() => setShowingMFAWarning(!showingMFAWarning)}
          content={generateMFAWarningContent()}
        />
      )}
      <ContainerLayout>
        <LeftContent>
          {accountItems
            .filter((item) => !item.hidden)
            .map((form, index) => {
              return (
                <FormChunk
                  key={index}
                  type={form.type}
                  header={form.header}
                  formText={form.formText}
                  value={form.value}
                  inputs={form.inputs}
                  onChange={form.onChange}
                  button={form.button}
                  hasErrorDisplay={form.hasErrorDisplay}
                  errorFields={
                    form.errorFields ? form.errorFields : errorFields
                  }
                  errorMessages={form.errorMessages}
                  borderBottom={form.borderBottom}
                />
              );
            })}
          <ButtonContainer>
            <Button text={'Update'} onClick={updateInfo} />
          </ButtonContainer>
          {errorMessages && errorMessages.length !== 0 && (
            <ErrorMessageContainer>
              {errorMessages.map((error, index) => {
                return <ErrorMessage key={index}>{error}</ErrorMessage>;
              })}
            </ErrorMessageContainer>
          )}
        </LeftContent>
        <RightContent>
          <FormContainer>
            <FormHeader>Organization Logo</FormHeader>
            <ProfilePictureContainer>
              <UploadContainer>
                <DropZone
                  dispatchFunction={setOrgLogo}
                  clearFiles={clearOrgLogo}
                  additionalText={
                    hasBranding ? 'Recommended Aspect Ratio: 5.45:1' : null
                  }
                />
              </UploadContainer>
              <ModifyContainer>
                <PreviewContainer>
                  {organizationLogo ? (
                    <LogoPreview
                      src={organizationLogo}
                      alt="logo"
                      data-image="organization-logo"
                    />
                  ) : (
                    <LogoPlaceholder>Preview</LogoPlaceholder>
                  )}
                  <ButtonColumn>
                    <Button
                      text="Save Upload"
                      onClick={() => updateOrganizationLogo()}
                      showLoading={loadingOrgLogo}
                      loadingText={'Updating'}
                    />
                    <Button
                      text="Remove Logo"
                      onClick={() => deleteOrganizationLogo()}
                      showLoading={loadingOrgDeleteLogo}
                      loadingText={'Deleting'}
                    />
                  </ButtonColumn>
                </PreviewContainer>
              </ModifyContainer>
            </ProfilePictureContainer>
          </FormContainer>
        </RightContent>
      </ContainerLayout>
    </>
  );
};

const ProfilePictureContainer = styled.div`
  display: flex;
  flex-wrap: wrap-reverse;
  align-content: center;
  align-items: flex-start;
  justify-content: center;
  margin-bottom: 10px;
`;

const UploadContainer = styled.div`
  @media ${maxDevice.sideNav} {
    border-right: none;
    justify-content: center;
    width: 100%;
  }
  flex: 1 1 auto;
  width: 250px;
  display: flex;
  align-content: center;
  align-items: flex-start;
  justify-content: flex-start;
  border-right: 1px solid ${colors.lightGrey};
`;

const ModifyContainer = styled.div`
  @media ${maxDevice.sideNav} {
    border-right: none;
    margin-bottom: 40px;
  }
  flex: 1 1 auto;
  width: 200px;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
`;

const PreviewContainer = styled.div`
  @media ${maxDevice.sideNav} {
    border-right: none;
    margin-left: 0px;
  }
  margin-left: 30px;
  padding: 0 0 20px 0;
  height: 220px;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;
`;

const LogoPreview = styled.img`
  width: 100%;
  max-height: 150px;
  max-width: 275px;
  margin-bottom: 20px;
`;

const LogoPlaceholder = styled.div`
  height: 150px;
  width: 150px;
  margin-bottom: 30px;
  background: white;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  border: 1px solid ${colors.lightGrey};
  color: ${colors.paleGrey};
`;

const ButtonColumn = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;
  button {
    width: 150px;
    margin-bottom: 8px;
    background: ${colors.paleGrey};
  }
`;

const ContainerLayout = styled.div`
  display: flex;
  flex-wrap: wrap-reverse;
  align-content: center;
  align-items: flex-end;
  justify-content: center;
  margin: -25px 0 0 0;
  background: ${colors.lighterGrey};
`;

const FormContainer = styled.div`
  padding-bottom: 25px;
  margin-bottom: 20px;
  border-bottom: 1px solid ${colors.lightGrey};
`;

const FormHeader = styled.h3`
  font-weight: ${fonts.semiBold};
  margin-bottom: 20px;
`;

const LeftContent = styled.div`
  @media ${maxDevice.laptopL} {
    max-width: 50%;
  }
  @media ${maxDevice.laptopAlt} {
    width: 100%;
    max-width: 100%;
  }
  @media ${maxDevice.sideNav} {
    padding: 20px 20px;
    overflow-x: hidden;
  }
  flex: 1 1 auto;
  max-width: 50%;
  background: white;
  padding: 30px 40px 100px 40px;
`;

const RightContent = styled.div`
  @media ${maxDevice.laptop} {
    max-width: 50%;
  }
  @media ${maxDevice.laptopAlt} {
    width: 100%;
    max-width: 100%;
    ${FormContainer} {
      border: none;
      padding-bottom: 0;
    }
  }
  @media ${maxDevice.sideNav} {
    padding: 20px;
    ${FormContainer} {
      border: none;
      padding-bottom: 0;
    }
  }
  flex: 1 1 auto;
  max-width: 50%;
  padding: 30px 20px 20px 40px;
  /* position: relative; */
`;

const ButtonContainer = styled.div`
  width: 100%;
  margin-top: 5px;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-end;
  button {
    width: 130px;
  }
`;

const ErrorMessageContainer = styled.div`
  margin-top: 15px;
  margin-bottom: -5px;
  font-size: 13px;
  text-align: right;
  color: ${messageColors.error};
`;

const ErrorMessage = styled.p`
  &::first-letter {
    text-transform: capitalize;
  }
`;

ManagerAccountContainer.propTypes = {
  customerId: PropTypes.string,
};

export default ManagerAccountContainer;
