import {
  MemberTypes as MEMBER,
  HouseholdTypes as HOUSEHOLD,
  InstrumentTypes as INSTRUMENT,
  ApiTypes as API,
} from '../actions';

const initialState = {
  showingMemberModal: false,
  showingProfessionalModal: false,
  showingEntityModal: false,
  isNewMember: true,
  isNewEntity: true,
  isPersonal: false,
  isExcluded: false,
  isNewProfessional: true,
};

const members = (state = initialState, action) => {
  switch (action.type) {
    case MEMBER.UPDATE_MEMBER_STORE_VALUE:
      return {
        ...state,
        [action.payload.fieldName]: action.payload.value,
      };
    case MEMBER.CLOSE_ALL_MEMBER_MODALS:
      return {
        ...state,
        ...initialState,
      };
    case MEMBER.GET_MEMBER_INFO:
      return {
        ...state,
        member: action.payload,
        memberError: null,
        memberIntegrationError: null,
        loadedMemberFromAPI: true,
      };
    case MEMBER.GET_PROFESSIONAL_INFO:
      return {
        ...state,
        professional: action.payload,
        professionalError: null,
        loadedMemberFromAPI: true,
      };
    case MEMBER.GET_PRIMARY_MEMBER:
      return {
        ...state,
        primaryMember: action.payload,
      };
    case MEMBER.CLEAR_MEMBER_INFO:
      return {
        ...state,
        member: null,
        memberError: null,
        memberIntegrationError: null,
        loadedMemberFromAPI: null,
        clearedMemberInfo: true,
      };
    case HOUSEHOLD.GET_PROPOSAL_ITEM_INFO:
      return {
        ...state,
        member: action.payload,
        memberError: null,
        loadedMemberFromAPI: true,
      };
    case MEMBER.GET_MEMBER_INTEGRATION_INFO:
      return {
        ...state,
        memberIntegration: action.payload,
        memberIntegrationError: null,
      };
    case MEMBER.SEARCH_MEMBER_ACTIVITY:
      return {
        ...state,
        activitySearchResults: action.payload,
      };
    case MEMBER.TOGGLE_SHOW_MEMBER_MODAL:
      return {
        ...state,
        showingMemberModal: action.payload.showModal,
        isNewMember: action.payload.newMember,
        memberDefaultTab: action.payload.defaultTab,
        isPersonal: action.payload.isPersonal,
        memberError: null,
        loadedMemberFromAPI: action.payload.newMember,
      };
    case MEMBER.TOGGLE_SHOW_ENTITY_MODAL:
      return {
        ...state,
        showingEntityModal: action.payload.showModal,
        isNewEntity: action.payload.newEntity,
        entityDefaultTab: action.payload.defaultTab,
        isExcluded: action.payload.isExcluded,
        memberError: null,
        loadedMemberFromAPI: action.payload.newEntity,
      };
    case MEMBER.TOGGLE_SHOW_PROFESSIONAL_MODAL:
      return {
        ...state,
        showingProfessionalModal: action.payload.showModal,
        isNewProfessional: action.payload.newProfessional,
        professionalDefaultTab: action.payload.defaultTab,
        professionalError: null,
        loadedMemberFromAPI: action.payload.newProfessional,
      };
    case MEMBER.TOGGLE_SHOW_ADVISOR_MODAL:
      return {
        ...state,
        showingAdvisorModal: action.payload.showModal,
        isNewAdvisor: action.payload.newAdvisor,
      };
    case MEMBER.LOAD_MEMBER_FOLDER:
    case INSTRUMENT.LOAD_INSTRUMENT_FOLDER:
      return {
        ...state,
        integrationTabFolder: action.payload,
        integrationTabFolderError: null,
      };
    case HOUSEHOLD.GET_HOUSEHOLD_INFO:
      return {
        ...state,
        showingAdvisorModal: false,
        memberAction: null,
        memberId: null,
        updatedMember: null,
        professional: null,
        professionalId: null,
        updatedProfessional: null,
      };
    case HOUSEHOLD.GET_PROPOSAL_HOUSEHOLD_INFO:
      return {
        ...state,
        showingAdvisorModal: false,
        memberAction: null,
        memberId: null,
        professional: null,
        professionalId: null,
        updatedProfessional: null,
      };
    case HOUSEHOLD.REMOVE_HOUSEHOLD_INFO:
      return {
        ...state,
        showingMemberModal: false,
        isNewMember: true,
        isPersonal: false,
        isExcluded: false,
        showingProfessionalModal: false,
        isNewProfessional: true,
        showingEntityModal: false,
        isNewEntity: true,
        showingAdvisorModal: false,
        memberAction: null,
        memberId: null,
        updatedMember: null,
        professional: null,
        professionalId: null,
        updatedProfessional: null,
        integrationTabFolder: null,
        integrationTabFolderError: null,
        isNewAdvisor: false,
        member: null,
        memberError: null,
        memberIntegrationError: null,
        loadedMemberFromAPI: null,
        primaryMember: null,
        memberIntegration: null,
        activitySearchResults: null,
      };
    case API.API_SUCCESS:
      switch (action.payload.label) {
        case MEMBER.GET_MEMBER_INFO:
          return {
            ...state,
            memberAction: 'read',
            memberId: action?.payload?.data?.id,
            memberUuid: action?.payload?.data?.uuid,
          };
        case MEMBER.GET_PROFESSIONAL_INFO:
          return {
            ...state,
            professionalAction: 'read',
            professionalId: action?.payload?.data?.id,
          };
        case MEMBER.CREATE_MEMBER:
        case HOUSEHOLD.CREATE_PROPOSAL_ITEM:
          return {
            ...state,
            memberAction: 'create',
            showingMemberModal: false,
            showingEntityModal: false,
            memberId: action?.payload?.data?.id,
            updatedMember: action?.payload?.data,
            memberFromSidebar: action?.payload?.data?.passSubId,
          };
        case MEMBER.CREATE_PROFESSIONAL:
          return {
            ...state,
            professionalAction: 'create',
            showingProfessionalModal: false,
            professionalId: action?.payload?.data?.id,
            updatedProfessional: action?.payload?.data,
          };
        case MEMBER.UPDATE_MEMBER_INFO:
          return {
            ...state,
            memberAction: 'update',
            showingMemberModal: false,
            showingEntityModal: false,
            memberId: action?.payload?.data?.id,
            updatedMember: action?.payload?.data,
            memberFromSidebar: action?.payload?.data?.passSubId,
          };
        case HOUSEHOLD.UPDATE_PROPOSAL_ITEM_INFO:
        case HOUSEHOLD.TAKE_PROPOSAL_ACTION:
        case HOUSEHOLD.DELETE_PROPOSAL_ITEM:
          return {
            ...state,
            showingMemberModal: false,
            showingEntityModal: false,
          };
        case MEMBER.UPDATE_PROFESSIONAL_INFO:
          return {
            ...state,
            professionalAction: 'update',
            showingProfessionalModal: false,
            professionalId: action?.payload?.data?.id,
            updatedProfessional: action?.payload?.data,
          };
        case MEMBER.DELETE_MEMBER:
          return {
            ...state,
            memberAction: 'delete',
            showingMemberModal: false,
            showingEntityModal: false,
            memberId: action?.payload?.data?.passSubId,
            updatedMember: action?.payload?.data,
            memberFromSidebar: action?.payload?.data?.passThirdParam,
          };
        case MEMBER.DELETE_PROFESSIONAL:
          return {
            ...state,
            professionalAction: 'delete',
            showingProfessionalModal: false,
            professionalId: action?.payload?.data?.passSubId,
            updatedProfessional: action?.payload?.data,
          };
        case HOUSEHOLD.UNLINK_SALESFORCE_MEMBER:
          return {
            ...state,
            showingMemberModal: false,
            showingEntityModal: false,
          };
        case MEMBER.LINK_MEMBER_FOLDER:
        case INSTRUMENT.LINK_INSTRUMENT_FOLDER:
          return {
            ...state,
            memberIntegrationError: null,
            integrationTabFolderError: null,
          };
        default:
          return {
            ...state,
          };
      }
    case API.API_START:
      switch (action.payload) {
        case HOUSEHOLD.TAKE_PROPOSAL_ACTION:
          return {
            ...state,
            memberError: false,
          };
        default:
          return { ...state };
      }
    case API.API_ERROR:
      switch (action.payload.label) {
        case MEMBER.CREATE_MEMBER:
        case MEMBER.UPDATE_MEMBER_INFO:
        case MEMBER.GET_MEMBER_INFO:
        case HOUSEHOLD.UPDATE_PROPOSAL_ITEM_INFO:
        case HOUSEHOLD.CREATE_PROPOSAL_ITEM:
        case HOUSEHOLD.TAKE_PROPOSAL_ACTION:
          return {
            ...state,
            memberError: action.payload.error,
          };
        case MEMBER.CREATE_PROFESSIONAL:
        case MEMBER.UPDATE_PROFESSIONAL_INFO:
        case MEMBER.GET_PROFESSIONAL_INFO:
          return {
            ...state,
            professionalError: action.payload.error,
          };
        case MEMBER.GET_MEMBER_INTEGRATION_INFO:
        case MEMBER.LINK_MEMBER_FOLDER:
        case INSTRUMENT.LINK_INSTRUMENT_FOLDER:
          return {
            ...state,
            memberIntegrationError: action.payload,
          };
        case MEMBER.LOAD_MEMBER_FOLDER:
        case INSTRUMENT.LOAD_INSTRUMENT_FOLDER:
          return {
            ...state,
            integrationTabFolderError: action.payload,
          };
        default:
          return state;
      }
    default:
      return state;
  }
};

export default members;
