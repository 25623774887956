export const BASENAME = 'ampux';
export const CONSUMER_BASENAME = 'consumer';
export const CONSUMER_API = 'consumer';
export const APP_NAME = 'Asset-Map';
export const NOTIFICATION_INTERVAL = 120000;
export const SUPPORT_LINK_START =
  'https://usersupport.asset-map.com/start-here-fast';
export const CONSUMER_BETA_LINK = 'https://www.asset-map.com/consumer-beta';
export const SUPPORT_LINK = 'https://usersupport.asset-map.com/en/';
export const CONSUMER_SUPPORT_LINK =
  'https://intercom.help/asset-map-client-portal-support/en';
export const FEEDBACK = 'https://portal.prodpad.com/24499';
export const DRAFTS_SUPPORT =
  'https://usersupport.asset-map.com/en/articles/6909275-drafts';
export const CONSUMER_FEEDBACK =
  'https://asset-map.clickup.com/forms/37445812/f/13pr5m-18273/9Y087L7P5QGMKCOHFU';
export const SIDEBAR_FEEDBACK =
  'https://forms.clickup.com/37445812/f/13pr5m-26213/ZFQQ41O9KMSONR2XM3?Topic%20of%20feedback=sidebar';
export const REFER = 'https://www.asset-map.com/referral-program';
export const DISCOVERY_TUTORIAL =
  'https://usersupport.asset-map.com/en/articles/1183996-send-an-online-factfinder-interview';
export const STENCIL_LINK =
  'https://usersupport.asset-map.com/en/articles/797852-stencils';
export const SIGNALS_LINK =
  'https://usersupport.asset-map.com/en/articles/5541786-signals';
