/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { LoadingAnimation, TextEditor } from '..';
import { LoadingContainer } from '../../styles/library/layoutStyles';
import { chevronDownDark, noReports } from '../../assets';
import {
  CountLabel,
  EmptyContainer,
  ExpandableItem,
  ExpandedDetails,
  ExpandHeadingContainer,
  ExpandHeadingLabel,
  ExpandIcon,
  LabelText,
  NotFoundImage,
  NotFoundText,
} from '../../styles/library/sidebarStyles';
import { getHouseholdNotes } from '../../store/actions';
import { DateDiff, HtmlToString, TransformTargetMapType } from '../../utils';
import { colors, fonts } from '../../styles/variables';

const NotesSidebar = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const householdId = params?.id;
  const {
    currentHousehold,
    targetMaps,
    allMemberCategories,
    loadedHouseholdFields,
    householdNotes,
    loadedHouseholdNotes,
    isStencil,
    showTargetMaps,
    hasLegal,
    legalCategories,
  } = useSelector((state) => ({
    currentHousehold: state.households.currentHousehold,
    targetMaps: state.targetMaps.targetMaps,
    allMemberCategories: state.configs.allMemberCategories,
    loadedHouseholdFields: state.configs.loadedHouseholdFields,
    householdNotes: state.households.householdNotes,
    loadedHouseholdNotes: state.households.loadedHouseholdNotes,
    isStencil: state.households.isStencil,
    showTargetMaps: state.user.showTargetMaps,
    hasLegal: state.user.hasLegal,
    legalCategories: state.configs.legalCategories,
  }));
  const [loadingNotes, setLoadingNotes] = useState(true);
  const [currentNote, setCurrentNote] = useState();
  const [notesTypes, setNotesTypes] = useState([
    {
      value: 'member',
      group: 'allHouseholdMembers',
      label: 'Members',
      count: 0,
      isExpanded: false,
      children: [],
    },
    {
      value: 'professional',
      label: 'Professionals',
      group: 'householdprofessionals',
      // isHidden: true,
      count: 0,
      isExpanded: false,
      children: [],
    },
    {
      value: 'instrument',
      label: 'Financials',
      group: 'instruments',
      count: 0,
      isExpanded: false,
      children: [],
    },
    {
      value: 'legal',
      label: 'Legals',
      group: 'householdlegals',
      count: 0,
      isExpanded: false,
      isHidden: true,
      children: [],
    },
    {
      value: 'targetmap',
      label: 'Target-Maps',
      count: 0,
      isExpanded: false,
      isHidden: true,
      children: [],
    },
  ]);

  useEffect(() => {
    if (householdId) {
      dispatch(getHouseholdNotes(householdId));
    }
  }, [householdId]);

  const transformNotes = (notesList, key, groupKey) => {
    const updateNotes = notesList?.[key]?.reduce((acc, note) => {
      if (groupKey) {
        let matching = currentHousehold?.[groupKey]?.find(
          (item) => item.id === note.id
        );
        note.details = matching;
        if (matching && note?.content !== '') {
          if (key === 'professional') {
            note.details.name = matching?.business_name;
          }
          if (key === 'legal') {
            note.details.name = matching?.reference_name;
          }
          if (matching && !matching?.is_hidden) {
            return [...acc, note];
          }
        }
      } else if (key === 'targetmap') {
        const matching = targetMaps.find(
          (targetMap) => targetMap.id === note.id
        );
        note.details = matching;
        if (matching && note?.content !== '') {
          if (key === 'professional') {
            note.details.name = matching?.business_name;
          }
          if (key === 'legal') {
            note.details.name = matching?.reference_name;
          }
          if (matching && !matching?.is_hidden) {
            return [...acc, note];
          }
        }
      } else {
        return [...acc, note];
      }
      return acc;
    }, []);
    return updateNotes;
  };

  useEffect(() => {
    if (loadedHouseholdNotes && loadedHouseholdFields) {
      const updatedNotesType = notesTypes.map((noteType) => {
        const transformedNotes = transformNotes(
          householdNotes,
          noteType.value,
          noteType.group
        );
        noteType.children = transformedNotes;
        noteType.count = transformedNotes?.length;
        if (noteType.value === 'legal') {
          noteType.isHidden = !hasLegal;
        }
        if (noteType.value === 'legal') {
          noteType.isHidden = !hasLegal;
        }
        if (noteType.value === 'targetmap') {
          noteType.isHidden = !showTargetMaps;
        }
        return noteType;
      });
      setNotesTypes(updatedNotesType);
      setLoadingNotes(false);
    }
  }, [householdNotes, loadedHouseholdFields, loadedHouseholdNotes]);

  useEffect(() => {
    if (currentHousehold) {
      setCurrentNote(currentHousehold?.description);
    }
  }, [currentHousehold]);

  const onExpandNotes = (noteType) => {
    setNotesTypes(
      notesTypes.map((note) => {
        if (note.value === noteType) {
          note.isExpanded = !note.isExpanded;
        }
        return note;
      })
    );
  };

  const createAge = (note) => {
    if (note?.details) {
      if (note.details.member_type === 'member') {
        return (
          <MemberAge>{`(${
            note.details?.age
              ? note.details?.age
              : DateDiff(undefined, note.details?.date_of_birth)
          })`}</MemberAge>
        );
      }
    }
    return '';
  };

  const generateCategory = (noteType, note) => {
    let categoryValue = '';
    if (noteType.value === 'member' || noteType.value === 'professional') {
      categoryValue = note?.details?.category;
      if (allMemberCategories) {
        const matching = allMemberCategories.find(
          (cat) => cat.value === categoryValue
        );
        if (matching) {
          categoryValue = matching?.label;
        }
      }
    }
    if (noteType.value === 'instrument') {
      categoryValue = note?.details?.source;
    }
    if (noteType.value === 'legal') {
      categoryValue = legalCategories[note?.details?.category]?.name;
    }
    if (noteType.value === 'targetmap') {
      categoryValue = TransformTargetMapType(note.details?.type);
    }
    return categoryValue;
  };

  return (
    <>
      {loadingNotes ? (
        <LoadingContainer>
          <LoadingAnimation />
        </LoadingContainer>
      ) : isStencil ? (
        <EmptyContainer>
          <NotFoundImage
            src={noReports}
            alt="No Target-Maps"
            data-image="no-target-maps"
          />
          <NotFoundText>
            Notes are not available for Stencil Households
          </NotFoundText>
        </EmptyContainer>
      ) : (
        <>
          <EditNoteContainer>
            <TextEditor
              value={currentNote}
              onChange={(e) => setCurrentNote(e)}
              // error={input.hasError}
              id="sidebar"
              // style={{ width: input.width }}
              // showClear={input.showClear}
            />
            <SaveButton>Save</SaveButton>
          </EditNoteContainer>
          {notesTypes.map((noteType) => {
            return (
              !noteType.isHidden && (
                <ExpandableItem key={noteType.value}>
                  <ExpandHeadingContainer
                    onClick={() => onExpandNotes(noteType.value)}
                    $isExpanded={noteType.isExpanded}
                  >
                    <ExpandHeadingLabel>
                      <LabelText>{noteType.label}</LabelText>
                      <CountLabel>{noteType.count}</CountLabel>
                    </ExpandHeadingLabel>
                    <ExpandIcon
                      // onClick={() => onExpandNotes(noteType.value)}
                      $isExpanded={noteType.isExpanded}
                    >
                      <img src={chevronDownDark} alt="down" />
                    </ExpandIcon>
                  </ExpandHeadingContainer>
                  {noteType.isExpanded && (
                    <ExpandedDetails $padding="0">
                      {noteType?.children.length ? (
                        noteType?.children.map((note) => {
                          return (
                            <NoteContainer key={note.id}>
                              <NameContainer>
                                <Name>
                                  <span>
                                    {HtmlToString(note?.details?.name)}
                                  </span>
                                  {createAge(note)}
                                </Name>
                                <Category>
                                  {generateCategory(noteType, note)}
                                </Category>
                              </NameContainer>
                              <NoteValue
                                dangerouslySetInnerHTML={{
                                  __html: note.content,
                                }}
                              />
                            </NoteContainer>
                          );
                        })
                      ) : (
                        <NoteContainer>No Notes</NoteContainer>
                      )}
                    </ExpandedDetails>
                  )}
                </ExpandableItem>
              )
            );
          })}
        </>
      )}
    </>
  );
};

const EditNoteContainer = styled.div`
  position: relative;
  .editor-container-sidebar {
    .ql-toolbar.ql-snow {
      border-color: #f8d864;
      background: rgb(248, 216, 100);
      border-radius: 0px;
      /* border-radius: 2px 2px 0px 0px; */
    }
    .ql-container.ql-snow {
      border-color: #f8d864;
      border-radius: 0 0 5px 5px;
      border-color: transparent;
      background: #fffeee;
    }
  }
`;

const SaveButton = styled.button`
  ${'' /* background: #fae492; */}
  ${'' /* border: 1px solid #fffeee; */}
  border: 1px solid #fffeee;
  color: #fffeee;
  padding: 5px 10px;
  margin: 0px;
  position: absolute;
  right: 15px;
  top: 6.5px;
  border-radius: 5px;
  ${'' /* color: ${colors.darkGrey}; */}
  font-size: 12px;
  font-weight: ${fonts.semiBold};
  &:hover {
    border: 1px solid #fae492;
    background: #fae492;
  }
`;

const NoteContainer = styled.div`
  display: flex;
  align-content: center;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  padding: 20px;
  border-bottom: 1px solid ${colors.lightGrey};
`;

const NameContainer = styled.div`
  text-align: left;
  margin-bottom: 15px;
`;

const Name = styled.p`
  color: ${colors.darkGrey};
  overflow-wrap: break-word;
  font-size: 14px;
  font-weight: ${fonts.semiBold};
`;

const MemberAge = styled.span`
  margin-left: 5px;
  font-size: 12px;
  font-weight: ${fonts.light};
  color: ${colors.paleGrey};
`;

const Category = styled.p`
  color: ${colors.paleGrey};
  overflow-wrap: break-word;
  text-transform: capitalize;
  font-size: 12px;
`;

const NoteValue = styled.div`
  b,
  strong {
    font-weight: ${fonts.semiBold};
  }
  i {
    font-style: italic;
  }
  p {
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  a {
    color: ${colors.lightBlue};
    &:hover {
      color: ${colors.blue};
    }
  }
`;

export default NotesSidebar;
