import PropTypes from 'prop-types';
import { info } from '../assets';
import styled from 'styled-components';
import { colors, fonts } from '../styles/variables';

const InfoBox = ({ message, title, margin, fontSize }) => {
  return (
    <InfoBoxContainer margin={margin} fontSize={fontSize}>
      <InfoIcon
        src={info}
        alt="info"
        title={title ? title : 'Info'}
        data-image="info"
      />
      <p dangerouslySetInnerHTML={{ __html: message }} />
    </InfoBoxContainer>
  );
};

export const InfoBoxContainer = styled.div`
  border: 1px solid ${colors.lightGrey};
  background: ${colors.lighterGrey};
  padding: 10px 15px;
  border-radius: 3px;
  margin: ${(props) => (props.margin ? props.margin : '8px 0')};
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  p {
    color: ${colors.darkGrey};
    font-size: ${(props) => (props.fontSize ? props.fontSize : '12px')};
    flex: 1 1 auto;
    text-align: left;
    a {
      color: ${colors.lightBlue};
      font-weight: ${fonts.semiBold};
    }
  }
`;

const InfoIcon = styled.img`
  width: 15px;
  height: 15px;
  margin-right: 15px;
`;

InfoBox.propTypes = {
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  title: PropTypes.string,
  margin: PropTypes.string,
  fontSize: PropTypes.string,
};

export default InfoBox;
