/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { noReports } from '../../assets';
import {
  PageHeader,
  ShazamBar,
  SmallTable,
  LoadingAnimation,
  EmptyImage,
  PageFooter,
} from '../../components';
import { PageContent } from '../../styles/library/layoutStyles';
import {
  inputColors,
  colors,
  messageColors,
  fonts,
} from '../../styles/variables';
import {
  UpdatePageTitle,
  headerContent,
  DownloadReport,
  FormatCurrency,
  IsNegative,
  FilterInstruments,
  HtmlToString,
  SendTrackingEvent,
  allMembersOption,
} from '../../utils';

const HouseholdBalanceSheet = ({ isConsumer, canEdit = true, showPDF }) => {
  UpdatePageTitle('Balance Sheet');
  const {
    currentHousehold,
    members,
    instruments,
    currency,
    customLDD,
    isStencil,
    invocationId,
  } = useSelector((state) => ({
    currentHousehold: state.households.currentHousehold,
    members: state.households.currentHousehold.householdmembers,
    instruments: state.households.currentHousehold.instruments,
    currency: state.households.householdCurrency,
    customLDD: state.user.customLDD,
    isStencil: state.households.isStencil,
    invocationId: state.configs.invocationId,
  }));
  const [membersList, setMembersList] = useState([]);
  const [loadedMembers, setLoadedMembers] = useState(false);
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [assets, setAssets] = useState([]);
  const [retirementAssets, setRetirementAssets] = useState([]);
  const [showRetirementTable, setShowRetirementTable] = useState(false);
  const [nonRetirementAssets, setNonRetirementAssets] = useState([]);
  const [showNonRetirementTable, setShowNonRetirementTable] = useState(false);
  const [realEstateAssets, setRealEstateAssets] = useState([]);
  const [showRealEstateTable, setShowRealEstateTable] = useState(false);
  const [loadedAssets, setLoadedAssets] = useState(false);
  const [liabilities, setLiabilities] = useState([]);
  const [selectedLiabilities, setSelectedLiabilities] = useState([]);
  const [loadedLiabilities, setLoadedLiabilities] = useState(false);
  const [showLiabilitiesTable, setShowLiabilitiesTable] = useState(false);
  const [insurances, setInsurances] = useState([]);
  const [selectedInsurances, setSelectedInsurances] = useState([]);
  const [loadedInsurances, setLoadedInsurances] = useState(false);
  const [showInsuranceTable, setShowInsuranceTable] = useState(false);
  const [columnHeadings, setColumnHeadings] = useState([
    {
      text: 'Name',
      display: true,
      width: '12%',
      showTotal: true,
      value: 'name',
    },
    { text: 'Location', display: true, width: '13%' },
    { text: 'Data Collected', display: true, width: '12%' },
    {
      text: 'Total',
      value: 'total_balance',
      display: true,
      align: 'right',
      width: '100px',
      // minWidth: '100px',
      showTotal: true,
    },
  ]);
  const selectedCategories = ['primary', 'spouse', 'partner'];
  const insuranceCategories = ['lifeterm', 'lifeul', 'lifewhole', 'lifevul'];

  useEffect(() => {
    if (members) {
      const updated = members.map((member) => {
        member.name = HtmlToString(member.name);
        member.is_selected = false;
        if (selectedCategories.includes(member.category)) {
          member.is_selected = true;
        }
        return member;
      });
      allMembersOption.is_selected = false;
      setMembersList([allMembersOption, ...updated]);
      setLoadedMembers(true);
    }
  }, [members]);

  useEffect(() => {
    if (membersList) {
      let selectedMembersList = membersList.filter((member) => {
        return member.is_selected;
      });
      setSelectedMembers(selectedMembersList);
    }
  }, [membersList]);

  useEffect(() => {
    if (instruments) {
      const assets = FilterInstruments(instruments, 'asset');
      const updatedAssets = assets.filter(
        (asset) => !asset.is_expected && asset.status === 'current'
      );
      setAssets(updatedAssets);
      setLoadedAssets(true);

      const liabilities = FilterInstruments(instruments, 'liability');
      const updatedLiabilities = liabilities.filter(
        (liability) => liability.status === 'current'
      );
      setLiabilities(updatedLiabilities);
      if (updatedLiabilities.length !== 0) {
        setShowLiabilitiesTable(true);
      }
      setLoadedLiabilities(true);

      const insurances = FilterInstruments(instruments, 'insurance');
      const updatedInsurances = insurances.filter(
        (insurance) => insurance.cash_value && insurance.status === 'current'
      );

      setInsurances(updatedInsurances);
      if (updatedInsurances.length !== 0) {
        setShowInsuranceTable(true);
      }
      setLoadedInsurances(true);
      //If no matching insurances hide table
    }
  }, [instruments]);

  useEffect(() => {
    if (assets && assets.length !== 0 && selectedMembers) {
      const matched = [];

      assets.forEach((asset) => {
        asset.members.filter((member) => {
          if (
            selectedMembers.some(
              (mem) => mem.id === member.id && member.relation === 'owner'
            )
          ) {
            matched.push(asset);
          }
        });
      });

      const createdSet = new Set(matched);
      let assetList = [...createdSet];
      const retirementAssets = assets.filter(
        (asset) => asset.category === 'retirement'
      );
      if (retirementAssets.length !== 0) {
        setShowRetirementTable(true);
      }
      setRetirementAssets(
        assetList.filter((asset) => asset.category === 'retirement')
      );

      const nonRetirementAssets = assets.filter(
        (asset) => asset.category === 'nonretirement'
      );
      if (nonRetirementAssets.length !== 0) {
        setShowNonRetirementTable(true);
      }
      setNonRetirementAssets(
        assetList.filter((asset) => asset.category === 'nonretirement')
      );

      const realEstateAssets = assets.filter(
        (asset) => asset.category === 'residence'
      );
      if (realEstateAssets.length !== 0) {
        setShowRealEstateTable(true);
      }
      setRealEstateAssets(
        assetList.filter((asset) => asset.category === 'residence')
      );
    }
  }, [assets, selectedMembers]);

  useEffect(() => {
    if (liabilities && liabilities.length !== 0 && selectedMembers) {
      const matched = [];

      liabilities.forEach((liability) => {
        liability.members.filter((member) => {
          if (
            selectedMembers.some(
              (mem) => mem.id === member.id && member.relation === 'owner'
            )
          ) {
            matched.push(liability);
          }
        });
      });

      const createdSet = new Set(matched);
      setSelectedLiabilities([...createdSet]);
    }
  }, [liabilities, selectedMembers]);

  useEffect(() => {
    if (insurances && insurances.length !== 0 && selectedMembers) {
      const matched = [];
      insurances.forEach((insurance) => {
        if (insuranceCategories.some((cat) => cat === insurance.category)) {
          insurance.members.filter((member) => {
            if (
              selectedMembers.some(
                (mem) => mem.id === member.id && member.relation === 'owner'
              )
            ) {
              matched.push(insurance);
            }
          });
        }
      });

      const createdSet = new Set(matched);
      setSelectedInsurances([...createdSet]);
    }
  }, [insurances, selectedMembers]);

  useEffect(() => {
    if (
      loadedAssets &&
      loadedLiabilities &&
      loadedInsurances &&
      selectedMembers &&
      selectedMembers.length !== 0
    ) {
      const filteredMembers = selectedMembers.filter((mem) => mem.id !== 'all');
      const memberHeadings = filteredMembers.map((member) => {
        let headingText = member.name;
        if (member.member_type === 'member') {
          headingText = `${member.name} (${member.age})`;
        }
        return {
          text: headingText,
          value: 'member_balance',
          id: member.id,
          display: true,
          align: 'right',
          width: `${50 / filteredMembers.length}%`,
          showTotal: true,
          minWidth: '115px',
          padding: '0 5px 5px 8px',
        };
      });
      setColumnHeadings([
        {
          text: 'Name',
          value: 'name',
          display: true,
          width: '15%',
          showTotal: true,
          // minWidth: '100px',
        },
        {
          text: 'Location',
          display: true,
          width: '15%',
          //  minWidth: '100px'
        },
        {
          text: 'Data Collected',
          display: true,
          width: '12%',
          // minWidth: '100px',
        },
        ...memberHeadings,
        {
          text: 'Total',
          value: 'total_balance',
          display: true,
          align: 'right',
          width: '10%',
          // minWidth: '100px',
          showTotal: true,
        },
      ]);
    }
  }, [loadedAssets, loadedLiabilities, loadedInsurances, selectedMembers]);

  const selectMember = (clickedMember) => {
    if (clickedMember.id === 'all') {
      return setMembersList(
        membersList.map((member) => {
          if (member.id === clickedMember.id) {
            member.is_selected = !member.is_selected;
          } else {
            if (member.category === 'primary') {
              member.is_selected = true;
            } else {
              member.is_selected = clickedMember.is_selected;
            }
          }
          return member;
        })
      );
    }
    const pureMembers = selectedMembers.filter(
      (mem) => mem.member_type !== 'all'
    );
    if (pureMembers.length <= 1 && pureMembers.includes(clickedMember)) {
      return;
    }
    setMembersList(
      membersList.map((member) => {
        if (member.id === 'all') {
          member.is_selected = false;
        }
        if (member.id === clickedMember.id) {
          member.is_selected = !member.is_selected;
        }
        return member;
      })
    );
  };

  const tableHeaders = {
    retirementAssets: {
      heading: 'Retirement Assets',
      type: 'assets_balance',
    },
    nonRetirementAssets: {
      heading: 'Non-Retirement Assets',
      type: 'assets_balance',
    },
    realEstate: {
      heading: 'Property',
      type: 'assets_balance',
    },
    liabilities: {
      heading: 'Liabilities',
      type: 'liabilities_balance',
    },
    insurances: {
      heading: 'Insurance Cash Value',
      type: 'insurance_balance',
    },
  };

  const onClickPDF = () => {
    const children = selectedMembers.reduce((acc, curr) => {
      if (curr.id !== 'all') {
        return [
          ...acc,
          {
            id: curr.id,
            selected: true,
          },
        ];
      } else {
        return acc;
      }
    }, []);
    SendTrackingEvent(
      invocationId,
      'click',
      'create_pdf_report',
      'household_balance_sheet',
      {
        isConsumer: isConsumer,
      }
    );
    return DownloadReport({
      value: 'balancesheet',
      childValue: 'balancesheet_members',
      hasOptions: true,
      children,
    });
  };

  const generateMemberTotal = (instruments, memberId) => {
    const matchedInstruments = instruments.filter((instrument) => {
      let filteredMem = instrument.members.filter(
        (mem) => mem.relation === 'owner'
      );
      let hasMember = filteredMem.find((mem) => mem.id === memberId);
      if (hasMember) {
        instrument.matchedMem = hasMember;
        return instrument;
      }
    });
    let total = null;
    if (matchedInstruments && matchedInstruments.length !== 0) {
      total = matchedInstruments.reduce((acc, curr) => {
        const matchingMember = curr.members.filter(
          (mem) => mem.id === memberId
        );
        const matchingOwner = matchingMember.find(
          (mem) => mem.relation === 'owner'
        );
        if (curr.instrument_type !== 'insurance') {
          return acc + generateRatio(curr.amount, matchingOwner.ratio);
        } else {
          return acc + generateRatio(curr.cash_value, matchingOwner.ratio);
        }
      }, 0);
    }
    if (total !== null) {
      return Math.round(total);
    } else {
      return 0;
    }
  };

  const generateRatio = (amount, memberRatio, isJoint) => {
    let ratio = 1;
    isJoint ? (ratio = 1) : (ratio = memberRatio / 100);
    return Math.round(amount * ratio);
  };

  const getMembersRowTotal = (item, selectedMembers) => {
    return selectedMembers.reduce((acc, curr) => {
      const matchingMember = item.members.filter((mem) => mem.id === curr.id);
      // let matchedMember = item.members.find((member) => member.id === curr.id);
      if (matchingMember.length !== 0) {
        const matchingMemberRelations = matchingMember.map(
          (mem) => mem.relation
        );
        const ownerMember = matchingMember.find(
          (mem) => mem.relation === 'owner'
        );
        if (item.instrument_type === 'insurance') {
          if (matchingMemberRelations.includes('owner')) {
            return acc + generateRatio(item.cash_value, ownerMember.ratio);
          } else {
            return acc + 0;
          }
        } else {
          if (matchingMemberRelations.includes('owner')) {
            return acc + generateRatio(item.amount, ownerMember.ratio);
          } else {
            return acc + 0;
          }
        }
      } else {
        return acc + 0;
      }
    }, 0);
  };

  const generateBalanceTotal = (instruments, members) => {
    return instruments.reduce((acc, curr) => {
      return acc + getMembersRowTotal(curr, members);
    }, 0);
  };

  const generateMemberTotalBalance = (memberId) => {
    const retirementTotal = generateMemberTotal(retirementAssets, memberId);
    const nonRetirementTotal = generateMemberTotal(
      nonRetirementAssets,
      memberId
    );
    const realEstateTotal = generateMemberTotal(realEstateAssets, memberId);
    const insuranceTotal = generateMemberTotal(selectedInsurances, memberId);
    const liabilitiesTotal = generateMemberTotal(selectedLiabilities, memberId);
    const total =
      retirementTotal +
      nonRetirementTotal +
      realEstateTotal +
      insuranceTotal -
      liabilitiesTotal;
    return (
      <span style={{ color: IsNegative(total) ? messageColors.error : null }}>
        {FormatCurrency(total, currency)}
      </span>
    );
  };

  const generateTotalBalance = () => {
    const retirementTotal = generateBalanceTotal(
      retirementAssets,
      selectedMembers
    );
    const nonRetirementTotal = generateBalanceTotal(
      nonRetirementAssets,
      selectedMembers
    );
    const realEstateTotal = generateBalanceTotal(
      realEstateAssets,
      selectedMembers
    );
    const insuranceTotal = generateBalanceTotal(
      selectedInsurances,
      selectedMembers
    );
    const liabilitiesTotal = generateBalanceTotal(
      selectedLiabilities,
      selectedMembers
    );
    const total =
      retirementTotal +
      nonRetirementTotal +
      realEstateTotal +
      insuranceTotal -
      liabilitiesTotal;
    return (
      <span style={{ color: IsNegative(total) ? messageColors.error : null }}>
        {FormatCurrency(total, currency)}
      </span>
    );
  };

  const generateRowTotal = (heading) => {
    if (heading.value === 'name') {
      return 'Net Worth';
    }
    if (heading.value === 'total_balance') {
      return generateTotalBalance();
    }
    if (heading.value === 'member_balance') {
      return generateMemberTotalBalance(heading.id);
    }
    return heading.value;
  };

  return (
    <PageContainer>
      <PageHeader
        content={{
          ...headerContent.balanceSheet,
          showButton: !isStencil && showPDF,
        }}
        buttonFunction={onClickPDF}
      />
      {isStencil ? (
        <>
          <EmptyImage
            imageSrc={noReports}
            imageName="No Reports"
            text="Reports are not available for Stencil Households"
          />
        </>
      ) : (
        <PageContent>
          <ShazamBar
            items={membersList}
            onClick={selectMember}
            loaded={loadedMembers}
          />
          {!loadedAssets && !loadedLiabilities && !loadedInsurances && (
            <LoadingContainer>
              <LoadingAnimation />
            </LoadingContainer>
          )}
          <ContentContainer>
            {showRetirementTable && (
              <SmallTable
                tableHeading={tableHeaders.retirementAssets.heading}
                columnHeadings={columnHeadings}
                type={tableHeaders.retirementAssets.type}
                items={retirementAssets}
                loaded={loadedAssets}
                memberList={selectedMembers.filter((mem) => mem.id !== 'all')}
                showTotals={true}
                marginBottom={'15px'}
              />
            )}
            {showNonRetirementTable && (
              <SmallTable
                tableHeading={tableHeaders.nonRetirementAssets.heading}
                columnHeadings={columnHeadings}
                type={tableHeaders.nonRetirementAssets.type}
                items={nonRetirementAssets}
                loaded={loadedAssets}
                memberList={selectedMembers.filter((mem) => mem.id !== 'all')}
                showTotals={true}
                marginBottom={'15px'}
              />
            )}
            {showRealEstateTable && (
              <SmallTable
                tableHeading={tableHeaders.realEstate.heading}
                columnHeadings={columnHeadings}
                type={tableHeaders.realEstate.type}
                items={realEstateAssets}
                loaded={loadedAssets}
                memberList={selectedMembers.filter((mem) => mem.id !== 'all')}
                showTotals={true}
                marginBottom={'15px'}
              />
            )}
            {showLiabilitiesTable && (
              <SmallTable
                tableHeading={tableHeaders.liabilities.heading}
                columnHeadings={columnHeadings}
                type={tableHeaders.liabilities.type}
                items={selectedLiabilities}
                loaded={loadedLiabilities}
                memberList={selectedMembers.filter((mem) => mem.id !== 'all')}
                showTotals={true}
                negativeTotals={true}
                marginBottom={'15px'}
              />
            )}
            {showInsuranceTable && (
              <SmallTable
                tableHeading={tableHeaders.insurances.heading}
                columnHeadings={columnHeadings}
                type={tableHeaders.insurances.type}
                items={selectedInsurances}
                loaded={loadedInsurances}
                memberList={selectedMembers.filter((mem) => mem.id !== 'all')}
                showTotals={true}
                marginBottom={'15px'}
              />
            )}
            {loadedAssets &&
              !showRetirementTable &&
              !showNonRetirementTable &&
              !showRealEstateTable &&
              !showLiabilitiesTable &&
              !showInsuranceTable && (
                <EmptyState>
                  No matching financials. Modify or add new financials.
                </EmptyState>
              )}
            {loadedAssets && loadedLiabilities && loadedInsurances && (
              <TotalRow>
                <table>
                  <tbody>
                    <tr>
                      {columnHeadings.map((heading, index) => {
                        return (
                          <td
                            key={index}
                            style={{
                              width: heading.width,
                              minWidth: heading.minWidth,
                              textAlign: heading.align,
                              borderBottom: 'none',
                              fontWeight: fonts.semiBold,
                              padding: heading.padding,
                              fontStyle:
                                heading.value === 'name' ? 'italic' : null,
                              color:
                                heading.value === 'name'
                                  ? inputColors.label
                                  : null,
                            }}
                          >
                            {heading.showTotal && generateRowTotal(heading)}
                          </td>
                        );
                      })}
                    </tr>
                  </tbody>
                </table>
              </TotalRow>
            )}
          </ContentContainer>
          {isConsumer ? (
            <PageFooter footerValue={currentHousehold?.disclaimer} />
          ) : (
            customLDD?.disclaimer && (
              <PageFooter footerValue={customLDD?.disclaimer} />
            )
          )}
        </PageContent>
      )}
    </PageContainer>
  );
};

const PageContainer = styled.div`
  flex: 1 1 auto;
  overflow-y: auto;
`;

const ContentContainer = styled.div`
  margin: 15px 0 100px 0;
`;

const TotalRow = styled.div`
  border-top: 2px solid ${colors.darkGrey};
  margin-top: 10px;
  padding-top: 5px;
  table {
    width: 100%;
    tr {
      td {
        font-weight: ${fonts.semiBold};
        font-size: 14px;
      }
    }
  }
`;

const EmptyState = styled.div`
  text-align: center;
  background: ${colors.lighterGrey};
  padding: 40px 0;
  font-size: 14px;
`;

const LoadingContainer = styled.div`
  /* background: ${colors.lighterGrey}; */
  margin-top: 30px;
  padding: 50px 0;
  font-size: 14px;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
`;

HouseholdBalanceSheet.propTypes = {
  isConsumer: PropTypes.bool,
  canEdit: PropTypes.bool,
  showPDF: PropTypes.bool,
};

export default HouseholdBalanceSheet;
