/* eslint-disable react-hooks/exhaustive-deps */
import { useRef, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button, Select, Error, DetectModalKeys, TextInput, InfoBox } from '..';
import {
  getAssetMapProposals,
  getAssetMapProposal,
  createAssetMapProposal,
  toggleShowDeleteModal,
  updateHouseholdStoreValue,
} from '../../store/actions';
import {
  ModalBackground,
  ModalContainer,
  ModalOverlay,
  ModalContentContainer,
  ModalHeadingContainer,
  ModalHeading,
  CloseButton,
  TabContent,
  ButtonsRowContainer,
  ButtonContainer,
} from '../../styles/library/modalStyles';
import { timesWhite } from '../../assets';
import { AMRProposalsOption, DetectEnterKeyPress } from '../../utils';
import { ButtonThemes } from '../../styles/themes';
import { colors, fonts } from '../../styles/variables';

const ProposalsModal = ({ hide, householdId, isConsumer }) => {
  const ref = useRef();
  const dispatch = useDispatch();
  const {
    assetMapProposals,
    justDeletedProposal,
    assetMapProposalError,
    currentHousehold,
  } = useSelector((state) => ({
    assetMapProposals: state.households.assetMapProposals,
    justDeletedProposal: state.households.justDeletedProposal,
    assetMapProposalError: state.households.assetMapProposalError,
    currentHousehold: state.households.currentHousehold,
  }));
  const [loadingProposals, setLoadingProposals] = useState(true);
  const [proposalOptions, setProposalOptions] = useState([]);
  const [selectedProposal, setSelectedProposal] = useState();
  const [buttonLoading, setButtonLoading] = useState(false);
  const [createNewProposal, setCreateNewProposal] = useState(false);
  const [proposalDetails, setProposalDetails] = useState({
    name: '',
    description: '',
  });
  const [proposalError, setProposalError] = useState('');

  useEffect(() => {
    dispatch(getAssetMapProposals(householdId, isConsumer));
  }, []);

  useEffect(() => {
    if (assetMapProposals) {
      setLoadingProposals(false);
      const transformedProposals = assetMapProposals?.results.map(
        (proposal) => {
          proposal.label = proposal.name;
          proposal.value = proposal.id;
          return proposal;
        }
      );
      const sortedProposals = transformedProposals.sort(
        (a, b) => b.is_portal_household - a.is_portal_household
      );
      setProposalOptions(sortedProposals);
    }
  }, [assetMapProposals]);

  useEffect(() => {
    if (justDeletedProposal) {
      setSelectedProposal(null);
      dispatch(updateHouseholdStoreValue('justDeletedProposal', false));
    }
  }, [justDeletedProposal]);

  useEffect(() => {
    if (assetMapProposalError) {
      setButtonLoading(false);
      if (assetMapProposalError?.data?.status === 'maximum_proposals_reached') {
        return setProposalError(
          `Cannot create more than 10 drafts per household. Delete an existing draft to create a new one.`
        );
      }
      let action = 'creating';
      if (assetMapProposalError?.action) {
        action = assetMapProposalError?.action;
      }
      setProposalError(assetMapProposalError?.data || `Error ${action} draft.`);
    }
  }, [assetMapProposalError]);

  const applyProposal = () => {
    if (!buttonLoading) {
      dispatch(updateHouseholdStoreValue('localHousehold', currentHousehold));
      if (createNewProposal) {
        const { name, description } = proposalDetails;
        if (name === '' || name === null || name === undefined) {
          return setProposalError('Please provide a name for your draft.');
        }
        setProposalError('');
        const proposalObj = {
          name: name,
          description: description,
        };
        setButtonLoading(true);
        dispatch(createAssetMapProposal(householdId, proposalObj, isConsumer));
      } else {
        if (selectedProposal && selectedProposal?.value) {
          dispatch(getAssetMapProposal(householdId, selectedProposal?.value));
          setButtonLoading(true);
        } else {
          return setProposalError('Please select a draft.');
        }
      }
    }
  };

  const deleteProposal = (proposal) => {
    return dispatch(
      toggleShowDeleteModal(true, 'amrProposal', proposal.label, proposal.value)
    );
  };

  return ReactDOM.createPortal(
    <>
      <DetectModalKeys onEnter={applyProposal} onEsc={hide} />
      <ModalBackground />
      <ModalOverlay>
        <ModalContainer role="dialog" ref={ref}>
          <ModalHeadingContainer>
            <ModalHeading>Drafts</ModalHeading>
            <CloseButton
              src={timesWhite}
              onClick={() => hide()}
              alt="close"
              data-image="close"
            />
          </ModalHeadingContainer>
          <ModalContentContainer>
            <ModalText htmlFor="existing">
              <input
                type="radio"
                id="existing"
                name="proposal"
                value="existing"
                checked={!createNewProposal}
                onChange={() => {
                  setCreateNewProposal(false);
                  setProposalError('');
                }}
              />
              Select an existing Draft
            </ModalText>
            <TabContent>
              {!createNewProposal && (
                <SelectContainer>
                  <Select
                    selectedOption={selectedProposal}
                    onChange={(e) => {
                      setSelectedProposal(e);
                      setProposalError('');
                    }}
                    components={{
                      Option: (e) => AMRProposalsOption(e, deleteProposal),
                    }}
                    options={proposalOptions}
                    isSearchable={true}
                    placeholder={'Select a Draft'}
                    autoFocus={true}
                    isLoading={loadingProposals}
                    isDisabled={false}
                    hasError={proposalError !== ''}
                    closeMenuOnSelect={true}
                    onEnter={applyProposal}
                  />
                </SelectContainer>
              )}
            </TabContent>
            <SubText>OR</SubText>
            <ModalText htmlFor="new">
              <input
                type="radio"
                id="new"
                name="proposal"
                value="new"
                checked={createNewProposal}
                onChange={() => {
                  setCreateNewProposal(true);
                  setProposalError('');
                }}
              />
              Create new Draft
            </ModalText>
            {createNewProposal && (
              <>
                <TextInputContainer>
                  <TextInput
                    type={'text'}
                    name={'name'}
                    maxLength={'55'}
                    hasError={proposalError !== ''}
                    placeholder={'Draft Name'}
                    value={proposalDetails?.name}
                    onKeyPress={(e) => DetectEnterKeyPress(e, applyProposal)}
                    onChange={(e) => {
                      setProposalError('');
                      setProposalDetails({
                        ...proposalDetails,
                        name: e.currentTarget.value,
                      });
                    }}
                  />
                </TextInputContainer>
              </>
            )}
            {selectedProposal?.is_portal_household && (
              <InfoBox
                message={`When viewing a <strong>Client Portal Suggest</strong> draft you can only review suggested changes.`}
              />
            )}
            <ButtonsRowContainer>
              <ButtonContainer primaryButtonWidth="130px">
                <Button
                  text={'Cancel'}
                  onClick={() => hide()}
                  theme={ButtonThemes.cancel}
                />
                <Button
                  text={createNewProposal ? 'Create Draft' : 'Apply Draft'}
                  onClick={() => applyProposal()}
                  showLoading={buttonLoading}
                  loadingText={createNewProposal ? 'Creating' : 'Applying'}
                />
              </ButtonContainer>
            </ButtonsRowContainer>
          </ModalContentContainer>
          {proposalError && proposalError !== '' && (
            <Error errorMessage={proposalError} />
          )}
        </ModalContainer>
      </ModalOverlay>
    </>,
    document.body
  );
};

const SubText = styled.p`
  margin: -5px 0 5px 0;
  font-size: 12px;
  color: ${colors.paleGrey};
  font-weight: ${fonts.light};
`;

const ModalText = styled.label`
  margin-bottom: 5px;
  font-size: 12px;
  font-weight: ${fonts.semiBold};
  color: ${colors.darkGrey};
  text-align: left;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  input {
    margin: 0 8px 0 0;
  }
`;

const TextInputContainer = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 10px;
  input {
    font-size: 13px;
  }
`;

const SelectContainer = styled.div`
  margin-bottom: 10px;
  display: flex;
  text-align: left;
  flex-direction: column;
  width: 100%;
  div {
    div {
      div {
        font-size: 13px;
      }
    }
  }
`;

export const SelectName = styled.span`
  font-weight: ${fonts.semiBold};
  color: ${colors.darkGrey};
`;

export const SelectDescription = styled.span`
  font-weight: ${fonts.regular};
  margin-left: 8px;
  font-size: 11px;
  color: ${colors.paleGrey};
  max-width: 180px;
  white-space: nowrap;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
`;

ProposalsModal.propTypes = {
  hide: PropTypes.func,
  householdId: PropTypes.string,
  isConsumer: PropTypes.bool,
};

export default ProposalsModal;
