import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colors } from '../styles/variables';
import { legendItems } from '../utils';
import { useSelector } from 'react-redux';

const Legend = ({ trayWidth, context }) => {
  const { sideNavWidth } = useSelector((state) => ({
    sideNavWidth: state.user.sideNavWidth,
  }));

  return (
    <LegendContainer trayWidth={trayWidth} sideNavWidth={sideNavWidth}>
      {legendItems.map((item, index) => {
        return (
          item.context.includes(context) && (
            <LegendItem key={index}>
              <LegendBox
                style={{
                  background: item.background,
                  border: item.border,
                  borderRadius: item.borderRadius,
                }}
              />
              <LegendLabel>{item.label}</LegendLabel>
            </LegendItem>
          )
        );
      })}
    </LegendContainer>
  );
};

const LegendContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: ${(props) => props.sideNavWidth};
  width: ${(props) =>
    `calc(100% - ${props.sideNavWidth} - ${props.trayWidth} - 20px)`};
  background: white;
  padding: 10px 10px;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  align-items: center;
  justify-content: center;
  border-top: thin solid ${colors.lightGrey};
  z-index: 4;
`;

const LegendItem = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  align-items: center;
  justify-content: center;
  margin: 4px 8px;
`;

const LegendBox = styled.div`
  width: 12px;
  height: 12px;
`;

const LegendLabel = styled.p`
  font-size: 12px;
  margin-left: 6px;
`;

Legend.propTypes = {
  trayWidth: PropTypes.string,
  context: PropTypes.string,
};

export default Legend;
