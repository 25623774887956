/* eslint-disable react-hooks/exhaustive-deps */
import { useRef, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Error, LoadingAnimation, Button, InfoBox } from '..';
import { CreateAccountModal, VerifyCodeModal } from '../../containers';
import {
  declineSharingInvite,
  acceptSharingInvite,
  updateUserStoreValue,
} from '../../store/actions';
import { checkCircle, errorCircleRed, timesWhite } from '../../assets';
import {
  ModalBackground,
  ModalContainer,
  ModalOverlay,
  ModalContentContainer,
  ModalHeadingContainer,
  ModalHeading,
  CloseButton,
  ButtonsRowContainer,
  ButtonContainer,
  CenterModal,
  ModalHeader,
  ModalHeadingConfig,
} from '../../styles/library/modalStyles';
import { LinkText } from '../../styles/library/fontStyles';
import { LightBackgroundIcon } from '../../styles/library/imageStyles';
import { ButtonThemes } from '../../styles/themes';
import { colors, fonts, messageColors } from '../../styles/variables';
import { UpdatePageTitle } from '../../utils';

const InviteModal = ({
  hide,
  screen = 'select',
  setScreenValue,
  isAuth = false,
}) => {
  const ref = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    loggedIn,
    consumerInviteId,
    inviteDetails,
    inviteDetailsError,
    consumerInviteError,
  } = useSelector((state) => ({
    loggedIn: state.user.loggedIn,
    consumerInviteId: state.user.consumerInviteId,
    inviteDetails: state.user.inviteDetails,
    inviteDetailsError: state.user.inviteDetailsError,
    consumerInviteError: state.user.consumerInviteError,
  }));
  const [inviteContent, setInviteContent] = useState(false);
  const [isAccepting, setIsAccepting] = useState(false);
  const [showError, setShowError] = useState(false);
  const [code, setCode] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  //UPDATE PAGE TITLE DEPENDING ON MODAL DISPLAY
  UpdatePageTitle(
    screen === 'create' || screen === 'verify'
      ? 'Create Account'
      : 'Client Portal Invite'
  );

  useEffect(() => {
    //IF SHOWING AND HAS CODE IN QUERY PARAMS SET THAT VALUE LOCALLY
    const queryParams = new URLSearchParams(window.location.search);
    const code = queryParams.get('code');
    if (code) {
      setCode(code);
    }
    return () => {
      resetErrorDisplay();
      setScreenValue('loading');
      setIsAccepting(false);
    };
  }, []);

  //SET INVITE DETAILS LOCALLY AND DISPLAY SCREEN DEPENDING ON AUTH STATE
  useEffect(() => {
    if (inviteDetails) {
      setInviteContent(inviteDetails);
      if (loggedIn) {
        setScreenValue('accept');
      } else {
        setScreenValue('select');
      }
    }
  }, [inviteDetails]);

  //DISPLAY ERROR MODAL IF ERROR WITH INVITE DETAILS
  useEffect(() => {
    if (inviteDetailsError) {
      setScreenValue('error');
      setInviteContent({
        ...inviteContent,
        error: inviteDetailsError?.data?.status || 'not_found',
      });
    }
  }, [inviteDetailsError]);

  //DISPLAY ERROR IF ERROR ACCEPT/REJECT INVITE SHARE
  useEffect(() => {
    if (consumerInviteError) {
      setIsAccepting(false);
      let errorText;
      if (consumerInviteError?.statusText) {
        errorText = consumerInviteError?.statusText;
      } else if (consumerInviteError?.data) {
        errorText = consumerInviteError?.data;
      } else {
        errorText = 'Unknown Error';
      }
      setShowError(true);
      setErrorMessage(errorText);
      dispatch(updateUserStoreValue('consumerInviteError', null));
    }
  }, [consumerInviteError]);

  //FUNCTION TO RESET ERROR DISPLAY
  const resetErrorDisplay = () => {
    setShowError(false);
    setErrorMessage('');
  };

  //FUNCTION TO UPDATE MODAL HEADING
  const generateTitleValue = () => {
    if (screen === 'verify') {
      return 'Create Asset-Map Account';
    } else {
      return 'Client Portal Invite';
    }
  };

  //FUNCTION TO GENERATE ERROR ICON FOR ERROR DISPLAY
  const generateErrorIcon = (error) => {
    switch (error) {
      case 'expired':
      case 'not_found':
      case 'email_mismatch':
      case 'declined':
        return errorCircleRed;
      case 'redeemed':
      case 'accepted':
        return checkCircle;
      default:
        return errorCircleRed;
    }
  };

  //FUNCTION TO GENERATE ERROR MESSAGE FOR ERROR DISPLAY
  const generateErrorMessage = (error) => {
    switch (error) {
      case 'expired':
        return 'Invite has expired';
      case 'not_found':
        return 'Error fetching invite details';
      case 'redeemed':
        return 'Invite token already redeemed';
      case 'accepted':
        return 'Invite has already been accepted';
      case 'declined':
        return 'Invite has been rejected';
      case 'email_mismatch':
        return 'Your email does not match';
      default:
        return 'Unknown Error';
    }
  };

  //FUNCTION TO GENERATE ERROR MESSAGE DETAILS FOR ERROR DISPLAY
  const generateErrorMessageDetails = (error) => {
    switch (error) {
      case 'expired':
      case 'redeemed':
        return 'Please ask the sender to create a new link';
      case 'not_found':
        return 'Please check that you have the correct URL';
      case 'accepted':
        return 'This household has already been connected';
      case 'declined':
        return 'This household invite has been rejected';
      case 'email_mismatch':
        return 'The email on the invite does not match your email';
      default:
        return 'Something has gone wrong';
    }
  };

  //PLACEHOLDER COMPONENT FOR WHETHER DISPLAYING INVITE MODAL ON AUTH PAGE OR LOGGED IN
  const PlaceholderContainer = ({ children }) =>
    isAuth ? (
      <CenterModal role="dialog" ref={ref} width="375px">
        {children}
      </CenterModal>
    ) : (
      <ModalContainer role="dialog" ref={ref} $width="375px">
        {children}
      </ModalContainer>
    );

  PlaceholderContainer.propTypes = {
    children: PropTypes.array,
  };

  /*
    SIX MODAL UIS CAN BE DISPLAYED
    - Create Account (create) - render create account modal component
    - Verify Code (verify) - render verify code modal component
    - Loading (loading) - auto displays while getting API info
    - Error (error) - invite error display (not_found/expired/redeemed/accepted/declined/email_mismatch)
    - Accept/Reject (accept) - modal to accept/reject sharing of household
    - Create Account/Login option (select) - prompt user to login/sign up to accept household sharing
  */
  return screen === 'create' ? (
    <CreateAccountModal />
  ) : screen === 'verify' ? (
    <VerifyCodeModal code={code} />
  ) : (
    ReactDOM.createPortal(
      <>
        {!isAuth && <ModalBackground />}
        <ModalOverlay>
          <PlaceholderContainer>
            {isAuth ? (
              <ModalHeader>
                <ModalHeadingConfig>{generateTitleValue()}</ModalHeadingConfig>
              </ModalHeader>
            ) : (
              <ModalHeadingContainer>
                <ModalHeading>{generateTitleValue()}</ModalHeading>
                {!isAuth && (
                  <CloseButton
                    src={timesWhite}
                    onClick={() => hide()}
                    data-image="close"
                  />
                )}
              </ModalHeadingContainer>
            )}
            <ModalContentContainer>
              {screen === 'loading' && (
                <InfoContainer>
                  <LoadingAnimation smaller={true} />
                  <InfoMessage>Fetching Invite Info</InfoMessage>
                  <InfoDetails>Please wait...</InfoDetails>
                </InfoContainer>
              )}
              {screen === 'error' && (
                <InfoContainer>
                  <LightBackgroundIcon
                    backgroundColor={
                      inviteContent?.error === 'redeemed' ||
                      inviteContent?.error === 'accepted'
                        ? messageColors.successLabelBg
                        : messageColors.errorLabelBg
                    }
                  >
                    <img
                      src={generateErrorIcon(inviteContent?.error)}
                      alt="error"
                    />
                  </LightBackgroundIcon>
                  <InfoMessage>
                    {generateErrorMessage(inviteContent?.error)}
                  </InfoMessage>
                  <InfoDetails>
                    {generateErrorMessageDetails(inviteContent?.error)}
                  </InfoDetails>
                </InfoContainer>
              )}
              {screen === 'accept' && (
                <InfoContainer>
                  <HouseholdMessage>
                    <strong>{inviteContent?.advisor_name}</strong> wants to
                    share <br />
                    <strong>{inviteContent?.household}</strong> with you.
                  </HouseholdMessage>
                  <ButtonsRowContainer style={{ width: '100%' }}>
                    <ButtonContainer
                      flex="1 1 auto"
                      width="100%"
                      primaryButtonWidth="100%"
                    >
                      <Button
                        style={{ flex: '1 1 auto', width: '100%' }}
                        text={'Reject'}
                        onClick={() =>
                          dispatch(declineSharingInvite(consumerInviteId))
                        }
                        theme={ButtonThemes.error}
                      />
                      <Button
                        style={{ flex: '1 1 auto', width: '100%' }}
                        text={'Accept'}
                        showLoading={isAccepting}
                        loadingText={'Accepting'}
                        onClick={() => {
                          setIsAccepting(true);
                          dispatch(acceptSharingInvite(consumerInviteId));
                        }}
                      />
                    </ButtonContainer>
                  </ButtonsRowContainer>
                </InfoContainer>
              )}
              {screen === 'select' && (
                <InfoContainer>
                  <HouseholdMessage>
                    <strong>{inviteContent?.advisor_name}</strong> wants to
                    share <br />
                    <strong>{inviteContent?.household}</strong> with you.
                  </HouseholdMessage>
                  <InfoBox
                    message={`You must create an account or sign in in order to view this household.`}
                  />
                  <ButtonsRowContainer style={{ width: '100%' }}>
                    <Button
                      text={'Create Account'}
                      onClick={() => setScreenValue('create')}
                      theme={{ ...ButtonThemes.primary, width: '100%' }}
                    />
                  </ButtonsRowContainer>
                  <ExtraActions>
                    <ExtraMessage>
                      Already have an Asset-Map account?
                    </ExtraMessage>
                    <LinkText
                      onClick={() => navigate(`/auth/i/${consumerInviteId}`)}
                    >
                      Sign In
                    </LinkText>
                  </ExtraActions>
                </InfoContainer>
              )}
            </ModalContentContainer>
            {showError && <Error errorMessage={errorMessage} />}
          </PlaceholderContainer>
        </ModalOverlay>
      </>,
      document.body
    )
  );
};

const ExtraActions = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
  width: 100%;
  font-size: 11px;
  margin-top: 15px;
`;

const ExtraMessage = styled.div`
  flex: 1 1 auto;
  text-align: left;
`;

const InfoContainer = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
  flex-direction: column;
`;

const InfoMessage = styled.p`
  font-size: 18px;
  font-weight: ${fonts.bold};
  color: ${colors.darkGrey};
  margin: 10px 0 5px 0;
`;

const InfoDetails = styled.p`
  font-size: 14px;
  color: ${colors.paleGrey};
  margin-bottom: 10px;
`;

const HouseholdMessage = styled.p`
  font-size: 14px;
  margin-bottom: 10px;
  color: ${colors.darkGrey};
  text-align: center;
`;

InviteModal.propTypes = {
  hide: PropTypes.func,
  screen: PropTypes.string,
  setScreenValue: PropTypes.func,
  isAuth: PropTypes.bool,
  children: PropTypes.bool,
};

export default InviteModal;
